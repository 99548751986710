/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-console */
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import { useDispatch, useSelector } from 'react-redux'
import Carousel from 'react-material-ui-carousel'

import { getItemHistory } from '../LaundryDashboardActions'
import { dateFormatedFullDate } from '../../../util/utils'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  table: {
    padding: theme.spacing(2),
  },
  centerImage: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    height: 'auto',
    maxWidth: '100%',
    cursor: 'pointer',
  },
  containerImage: {
    padding: '16px',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

function Details(props) {
  const classes = useStyles()
  const { itemStatus, documents } = useSelector(
    state => state.laundryDashboard.itemHistoric
  )

  const modalRef = useRef()
  const modalImgRef = useRef()
  const captionTextRef = useRef()

  const clickImg = e => {
    modalRef.current.style.display = 'block'
    modalImgRef.current.src = e.target.src
    captionTextRef.current.innerHTML = e.target.alt
  }

  const closesModal = () => {
    modalRef.current.style.display = 'none'
  }

  const dispatch = useDispatch()

  useEffect(() => {
    const { id } = props.match.params
    dispatch(getItemHistory(id))
  }, [])

  const onClose = () => {
    props.history.goBack()
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={itemStatus !== null}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Histórico do item
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Paper elevation={3}>
          <Grid container spacing={1}>
            <Grid item xs={documents?.length ? 6 : 12}>
              <TableContainer className={classes.table}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Usuário</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Data</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {itemStatus?.map(item => (
                      <TableRow key={item.id}>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>{item.user.name}</TableCell>
                        <TableCell>{item.status.name}</TableCell>
                        <TableCell>
                          {dateFormatedFullDate(item.created_at)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {documents?.length > 0 && (
              <Grid item xs={6} style={{ padding: '16px' }}>
                <Carousel autoPlay={false} navButtonsAlwaysVisible>
                  {documents?.map((item, key) => (
                    <img
                      src={item.link}
                      alt={`Foto ${key + 1}`}
                      className={classes.centerImage}
                      onClick={clickImg}
                    />
                  ))}
                </Carousel>
              </Grid>
            )}
          </Grid>
          <>
            <div id="myModal" ref={modalRef} className="modal">
              <span className="close" onClick={closesModal}>
                &times;
              </span>

              <img className="modal-content" ref={modalImgRef} id="img01" />

              <div id="caption" ref={captionTextRef} />
            </div>
          </>
        </Paper>
      </Dialog>
    </div>
  )
}

export default React.memo(Details)
