import {
  EDIT_PAYMENT_VERIFICATION,
  FETCH_PAYMENT_VERIFICATION,
} from './PaymentVerificationActions'

const INITIAL_STATE = {
  items: [],
  users: [],
  filters: { status: 0 },
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  editingPaymentVerification: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PAYMENT_VERIFICATION.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        totalSize: action.payload.data.meta.total,
        page: action.payload.data.meta.current_page,
        sizePerPage: action.payload.data.meta.per_page,
        users: action.payload.data.users,
        filters: action.payload.filters,
      }
    case EDIT_PAYMENT_VERIFICATION.SUCCESS:
      return {
        ...state,
        editingPaymentVerification: action.payload,
      }
    default:
      return state
  }
}

export const getPaymentVerificationListData = state => {
  return {
    items: state.paymentVerification.items,
    page: state.paymentVerification.page,
    sizePerPage: state.paymentVerification.sizePerPage,
    totalSize: state.paymentVerification.totalSize,
    filters: state.paymentVerification.filters,
  }
}

export const getEditingServiceCat = state => {
  return state.paymentVerification.editingPaymentVerification
}
