import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@material-ui/core'
import React from 'react'
import { Line } from 'react-chartjs-2'
import { useSelector } from 'react-redux'

const buildData = data => {
  const labels = Object.entries(data)[0]
    ? Object.entries(data)[0][1]
        .map(({ period }) => period)
        .sort()
    : []
  return {
    labels: [...labels],
    datasets: [
      ...Object.entries(data).map(bank => {
        const R = Math.floor(Math.random() * 256)
        const G = Math.floor(Math.random() * 256)
        const B = Math.floor(Math.random() * 256)

        return {
          label: bank[0],
          data: bank[1].map(({ balance }) => Number(balance)).sort(),
          fill: true,
          backgroundColor: `rgba(${R}, ${G}, ${B}, 0.2)`,
          borderColor: `rgba(${R},${G},${B}, 1)`,
        }
      }),
    ],
  }
}

function Graph({ graph }) {
  const filters = useSelector(state => state.bankReport.filters)

  const getGraphTitle = filter => {
    switch (filter.graph) {
      case 'days':
        return 'Movimentações dos últimos 12 Dias'
      case 'months':
        return 'Movimentações dos últimos 12 Meses'
      default:
        return 'Movimentações dos últimos 12 Anos'
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs>
        <Card>
          <CardHeader title={getGraphTitle(filters)} />
          <Divider />
          <CardContent>
            <Box position="relative">
              <Line
                data={buildData(graph)}
                // width={100}
                height={400}
                options={{ maintainAspectRatio: false }}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Graph
