import React from 'react'
import filterStylesComponents from '../../../../../assets/filterComponentsStyles'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  TextField,
  TextareaAutosize,
  Typography,
} from '@material-ui/core'
import { closeLead, createDescription, transferLead } from '../../PanelActions'
import colors from '../../../../../assets/colors'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js'

const ModalCloseLead = ({
  open = false,
  handleOpen = () => {},
  leadId = '',
}) => {
  const filterStyles = filterStylesComponents()
  const dispatch = useDispatch()
  const history = useHistory()

  const handleSubmit = async e => {
    e.preventDefault()

    const formData = e.currentTarget
    const data = new FormData(formData)
    const entries = data.entries()
    const values = Object.fromEntries(entries)

    try {
      const resp = await dispatch(closeLead(leadId, values))

      if (resp) {
        history.push('/crm/panel')
      }
    } catch (e) {
      //
    }
  }

  return (
    <Dialog open={open} onClose={handleOpen} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography style={{ fontSize: '20px' }}>
              Bloquear Cliente
            </Typography>
          </Grid>

          <Grid item>
            <IconButton onClick={handleOpen}>
              <Icon path={mdiClose} size={1} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            name="close_reason"
            variant="standard"
            fullWidth
            label="Informe o motivo do bloqueio"
            type="text"
            required
          />
        </DialogContent>

        <DialogActions>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <Button
                type="submit"
                className={filterStyles.btnAdd}
                style={{ textTransform: 'none', backgroundColor: colors.error }}
              >
                Bloquear
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ModalCloseLead
