import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const REMOVE_BANK = createAsyncConst('REMOVE_BANK')
export const SUBMIT_BANK = createAsyncConst('SUBMIT_BANK')
export const FETCH_BANK = createAsyncConst('FETCH_BANK')
export const EDIT_BANK = createAsyncConst('EDIT_BANK')

export function fetchBank(filters = {}) {
  return dispatch => {
    dispatch(action(FETCH_BANK.ACTION))
    return axios
      .get(`${config.API_URL}/bank`, {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => {
        dispatch(action(FETCH_BANK.SUCCESS, { data: resp.data, filters }))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_BANK, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_BANK.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/bank/${id}`)
      .then(resp => {
        return resp.data.data
      })
      .then(data => dispatch(action(EDIT_BANK.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(EDIT_BANK, e)))
  }
}

function submit(values, method) {
  const id = values.id ? `/${values.id}` : ''

  return dispatch => {
    dispatch(action(SUBMIT_BANK.ACTION))
    return axios[method](`${config.API_URL}/bank${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_BANK.SUCCESS, data))
        dispatch(fetchBank())
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_BANK, e)))
  }
}

export function post(values) {
  return submit(values, 'post')
}

export function update(values) {
  return submit(values, 'put')
}

export function remove(id) {
  return dispatch => {
    dispatch(action(REMOVE_BANK.ACTION))
    return axios
      .delete(`${config.API_URL}/bank/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(REMOVE_BANK.SUCCESS, data))
        dispatch(fetchBank())
      })
      .catch(e => dispatch(actionFailed(REMOVE_BANK, e)))
  }
}
