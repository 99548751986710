import Grid from '@material-ui/core/Grid'
import React from 'react'

import Cartao from '../../components/Card'

export default function List(props) {
  const { items, role } = props

  return (
    <Grid container spacing={3}>
      {items.map(item => (
        <Grid key={String(item.id)} item xs={4}>
          <Cartao item={item} role={role} />
        </Grid>
      ))}
    </Grid>
  )
}
