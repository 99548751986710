import axios from 'axios'

import config from '../../config'
import consts from '../../util/consts'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const FETCH_TEAM_PRODUTIVITY_RELATORY_INIT = createAsyncConst(
  'FETCH_TEAM_PRODUTIVITY_RELATORY_INIT'
)
export const MAKE_TEAM_PRODUTIVITY_RELATORY = createAsyncConst(
  'MAKE_TEAM_PRODUTIVITY_RELATORY'
)
export const FETCH_TEAM_PRODUTIVITY_RELATORY_SUBLIST = createAsyncConst(
  'FETCH_TEAM_PRODUTIVITY_RELATORY_SUBLIST'
)

export function fetchTeamProdutivityRelatoryInit(
  page,
  filters = {},
  rows = consts.DEFAULT_PAGINATION
) {
  return dispatch => {
    dispatch(action(FETCH_TEAM_PRODUTIVITY_RELATORY_INIT.ACTION))
    return axios
      .get(`${config.API_URL}/relatory-team-produtivity`, {
        params: {
          filters: JSON.stringify(filters),
          rows: rows || consts.DEFAULT_PAGINATION,
          page: page || 1,
        },
      })
      .then(resp => {
        dispatch(
          action(FETCH_TEAM_PRODUTIVITY_RELATORY_INIT.SUCCESS, {
            data: resp.data,
            filters,
          })
        )
        return resp
      })
      .catch(e =>
        dispatch(actionFailed(FETCH_TEAM_PRODUTIVITY_RELATORY_INIT, e))
      )
  }
}

export function makeRelatory(
  data,
  url = 'make-relatory-team-produtivity',
  name = 'team_produtivity_report'
) {
  return dispatch => {
    return axios({
      url: `${config.API_URL}/${url}`,
      method: 'POST',
      responseType: 'blob',
      headers: { Accept: 'application/vnd.ms-excel' },
      params: {
        filters: JSON.stringify(data),
      },
    })
      .then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')

        link.href = url
        link.setAttribute('download', `${name}.xlsx`)
        document.body.appendChild(link)
        link.click()
        return resp
      })
      .catch(e => dispatch(actionFailed(MAKE_TEAM_PRODUTIVITY_RELATORY, e)))
  }
}

export function team(id, page, rows = consts.DEFAULT_PAGINATION, filters = {}) {
  return dispatch => {
    dispatch(action(FETCH_TEAM_PRODUTIVITY_RELATORY_SUBLIST.ACTION))
    return axios
      .get(`${config.API_URL}/relatory-team-produtivity/${id}`, {
        params: {
          rows: rows || consts.DEFAULT_PAGINATION,
          page: page || 1,
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => {
        dispatch(
          action(FETCH_TEAM_PRODUTIVITY_RELATORY_SUBLIST.SUCCESS, {
            data: resp.data,
          })
        )
        return resp
      })
      .catch(e =>
        dispatch(actionFailed(FETCH_TEAM_PRODUTIVITY_RELATORY_SUBLIST, e))
      )
  }
}

export function clearFilter() {
  return { type: 'CLEAR_ACTION_TEAM_PRODUTIVITY' }
}
