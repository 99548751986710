import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBackIos'
import EventIcon from '@material-ui/icons/Event'

import styles from '../../../resources/theme/global'
import { readyLaundry } from '../HomeActions'

import ReadyItemsFilter from './ReadyItemsLaundryFilter'

function ReadyItems(props) {
  const dispatch = useDispatch()
  const { classes } = props

  useEffect(() => {
    dispatch(readyLaundry())
  }, [])

  const items = useSelector(state => state.dashboard.readyItemsLaundry)

  const renderOsNumber = item => {
    return (
      <TableCell component={Link} to={`/laundry-os/${item.id}/details`}>
        {item.id}
      </TableCell>
    )
  }

  return (
    <>
      <Grid
        container
        className={classes.pageHead}
        spacing={1}
        alignItems="center"
      >
        <Grid item xs>
          <Typography variant="h4" color="secondary">
            Tapetes de lavanderia prontos para agendamento
          </Typography>
        </Grid>
        <Grid item>
          <ReadyItemsFilter classes={classes} />
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" component={Link} to="/">
            <ArrowBack className={classes.btnIcon} /> Voltar
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs>
          <Paper elevation={1}>
            <TableContainer className={classes.tableContainer}>
              <Table size="medium" className={classes.tableStyle}>
                <TableHead>
                  <TableRow>
                    <TableCell>Número da OS</TableCell>
                    <TableCell>Lavanderia</TableCell>
                    <TableCell>Items na empresa</TableCell>
                    <TableCell>Items aguardando agendamento</TableCell>
                    <TableCell>Items agendados</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items?.map(item => (
                    <TableRow key={item.id}>
                      {renderOsNumber(item)}
                      <TableCell>{item.client_name}</TableCell>
                      <TableCell>{item.total_items}</TableCell>
                      <TableCell>{item.ready_to_delivery_items}</TableCell>
                      <TableCell>{item.items_booked}</TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="calendar"
                          color="primary"
                          component={Link}
                          to={`/calendar/${item.id}`}
                        >
                          <EventIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles)(ReadyItems)
