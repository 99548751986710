import { FETCH_PROSPECTS_RELATORY_INIT } from './ProspectsRelatoryActions'

const INITIAL_STATE = {
  items: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  filters: [],
  services_type: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PROSPECTS_RELATORY_INIT.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        totalSize: action.payload.data.meta.total,
        page: action.payload.data.meta.current_page,
        sizePerPage: action.payload.data.meta.per_page,
        filters: action.payload.filters,
        services_type: action.payload.data.services_type
      }
    case "CLEAR_ACTION_PROSPECTS":
      return {
        ...state,
        filters: []
      }
    default:
      return state
  }
}

export const getProspectsRelatoryListData = state => {
  return {
    items: state.prospects_relatory.items,
    page: state.prospects_relatory.page,
    sizePerPage: state.prospects_relatory.sizePerPage,
    totalSize: state.prospects_relatory.totalSize,
    filters: state.prospects_relatory.filters
  }
}