import { Button, Grid } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route } from 'react-router'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import { useConfirm } from 'material-ui-confirm'

import styles from '../../../resources/theme/global'
import {
  exportOSsRelatory,
  clearFilters,
  closeAllLaundryOSs,
  fetchAttendanceLaundry,
} from '../AttendanceLaundryActions'
import { getAttendanceListData } from '../AttendanceLaundryReducer'
import { renderRoute } from '../../../util/utils'

import Billings from './ModalAttachBilling'
import Filter from './Filter'
import List from './List'
import Details from './Details'
import Card from './Card'
import ClientAddress from './ClientAddress'

function AttendanceLaundry(props) {
  const { classes } = props
  const dispatch = useDispatch()
  const confirm = useConfirm()

  const permissions = useSelector(state => state.auth.permissions)
  const filters = useSelector(state => state.attendanceLaundry.filters)
  const totals = useSelector(state => state.attendanceLaundry.totals)
  const today = useSelector(state => state.attendanceLaundry.today)
  const pagination = useSelector(state => getAttendanceListData(state))

  useEffect(() => {
    return () => dispatch(clearFilters())
  }, [])

  const confirmCloseAll = () => {
    confirm({
      description: 'Deseja fechar todas as OSs em aberto?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(async () => {
      const resp = await dispatch(closeAllLaundryOSs())
      if (resp.status === 200) {
        dispatch(
          fetchAttendanceLaundry(
            filters,
            pagination.sizePerPage,
            pagination.page
          )
        )
      }
    })
  }

  return (
    <>
      <Grid
        container
        className={classes.pageHead}
        spacing={1}
        alignItems="center"
      >
        <Grid item xs>
          <Typography variant="h4" color="secondary">
            OSs de Lavanderias
          </Typography>
        </Grid>
        <Grid item>
          <Filter classes={classes} />
        </Grid>
        <Grid item>
          <Button
            color="primary"
            type="button"
            onClick={() =>
              dispatch(exportOSsRelatory(filters, 'laundryService/os-report'))
            }
          >
            <NoteAddIcon className={classes.btnIcon} /> Relatório
          </Button>
        </Grid>
      </Grid>

      {renderRoute(['read-laundry-os'], permissions) && (
        <>
          <Route path="/laundry-os/:id/details" component={Details} />
          <Route path="/laundry-os/:id/tickets" component={Billings} />
        </>
      )}

      {renderRoute(['read-laundry-os-pannel'], permissions) &&
        Object.keys(totals).length !== 0 && (
          <Grid
            container
            spacing={1}
            className={classes.laundryOsCard}
            style={{ marginBottom: '20px' }}
          >
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Card
                total={totals.totalCarpets}
                tipo="Total tapetes"
                today={today.totalCarpets}
                decimals={0}
              />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <Card
                total={totals.totalM2}
                tipo="Total M2"
                today={today.totalM2}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Card
                total={totals.totalValue}
                tipo="Valor total"
                today={today.totalValue}
                isMoney
              />
            </Grid>
          </Grid>
        )}

      <Grid
        container
        spacing={1}
        style={{ marginBottom: '20px' }}
        justify="flex-end"
      >
        {renderRoute(['update-laundry-os'], permissions) && (
          <Grid item>
            <Button
              color="primary"
              type="button"
              onClick={() => confirmCloseAll()}
            >
              Fechar Todas
            </Button>
          </Grid>
        )}
      </Grid>

      <List {...pagination} />
    </>
  )
}

export default withStyles(styles)(AttendanceLaundry)
