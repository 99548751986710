import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const REMOVE_SERVICE_TYPE = createAsyncConst('REMOVE_SERVICE_TYPE')
export const SUBMIT_SERVICE_TYPE = createAsyncConst('SUBMIT_SERVICE_TYPE')
export const FETCH_SERVICE_TYPE = createAsyncConst('FETCH_SERVICE_TYPE')
export const EDIT_SERVICE_TYPE = createAsyncConst('EDIT_SERVICE_TYPE')
export const EXPORT_REPORT = createAsyncConst('EXPORT_REPORT')

export function fetchServiceType(filters = {}) {
  return dispatch => {
    dispatch(action(FETCH_SERVICE_TYPE.ACTION))
    return axios
      .get(`${config.API_URL}/service-type`, {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => {
        dispatch(
          action(FETCH_SERVICE_TYPE.SUCCESS, { data: resp.data, filters })
        )
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_SERVICE_TYPE, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_SERVICE_TYPE.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/service-type/${id}`)
      .then(resp => {
        return resp.data.data
      })
      .then(data => dispatch(action(EDIT_SERVICE_TYPE.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(EDIT_SERVICE_TYPE, e)))
  }
}

function submit(values, method) {
  const id = values.id ? `/${values.id}` : ''

  return dispatch => {
    dispatch(action(SUBMIT_SERVICE_TYPE.ACTION))
    return axios[method](`${config.API_URL}/service-type${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_SERVICE_TYPE.SUCCESS, data))
        dispatch(fetchServiceType())
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_SERVICE_TYPE, e)))
  }
}

export function post(values) {
  return submit(values, 'post')
}

export function update(values) {
  return submit(values, 'put')
}

export function remove(id) {
  return dispatch => {
    dispatch(action(REMOVE_SERVICE_TYPE.ACTION))
    return axios
      .delete(`${config.API_URL}/service-type/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(REMOVE_SERVICE_TYPE.SUCCESS, data))
        dispatch(fetchServiceType())
      })
      .catch(e => dispatch(actionFailed(REMOVE_SERVICE_TYPE, e)))
  }
}

export function makeRelatory(data) {
  return dispatch => {
    return axios({
      url: `${config.API_URL}/service-type/relatory`,
      method: 'POST',
      responseType: 'blob',
      headers: { Accept: 'application/vnd.ms-excel' },
      params: {
        filters: JSON.stringify(data),
      },
    })
      .then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')

        link.href = url
        link.setAttribute('download', 'relatótio-tipos-serviço.xlsx')
        document.body.appendChild(link)
        link.click()
        return resp
      })
      .catch(e => dispatch(actionFailed(EXPORT_REPORT, e)))
  }
}
