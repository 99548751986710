import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const REMOVE_COMPANY = createAsyncConst('REMOVE_COMPANY')
export const SUBMIT_COMPANY = createAsyncConst('SUBMIT_COMPANY')
export const FETCH_COMPANY = createAsyncConst('FETCH_COMPANY')
export const EDIT_COMPANY = createAsyncConst('EDIT_COMPANY')
export const MAKE_COMPANY_RELATORY = createAsyncConst('MAKE_COMPANY_RELATORY')

export function fetchCompany(filters = []) {
  return dispatch => {
    dispatch(action(FETCH_COMPANY.ACTION))
    return axios
      .get(`${config.API_URL}/company`, {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => {
        dispatch(action(FETCH_COMPANY.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_COMPANY, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_COMPANY.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/company/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(EDIT_COMPANY.SUCCESS, data))
        return data
      })
      .catch(e => dispatch(actionFailed(EDIT_COMPANY, e)))
  }
}

export function makeReport() {
  return dispatch => {
    return axios({
      url: `${config.API_URL}/company/makeReport`,
      method: 'GET',
      responseType: 'blob',
    })
      .then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')

        link.href = url
        link.setAttribute('download', 'company_report.xlsx')
        document.body.appendChild(link)
        link.click()
        return resp
      })
      .catch(e => dispatch(actionFailed(MAKE_COMPANY_RELATORY, e)))
  }
}

function submit(values, method) {
  const id = values.id ? `/${values.id}` : ''

  return dispatch => {
    dispatch(action(SUBMIT_COMPANY.ACTION))
    const formData = new FormData()
    values.name && formData.append('name', values.name)
    values.host && formData.append('host', values.host)
    values.port && formData.append('port', values.port)
    values.username && formData.append('username', values.username)
    values.password && formData.append('password', values.password)
    formData.append('hide_values', values.hide_values ? 1 : 0)
    formData.append('is_know_way_required', values.is_know_way_required ? 1 : 0)
    formData.append('is_email_required', values.is_email_required ? 1 : 0)
    values.services &&
      formData.append('services', JSON.stringify(values.services))
    values.users && formData.append('users', JSON.stringify(values.users))
    values.files.length &&
      Object.values(values.files).map(key => {
        formData.append(`files[]`, key)
      })
    values.orcamentoModel.length &&
      formData.append('orcamentoModel', values.orcamentoModel[0])

    return axios[method](`${config.API_URL}/company${id}`, formData)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_COMPANY.SUCCESS, data))
        dispatch(fetchCompany())
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_COMPANY, e)))
  }
}

export function post(values) {
  return submit(values, 'post')
}

export function update(values) {
  return submit(values, 'post')
}

export function remove(id) {
  return dispatch => {
    dispatch(action(REMOVE_COMPANY.ACTION))
    return axios
      .delete(`${config.API_URL}/company/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(REMOVE_COMPANY.SUCCESS, data))
        dispatch(fetchCompany())
      })
      .catch(e => actionFailed(REMOVE_COMPANY.FAILURE, e))
  }
}
