/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import {
  FormControlLabel,
  Grid,
  Paper,
  Slide,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@material-ui/icons/Search'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import { endOfDay, format, startOfDay } from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps'
import { useDispatch } from 'react-redux'

import config from '../../../config'
import styles from '../../../resources/theme/global'
import SelectField from '../../components/SelectField'
import {
  fetchAttendantsList,
  fetchMonitoringMap,
  exportMonitoring,
} from '../MonitoringAction'

import Infos from './Infos'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  link: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      fontSize: '1.1em',
      transitionDuration: '.5s',
    },
  },
}))

function checkIfIsADarkColor(color) {
  const c = color.substring(1)
  const rgb = parseInt(c, 16)
  const r = (rgb >> 16) & 0xff
  const g = (rgb >> 8) & 0xff
  const b = (rgb >> 0) & 0xff

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709

  if (luma < 40) {
    return true
  }

  return false
}

const myStyles = [
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
]

const svgMarker = (color, focus) => {
  let strokeWeight = 0
  let scale = 2
  let fillOpacity = 0.9
  if (focus === 2) {
    strokeWeight = 1
    scale = 2.2
    fillOpacity = 1
  } else if (focus === 1) {
    strokeWeight = 0
    scale = 1.8
    fillOpacity = 0.4
  }
  return {
    path: 'M 0 -7.984 M 0 -7.984 q 2.906 0 5 1.984 t 2 5 q 0 1.453 -0.727 3.328 t -1.758 3.516 t -2.039 3.07 t -1.711 2.273 l -0.75 0.797 q -0.281 -0.328 -0.75 -0.867 t -1.688 -2.156 t -2.133 -3.141 t -1.664 -3.445 t -0.75 -3.375 q 0 -2.906 2.039 -4.945 t 4.945 -2.039 z',
    fillColor: color,
    fillOpacity,
    strokeWeight,
    rotation: 0,
    scale,
  }
}

const checkColor = color => {
  if (color[0] !== '#') {
    return `#${color}`
  }
  return color
}

const MapWithAMakredInfoWindow = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      options={{
        styles: myStyles,
      }}
      defaultZoom={5}
      zoom={props.markers.length ? 15 : 5}
      defaultCenter={{
        lat: parseFloat(props.latitude),
        lng: parseFloat(props.longitude),
      }}
      ref={props.onMapMounted}
      onCenterChanged={props.onCenterChanged}
      center={props.center}
      onClick={props.onMapClick}
    >
      {props.markers.map((marker, index) => {
        if (marker.visible === false) return

        let focus = 0
        if (props.selected) focus = props.selected == marker.id ? 2 : 1

        return (
          <Marker
            icon={svgMarker(checkColor(marker.color), focus)}
            label={{
              text: (index + 1).toString(),
              color: checkIfIsADarkColor(marker.color.toString())
                ? '#FFFFFF'
                : '#000000',
              fontWeight: 'bold',
            }}
            key={marker.id}
            position={{
              lat: parseFloat(marker.latitude),
              lng: parseFloat(marker.longitude),
            }}
            onClick={() => props.onClickMarker(marker)}
          >
            {marker.id === props.selected && (
              <InfoWindow onCloseClick={() => props.onClickMarker(marker.id)}>
                <Infos marker={marker} />
              </InfoWindow>
            )}
          </Marker>
        )
      })}
    </GoogleMap>
  ))
)

function AttendantMonitoring({ classes }) {
  const customStyle = useStyles()
  const dispatch = useDispatch()
  const [currentAttendant, setCurrentAttendant] = useState(null)
  const [center, setCenter] = useState({
    lat: -19.94279463607278,
    lng: -43.98043241969808,
  })
  const [attendants, setAttendants] = useState([])
  const [collapse, setCollapse] = useState(false)
  const [mapref, setMapRef] = useState(null)
  const [startDate, setStartDate] = useState(
    format(startOfDay(new Date()), "yyyy-MM-dd'T'HH:mm")
  )
  const [endDate, setEndDate] = useState(
    format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm")
  )
  const [selected, setSelected] = useState()

  const [markers, setMarkers] = useState([])

  const updateField = (name, value) => {
    setCurrentAttendant(value)
  }

  const handleOnLoad = map => {
    setMapRef(map)
  }

  const handleCenterChanged = () => {
    if (mapref) {
      const newCenter = mapref.getCenter()
      setCenter({
        lat: newCenter.lat(),
        lng: newCenter.lng(),
      })
    }
  }

  const handleChangeCollapse = useCallback(() => {
    setCollapse(prev => !prev)
  })

  useEffect(() => {
    setSelected(null)
    dispatch(fetchAttendantsList()).then(resp =>
      setAttendants(resp.data.attendants)
    )
  }, [])

  const fetchMarkers = () => {
    const filters = {
      attendantId: currentAttendant?.id,
      startDate,
      endDate,
    }
    setSelected(null)
    if (currentAttendant) {
      dispatch(fetchMonitoringMap(filters))
        .then(resp => {
          if (!resp) return false
          setMarkers([
            ...resp.data.data.map(item => ({ ...item, visible: true })),
          ])
          return resp.data.data
        })
        .then(mark => {
          setCenter({
            lat: parseFloat(mark[0]?.latitude) || -19.94279463607278,
            lng: parseFloat(mark[0]?.longitude) || -43.98043241969808,
          })
        })
    }
  }

  useEffect(() => {
    window.Echo.channel(`AttendantMonitoring`).listen(`NewPosition`, event => {
      const { userId } = event

      if (userId === currentAttendant?.id) {
        fetchMarkers()
      }
    })

    return () => window.Echo.leaveChannel(`AttendantMonitoring`)
  }, [currentAttendant])

  const exportRelatory = () => {
    const filters = {
      attendantId: currentAttendant?.id,
      startDate,
      endDate,
    }

    if (currentAttendant) {
      dispatch(exportMonitoring(filters))
    }
  }

  const onClickMarker = marker => {
    if (marker?.latitude && marker?.longitude) {
      setCenter({
        lat: parseFloat(marker.latitude),
        lng: parseFloat(marker.longitude),
      })
    }

    if (marker?.id !== selected) {
      setSelected(marker.id)
    } else {
      setSelected(null)
    }
  }

  const changeMarkerVisibility = itemId => {
    setMarkers(
      markers.map(marker => {
        if (marker.id === itemId) {
          return {
            ...marker,
            visible: !marker.visible,
          }
        }
        return marker
      })
    )
  }

  const onClickSetCenter = item => {
    setCenter({
      lat: parseFloat(item.latitude),
      lng: parseFloat(item.longitude),
    })
  }

  const Row = ({ item, number }) => {
    return (
      <TableRow className={classes.root}>
        <TableCell>
          <Switch
            checked={item.visible}
            onChange={() => changeMarkerVisibility(item.id)}
            style={{
              color: `#${item.color}`,
            }}
          />
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          onClick={() => onClickSetCenter(item)}
          className={customStyle.link}
        >
          {number}
        </TableCell>
        <TableCell align="right">{item.latitude}</TableCell>
        <TableCell align="right">{item.longitude}</TableCell>
      </TableRow>
    )
  }

  return (
    <>
      <Grid
        container
        className={classes.pageHead}
        spacing={1}
        alignItems="center"
      >
        <Grid item xs>
          <Typography variant="h4" color="secondary">
            Monitoramento de Atendentes
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <FormControlLabel
            control={
              <Switch checked={collapse} onChange={handleChangeCollapse} />
            }
            label="Mostrar tabela"
            color="primary"
          />
        </Grid>
        <Grid item xs={3}>
          <SelectField
            options={attendants}
            setForm={updateField}
            data={currentAttendant}
            name="status"
            margin="dense"
            variant="outlined"
            label="Atendente"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            id="datetime-local"
            label="De"
            type="datetime-local"
            variant="outlined"
            margin="dense"
            size="small"
            defaultValue={startDate}
            className={customStyle.textField}
            onChange={event => setStartDate(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            fullWidth
            id="datetime-local"
            label="Até"
            type="datetime-local"
            variant="outlined"
            margin="dense"
            size="small"
            defaultValue={endDate}
            className={customStyle.textField}
            onChange={event => setEndDate(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Button
          color="primary"
          variant="contained"
          onClick={() => fetchMarkers()}
        >
          <SearchIcon className={classes.btnIcon} /> Filtrar
        </Button>
        <Grid item>
          <Button
            color="primary"
            type="button"
            onClick={() => exportRelatory()}
          >
            <NoteAddIcon className={classes.btnIcon} /> Relatório
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center">
        {collapse ? (
          <Grid item xs={3}>
            <Slide
              direction="right"
              in={collapse}
              timeout={350}
              style={{ height: '89vh', overflow: 'auto' }}
            >
              <Paper className={classes.paper} elevation={2}>
                <TableContainer className={classes.table}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Número</TableCell>
                        <TableCell align="right">Lat</TableCell>
                        <TableCell align="right">Long</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {markers.map((row, key) => (
                        <Row item={row} number={key + 1} key={row.id} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Slide>
          </Grid>
        ) : (
          <div>
            <span />
          </div>
        )}
        <Grid item xs style={{ position: 'relative', height: '89vh' }}>
          <MapWithAMakredInfoWindow
            onMapMounted={handleOnLoad}
            onCenterChanged={handleCenterChanged}
            markers={markers}
            selected={selected}
            center={center}
            onClickMarker={onClickMarker}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.GOOGLE_KEY}&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: '100%' }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles)(AttendantMonitoring)
