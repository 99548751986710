import React from 'react'

import { remove } from '../ServiceTypeActions'
import GlobalList from '../../components/GlobalList'
import { exportFormatedMoney } from '../../../util/utils'

function createData(id, name, value, time, category, status, tipo) {
  return {
    id,
    name,
    value,
    time,
    category,
    status,
    tipo,
    update: 'update-service-types',
    delete: 'delete-service-types',
    link_update: `/service-type/${id}/edit`,
  }
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'value',
    numeric: true,
    disablePadding: false,
    label: 'Valor Padrão',
  },
  {
    id: 'time',
    numeric: true,
    disablePadding: false,
    label: 'Tempo padrão',
  },
  {
    id: 'category',
    numeric: true,
    disablePadding: false,
    label: 'Categoria',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'tipo',
    numeric: false,
    disablePadding: false,
    label: 'Tipo',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Ações',
  },
]

function List(props) {
  const { items } = props
  const rows = []

  items.map(item => {
    return rows.push(
      createData(
        item.id,
        item.name,
        exportFormatedMoney(item.default_value),
        item.execution_time,
        item.serviceCategory?.name,
        item.status_text,
        item.attendanceType_text
      )
    )
  })

  return (
    <GlobalList
      {...props}
      headCells={headCells}
      rows={rows}
      remove={remove}
      orderField="id"
    />
  )
}

export default List
