import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Paper,
  Slide,
  TextField,
} from '@material-ui/core'
/* eslint-disable import/order */
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { Field, FieldArray, Form, Formik } from 'formik'
import { Switch, TextField as TextFieldOr } from 'formik-material-ui'
import { Autocomplete } from 'formik-material-ui-lab'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AddIcon from '@material-ui/icons/Add'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'

import styles from '../../../resources/theme/global'
import {
  generatePayment,
  getAttendanceComments,
  getPaymentInformation,
} from '../AttendanceActions'
import NumberFormatCustom from '../../components/NumberFormat'
import { KeyboardDatePicker } from 'formik-material-ui-pickers'
import { format } from 'date-fns'
import { exportFormatedMoney } from '../../../util/utils'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function PaymentDetails(props) {
  const { classes } = props
  const dispatch = useDispatch()
  const [comments, setComments] = useState('')
  const [paymentInformation, setPaymentInformation] = useState('')

  const paymentMethods = useSelector(state => state.attendance.paymentMethods)
  const banks = useSelector(state => state.attendance.banks)

  const onSubmit = values => {
    const { id } = props.match.params
    dispatch(generatePayment(id, values)).then(data => {
      if (data && data.code === 200) {
        props.history.push('/attendance')
      }
    })
  }

  useEffect(() => {
    async function init() {
      const { id } = props.match.params
      const resp = await dispatch(getAttendanceComments(id))
      setComments(resp?.data)

      const fetchPaymentInformation = await dispatch(getPaymentInformation(id))
      setPaymentInformation(fetchPaymentInformation?.data)
    }

    init()
  }, [])

  const onClose = () => {
    props.history.goBack()
  }

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Forma de pagamento
          </Typography>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Formik
          initialValues={{
            payment_details: '',
            billings: [
              {
                paymentMethod: null,
                value: null,
                bank: null,
                paid: false,
                payment_date: null,
              },
            ],
          }}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false)
            const data = {
              ...values,
            }
            data.billings.map(item => {
              item.payment_date = item.payment_date
                ? format(new Date(item.payment_date), 'yyyy-MM-dd')
                : null

              return item
            })

            onSubmit && onSubmit(data)
          }}
        >
          {({ submitForm, isSubmitting, values, handleChange }) => (
            <Form>
              <Paper elevation={5}>
                <FieldArray name="billings">
                  {({ push, remove }) => (
                    <div className={classes.laundryTechniciansContainer}>
                      <Grid container spacing={1}>
                        <Grid item xs>
                          <Typography color="primary" variant="h6">
                            {`Recebimento - OS: ${
                              paymentInformation.id || ''
                            }  - Cliente: ${
                              paymentInformation.client_name || ''
                            } - Valor Total: ${exportFormatedMoney(
                              paymentInformation.value || ''
                            )}`}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            onClick={() =>
                              push({
                                paymentMethod: null,
                                value: null,
                                bank: null,
                                paid: false,
                                payment_date: null,
                              })
                            }
                          >
                            <AddIcon />
                          </Button>
                        </Grid>
                      </Grid>
                      {values.billings?.map((item, index) => (
                        <Box key={String(index)}>
                          <Grid
                            container
                            spacing={1}
                            style={{ margin: '10px 0px' }}
                          >
                            <Grid item xs={3} style={{ alignSelf: 'flex-end' }}>
                              <Field
                                name={`billings[${index}].paymentMethod`}
                                component={Autocomplete}
                                options={paymentMethods}
                                getOptionSelected={(option, value) =>
                                  option.id === value.id
                                }
                                getOptionLabel={option => option.name}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Método de pagamento"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Field name={`billings[${index}].value`}>
                                {({ field }) => (
                                  <TextField
                                    {...field}
                                    label="Valor"
                                    onChange={handleChange(
                                      `billings[${index}].value`
                                    )}
                                    fullWidth
                                    InputProps={{
                                      inputComponent: NumberFormatCustom,
                                    }}
                                  />
                                )}
                              </Field>
                            </Grid>

                            <Grid item xs={2}>
                              <Field
                                component={KeyboardDatePicker}
                                name={`billings[${index}].payment_date`}
                                clearable
                                clearLabel="Limpar"
                                label="Data do pagamento"
                                autoOk
                                format="dd/MM/yyyy"
                              />
                            </Grid>

                            <Grid item xs={2}>
                              <Field
                                name={`billings[${index}].bank`}
                                component={Autocomplete}
                                options={banks}
                                getOptionSelected={(option, value) =>
                                  option.id === value.id
                                }
                                getOptionLabel={option => option.name}
                                renderInput={params => (
                                  <TextField {...params} label="Banco" />
                                )}
                              />
                            </Grid>

                            <Grid item xs={2} style={{ alignSelf: 'flex-end' }}>
                              <FormControlLabel
                                control={
                                  <Field
                                    component={Switch}
                                    type="checkbox"
                                    name={`billings[${index}].paid`}
                                  />
                                }
                                label="Pagamento já compensado?"
                              />
                            </Grid>

                            <Grid item xs style={{ alignSelf: 'flex-end' }}>
                              <Button
                                color="inherit"
                                onClick={() => remove(index)}
                                aria-label="Close"
                              >
                                <RemoveCircleOutlineIcon />
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                    </div>
                  )}
                </FieldArray>
              </Paper>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Field
                    name="payment_details"
                    component={TextFieldOr}
                    variant="outlined"
                    placeholder="Descrição"
                    fullWidth
                    multiline
                    rows={5}
                    rowsMax={10}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    placeholder="Comentários do Técnico..."
                    fullWidth
                    multiline
                    disabled
                    value={comments}
                    rows={5}
                    rowsMax={10}
                  />
                </Grid>
              </Grid>
              <DialogActions>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Salvar
                </Button>
                <Button onClick={onClose}>Cancelar</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(PaymentDetails)
