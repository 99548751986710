/* eslint-disable react/destructuring-assignment */
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import queryString from 'query-string'

import consts from '../../../util/consts'
import Paginate from '../../components/Pagination'
import { exportFormatedMoney } from '../../../util/utils'
import { fetchSchedulesRelatoryInit } from '../SchedulesRelatoryActions'

function List(props) {
  const dispatch = useDispatch()
  const filters = useSelector(state => state.schedules_relatory.filters)
  const [rowsPerPage, setRowsPerPage] = useState(consts.DEFAULT_PAGINATION)
  const { classes, items, totalSize, page } = props

  const onMovePage = (event, newPage) => {
    const values = queryString.parse(props.location.search)

    // eslint-disable-next-line no-unused-vars
    const filtersLeads = {
      type: 'dashboardLeads',
      status: values?.status,
      filters: {
        date_start: values?.startDate,
        date_end: values?.finalDate,
        attendance: values?.attendance,
        laundry: values?.laundry,
      },
    }

    if (values?.status) {
      dispatch(
        fetchSchedulesRelatoryInit(newPage + 1, filtersLeads, rowsPerPage)
      )
    } else {
      dispatch(fetchSchedulesRelatoryInit(newPage + 1, filters, rowsPerPage))
    }
  }

  useEffect(() => {
    onMovePage()
  }, [rowsPerPage])

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  return (
    <Paper>
      <TableContainer
        className={classNames({
          [classes.tableContainer]: true,
          [classes.tableStickHeader]: true,
        })}
      >
        <Table stickyHeader size="medium" className={classes.tableStyle}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>Hora</TableCell>
              <TableCell>Equipe</TableCell>
              <TableCell>Atendente</TableCell>
              <TableCell>Empresa</TableCell>
              <TableCell>Tipo de serviço</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Etiqueta</TableCell>
              <TableCell>Telefone</TableCell>
              <TableCell>Endereço</TableCell>
              <TableCell>Bairro</TableCell>
              <TableCell>Cidade</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Total sem Desconto</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(item => (
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.date}</TableCell>
                <TableCell>{item.hour}</TableCell>
                <TableCell>{item.attendant_name}</TableCell>
                <TableCell>{item.seller_name}</TableCell>
                <TableCell>{item.company_name}</TableCell>
                <TableCell>{item.service_type_name}</TableCell>
                <TableCell>{item.client_name}</TableCell>
                <TableCell>{item.bar_code}</TableCell>
                <TableCell>{item.phone}</TableCell>
                <TableCell>{item.address}</TableCell>
                <TableCell>{item.neighborhood}</TableCell>
                <TableCell>{item.city}</TableCell>
                <TableCell>{item.type}</TableCell>
                <TableCell>{exportFormatedMoney(item.total)}</TableCell>
                <TableCell>
                  {exportFormatedMoney(item.total_sem_desconto || 0)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginate
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        rows={totalSize}
        page={page - 1}
        handleChangePage={onMovePage}
      />
    </Paper>
  )
}

export default List
