import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import styles from '../../../resources/theme/global'
import { renderRoute } from '../../../util/utils'
import { fetchServiceCategory } from '../ServiceCategoryActions'
import { getServiceCatListData } from '../ServiceCategoryReducer'

import Edit from './Edit'
import Filter from './Filter'
import List from './List'
import New from './New'

class ServiceCategory extends Component {
  componentDidMount() {
    this.props.fetchServiceCategory()
  }

  render() {
    const { classes, permissions } = this.props

    return (
      <>
        <Grid
          container
          className={classes.pageHead}
          spacing={1}
          alignItems="center"
        >
          <Grid item xs>
            <Typography variant="h4" color="secondary">
              Categorias de Serviço
            </Typography>
          </Grid>
          <Grid item>
            <Filter classes={classes} />
          </Grid>
          <Grid item>
            {renderRoute(['create-users'], permissions) && (
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to="/service-category/new"
              >
                <AddCircleOutlineIcon className={classes.btnIcon} /> Cadastrar
              </Button>
            )}
          </Grid>
        </Grid>

        {renderRoute(['create-service-categories'], permissions) && (
          <Route path="/service-category/new" component={New} />
        )}
        {renderRoute(['update-service-categories'], permissions) && (
          <Route path="/service-category/:id/edit" component={Edit} />
        )}

        <List {...this.props} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...getServiceCatListData(state),
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchServiceCategory,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ServiceCategory))
