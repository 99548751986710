import axios from 'axios/index'

import createAsyncConst from '../../../util/createAsyncConst'
import action, { actionFailed } from '../../../util/action'
import config from '../../../config'

// export const REMOVE_BANK = createAsyncConst('REMOVE_BANK')
export const SUBMIT_LOSS_REASON = createAsyncConst('SUBMIT_LOSS_REASON')
export const EDIT_LOSS_REASON = createAsyncConst('EDIT_LOSS_REASON')
export const FETCH_LOSS_REASON = createAsyncConst('FETCH_LOSS_REASON')

export function fetchLossReason(filters = {}) {
  return dispatch => {
    dispatch(action(FETCH_LOSS_REASON.ACTION))
    return axios
      .get(`${config.API_URL}/loss-reason`, {
        params: { ...filters },
      })
      .then(resp => {
        dispatch(
          action(FETCH_LOSS_REASON.SUCCESS, { data: resp.data, filters })
        )
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_LOSS_REASON, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_LOSS_REASON.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/loss-reason/${id}`)
      .then(resp => {
        return resp.data.data
      })
      .then(data => dispatch(action(EDIT_LOSS_REASON.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(EDIT_LOSS_REASON, e)))
  }
}

export function update(values, id) {
  return dispatch => {
    dispatch(action(SUBMIT_LOSS_REASON.ACTION))
    return axios
      .put(`${config.API_URL}/loss-reason/${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_LOSS_REASON.SUCCESS, data))
        dispatch(fetchLossReason())
        return true
      })
      .catch(e => dispatch(actionFailed(SUBMIT_LOSS_REASON, e)))
  }
}

export function create(values) {
  return dispatch => {
    dispatch(action(SUBMIT_LOSS_REASON.ACTION))
    return axios
      .post(`${config.API_URL}/loss-reason`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_LOSS_REASON.SUCCESS, data))
        dispatch(fetchLossReason())
        return true
      })
      .catch(e => dispatch(actionFailed(SUBMIT_LOSS_REASON, e)))
  }
}
