/* eslint-disable react/prop-types */
import { MenuItem } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { endOfDay, format, startOfDay } from 'date-fns'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useState } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
import { fetchBanks } from '../BankReportActions'

export default function Filter(props) {
  const items = useSelector(state => state.bankReport.items)

  const dispatch = useDispatch()
  const { classes, allBillingTypes } = props
  const [form, setForm] = useState({
    date: null,
    date_to: null,
    graph: 'months',
    billTypeFilter: null,
    paymentDateStart: null,
    paymentDateEnd: null,
  })

  const onSubmit = e => {
    e.preventDefault()
    const date = form.date
      ? format(startOfDay(new Date(form.date)), 'yyyy-MM-dd HH:mm:ss')
      : null
    const dateTo = form.date_to
      ? format(endOfDay(new Date(form.date_to)), 'yyyy-MM-dd HH:mm:ss')
      : null
    const paymentDateStart = form.paymentDateStart
      ? format(
          startOfDay(new Date(form.paymentDateStart)),
          'yyyy-MM-dd HH:mm:ss'
        )
      : null
    const paymentDateEnd = form.paymentDateEnd
      ? format(startOfDay(new Date(form.paymentDateEnd)), 'yyyy-MM-dd HH:mm:ss')
      : null
    const data = {
      ...form,
      date,
      date_to: dateTo,
      paymentDateStart,
      paymentDateEnd,
    }

    dispatch(fetchBanks(data))
  }
  const data = Array.from(allBillingTypes)

  return (
    <Tooltip title="Filter list">
      <PopupState variant="popover" popupId="demo-popup-popover">
        {popupState => (
          <div>
            <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
              <BadgeGlobal form={form} items={items}>
                <FilterListIcon />
              </BadgeGlobal>
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2} className={classes.popover}>
                <div className={classes.root}>
                  <ValidatorForm className={classes.form} onSubmit={onSubmit}>
                    <Grid
                      container
                      className={classes.gridContainer}
                      spacing={1}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item sm={12} xs={12} lg={6}>
                        <KeyboardDatePicker
                          clearable
                          value={form.date}
                          label="Data do vencimento inicial"
                          fullWidth
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          margin="dense"
                          inputVariant="outlined"
                          onChange={date => setForm({ ...form, date })}
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <KeyboardDatePicker
                          clearable
                          value={form.date_to}
                          label="Data do vencimento final"
                          fullWidth
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          margin="dense"
                          inputVariant="outlined"
                          onChange={date_to => setForm({ ...form, date_to })}
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <KeyboardDatePicker
                          clearable
                          value={form.paymentDateStart}
                          label="Data de pagamento inicial"
                          fullWidth
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          margin="dense"
                          inputVariant="outlined"
                          onChange={paymentDateStart =>
                            setForm({
                              ...form,
                              paymentDateStart,
                            })
                          }
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <KeyboardDatePicker
                          clearable
                          value={form.paymentDateEnd}
                          label="Data de pagamento final"
                          fullWidth
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          margin="dense"
                          inputVariant="outlined"
                          onChange={paymentDateEnd =>
                            setForm({
                              ...form,
                              paymentDateEnd,
                            })
                          }
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item sm={12} xs={12} ls={6}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, graph: e.target.value })
                          }
                          select
                          label="Gráfico"
                          margin="dense"
                          variant="outlined"
                          className={classes.inputFilter}
                          fullWidth
                          value={form.graph}
                        >
                          <MenuItem key={0} value="days">
                            Últimos 12 dias
                          </MenuItem>
                          <MenuItem key={1} value="months">
                            Últimos 12 Meses
                          </MenuItem>
                          <MenuItem key={2} value="years">
                            Últimos 12 Anos
                          </MenuItem>
                        </TextField>
                      </Grid>

                      <Grid item sm={12} xs={12} ls={6}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, billTypeFilter: e.target.value })
                          }
                          select
                          label="Tipo de Conta"
                          margin="dense"
                          variant="outlined"
                          className={classes.inputFilter}
                          fullWidth
                          // options= {allBillingTypes}
                          value={form.billTypeFilter}
                        >
                          <MenuItem key="" value="">
                            Selecione...
                          </MenuItem>
                          {data?.map(billing_type => (
                            <MenuItem
                              key={billing_type.id}
                              value={billing_type.id}
                            >
                              {billing_type.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs>
                        <Button
                          type="submit"
                          color="primary"
                          fullWidth
                          variant="contained"
                          className={classes.submit}
                          onClick={popupState.close}
                        >
                          <SearchIcon />
                          {` Filtrar`}
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                </div>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </Tooltip>
  )
}
