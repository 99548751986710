import React, { useState } from 'react'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { DialogContent, Grid, Button, DialogActions } from '@material-ui/core'

function FormDuplicate(props) {
  const { onClose } = props
  const [form, setForm] = useState({
    name: '',
    display_name: '',
    description: '',
  })

  const onSubmit = () => {
    props.onSubmit && props.onSubmit(form)
  }

  return (
    <ValidatorForm onSubmit={onSubmit}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs>
            <TextValidator
              validators={['required']}
              errorMessages={['Campo Obrigatório']}
              onChange={e => setForm({ ...form, name: e.target.value })}
              label="Nome"
              fullWidth
              value={form.name}
            />
          </Grid>

          <Grid item xs>
            <TextValidator
              validators={['required']}
              errorMessages={['Campo Obrigatório']}
              onChange={e => setForm({ ...form, display_name: e.target.value })}
              label="Nome a ser exibido"
              fullWidth
              value={form.display_name}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs>
            <TextValidator
              onChange={e => setForm({ ...form, description: e.target.value })}
              label="Descrição"
              fullWidth
              value={form.description}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" type="submit">
          Criar
        </Button>
        <Button onClick={onClose}>Cancelar</Button>
      </DialogActions>
    </ValidatorForm>
  )
}

export default FormDuplicate
