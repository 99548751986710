import axios from 'axios'

import config from '../../config'
import consts from '../../util/consts'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const FETCH_SERVICES_RELATORY_INIT = createAsyncConst('FETCH_SERVICES_RELATORY_INIT')
export const MAKE_SERVICES_RELATORY = createAsyncConst('MAKE_SERVICES_RELATORY')

export function fetchServicesRelatoryInit(page, filters = [], rows = consts.DEFAULT_PAGINATION) {
  return dispatch => {
    dispatch(action(FETCH_SERVICES_RELATORY_INIT.ACTION))
    return axios
      .get(`${config.API_URL}/relatory-services`, {
        params: {
          filters: JSON.stringify(filters),
          rows: rows || consts.DEFAULT_PAGINATION,
          page: page || 1,
        },
      })
      .then(resp => {
        dispatch(action(FETCH_SERVICES_RELATORY_INIT.SUCCESS, { data: resp.data, filters: filters }))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_SERVICES_RELATORY_INIT, e)))
  }
}

export function makeRelatory(data) {
  return dispatch => {
    return axios({
      url:
        `${config.API_URL}/make-relatory-services`,
        method: 'POST',
        responseType: 'blob',
        headers: { Accept: 'application/vnd.ms-excel' },
        params: {
          filters: JSON.stringify(data),
        }
      })
      .then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        
        link.href = url
        link.setAttribute('download', 'services_report.xlsx')
        document.body.appendChild(link)
        link.click()
        return resp
      })
    .catch(e => dispatch(actionFailed(MAKE_SERVICES_RELATORY, e)))
  }
}

export function clearFilter() {
  return { type: "CLEAR_ACTION_SERVICES" }
}