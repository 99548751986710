import {
  Box,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SyncIcon from '@material-ui/icons/Sync'
import classNames from 'classnames'
import Divider from '@material-ui/core/Divider'

import { fetchFinancialDashboard } from '../FinancialDashboardActions'
import styles from '../../../resources/theme/global'

import Card from './Card'
import Pagination from './Pagination'
import BankCard from './BankCard'
import ReceiptCard from './ReceiptCard'
import SummaryCard from './SummaryCard'

function FinancialDashboard(props) {
  const { classes } = props
  const [date, setDate] = useState(new Date())
  const refreshing = useSelector(state => state.financialDashboard.refreshing)
  const data = useSelector(state => state.financialDashboard)

  const dispatch = useDispatch()

  useEffect(() => {
    const filters = { month: date.toLocaleDateString('en-us') }
    dispatch(fetchFinancialDashboard(filters))
  }, [])

  const refresh = () => {
    const filters = { month: date.toLocaleDateString('en-us') }
    dispatch(fetchFinancialDashboard(filters))
  }

  const onClickNext = () => {
    setDate(prevDate => new Date(prevDate.setMonth(prevDate.getMonth() + 1)))
    const filters = { month: date.toLocaleDateString('en-us') }
    dispatch(fetchFinancialDashboard(filters))
  }

  const onClickPrev = () => {
    setDate(prevDate => new Date(prevDate.setMonth(prevDate.getMonth() - 1)))
    const filters = { month: date.toLocaleDateString('en-us') }
    dispatch(fetchFinancialDashboard(filters))
  }

  const today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
  const yyyy = today.getFullYear()

  const yesterday = new Date(new Date() - 86400000)
  const dd_y = String(yesterday.getDate()).padStart(2, '0')
  const mm_y = String(yesterday.getMonth() + 1).padStart(2, '0') // January is 0!
  const yyyy_y = yesterday.getFullYear()

  const dateNow = `${yyyy}-${mm}-${dd}`
  const dateLate = `${yyyy_y}-${mm_y}-${dd_y}`
  const dateEndMonth = `${yyyy}-${mm}-31` // tratado no backend se mês não tiver 31 dias

  const LAUNDRY_LINK = `/billing?billing_type=2&status=1&billing_type_generic=Receita&`
  const CLIENT_LINK = `/billing?billing_type=1&billing_type_generic=Receita&status=1`
  const TOTAL_LINK = `/billing?status=1&billing_type_generic=Receita&`
  const PAYMENTS_LINK = `/billing?status=1&billing_type_generic=Despesa&`

  return (
    <>
      <Grid
        container
        className={classes.pageHead}
        spacing={1}
        alignItems="center"
      >
        <Grid item xs>
          <Typography variant="h4" color="secondary">
            Dashboard Financeira
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip title="Atualizar">
            <IconButton color="secondary" onClick={refresh}>
              <SyncIcon
                className={classNames({
                  [classes.btnRefreshing]: refreshing,
                })}
              />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Container maxWidth="md">
        <Box paddingBottom={0} paddingTop={2}>
          <Pagination
            elevation={2}
            nextPage={() => onClickNext()}
            prevPage={() => onClickPrev()}
            classes={classes}
            text={data?.currentMonth}
            disabled={refreshing}
          />
        </Box>
      </Container>

      <Grid container spacing={1} className={classes.financialCard}>
        <Grid item xs={12} md={6} lg={4}>
          <Card
            total={data?.totalDespesas}
            tipo="Despesas"
            today={data?.despesasToday}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Card
            total={data?.totalReceitas}
            tipo="Receitas"
            positive
            today={data?.receitasToday}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Card
            total={data?.balance}
            tipo="Balanço"
            positive={data?.balance >= 0}
            today={data?.balanceToday}
            isBalanceCard
          />
        </Grid>
      </Grid>

      <Divider variant="middle" style={{ margin: '50px' }} />

      <Grid
        container
        xs
        justify="center"
        style={{ marginTop: '80px', marginBottom: '80px' }}
      >
        <Typography item variant="h4" color="secondary">
          Resumo Mês Atual
        </Typography>
      </Grid>

      <Grid container spacing={1} className={classes.financialCard}>
        <Grid item xs={12} md={6} lg={4}>
          <SummaryCard
            total={data?.banksTotal + data?.receiptsTotal}
            tipo="Saldo Bancário + Recebimentos"
            positive
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SummaryCard
            total={data?.paymentsTotal}
            tipo="Pagamentos"
            positive={false}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SummaryCard
            total={data?.banksTotal + data?.receiptsTotal - data?.paymentsTotal}
            tipo="Balanço Geral"
            positive={
              data?.banksTotal + data?.receiptsTotal - data?.paymentsTotal >= 0
            }
          />
        </Grid>
      </Grid>

      <Divider variant="middle" style={{ margin: '50px' }} />

      <Grid item xs>
        <Typography variant="h5" color="secondary">
          Saldos
        </Typography>
      </Grid>
      <Grid
        container
        spacing={1}
        justify="center"
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: '40px' }}
      >
        <Grid
          container
          spacing={1}
          justify="center"
          justifyContent="center"
          alignItems="center"
          className={classes.financialCard}
          xs={9}
        >
          {data.banksBalance?.map(item => {
            return (
              <Grid item xs={4}>
                <BankCard
                  total={item.balance}
                  tipo={item.name}
                  positive={item.balance >= 0}
                />
              </Grid>
            )
          })}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        justify="center"
        alignItems="center"
        style={{ marginBottom: '40px' }}
      >
        <Grid
          container
          spacing={1}
          justify="flex-end"
          alignItems="center"
          className={classes.financialCard}
          xs={9}
        >
          <Grid item xs={12}>
            <BankCard
              total={data?.banksTotal}
              tipo="Total"
              positive={data?.banksTotal >= 0}
              justRight
            />
          </Grid>
        </Grid>
      </Grid>

      <Divider variant="middle" style={{ margin: '50px' }} />

      <Grid item xs>
        <Typography variant="h5" color="secondary">
          Recebimentos
        </Typography>
      </Grid>

      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12} md={6} lg={9} style={{ marginBottom: '20px' }}>
          <ReceiptCard
            total={data?.balance}
            tipo="Lavanderia"
            positive
            val1={data?.laundryLate}
            val2={data?.laundryDue}
            val3={data?.laundryTotal}
            link1={`${LAUNDRY_LINK}date_end=${dateLate}`}
            link2={`${LAUNDRY_LINK}date=${dateNow}&date_end=${dateEndMonth}`}
            link3={`${LAUNDRY_LINK}date_end=${dateEndMonth}`}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={9} style={{ marginBottom: '20px' }}>
          <ReceiptCard
            total={data?.balance}
            tipo="Clientes"
            positive
            val1={data?.clientLate}
            val2={data?.clientDue}
            val3={data?.clientTotal}
            link1={`${CLIENT_LINK}&date_end=${dateLate}`}
            link2={`${CLIENT_LINK}&date=${dateNow}&date_end=${dateEndMonth}`}
            link3={`${CLIENT_LINK}&date_end=${dateEndMonth}`}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={9} style={{ marginBottom: '20px' }}>
          <ReceiptCard
            total={data?.balance}
            tipo="Total"
            positive
            val1={data?.lateTotal}
            val2={data?.dueTotal}
            val3={data?.receiptsTotal}
            link1={`${TOTAL_LINK}date_end=${dateLate}`}
            link2={`${TOTAL_LINK}date=${dateNow}&date_end=${dateEndMonth}`}
            link3={`${TOTAL_LINK}date_end=${dateEndMonth}`}
          />
        </Grid>
      </Grid>

      <Divider variant="middle" style={{ margin: '50px' }} />

      <Grid item xs>
        <Typography variant="h5" color="secondary">
          Pagamentos
        </Typography>
      </Grid>

      <Grid
        container
        spacing={1}
        justify="center"
        alignItems="center"
        className={classes.financialCard}
      >
        <Grid item xs={12} md={6} lg={9} style={{ marginBottom: '20px' }}>
          <ReceiptCard
            total={data?.balance}
            tipo=""
            positive={false}
            val1={data?.paymentsLate}
            val2={data?.paymentsDue}
            val3={data?.paymentsTotal}
            link1={`${PAYMENTS_LINK}date_end=${dateLate}`}
            link2={`${PAYMENTS_LINK}date=${dateNow}&date_end=${dateEndMonth}`}
            link3={`${PAYMENTS_LINK}date_end=${dateEndMonth}`}
          />
        </Grid>
      </Grid>

      <Divider variant="middle" style={{ margin: '50px' }} />
    </>
  )
}

export default withStyles(styles)(FinancialDashboard)
