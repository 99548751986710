import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import styles from '../../../resources/theme/global'
import { renderRoute } from '../../../util/utils'
import { fetchKnowWay } from '../KnowWayActions'
import { getKnowWayListData } from '../KnowWayReducer'


import List from './List'
import New from './New'
import Edit from './Edit'

class KnowWay extends Component {
  componentDidMount() {
    this.props.fetchKnowWay()
  }
  

  render() {
    const { classes, permissions } = this.props

    return (
      <>
        <Grid
          container
          className={classes.pageHead}
          spacing={1}
          alignItems="center"
        >
          <Grid item xs>
            <Typography variant="h4" color="secondary">
              Como conheceu
            </Typography>
          </Grid>

          <Grid item>
            {renderRoute(['create-know-way'], permissions) && (
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to="/knowWay/new"
              >
                <AddCircleOutlineIcon className={classes.btnIcon} /> Cadastrar
              </Button>
            )}
          </Grid>
        </Grid>

        {renderRoute(['create-know-way'], permissions) && (
          <Route path="/knowWay/New" component={New} />
        )}
        {renderRoute(['update-know-way'], permissions) && (
          <Route path="/knowWay/:id/edit" component={Edit} />
        )}

        <List {...this.props} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...getKnowWayListData(state),
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchKnowWay,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(KnowWay))
