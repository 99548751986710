import { FormControlLabel, Grid, Switch } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import NoteAddIcon from '@material-ui/icons/NoteAdd'

import styles from '../../../resources/theme/global'
import { renderRoute } from '../../../util/utils'
import { fetchUsers, makeReport } from '../UserActions'
import { getUserListData } from '../UserReducer'
import EditUser from './Edit'
import Filter from './Filter'
import List from './List'
import NewUser from './New'

class User extends Component {
  componentDidMount() {
    this.props.fetchUsers()
  }

  render() {
    const { classes, items, permissions, makeReport, filters } = this.props

    return (
      <>
        <Grid
          container
          className={classes.pageHead}
          spacing={1}
          alignItems="center"
        >
          <Grid item xs>
            <Typography variant="h4" color="secondary">
              Usuários
            </Typography>
          </Grid>
          <Grid item>
            <Filter classes={classes} />
          </Grid>
          <Grid item>
            <Button
              color="primary"
              type="button"
              onClick={() => makeReport(filters)}
            >
              <NoteAddIcon className={classes.btnIcon} /> Relatório
            </Button>
          </Grid>
          <Grid item>
            {renderRoute(['create-users'], permissions) && (
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to="/users/new"
              >
                <AddCircleOutlineIcon className={classes.btnIcon} /> Cadastrar
              </Button>
            )}
          </Grid>
        </Grid>

        {renderRoute(['create-users'], permissions) && (
          <Route path="/users/new" component={NewUser} />
        )}
        {renderRoute(['update-users'], permissions) && (
          <Route path="/users/:id/edit" component={EditUser} />
        )}
        <Route path="/users/:id/see/:see" component={EditUser} />

        <List permissions={permissions} classes={classes} items={items} />
      </>
    )
  }
}

User.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  ...getUserListData(state),
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUsers,
      makeReport,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(User))
