/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable radix */
// eslint-disable-next-line no-undef

export const isCpfCnpjValid = value => {
  const cleanedValue = value.replace(/[^\d]+/g, '')

  // Verifica se é CPF ou CNPJ com base no comprimento
  if (cleanedValue.length === 11) {
    return isCpfValid(cleanedValue)
  }
  if (cleanedValue.length === 14) {
    return isCnpjValid(cleanedValue)
  }

  return false
}

function isCpfValid(cpf) {
  cpf = cpf.replace(/[^\d]+/g, '')

  if (cpf.length !== 11) {
    return false
  }

  // Verifica se todos os dígitos são iguais (ex: 111.111.111-11)
  if (/^(\d)\1+$/.test(cpf)) {
    return false
  }

  // Calcula o primeiro dígito verificador
  let sum = 0
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i)
  }
  let mod = sum % 11
  const digit1 = mod < 2 ? 0 : 11 - mod

  // Calcula o segundo dígito verificador
  sum = 0
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i)
  }
  mod = sum % 11
  const digit2 = mod < 2 ? 0 : 11 - mod

  // Verifica se os dígitos verificadores são iguais aos do CPF
  if (
    parseInt(cpf.charAt(9)) !== digit1 ||
    parseInt(cpf.charAt(10)) !== digit2
  ) {
    return false
  }

  return true
}

function isCnpjValid(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, '')

  if (cnpj.length !== 14) {
    return false
  }

  // Verifica se todos os dígitos são iguais (ex: 11.111.111/1111-11)
  if (/^(\d)\1+$/.test(cnpj)) {
    return false
  }

  // Calcula o primeiro dígito verificador
  let sum = 0
  let factor = 5
  for (let i = 0; i < 12; i++) {
    sum += parseInt(cnpj.charAt(i)) * factor
    factor = factor === 2 ? 9 : factor - 1
  }
  let mod = sum % 11
  const digit1 = mod < 2 ? 0 : 11 - mod

  // Calcula o segundo dígito verificador
  sum = 0
  factor = 6
  for (let i = 0; i < 13; i++) {
    sum += parseInt(cnpj.charAt(i)) * factor
    factor = factor === 2 ? 9 : factor - 1
  }
  mod = sum % 11
  const digit2 = mod < 2 ? 0 : 11 - mod

  // Verifica se os dígitos verificadores são iguais aos do CNPJ
  if (
    parseInt(cnpj.charAt(12)) !== digit1 ||
    parseInt(cnpj.charAt(13)) !== digit2
  ) {
    return false
  }

  return true
}
