import {
  Box,
  DialogActions,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import { ColorBox } from 'material-ui-color'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import DialogContent from '@material-ui/core/DialogContent'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import withStyles from '@material-ui/core/styles/withStyles'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUp'
import PropTypes from 'prop-types'
import React from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { connect } from 'react-redux'
import AddIcon from '@material-ui/icons/Add'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import MaterialAutocomplete from '@material-ui/lab/Autocomplete'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { format } from 'date-fns'

import styles from '../../../resources/theme/users'
import EnhancedComponent from '../../components/EnhancedComponent'
import SelectField from '../../components/SelectField'
import CustomTextField from '../../components/Textfield'
import AutoComplete from '../../components/Autocomplete'

class UserForm extends EnhancedComponent {
  constructor(props) {
    super(props)

    this.state = {
      dirty: false,
      fields: {
        id: '',
        name: '',
        email: '',
        role_id: [],
        cpf: '',
        password: '',
        company_id: '',
        laundries: [],
        resend: false,
        color: '#93c3ea',
        status: 1,
        userWeekdays: [],
      },
    }
  }

  handleClose = () => this.props.onClose && this.props.onClose()

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.dirty && nextProps.data) {
      this.setState({
        fields: {
          ...this.state.fields,
          ...nextProps.data,
        },
      })
    }
  }

  handleChange = name => event => {
    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        [name]: !prevState.fields[name],
      },
    }))
  }

  updateFields = (name, value) => {
    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
    }))
  }

  onSubmit = e => {
    e.preventDefault()
    const send = {
      ...this.state.fields,
      status: this.state.fields.status || 0,
      userWeekdays: this.state.fields.userWeekdays.map(item => {
        item.from = item.from
          ? format(new Date(item.from), 'yyyy-MM-dd HH:mm')
          : null
        item.to = item.to ? format(new Date(item.to), 'yyyy-MM-dd HH:mm') : null

        return item
      }),
    }

    this.props.onSubmit && this.props.onSubmit(send)
  }

  updateFunction = (areas, array) => {
    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        [array]: areas.sort((a, b) => a.name.localeCompare(b.name)),
      },
    }))
  }

  updateChildren = (index, newValue, propName) => {
    const items = [...this.state.fields.userWeekdays]

    items[index][propName] = newValue

    this.setState(state => ({
      fields: {
        ...state.fields,
        userWeekdays: items,
      },
    }))
  }

  addUserWeekday = () => {
    this.setState(state => ({
      fields: {
        ...state.fields,
        userWeekdays: [
          ...state.fields.userWeekdays,
          { weekday: '', from: null, to: null, has_access: 0 },
        ],
      },
    }))
  }

  removeChildren = index => {
    this.setState(state => ({
      fields: {
        ...state.fields,
        userWeekdays: state.fields.userWeekdays.filter(
          (item, key) => key !== index
        ),
      },
    }))
  }

  render() {
    const { roles, companies, laundries, weekdays, classes } = this.props

    const {
      company_id,
      role_id,
      email,
      name,
      cpf,
      password,
      color,
      id,
      resend,
      status,
      userWeekdays,
    } = this.state.fields
    const readOnly = !!this.props.see

    const roleHasID = array => {
      const item = role_id.find(item =>
        array.find(arrayItem => {
          return item.sys_name?.toLowerCase().includes(arrayItem.toLowerCase())
        })
      )

      if (item) return true

      return false
    }

    return (
      <ValidatorForm onSubmit={this.onSubmit}>
        <DialogContent style={{ height: 'calc(100vh - 64px - 52px)' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={6} sm={12} md={6}>
              <CustomTextField
                validators={['required', 'minStringLength:5']}
                errorMessages={[
                  'Campo Obrigatório',
                  'Tamanho Mínimo de 5 caracteres',
                ]}
                onChange={this.onChange('name')}
                label="Nome"
                fullWidth
                value={name}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={12} lg={6} sm={12} md={6}>
              <CustomTextField
                validators={['isEmail']}
                errorMessages={['O campo não representa um email válido']}
                onChange={this.onChange('email')}
                required
                label="E-mail"
                fullWidth
                value={email}
                disabled={readOnly}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={6} sm={12} md={6}>
              <CustomTextField
                value={cpf}
                onChange={this.onChange('cpf')}
                disabled={readOnly}
                validators={[
                  'matchRegexp:^([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})$',
                ]}
                errorMessages={['CPF/CNPJ Inválido']}
                label="CPF/CNPJ"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={6} sm={12} md={6}>
              <CustomTextField
                onChange={this.onChange('password')}
                label="Senha"
                fullWidth
                type="password"
                value={password}
                disabled={readOnly}
                autoComplete="current-password"
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12} sm={12} md={6}>
              {role_id && (
                <AutoComplete
                  options={roles}
                  label="Tipo de Usuário"
                  data={role_id}
                  updateFunction={this.updateFunction}
                  limitTags={8}
                  name="role_id"
                />
              )}
            </Grid>
          </Grid>

          <Grid container spacing={1} alignItems="flex-end">
            {roleHasID(['administrador', 'atendente']) && (
              <Grid item xs={12} lg={6} sm={12} md={6}>
                <SelectField
                  options={companies}
                  setForm={this.updateFields}
                  data={company_id}
                  name="company_id"
                  label="Empresa"
                  disabled={readOnly}
                />
              </Grid>
            )}

            {roleHasID(['lavanderia']) && (
              <Grid item xs={12} lg={6} sm={12} md={6}>
                <AutoComplete
                  options={laundries}
                  label="Lavanderias"
                  data={this.state.fields.laundries}
                  updateFunction={this.updateFunction}
                  limitTags={8}
                  name="laundries"
                />
              </Grid>
            )}

            {roleHasID(['operador_rua']) && (
              <Grid item xs={12} lg={12} sm={12} md={12}>
                <ColorBox
                  value={color}
                  disableAlpha
                  hideTextfield
                  onChange={color =>
                    this.setState(prvState => ({
                      dirty: true,
                      fields: {
                        ...prvState.fields,
                        color: `#${color.hex}`,
                      },
                    }))
                  }
                />
              </Grid>
            )}
          </Grid>
          <Paper elevation={5}>
            <div className={classes.weekdaysContainer}>
              <Grid container spacing={1}>
                <Grid item xs>
                  <Typography variant="h6">Acessos</Typography>
                </Grid>
                <Grid item>
                  <Button onClick={() => this.addUserWeekday()}>
                    <AddIcon />
                  </Button>
                </Grid>
              </Grid>
              {userWeekdays?.map((item, index) => (
                <Box key={String(index)}>
                  <Grid
                    container
                    spacing={1}
                    style={{ margin: '10px 0px' }}
                    justify="flex-end"
                    alignItems="flex-end"
                  >
                    <Grid item xs={5}>
                      <MaterialAutocomplete
                        options={weekdays}
                        blurOnSelect
                        noOptionsText="Sem opções"
                        getOptionSelected={(option, value) =>
                          option.id === value.id
                        }
                        getOptionLabel={option => option.name}
                        value={item.weekday || null}
                        renderInput={params => (
                          <TextField {...params} label="Dia da Semana" />
                        )}
                        onChange={(event, newValue) =>
                          this.updateChildren(index, newValue, 'weekday')
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <KeyboardTimePicker
                        margin="normal"
                        label="De"
                        value={item.from || null}
                        onChange={date =>
                          this.updateChildren(index, date, 'from')
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                        clearable
                        fullWidth
                        ampm={false}
                        cancelLabel="Cancelar"
                        invalidDateMessage="Formato inválido"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <KeyboardTimePicker
                        margin="normal"
                        label="Até"
                        value={item.to || null}
                        onChange={date =>
                          this.updateChildren(index, date, 'to')
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                        clearable
                        fullWidth
                        ampm={false}
                        cancelLabel="Cancelar"
                        invalidDateMessage="Formato inválido"
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={Boolean(item.has_access)}
                              onChange={e =>
                                this.updateChildren(
                                  index,
                                  e.target.checked,
                                  'has_access'
                                )
                              }
                              value={resend}
                            />
                          }
                          label="Sem acesso"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs style={{ alignSelf: 'flex-end' }}>
                      <Button
                        color="inherit"
                        onClick={() => this.removeChildren(index)}
                        aria-label="Close"
                      >
                        <RemoveCircleOutlineIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </div>
          </Paper>
          <Grid container spacing={1}>
            {id && email && (
              <Grid item xs={12} lg={3} sm={12} md={6}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={readOnly}
                        checked={Boolean(resend)}
                        onChange={this.handleChange('resend')}
                        value={resend}
                      />
                    }
                    label="Reenviar email de cadastro"
                  />
                </FormGroup>
              </Grid>
            )}

            <Grid item xs={12} lg={3} sm={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<ThumbDownAltOutlinedIcon />}
                    checkedIcon={<ThumbUpAltOutlinedIcon color="secondary" />}
                    checked={status}
                    onChange={this.handleChange('status')}
                    value={status}
                    disabled={readOnly}
                  />
                }
                label="Ativo?"
              />
            </Grid>
          </Grid>
        </DialogContent>
        {!readOnly && (
          <DialogActions>
            <Button color="primary" type="submit">
              Salvar
            </Button>
            <Button onClick={this.handleClose}>Cancelar</Button>
          </DialogActions>
        )}
      </ValidatorForm>
    )
  }
}

UserForm.propTypes = {
  classes: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  roles: state.user.roles || [],
  companies: state.user.companies,
  laundries: state.user.laundries,
  weekdays: state.user.weekdays,
})

export default connect(mapStateToProps)(withStyles(styles)(UserForm))
