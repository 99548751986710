/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable simple-import-sort/sort */
import { Button, Grid } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import { bindActionCreators } from 'redux'

import styles from '../../../resources/theme/global'
import { getLaundryListData } from '../LaundryDashboardReducer'
import { makeReport } from '../LaundryDashboardActions'

import Details from './Details'
import Filter from './Filter'
import List from './List'

class Laundries extends Component {
  render() {
    const { classes, permissions, makeReport, filters } = this.props
    return (
      <>
        <Grid
          container
          className={classes.pageHead}
          spacing={1}
          alignItems="center"
        >
          <Grid item xs>
            <Typography variant="h4" color="secondary">
              Tapetes
            </Typography>
          </Grid>
          <Grid item>
            <Filter classes={classes} />
          </Grid>
          <Grid item>
            <Button
              color="primary"
              type="button"
              onClick={() => makeReport(filters)}
            >
              <NoteAddIcon className={classes.btnIcon} /> Relatório
            </Button>
          </Grid>
        </Grid>

        <Route path="/laundry-dashboard/:id" component={Details} />

        <List {...this.props} classes={classes} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...getLaundryListData(state),
  filters: state.laundryDashboard.filters,
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      makeReport,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Laundries))
