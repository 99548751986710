/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { logout } from '../common/auth/AuthActions'

export default function action(
  type,
  payload = {},
  error = false,
  putLoading = true
) {
  const action = { type }
  if (payload) action.payload = payload
  if (error) action.error = error
  action.loading = putLoading
  return action
}

export function actionFailed(type, e, putLoading = true) {
  return dispatch => {
    let error = {}

    if (e.response.data.message) {
      error = e.response.data.message
    }

    if (e.response.data.errors) {
      for (const prop in e.response.data.errors) {
        error = Object.values(e.response.data.errors[prop])
        break
      }
    }

    if (e.response && e.response.status === 401) {
      return dispatch(logout())
    }

    if (typeof error === 'object') {
      error = error.map(err => err)
    }

    return dispatch(action(type.FAILURE, null, { message: error }, putLoading))
  }
}
