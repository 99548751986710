import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import {
  AppBar,
  Grid,
  IconButton,
  Typography,
  withStyles,
  Toolbar,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useParams } from 'react-router'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

import Dropzone from '../../components/Dropzone'
import {
  fetchDocuments,
  updateServiceBill,
  download,
} from '../AttendanceLaundryActions'
import styles from '../../../resources/theme/global'
import { renderRoute } from '../../../util/utils'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ModalAttachBilling = props => {
  const { classes } = props
  const dispatch = useDispatch()
  const { id } = useParams()
  const [files, setFiles] = useState(null)
  const [canRender, setCanRender] = useState(false)
  const [hasFiles, setHasFiles] = useState(false)
  const permissions = useSelector(state => state.auth.permissions)

  useEffect(() => {
    dispatch(fetchDocuments(id))
      .then(resp => {
        const { data } = resp
        setFiles(data.data)
        setHasFiles(data.has_files)
      })
      .finally(() => setCanRender(true))
  }, [])

  const onClose = () => {
    props.history.push('/laundry-os')
  }

  return (
    <div>
      <Dialog
        open
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
        onClose={onClose}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Boletos Anexos
            </Typography>
            <IconButton color="inherit" onClick={onClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {canRender && renderRoute(['update-laundry-os'], permissions) && (
            <Formik
              initialValues={{ files }}
              enableReinitialize
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(false)
                await dispatch(updateServiceBill(id, values))
                onClose()
              }}
            >
              {({ submitForm, isSubmitting, setFieldValue }) => (
                <Form>
                  <Field name="files">
                    {({ field }) => (
                      <Dropzone
                        initialfiles={files}
                        handleChangeFiles={e => setFieldValue('files', e)}
                        limit={3}
                        classes={classes}
                        text="Clique aqui ou arraste documentos para selecionar"
                        mimeTypes={[
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                          'application/msword',
                          'application/pdf',
                        ]}
                      />
                    )}
                  </Field>
                  <DialogActions>
                    <Button onClick={onClose} color="primary">
                      Fechar
                    </Button>

                    <Button
                      disabled={isSubmitting}
                      onClick={submitForm}
                      color="primary"
                    >
                      Confirmar
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          )}
          <Grid container spacing={1} justify="center" alignItems="center">
            <Grid item style={{ margin: '20px' }}>
              {hasFiles ? (
                <Button
                  color="primary"
                  type="button"
                  size="large"
                  startIcon={<CloudDownloadIcon className={classes.btnIcon} />}
                  onClick={() => dispatch(download(id))}
                >
                  Baixar Boletos
                </Button>
              ) : (
                <Typography variant="h5">
                  A OS ainda não possui boletos
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default React.memo(withStyles(styles)(ModalAttachBilling))
