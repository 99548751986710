import {
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'

import { exportFormatedMoney } from '../../../util/utils'
import AutoComplete from '../../components/Autocomplete'
import NumberFormatCustom from '../../components/NumberFormat'

function ServiceTypes({
  services,
  updateFunction,
  handleChangeServices,
  classes,
  checkAllServices,
  servicesTypeList,
  removeItem,
}) {
  const [filter, setFilter] = useState('')
  const [checkAll, setCheckAll] = useState(false)

  const toggleChecked = e => {
    if (e.target.checked) checkAllServices(true)
    else checkAllServices(false)

    setCheckAll(e.target.checked)
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs>
          <AutoComplete
            options={servicesTypeList}
            label="Tipos de Serviço"
            data={services}
            updateFunction={updateFunction}
            limitTags={8}
            name="services"
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} justify="space-between">
        <Grid item xs={12} md={6} lg={3} style={{ margin: '10px' }}>
          <TextField
            placeholder="Pesquisar..."
            onChange={e => setFilter(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={3} style={{ marginTop: 'auto' }}>
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(checkAll)}
                onChange={toggleChecked}
                value={checkAll}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Marcar Todas"
          />
        </Grid>
      </Grid>

      <Grid container>
        {services.map((service, key) => {
          return (
            service.name.toLowerCase().includes(filter.toLowerCase()) && (
              <Grid
                item
                lg={4}
                md={6}
                xs={12}
                key={String(service.id)}
                className={classes.item}
              >
                <Typography
                  variant="inherit"
                  color="inherit"
                  className={classes.price}
                >
                  {`Valor padrão: ${exportFormatedMoney(
                    service.default_value
                  )}`}
                </Typography>
                <IconButton
                  className={classes.closeButton}
                  onClick={() => removeItem(service.id)}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="inherit" color="inherit">
                  {`${service.name}`}
                </Typography>
                <TextField
                  placeholder="Percent."
                  value={services[key].percent}
                  onChange={handleChangeServices('percent', key)}
                  variant="outlined"
                  margin="dense"
                  style={{ maxWidth: '120px' }}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: '%',
                  }}
                />
                <Switch
                  checked={Boolean(services[key].status)}
                  onChange={handleChangeServices('service_type_id', key)}
                  value={service.status}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Grid>
            )
          )
        })}
      </Grid>
    </>
  )
}

export default ServiceTypes
