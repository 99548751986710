import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from '../../../resources/theme/users'
import { edit, update } from '../LaundryActions'

import Form from './Form'

function Transition(props) {
  return <Slide direction="down" {...props} />
}

function Edit(props) {
  const { classes, match, history, location } = props
  const permissions = useSelector(state => state.role.permissions)
  const dispatch = useDispatch()

  const isReadnOnly = useMemo(() => location.pathname.includes('read'))

  useEffect(() => {
    dispatch(edit(match.params.id))
  }, [])

  const laundry = useSelector(state => state.laundry.editingLaundry)

  const onSubmit = values => {
    dispatch(update(values)).then(data => {
      if (data && data.code === 200) {
        history.push('/laundry')
      }
    })
  }

  const onClose = () => {
    history.push('/laundry')
  }

  return (
    <Dialog
      open={laundry !== null}
      fullScreen
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Editar Lavanderia
          </Typography>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Form
        data={laundry}
        permissions={permissions}
        onClose={onClose}
        onSubmit={onSubmit}
        isReadnOnly={isReadnOnly}
      />
    </Dialog>
  )
}

export default withStyles(styles)(Edit)
