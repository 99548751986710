/* eslint-disable radix */
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import VisibilityIcon from '@material-ui/icons/Visibility'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { GiTakeMyMoney } from 'react-icons/gi'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import { RiBillLine } from 'react-icons/ri'
import { useConfirm } from 'material-ui-confirm'
import { GoIssueReopened } from 'react-icons/go'
import {
  Box,
  Collapse,
  TableHead,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import { getSorting, stableSort } from '../../../util/TableUtils'
import Paginate from '../../components/Pagination'
import EnhancedTableHead from '../../components/TableHead'
import {
  dateFormatedPT,
  exportFormatedMoney,
  renderRoute,
} from '../../../util/utils'
import { sendMail, reopenOS } from '../AttendanceLaundryActions'

import Modal from './Modal'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableStickHeader: {
    maxHeight: 'calc(100vh - 400px)',
  },
}))

function List(props) {
  const {
    headCells,
    rows,
    handleChangeRowsPerPage,
    onMovePage,
    rowsPerPage,
    page,
    totalSize,
  } = props
  const classes = useStyles()
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('id')
  const [modalOpen, setModalOpen] = React.useState(false)
  const [currentID, setCurrentID] = React.useState(null)
  const permissions = useSelector(state => state.auth.permissions)

  const confirm = useConfirm()
  const dispatch = useDispatch()

  const confirmSendMail = id => {
    confirm({
      description: 'Deseja enviar o boleto para a lavanderia?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(async () => {
      await dispatch(sendMail(id))
    })
  }

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const closeOS = id => {
    confirm({
      description: 'Deseja fechar esta OS?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      setModalOpen(true)
      setCurrentID(id)
    })
  }

  const confirmReopenOS = id => {
    confirm({
      description: 'Deseja reabrir esta OS?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(async () => {
      await dispatch(reopenOS(id))
    })
  }

  function Row(props) {
    const { row } = props
    const [open, setOpen] = React.useState(false)

    return (
      <>
        <TableRow hover tabIndex={-1} key={row.id}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {Object.entries(row).map((item, index) => {
            const except = ['update', 'delete', 'link_update']
            if (
              item[0] === headCells[index]?.id &&
              except.indexOf(item[0]) === -1
            ) {
              if (item[0] === 'id') {
                return (
                  <TableCell
                    key={item[0]}
                    component="th"
                    id={row.Id}
                    scope="row"
                    padding="none"
                  >
                    {row.id}
                  </TableCell>
                )
              }
              return <TableCell key={item[0]}>{item[1]}</TableCell>
            }
          })}
          <TableCell>
            <IconButton
              aria-label="see"
              component={Link}
              to={`laundry-os/${row.id}/details`}
            >
              <VisibilityIcon />
            </IconButton>
            {!row.isLaundryUser && (
              <IconButton
                aria-label="billing"
                component={Link}
                to={`/billing?service_id=${row.id}`}
              >
                <GiTakeMyMoney size={25} />
              </IconButton>
            )}
            {renderRoute(['update-laundry-os'], permissions) &&
            !row.hasBilling ? (
              <IconButton onClick={() => closeOS(row.id)}>
                <AttachMoneyIcon />
              </IconButton>
            ) : (
              renderRoute(['update-laundry-os'], permissions) && (
                <Tooltip title="Reabrir OS">
                  <IconButton onClick={() => confirmReopenOS(row.id)}>
                    <GoIssueReopened />
                  </IconButton>
                </Tooltip>
              )
            )}
            <Tooltip title="Boletos">
              <IconButton
                aria-label="tickets"
                component={Link}
                to={`laundry-os/${row.id}/tickets`}
              >
                <RiBillLine />
              </IconButton>
            </Tooltip>
            {renderRoute(['update-laundry-os'], permissions) && (
              <Tooltip title="Enviar boletos por email">
                <IconButton
                  aria-label="close"
                  color={row.billSended ? 'primary' : ''}
                  onClick={() => confirmSendMail(row.id)}
                >
                  <MailOutlineIcon />
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Informações adicionais
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Vencimento</TableCell>
                      <TableCell>Valor</TableCell>
                      <TableCell>Data de pagamento</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.billings.length > 0 ? (
                      row.billings.map(billing => (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {billing.id}
                          </TableCell>
                          <TableCell>{dateFormatedPT(billing.date)}</TableCell>
                          <TableCell>
                            {exportFormatedMoney(billing.value)}
                          </TableCell>
                          <TableCell>
                            {billing.payment_date
                              ? dateFormatedPT(billing.payment_date)
                              : '-'}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          -
                        </TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <>
      <Modal open={modalOpen} handleClose={setModalOpen} id={currentID} />
      <Paper>
        <TableContainer className={classes.tableStickHeader}>
          <Table
            className={classes.table}
            aria-labelledby="Tabela de Oss de lavanderia"
            size="medium"
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy)).map(row => {
                return <Row key={String(row.id)} row={row} />
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Paginate
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={parseInt(rowsPerPage)}
          rows={totalSize}
          page={page}
          handleChangePage={onMovePage}
        />
      </Paper>
    </>
  )
}

export default withRouter(List)
