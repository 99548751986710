import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const REMOVE_SUPPLIER = createAsyncConst('REMOVE_SUPPLIER')
export const SUBMIT_SUPPLIER = createAsyncConst('SUBMIT_SUPPLIER')
export const FETCH_SUPPLIER = createAsyncConst('FETCH_SUPPLIER')
export const EDIT_SUPPLIER = createAsyncConst('EDIT_SUPPLIER')
export const FETCH_CEP = createAsyncConst('FETCH_CEP')

export function fetchSupplier(filters = []) {
  return dispatch => {
    dispatch(action(FETCH_SUPPLIER.ACTION))
    return axios
      .get(`${config.API_URL}/supplier`, {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => {
        dispatch(action(FETCH_SUPPLIER.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_SUPPLIER, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_SUPPLIER.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/supplier/${id}`)
      .then(resp => {
        return resp.data.data
      })
      .then(data => dispatch(action(EDIT_SUPPLIER.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(EDIT_SUPPLIER, e)))
  }
}

function submit(values, method) {
  const id = values.id ? `/${values.id}` : ''

  return dispatch => {
    dispatch(action(SUBMIT_SUPPLIER.ACTION))
    return axios[method](`${config.API_URL}/supplier${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_SUPPLIER.SUCCESS, data))
        dispatch(fetchSupplier())
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_SUPPLIER, e)))
  }
}

export function post(values) {
  return submit(values, 'post')
}

export function update(values) {
  return submit(values, 'put')
}

export function remove(id) {
  return dispatch => {
    dispatch(action(REMOVE_SUPPLIER.ACTION))
    return axios
      .delete(`${config.API_URL}/supplier/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(REMOVE_SUPPLIER.SUCCESS, data))
        dispatch(fetchSupplier())
      })
      .catch(e => actionFailed(REMOVE_SUPPLIER.FAILURE, e))
  }
}

export function findCep(cep) {
  return dispatch => {
    dispatch(action(FETCH_CEP.ACTION))
    return axios
      .get(`${config.API_URL}/buscaCep/${cep}`)
      .then(resp => {
        dispatch(action(FETCH_CEP.SUCCESS, resp.data))
        return resp.data
      })
      .catch(e => dispatch(actionFailed(FETCH_CEP, e)))
  }
}
