export default theme => ({
  grow: {
    flex: 1,
  },
  btnIcon: {
    marginRight: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    minHeight: '70vh',
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(3),
  },
  formControl: {
    minWidth: 180,
    width: '100%',
  },
  filterIcon: {
    marginRight: '20px',
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
  weekdaysContainer: {
    margin: '20px 0px',
    padding: '15px',
  },
  tableStickHeader: {
    maxHeight: 'calc(100vh - 200px)',
  },
})
