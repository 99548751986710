export default theme => ({
  main: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  animationNono: {
    animation: '$nono',
    animationIterationCount: '2',
    animationDuration: '300ms',
    animationTimingFunction: 'linear',
  },
  animationFade: {
    animation: '$fade',
    animationDuration: '1000ms',
    animationDelay: '0.2s',
    animationFillMode: 'backwards',
  },
  paper: {
    maxWidth: 400,
    alignItems: 'center',
    flexDirection: 'column',
    padding: `${theme.spacing(3)}px`,
  },
  logo: {
    width: 180,
    display: 'block',
    marginBottom: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  field: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  fieldMargin: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  submit: {
    marginRight: theme.spacing(3),
  },
  circular: {
    color: '#fff',
  },
  link: {
    color: theme.palette.primary.main,
  },
  '@keyframes nono': {
    '0%, 100%': {
      transform: 'translateX(0%)',
    },
    '35%': {
      transform: 'translateX(-2%)',
    },
    '70%': {
      transform: 'translateX(2%)',
    },
  },
  '@keyframes fade': {
    from: {
      opacity: 0,
      transform: 'scale(0.5)',
    },
    to: {
      opacity: 1,
      transform: 'scale(1)',
    }
  }
})
