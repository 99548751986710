import { Slide } from '@material-ui/core'
/* eslint-disable import/order */
/* eslint-disable import/extensions */
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import { useDispatch } from 'react-redux'

import styles from '../../../resources/theme/global'
import { post } from '../BillingTypeActions'
import Form from './Form'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

function NewBillingType(props) {
  const { classes } = props
  const dispatch = useDispatch()

  const onSubmit = values => {
    dispatch(post(values)).then(data => {
      if (data && data.code === 200) {
        props.history.push('/billing-type')
      }
    })
  }

  const onClose = () => {
    props.history.push('/billing-type')
  }

  return (
    <Dialog
      open
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Novo Tipo de conta
          </Typography>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Form classes={classes} onClose={onClose} onSubmit={onSubmit} />
    </Dialog>
  )
}

export default withStyles(styles)(NewBillingType)
