import {
  FETCH_TEAM_PRODUTIVITY_RELATORY_INIT,
  FETCH_TEAM_PRODUTIVITY_RELATORY_SUBLIST,
} from './TeamProdutivityRelatoryActions'

const INITIAL_STATE = {
  items: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  filters: [],
  teams: [],
  services_types: [],

  subItems: [],
  subTotalSize: 0,
  subPage: 1,
  subSizePerPage: 10,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TEAM_PRODUTIVITY_RELATORY_INIT.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        totalSize: action.payload.data.meta.total,
        page: action.payload.data.meta.current_page,
        sizePerPage: action.payload.data.meta.per_page,
        filters: action.payload.filters,
        teams: action.payload.data.teams,
        services_types: action.payload.data.services_types,
      }
    case FETCH_TEAM_PRODUTIVITY_RELATORY_SUBLIST.SUCCESS:
      return {
        ...state,
        subItems: action.payload.data.data,
        subTotalSize: action.payload.data.meta.total,
        subPage: action.payload.data.meta.current_page,
        subSizePerPage: action.payload.data.meta.per_page,
      }
    case 'CLEAR_ACTION_TEAM_PRODUTIVITY':
      return {
        ...state,
        filters: {},
      }
    default:
      return state
  }
}

export const getTeamProdutivityRelatoryListData = state => {
  return {
    items: state.team_produtivity_relatory.items,
    page: state.team_produtivity_relatory.page,
    sizePerPage: state.team_produtivity_relatory.sizePerPage,
    totalSize: state.team_produtivity_relatory.totalSize,
    filters: state.team_produtivity_relatory.filters,
  }
}

export const getTeamProdutivityRelatorySubListData = state => {
  return {
    subItems: state.team_produtivity_relatory.items,
    subPage: state.team_produtivity_relatory.page,
    subSizePerPage: state.team_produtivity_relatory.sizePerPage,
    subTotalSize: state.team_produtivity_relatory.totalSize,
  }
}
