/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import {
  Checkbox,
  DialogActions,
  FormControlLabel,
  TextField,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'

import Dropzone from '../../components/Dropzone'
import styles from '../../../resources/theme/global'

function Form(props) {
  const {
    handleClose,
    classes,
    updateField,
    form,
    handleChangeFiles,
    handleChangeOrcamento,
    onSubmitForm,
    handleCheckbox,
    data,
  } = props

  return (
    <form onSubmit={onSubmitForm}>
      <DialogContent>
        <Grid container spacing={1} alignItems="center">
          <Grid item sm={12} md={6} lg={4}>
            <TextField
              value={form.name}
              onChange={updateField('name')}
              variant="outlined"
              label="Nome"
              fullWidth
            />
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <TextField
              value={form.host}
              onChange={updateField('host')}
              variant="outlined"
              label="Host"
              fullWidth
            />
          </Grid>

          <Grid item sm={12} md={6} lg={4}>
            <TextField
              value={form.port}
              onChange={updateField('port')}
              variant="outlined"
              label="Porta"
              fullWidth
            />
          </Grid>

          <Grid item sm={12} md={6} lg={4}>
            <TextField
              value={form.username}
              onChange={updateField('username')}
              variant="outlined"
              label="Email"
              fullWidth
            />
          </Grid>

          <Grid item sm={12} md={6} lg={4}>
            <TextField
              value={form.password}
              onChange={updateField('password')}
              type="password"
              variant="outlined"
              label="Senha"
              fullWidth
            />
          </Grid>

          <Grid item sm={12} md={6} lg={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(form.hide_values)}
                  onChange={() => handleCheckbox('hide_values')}
                  value={form.hide_values}
                  color="primary"
                  name="hide_values"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Esconder valores no app?"
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item sm={12} md={6} lg={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(form.is_know_way_required)}
                  onChange={() => handleCheckbox('is_know_way_required')}
                  value={form.is_know_way_required}
                  color="primary"
                  name="is_know_way_required"
                />
              }
              label="Como conheceu obrigatório?"
            />
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(form.is_email_required)}
                  onChange={() => handleCheckbox('is_email_required')}
                  value={form.is_email_required}
                  color="primary"
                  name="is_email_required"
                />
              }
              label="Email obrigatório?"
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs>
            <Dropzone
              initialfiles={data?.files}
              handleChangeFiles={handleChangeFiles}
              classes={classes}
              limit={3}
              text="Clique aqui ou arraste documentos para selecionar"
              mimeTypes={['image/*']}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs>
            <Dropzone
              handleChangeFiles={handleChangeOrcamento}
              classes={classes}
              limit={1}
              text="Selecione o modelo de orçamento"
              mimeTypes={[
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              ]}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" type="submit">
          Salvar
        </Button>
        <Button onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </form>
  )
}

export default withStyles(styles)(Form)
