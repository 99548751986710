/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

function ModalShowOpenedServices({ openedServices }) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (openedServices?.length > 0) {
      setOpen(true)
    }
  }, [openedServices])

  return (
    <div>
      <Dialog maxWidth="md" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Cliente com orçamentos não finalizados.</DialogTitle>
        <DialogContent>
          <DialogContentText>
            O cliente possui {openedServices.length} orçamentos abertos. São
            eles os seguintes: {openedServices.map(service => `${service}, `)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary" autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default React.memo(ModalShowOpenedServices)
