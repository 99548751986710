/* eslint-disable radix */
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete'
import PencilIcon from '@material-ui/icons/Edit'
import { useConfirm } from 'material-ui-confirm'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import {
  Box,
  Collapse,
  TableHead,
  Tooltip,
  Typography,
} from '@material-ui/core'
import WalletIcon from '@material-ui/icons/AccountBalanceWallet'

import { getSorting, stableSort } from '../../../util/TableUtils'
import { renderRoute, dateFormatedPT } from '../../../util/utils'
import Paginate from '../../components/Pagination'
import EnhancedTableHead from '../../components/TableHead'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableStickHeader: {
    maxHeight: 'calc(100vh - 200px)',
  },
}))

function List(props) {
  const {
    permissions,
    remove,
    headCells,
    rows,
    handleChangeRowsPerPage,
    onMovePage,
    rowsPerPage,
    page,
    totalSize,
  } = props
  const classes = useStyles()
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState()

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const confirmDelete = id => {
    confirm({
      description: 'Após ser apagado o conteúdo não poderá ser recuperado?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      dispatch(remove(id))
    })
  }

  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  })

  function Row(props) {
    const { row } = props
    const [open, setOpen] = React.useState(false)
    const classes = useRowStyles()

    return (
      <>
        <TableRow
          className={classes.root}
          style={
            row.deadlineCurrentMonth ? { backgroundColor: 'aquamarine' } : {}
          }
        >
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.id}
          </TableCell>
          <TableCell>{row.billingType}</TableCell>
          <TableCell>{row.companyName}</TableCell>
          <TableCell>{row.value}</TableCell>
          <TableCell>{dateFormatedPT(row.date)}</TableCell>
          <TableCell>{row.created}</TableCell>
          <TableCell>
            {row.actualPaymentDate
              ? dateFormatedPT(row.actualPaymentDate)
              : '-'}
          </TableCell>
          <TableCell>{row.paymentStatus}</TableCell>
          <TableCell>{row.client}</TableCell>
          <TableCell>{row.bank}</TableCell>
          <TableCell>
            {renderRoute([row.update], permissions) && (
              <IconButton
                aria-label="edit"
                component={Link}
                to={row.link_update}
              >
                <PencilIcon />
              </IconButton>
            )}
            {renderRoute([row.delete], permissions) && (
              <IconButton onClick={() => confirmDelete(row.id)}>
                <DeleteIcon />
              </IconButton>
            )}

            <Tooltip title="Definir pagamento">
              <IconButton
                aria-label="close"
                component={Link}
                to={`billing/${row.id}/close`}
              >
                <WalletIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Informações adicionais
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Tipo</TableCell>
                      <TableCell>Data do pagamento</TableCell>
                      {row.serviceID && <TableCell>Número da OS</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {row.type}
                      </TableCell>
                      <TableCell>
                        {row.actualPaymentDate
                          ? dateFormatedPT(row.actualPaymentDate)
                          : '-'}
                      </TableCell>
                      {row.serviceID && (
                        <TableCell
                          aria-label="attendance-number"
                          component={Link}
                          to={row.linkTo}
                        >
                          {row.serviceID}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <Paper>
      <TableContainer className={classes.tableStickHeader}>
        <Table
          className={classes.table}
          aria-labelledby="Tabela de lançamentos de contas"
          size="medium"
          stickyHeader
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy)).map(row => {
              return <Row key={String(row.id)} row={row} />
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginate
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={parseInt(rowsPerPage)}
        rows={totalSize}
        page={page}
        handleChangePage={onMovePage}
      />
    </Paper>
  )
}

export default withRouter(List)
