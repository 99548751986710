import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'

import BankTable from './BankTable'
import Graph from './Graph'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
})

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function BanksTable({ items, graph }) {
  const classes = useStyles()
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs>
        <Paper className={classes.root}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {items.map((bank, key) => {
              return <Tab label={bank.name} {...a11yProps(key)} />
            })}
            <Tab label="Gráfico" {...a11yProps(items.length)} />
          </Tabs>

          {items.map((bank, key) => {
            return (
              <TabPanel value={value} index={key}>
                <BankTable historic={bank?.historic} />
              </TabPanel>
            )
          })}

          <TabPanel value={value} index={items.length}>
            <Graph graph={graph} />
          </TabPanel>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default BanksTable
