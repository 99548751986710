import { FETCH_RESTORATION_ITEMS } from './RestorationActions'

const INITIAL_STATE = {
  items: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  filters: {},
  laundries: [],
  editingLaundry: null,
  itemHistoric: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_RESTORATION_ITEMS.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        totalSize: action.payload.data.meta.total,
        page: action.payload.data.meta.current_page,
        sizePerPage: action.payload.data.meta.per_page,
        laundries: action.payload.data.laundries,
        filters: action.payload.filters,
      }
    default:
      return state
  }
}

export const getLaundryListData = state => {
  return {
    items: state.restoration.items,
    page: state.restoration.page,
    sizePerPage: state.restoration.sizePerPage,
    totalSize: state.restoration.totalSize,
  }
}
