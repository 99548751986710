import axios from 'axios'

import config from '../../config'
import consts from '../../util/consts'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'
import { SNACKBAR } from '../main/MainActions'


export const FETCH_SERVICES_RELATORY_INIT = createAsyncConst('FETCH_SERVICES_RELATORY_INIT')
export const MAKE_SERVICES_RELATORY = createAsyncConst('MAKE_SERVICES_RELATORY')

export function fetchServicesRelatoryInit(page, filters = [], rows = consts.DEFAULT_PAGINATION, suppliers = []) {
  return dispatch => {
    dispatch(action(FETCH_SERVICES_RELATORY_INIT.ACTION))
    return axios
      .get(`${config.API_URL}/commission-report`, {
        params: {
          filters: JSON.stringify(filters),
          rows: rows || consts.DEFAULT_PAGINATION,
          page: page || 1,
        },
      })
      .then(resp => {
        dispatch(action(FETCH_SERVICES_RELATORY_INIT.SUCCESS, { data: resp.data, filters: filters, suppliers: suppliers }))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_SERVICES_RELATORY_INIT, e)))
  }
}

export function makeRelatory(data) {
  return dispatch => {
    return axios({
      url:
        `${config.API_URL}/commission-report-export`,
        method: 'POST',
        responseType: 'blob',
        headers: { Accept: 'application/vnd.ms-excel' },
        params: {
          filters: JSON.stringify(data),
        }
      })
      .then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        
        link.href = url
        link.setAttribute('download', 'commission_report.xlsx')
        document.body.appendChild(link)
        link.click()
        return resp
      })
      .catch(e => console.log(e))
    // .catch(e => dispatch({
    //   type: SNACKBAR.HARDFAIL,
    //   error: { message: 'Erro na geração do arquivo.' },
    // }))
  }
}

export function clearFilter() {
  return { type: "CLEAR_ACTION_SERVICES" }
}