import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchAttendanceLaundry } from '../AttendanceLaundryActions'
import { exportFormatedMoney } from '../../../util/utils'

import GlobalList from './ListTable'

function createData(
  id,
  name,
  paymentStatus,
  month,
  value,
  items,
  paymentStatusId,
  isLaundryUser,
  totalM2,
  hasBilling,
  billSended,
  billings
) {
  return {
    id,
    name,
    paymentStatus,
    month,
    items,
    totalM2,
    value,
    paymentStatusId,
    isLaundryUser,
    hasBilling,
    billSended,
    billings,
  }
}

const headCells = [
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: 'OS',
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'paymentStatus',
    numeric: true,
    disablePadding: false,
    label: 'Status do Pag.',
  },
  {
    id: 'month',
    numeric: true,
    disablePadding: false,
    label: 'Mês de ref.',
  },
  {
    id: 'items',
    numeric: true,
    disablePadding: false,
    label: 'Items',
  },
  {
    id: 'totalM2',
    numeric: true,
    disablePadding: false,
    label: 'Metros quadrados',
  },
  {
    id: 'value',
    numeric: true,
    disablePadding: false,
    label: 'Valor',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Ações',
  },
]

function List(props) {
  const { items } = props
  const rows = []
  const dispatch = useDispatch()
  const sizePerPage = useSelector(state => state.attendanceLaundry.sizePerPage)
  const page = useSelector(state => state.attendanceLaundry.page)
  const totalSize = useSelector(state => state.attendanceLaundry.totalSize)
  const filters = useSelector(state => state.attendanceLaundry.filters)
  const [rowsPerPage, setRowsPerPage] = useState(sizePerPage)

  items.map(item => {
    return rows.push(
      createData(
        item.id,
        item.laundry.name,
        item.payment_details,
        item.month,
        exportFormatedMoney(item.total_with_discount),
        item.items,
        item.payment_details?.id,
        item.isLaundryUser,
        item.totalM2,
        item.hasBilling,
        item.bill_sended,
        item.billings
      )
    )
  })

  const onMovePage = (event, newPage) => {
    dispatch(fetchAttendanceLaundry(filters, rowsPerPage, newPage + 1))
  }

  useEffect(() => {
    onMovePage()
  }, [rowsPerPage])

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  return (
    <GlobalList
      {...props}
      onMovePage={onMovePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      headCells={headCells}
      rows={rows}
      rowsPerPage={rowsPerPage}
      page={page - 1}
      totalSize={totalSize}
    />
  )
}

export default List
