/* eslint-disable import/order */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { theme } from '../../resources/theme'
import { themeDark } from '../../resources/themeDark'
import { ColorContext } from '../components/ColorContext'
import App from './AuthOrApp'

function returnTheme(thema) {
  switch (thema) {
    case 'themeDark':
      return themeDark
    case 'theme':
      return theme
    default:
      return theme
  }
}

function AuthorAppIndex() {
  const thema = useSelector(state => state.auth.theme)
  const [themeState, setTheme] = useState(returnTheme(thema))

  const toogleTheme = () => {
    setTheme(themeState === theme ? themeDark : theme)
  }

  return (
    <ColorContext.Provider value={themeState}>
      <ColorContext.Consumer>
        {theme => <App theme={theme} toogleTheme={toogleTheme} />}
      </ColorContext.Consumer>
    </ColorContext.Provider>
  )
}

export default AuthorAppIndex
