import { DialogActions, TextField as TextFieldOr } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'

import styles from '../../../resources/theme/users'

function Formulario(props) {
  const { data, onClose, onSubmit } = props

  const handleClose = () => onClose && onClose()

  const initialValues = data || {
    id: '',
    slug: '',
    value: '',
    default: '',
  }

  return (
    <>
      <DialogContent>
        <Formik
          key={initialValues.id}
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false)
            onSubmit && onSubmit(values)
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Grid container spacing={1}>
                <Grid item xs>
                  <Field
                    component={TextField}
                    name="slug"
                    label="Slug"
                    fullWidth
                    disabled
                  />
                </Grid>

                <Grid item xs>
                  <Field
                    component={TextField}
                    name="default"
                    label="Valor Padrão"
                    fullWidth
                    disabled
                  />
                </Grid>

                <Grid item xs>
                  <Field
                    component={TextField}
                    name="value"
                    label="Valor Atual"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <DialogActions>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  // onClick={submitForm}
                  type="submit"
                >
                  Salvar
                </Button>
                <Button onClick={handleClose}>Cancelar</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </>
  )
}

export default withStyles(styles)(Formulario)
