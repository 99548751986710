import { Grid } from '@material-ui/core'
import React from 'react'

import BankCard from './Card'

function BankCards({ balance }) {
  return (
    <Grid container spacing={1}>
      {Object.entries(balance).map(entry => {
        return (
          <Grid item xs>
            <BankCard tipo={entry[1].name} total={entry[1].balance} />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default BankCards
