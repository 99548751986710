import React from 'react'

function Infos({ marker }) {
  return (
    <>
      <p>{`Nome: ${marker.name}`}</p>
      <p>{`Atendente: ${marker.attendant}`}</p>
      <p>{`Endereço: ${marker.address}`}</p>
      <p>{`Observações: ${marker.observations}`}</p>
    </>
  )
}

export default Infos
