import React from 'react'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
  Box,
} from '@material-ui/core'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import classnames from 'classnames'
import CountUp from 'react-countup'

import { exportFormatedMoney } from '../../../util/utils'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1),
  },
  differenceValueNegative: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
  },
  noDifference: {
    color: colors.grey[900],
    marginRight: theme.spacing(1),
  },
}))

const FinancialCard = ({ total, tipo, positive, today, isBalanceCard }) => {
  const classes = useStyles()

  const contUp = money => {
    return (
      <CountUp
        end={money}
        duration={0.7}
        decimals={2}
        decimal=","
        prefix="R$"
        separator="."
      />
    )
  }

  const renderArrow = () => {
    if (isBalanceCard) {
      return (
        <>
          {today >= 0 ? (
            <ArrowUpward className={classes.differenceIcon} />
          ) : (
            <ArrowDownward className={classes.nullIcon} />
          )}
          <Typography
            className={classnames({
              [classes.differenceValue]: today >= 0,
              [classes.differenceValueNegative]: today < 0,
            })}
            variant="body2"
          >
            {contUp(today)}
          </Typography>
        </>
      )
    }
    if (positive) {
      return (
        <>
          <ArrowUpward className={classes.differenceIcon} />
          <Typography className={classes.differenceValue} variant="body2">
            {contUp(today)}
          </Typography>
        </>
      )
    }
    if (!positive) {
      return (
        <>
          <ArrowDownward className={classes.nullIcon} />
          <Typography
            className={classes.differenceValueNegative}
            variant="body2"
          >
            {contUp(today)}
          </Typography>
        </>
      )
    }
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              {tipo}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
              className={classnames({
                [classes.differenceValue]: positive,
                [classes.differenceValueNegative]: !positive,
              })}
            >
              {contUp(total)}
            </Typography>
          </Grid>
        </Grid>
        <Box mt={2} display="flex" alignItems="center">
          {renderArrow()}
          <Typography color="textSecondary" variant="caption">
            Hoje
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default FinancialCard
