/* eslint-disable import/order */
/* eslint-disable import/extensions */
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import { useDispatch } from 'react-redux'

import styles from '../../../resources/theme/global'
import { post } from '../BillingActions'
import Form from './Form'
import { Slide } from '@material-ui/core'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function NewBilling(props) {
  const { classes, history } = props
  const dispatch = useDispatch()

  const onSubmit = async (values) => {
    const data = await dispatch(post(values))
    if (data?.status === 204) {
      history.push('/billing')
    }
  }

  const onClose = () => {
    props.history.push('/billing')
  }

  return (
    <Dialog
      open
      fullWidth
      fullScreen
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Nova Conta
          </Typography>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Form
        {...props}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Dialog>
  )
}

export default withStyles(styles)(NewBilling)
