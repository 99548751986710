const colors = {
  primary: '#000B41',
  secondary: '#539FDD',
  textPrimary: '#8C8C8C',
  textSecondary: '#D9D9D9',
  textGray: '#646464',
  gray: '#646464',
  graylight: '#D9D9D9',
  grayMedium: '#BABABA',
  textCustom: '#000B41',
  dangerLight: '#ffebee',
  dangerMain: '#e84118',
  lightGray: '#F1F1F1',

  bgError: 'rgba(193, 88, 88, 0.20)',
  bgSucess: 'rgba(58, 168, 115, 0.20)',
  bgInfo: ' rgba(204, 160, 75, 0.20)',

  error: '#c15858',
  sucess: '#3AA873',
  info: '#CCA04B',
}

export default colors
