import React, { useState } from 'react'
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import Icon from '@mdi/react'
import {
  mdiFilterMultiple,
  mdiMagnify,
  mdiMicrosoftExcel,
  mdiNoteEditOutline,
} from '@mdi/js'
import filterStylesComponents from '../../../../../assets/filterComponentsStyles'
import colors from '../../../../../assets/colors'
import { useDispatch, useSelector } from 'react-redux'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { addDays, format, subMonths } from 'date-fns'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'

const Filter = ({ form, setForm, classes }) => {
  const attendants = useSelector(state => state.gerencyReport.attendants)

  const dispatch = useDispatch()

  const styles = filterStylesComponents()

  const [dates, setDates] = useState({
    date_start: format(subMonths(new Date(), 1), 'yyyy-MM-dd 00:00:00'),
    date_end: format(new Date(), 'yyyy-MM-dd 23:59:59'),
  })

  const handleChangeDateStart = date => {
    let formattedDate = date

    try {
      formattedDate = format(date, 'yyyy-MM-dd 00:00:00')
    } catch (error) {
      console.log('Invalid Date')
    }

    setDates({
      ...dates,
      date_start: formattedDate,
    })
  }

  const handleChangeDateEnd = date => {
    let formattedDate = date

    try {
      formattedDate = format(date, 'yyyy-MM-dd 23:59:59')
    } catch (error) {
      console.log('Invalid Date')
    }

    setDates({
      ...dates,
      date_end: formattedDate,
    })
  }

  const hanldeSubmit = e => {
    e.preventDefault()

    const formData = e.currentTarget
    const data = new FormData(formData)
    const entries = data.entries()
    const values = Object.fromEntries(entries)

    let filters = {
      ...values,
      ...dates,
    }

    setForm({
      ...form,
      ...filters,
    })
  }

  return (
    <Tooltip title="Filter list">
      <PopupState variant="popover" popupId="demo-popup-popover">
        {popupState => (
          <div>
            <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
              <FilterListIcon />
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2} className={classes.popover}>
                <div className={classes.root}>
                  <form onSubmit={hanldeSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <KeyboardDatePicker
                          clearable
                          label="Data Inicial"
                          fullWidth
                          views={['date']}
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          value={dates.date_start}
                          onChange={handleChangeDateStart}
                          format="dd/MM/yyyy"
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <KeyboardDatePicker
                          clearable
                          label="Data Final"
                          fullWidth
                          views={['date']}
                          margin="dense"
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          value={dates.date_end}
                          onChange={handleChangeDateEnd}
                          format="dd/MM/yyyy"
                          inputVariant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          select
                          name="attendant_id"
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          className={classes.inputFilter}
                          key={`textField-attendant_id-${form?.attendant_id}`}
                          defaultValue={form?.attendant_id}
                          label="Atendente"
                        >
                          <MenuItem value="">Selecione...</MenuItem>

                          {attendants?.map((attendant, index) => (
                            <MenuItem value={attendant?.id} key={index}>
                              {attendant?.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs>
                        <Button
                          type="submit"
                          color="primary"
                          fullWidth
                          variant="contained"
                          className={classes.submit}
                          onClick={popupState.close}
                        >
                          <SearchIcon />
                          {` Filtrar`}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </Tooltip>
  )
}

export default Filter
