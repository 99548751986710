import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const SUBMIT_CONFIG = createAsyncConst('SUBMIT_CONFIG')
export const FETCH_CONFIG = createAsyncConst('FETCH_CONFIG')
export const EDIT_CONFIG = createAsyncConst('EDIT_CONFIG')

export function fetchConfig(filters = {}) {
  return dispatch => {
    dispatch(action(FETCH_CONFIG.ACTION))
    return axios
      .get(`${config.API_URL}/setting`, {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => {
        dispatch(action(FETCH_CONFIG.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_CONFIG, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_CONFIG.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/setting/${id}`)
      .then(resp => {
        return resp.data.data
      })
      .then(data => dispatch(action(EDIT_CONFIG.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(EDIT_CONFIG, e)))
  }
}

function submit(values, method) {
  const id = values.id ? `/${values.id}` : ''

  return dispatch => {
    dispatch(action(SUBMIT_CONFIG.ACTION))
    return axios[method](`${config.API_URL}/setting${id}`, values)
      .then(resp => resp)
      .then(data => {
        dispatch(action(SUBMIT_CONFIG.SUCCESS, data))
        dispatch(fetchConfig())
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_CONFIG, e)))
  }
}

export function post(values) {
  return submit(values, 'post')
}

export function update(values) {
  return submit(values, 'put')
}
