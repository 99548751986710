import { EDIT_PAYMENT_METHOD, FETCH_PAYMENT_METHOD } from './PaymentTypeActions'

const INITIAL_STATE = {
  items: [],
  filters: {},
  editingPaymentMethod: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PAYMENT_METHOD.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data, 
        filters: action.payload.filters
      }
    case EDIT_PAYMENT_METHOD.SUCCESS:
      return {
        ...state,
        editingPaymentMethod: action.payload,
      }
    default:
      return state
  }
}

export const getPaymentMethodListData = state => {
  return {
    items: state.paymentMethod.items,
  }
}
