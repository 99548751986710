import React from 'react'
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
} from '@material-ui/core'

import { exportFormatedMoney } from '../../../util/utils'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  positive: {
    color: colors.green[900],
  },
  negative: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
  },
}))

const DashboardCard = props => {
  const classes = useStyles()

  const { name, value, colorClass } = props

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              {name}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
              className={classes[colorClass]}
            >
              {exportFormatedMoney(value)}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default DashboardCard
