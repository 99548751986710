/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import { TextField } from 'formik-material-ui'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React from 'react'
import { Formik, Form, Field } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { KeyboardDatePicker } from 'formik-material-ui-pickers'

import { fetchAttendanceLaundry } from '../AttendanceLaundryActions'

export default function Filter(props) {
  const dispatch = useDispatch()
  const rowsPerPage = useSelector(state => state.attendanceLaundry.sizePerPage)
  const { classes } = props

  return (
    <Tooltip title="Filter list">
      <PopupState variant="popover" popupId="demo-popup-popover">
        {popupState => (
          <div>
            <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
              <FilterListIcon />
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2} className={classes.popover}>
                <div className={classes.root}>
                  <Formik
                    initialValues={{
                      // email: '',
                      name: '',
                      date: null,
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setSubmitting(false)
                      dispatch(fetchAttendanceLaundry(values, rowsPerPage))
                    }}
                  >
                    {({ submitForm, isSubmitting }) => (
                      <Form className={classes.form}>
                        <Grid
                          container
                          className={classes.gridContainer}
                          spacing={1}
                          justify="center"
                          alignItems="center"
                        >
                          <Grid item xs={6}>
                            <Field
                              component={TextField}
                              label="Nome"
                              margin="dense"
                              className={classes.inputFilter}
                              variant="outlined"
                              fullWidth
                              name="name"
                            />
                          </Grid>

                          {/* <Grid item xs={6}>
                            <Field
                              component={TextField}
                              label="Email"
                              margin="dense"
                              className={classes.inputFilter}
                              variant="outlined"
                              fullWidth
                              name="email"
                            />
                          </Grid> */}

                          <Grid item xs={6}>
                            <Field
                              component={KeyboardDatePicker}
                              name="date"
                              clearLabel="Limpar"
                              label="Mês de referência"
                              margin="dense"
                              views={['year', 'month']}
                              openTo="year"
                              fullWidth
                              clearable
                              orientation="landscape"
                              inputVariant="outlined"
                              autoOk
                            />
                          </Grid>

                          <Grid item xs>
                            <Button
                              color="primary"
                              fullWidth
                              type="submit"
                              variant="contained"
                              className={classes.submit}
                              onClick={popupState.close}
                            >
                              <SearchIcon />
                              {` Filtrar`}
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </Tooltip>
  )
}
