/* eslint-disable eqeqeq */
/* eslint-disable react/no-array-index-key */
import {
  Box,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
  colors,
  makeStyles,
} from '@material-ui/core'
import classnames from 'classnames'
import React from 'react'
import CountUp from 'react-countup'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    padding: '12px 0',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '.5rem',
  },
  cardTitle: {
    textAlign: 'start',
  },
  cardTitleLink: {
    cursor: 'pointer',
    textAlign: 'start',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1),
  },
  differenceValueNegative: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
  },
  noDifference: {
    color: colors.grey[900],
    marginRight: theme.spacing(1),
  },
}))

const CardSection = ({
  title,
  data,
  classes,
  isLinkCard = false,
  isMonetary = false,
  isLinkRelatory = false,
  finalDate = '',
  startDate = '',
  selectedLaundry = '',
  selectedAttendance = '',
}) => {
  const props = useStyles()

  const contUp = (money, isPercent = false) => {
    return (
      <CountUp
        end={money}
        duration={0.9}
        decimals={2}
        decimal=","
        prefix={isPercent ? '' : 'R$'}
        suffix={isPercent ? '%' : ''}
        separator="."
      />
    )
  }

  const attendancesPath = element => {
    return `/relatory-services?status=${element?.type}&finalDate=${finalDate}&startDate=${startDate}&laundry=${selectedLaundry}&attendance=${selectedAttendance}`
  }

  const relatoryPath = element => {
    return `/relatory-schedules?status=${element?.type}&finalDate=${finalDate}&startDate=${startDate}&laundry=${selectedLaundry}&attendance=${selectedAttendance}`
  }

  const cardLinkable = isLinkCard || isLinkRelatory

  return (
    <Box className={props.root}>
      <Typography
        variant="h4"
        color="secondary"
        style={{ marginBottom: '1.5rem' }}
      >
        {title}
      </Typography>
      <Grid
        className={classes.financialCard}
        direction="row"
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        {data?.data?.map((element, index) => {
          return (
            <Grid
              item
              className={props.cardElement}
              key={index}
              xs={12}
              md={6}
              lg={3}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item>
                      {cardLinkable &&
                      element?.type != 'total' &&
                      element?.type != 'ticket' ? (
                        <Link
                          component={RouterLink}
                          to={
                            isLinkCard
                              ? attendancesPath(element)
                              : relatoryPath(element)
                          }
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                          className={props.cardTitleLink}
                        >
                          {element.name}
                        </Link>
                      ) : (
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                          className={props.cardTitle}
                        >
                          {element.name}
                        </Typography>
                      )}
                      {isMonetary ? (
                        <Typography
                          color="textPrimary"
                          variant="h3"
                          className={classnames({
                            [props.differenceValue]: element.value >= 0,
                            [props.differenceValueNegative]: element.value < 0,
                          })}
                        >
                          {contUp(element.value, element?.agendament)}
                        </Typography>
                      ) : (
                        <Typography color="textPrimary" variant="h3">
                          {element.value}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default CardSection
