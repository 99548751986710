/* eslint-disable import/order */
/* eslint-disable no-new */
/* eslint-disable global-require */
import React, { useEffect, useState, useCallback, useRef } from 'react'
import ptLocale from '@fullcalendar/core/locales/pt-br'
import { useDispatch, useSelector } from 'react-redux'
import {
  withStyles,
  Grid,
  Paper,
  FormControlLabel,
  Checkbox,
  Slide,
  TextField,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Typography,
  Switch,
  Box,
} from '@material-ui/core'
import moment from 'moment-timezone'
import { useConfirm } from 'material-ui-confirm'
import queryString from 'query-string'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Backdrop from '../../components/Backdrop'
import styles from '../../../resources/theme/global'
import {
  findAttendantes,
  getServicesUnbooked,
  getServiceUnbookedById,
  updateServiceItem,
  getServicesBooked,
  toogleOpenNextDay,
} from '../CalendarActions'
import { convertDateToUTC, renderRoute } from '../../../util/utils'

import Modal from './modal'
import Chips from './chips'
import BackdropProgress from './BackdropProgress'

import { SNACKBAR } from '../../main/MainActions'

import '../../../resources/less/fullCalendar.less'
import DetachedForm from './DetachedForm'
import Reorder from './Reorder'
import classNames from 'classnames'
import { addDays, format } from 'date-fns'

function Calendar(props) {
  const { classes, match } = props
  const calendarComponentRef = useRef()
  const eventsRef = useRef()
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const attendants = useSelector(state => state.calendar.attendants)
  const [modalOpen, setModalOpen] = useState(false)
  const [backdrop, setBackdrop] = useState(false)
  const [currentAttendant, setCurrentattendant] = useState(null)
  const [currentEvent, setCurrentEvent] = useState(null)
  const [attendant, setAttendant] = useState('')
  const [unbooked, setUnbooked] = useState([])
  const [bookedEvents, setBookedEvents] = useState([])
  const [checkeds, setCheckeds] = useState([])
  const [collapse, setCollapse] = useState(false)
  const [showCurrent, setShowCurrent] = useState(false)
  const [detachedOpen, setDetachedOpen] = useState(false)
  const [reorderOpen, setReorderOpen] = useState(false)
  const [expanded, setExpanded] = React.useState(true)
  const [nextDayCalendarOpen, setNextDayCalendarOpen] = useState(false)
  const [selectedEvents, setSelectedEvents] = useState([])
  const [progress, setProgress] = useState(0)
  const [isReordering, setIsReordering] = useState(false)
  const [textFinishedReorder, setTextFinishedReorder] = useState('')
  const [messageColor, setMessageColor] = useState(null)
  const permissions = useSelector(state => state.auth.permissions)
  const user = useSelector(state => state.auth.user)
  const fetchingReorder = useSelector(state => state.calendar.fetching)
  let timeout = 0

  const [bookedFiltered, setBookedFiltered] = useState(bookedEvents)

  if (typeof window !== 'undefined') {
    const { default: FullCalendar } = require('@fullcalendar/react')
    const { default: dayGridPlugin } = require('@fullcalendar/daygrid')
    const { default: timeGridPlugin } = require('@fullcalendar/timegrid')
    const {
      default: interactionPlugin,
      Draggable,
    } = require('@fullcalendar/interaction')

    const filterAttendant = useCallback(id => {
      setAttendant(id)
      setSelectedEvents([])
    })

    const handleChangeCollapse = useCallback(() => {
      setCollapse(prev => !prev)
    })

    const handleShowCurrent = useCallback(() => {
      setShowCurrent(prev => !prev)
    })

    const handleDetachedClose = useCallback(() => {
      setDetachedOpen(false)
    }, [])

    const handleReopenClose = useCallback(() => {
      setReorderOpen(false)
    }, [])

    const handleChecked = event => {
      const id = event.target.name
      const find = checkeds.findIndex(item => item === parseInt(id, 10))
      if (find > -1) {
        setCheckeds(checkeds.filter(item => item !== parseInt(id, 10)))
      } else {
        setCheckeds([...checkeds, parseInt(id, 10)])
      }
    }

    const handleChangeFilter = async e => {
      const { value } = e.target
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(async () => {
        const serviceId = match.params.id
        setBackdrop(true)
        const filtered = await dispatch(getServicesUnbooked(serviceId, value))
        setBackdrop(false)

        const { data } = filtered
        setUnbooked(data)
      }, 500)
    }

    useEffect(() => {
      const qs = queryString.parse(props.location.search)

      if (attendant) {
        setBookedFiltered(
          bookedEvents.filter(item => item.attendant?.id === attendant)
        )
      } else if (showCurrent) {
        setBookedFiltered(
          bookedEvents.filter(
            item => item.serviceId == qs.os || item.id == qs.ositem
          )
        )
      } else {
        setBookedFiltered(bookedEvents)
      }
    }, [bookedEvents, attendant, showCurrent])

    const initialLoad = async () => {
      const serviceId = match.params.id
      // dispatch(findAttendantes())
      const resp = await dispatch(getServicesUnbooked(serviceId))
      const respBooked = await dispatch(getServicesBooked())
      if (resp?.code === 200) {
        const { data } = resp

        setUnbooked(data)
      }

      if (respBooked?.code === 200) {
        const { data, nextDayCalendarOpen } = respBooked
        const qs = queryString.parse(props.location.search)

        setNextDayCalendarOpen(nextDayCalendarOpen)

        setBookedEvents(data)

        if (qs.os || qs.ositem) {
          setShowCurrent(true)
          const calendarApi = calendarComponentRef.current.getApi()
          calendarApi.changeView('dayGridMonth')
        }
      }
    }

    useEffect(() => {
      dispatch(findAttendantes())

      initialLoad()
    }, [])

    useEffect(() => {
      window.Echo.private(`Calendar.${user.id}`).listen(
        `OrderCalendarForUserFinished`,
        event => {
          const { percentage, message, userColor } = event
          setProgress(percentage < 100 ? percentage : 0)
          setTextFinishedReorder(percentage < 100 ? message : '')
          setMessageColor(userColor)
        }
      )

      window.Echo.channel(`Calendar`).listen(`OrderCalendarStarted`, () =>
        setIsReordering(true)
      )

      window.Echo.channel(`Calendar`).listen(`OrderCalendarFinished`, () => {
        setIsReordering(false)
        initialLoad()
      })

      window.Echo.channel(`Calendar`).listen(
        `CalendarEventHasUpdatedEvent`,
        () => {
          initialLoad()
        }
      )

      return () => {
        window.Echo.leaveChannel(`Calendar`)
        window.Echo.leaveChannel(`Calendar.${user.id}`)
      }
    }, [])

    const reloadBookedEvents = useCallback(async () => {
      const respBooked = await dispatch(getServicesBooked())

      if (respBooked?.code === 200) {
        const { data } = respBooked
        const qs = queryString.parse(props.location.search)

        setBookedEvents(data)

        if (qs.os || qs.ositem) {
          setShowCurrent(true)
          const calendarApi = calendarComponentRef.current.getApi()
          calendarApi.changeView('dayGridMonth')
        }
      }
    }, [])

    useEffect(() => {
      const draggableEl = eventsRef.current
      new Draggable(draggableEl, {
        itemSelector: '.fc-event',
        eventData(eventEl) {
          const title = eventEl.getAttribute('title')
          const id = eventEl.getAttribute('id')
          const duration = eventEl.getAttribute('duration')
          const serviceId = eventEl.getAttribute('serviceId')
          const isLaundry = eventEl.getAttribute('isLaundry')
          const schedulingInformation = eventEl.getAttribute(
            'schedulingInformation'
          )
          return {
            title,
            id,
            duration,
            serviceId,
            isLaundry,
            schedulingInformation,
            revert: true,
            create: false,
          }
        },
      })
    }, [collapse])

    const onDrop = dropInfo => {
      if (!attendant) {
        dispatch({
          type: SNACKBAR.HARDFAIL,
          error: { message: 'Para agendar o evento selecione um técnico.' },
        })

        return
      }

      const startHour = moment(dropInfo.dateStr).format('HH:mm')

      confirm({
        description: `Deseja marcar o compromisso à partir das ${startHour}?`,
        title: 'Tem certeza?',
        confirmationText: 'Sim',
        cancellationText: 'Cancelar',
        dialogProps: {
          fullWidth: true,
        },
      }).then(async () => {
        const { id } = dropInfo.draggedEl

        let newCheckeds = [...checkeds]

        if (checkeds.indexOf(parseInt(id, 10)) === -1) {
          newCheckeds = [parseInt(id, 10), ...newCheckeds]
        }

        let initial = null
        let final = null

        const newEvents = []
        newCheckeds.forEach((ident, key) => {
          const unbookedFind = unbooked.find(
            item => item.id === parseInt(ident, 10)
          )

          if (key === 0) {
            initial = moment(dropInfo.dateStr)
            final = moment(dropInfo.dateStr).add(unbookedFind.final_time)
          } else {
            initial = final.clone()
            final = final.add(unbookedFind.final_time)
          }

          let newEvent = {}

          if (unbookedFind.service?.measure_type_id === 1) {
            newEvent = {
              title: `Bairro: ${unbookedFind.client?.bairro} - Item: ${
                unbookedFind.service?.name
              } - Tempo: ${unbookedFind.final_time} - Cliente: ${
                unbookedFind.client?.name
              } - Etiqueta: ${unbookedFind.bar_code || '-'}`,
              start: initial.format(),
              end: final.format(),
              serviceId: unbookedFind.serviceId,
              id: unbookedFind.id,
              isLaundry: unbookedFind.isLaundry,
              schedulingInformation: unbookedFind.scheduling_information,
            }
          } else {
            newEvent = {
              title: `Bairro: ${unbookedFind.client?.bairro} - Item: ${
                unbookedFind.service?.name
              } - Tempo: ${unbookedFind.final_time} - Cliente: ${
                unbookedFind.client?.name
              } - Etiqueta: ${unbookedFind.bar_code || '-'}`,
              start: dropInfo.dateStr,
              end: moment(dropInfo.dateStr)
                .add(unbookedFind.final_time)
                .format(),
              serviceId: unbookedFind.serviceId,
              id: unbookedFind.id,
              isLaundry: unbookedFind.isLaundry,
              schedulingInformation: unbookedFind.scheduling_information,
            }
          }

          if (attendant) {
            const att = attendants.find(item => item.id === attendant)

            const newProps = {
              ...newEvent,
              attendant: att,
              backgroundColor: att.color,
            }

            newEvents.push(newProps)
          } else {
            newEvents.push(newEvent)
          }
        })

        setCheckeds([])
        setBackdrop(true)
        const result = newEvents.reduce((promise, nextEvent, index, array) => {
          const isLast = index + 1 === array.length
          return promise.then(() => {
            return dispatch(updateServiceItem(nextEvent.id, nextEvent, isLast))
          })
        }, Promise.resolve())

        result.then(() => {
          setBackdrop(false)
          setBookedEvents([...bookedEvents, ...newEvents])
        })

        setUnbooked(
          unbooked.filter(item => {
            return newCheckeds.indexOf(item.id) === -1
          })
        )
      })
    }

    const handleSelectAllFromAttendant = attendantId => {
      const calendarApi = calendarComponentRef.current.getApi()
      const { start, end } = calendarApi.state.dateProfile.currentRange
      const startCalendarDate = convertDateToUTC(new Date(start))
      const endCalendarDate = convertDateToUTC(new Date(end))

      setSelectedEvents(
        bookedEvents
          .filter(item => {
            const startEventDate = new Date(item.start)
            const isBetween =
              startCalendarDate.getTime() <= startEventDate.getTime() &&
              endCalendarDate.getTime() >= startEventDate.getTime()

            return item.attendant?.id === attendantId && isBetween
          })
          .map(item => String(item.id))
      )
    }

    const handleeventDrop = eventDropInfo => {
      const booked = [...bookedEvents]
      const { id } = eventDropInfo.event
      const { copy } = eventDropInfo.event.extendedProps
      const finded = booked.findIndex(
        item => item.id === parseInt(id, 10) && item.copy === copy
      )

      booked[finded] = {
        ...booked[finded],
        start: moment(eventDropInfo.event.start)
          .tz('America/Sao_Paulo')
          .format(),
        end: moment(eventDropInfo.event.end).tz('America/Sao_Paulo').format(),
      }

      setBackdrop(true)
      const hasMoreEvents = selectedEvents.indexOf(id) === -1
      dispatch(
        updateServiceItem(booked[finded].id, booked[finded], hasMoreEvents)
      ).then(resp => {
        if (resp?.code === 200) {
          setBookedEvents(booked)
        }

        setBackdrop(false)
      })

      if (selectedEvents.indexOf(id) === -1) return
      const filteredNoCurrent = selectedEvents.filter(item => item !== id)

      if (filteredNoCurrent.length > 0) {
        const newStart = moment(eventDropInfo.event.start).tz(
          'America/Sao_Paulo'
        )
        const oldStart = moment(eventDropInfo.oldEvent.start).tz(
          'America/Sao_Paulo'
        )

        const diff = moment.duration(newStart.diff(oldStart)).asMinutes()

        filteredNoCurrent.forEach((filteredItem, key) => {
          const isLast = key + 1 === filteredNoCurrent.length

          const findIndex = booked.findIndex(
            item => item.id === parseInt(filteredItem, 10)
          )
          const currentEventStart = moment(booked[findIndex].start).tz(
            'America/Sao_Paulo'
          )
          const currentEventEnd = moment(booked[findIndex].end).tz(
            'America/Sao_Paulo'
          )
          const currentEventDiff = moment
            .duration(currentEventEnd.diff(currentEventStart))
            .asMinutes()

          booked[findIndex] = {
            ...booked[findIndex],
            start: currentEventStart.add(diff, 'minutes').format(),
            end: currentEventStart.add(currentEventDiff, 'minutes').format(),
          }

          dispatch(
            updateServiceItem(booked[findIndex].id, booked[findIndex], isLast)
          )
        })

        setBookedEvents(booked)
      }
      setSelectedEvents([])
    }

    const handleClose = useCallback(() => {
      setModalOpen(false)
    }, [])

    const handleEventClick = eventClickInfo => {
      const { id } = eventClickInfo.event
      const { copy } = eventClickInfo.event.extendedProps
      if (eventClickInfo?.jsEvent?.ctrlKey) {
        const index = selectedEvents.indexOf(id)

        if (index > -1) {
          setSelectedEvents(events => events.filter(item => item !== id))
        } else {
          setSelectedEvents(events => [...events, id])
        }
      } else {
        const event = bookedEvents.find(
          item => item.id === parseInt(id, 10) && item.copy === copy
        )
        setCurrentattendant(event.attendant)
        setCurrentEvent(event)
        setModalOpen(true)
      }
    }

    useEffect(() => {
      if (selectedEvents.length > 0) {
        dispatch({
          type: SNACKBAR.INFO,
          success: {
            message: `${selectedEvents.length} evento(s) selecionados`,
          },
        })
      }
    }, [selectedEvents])

    const handleCancel = useCallback(
      (id, copy) => {
        confirm({
          description: 'Deseja desmarcar o compromisso?',
          title: 'Tem certeza?',
          confirmationText: 'Sim',
          cancellationText: 'Cancelar',
          dialogProps: {
            fullWidth: true,
            classes: {},
          },
        }).then(async () => {
          setModalOpen(false)
          setBackdrop(true)
          const info = await dispatch(getServiceUnbookedById(id, copy))
          if (info?.code === 200) {
            const { data, renderBack } = info
            if (renderBack) setUnbooked([...unbooked, data])
            setBookedEvents(bookedEvents.filter(item => item.id !== id))
          }
          setBackdrop(false)
        })
      },
      [bookedEvents, unbooked]
    )

    const changeAttendant = (event, attendant) => {
      const booked = [...bookedEvents]

      const { copy } = event

      const finded = booked.findIndex(
        item => item.id === parseInt(event.id, 10) && item.copy === copy
      )

      booked[finded] = {
        ...booked[finded],
        start: moment(booked[finded].start).tz('America/Sao_Paulo').format(),
        end: moment(booked[finded].end).tz('America/Sao_Paulo').format(),
        attendant,
        backgroundColor: attendant.color,
      }

      setModalOpen(false)
      setBackdrop(true)
      const hasMoreEvents = selectedEvents.includes(String(event.id))
      dispatch(
        updateServiceItem(booked[finded].id, booked[finded], !hasMoreEvents)
      ).then(resp => {
        if (resp?.code === 200) {
          if (!selectedEvents.includes(String(event.id))) {
            setBookedEvents(booked)
            setAttendant(null)
            setBackdrop(false)
          }
        }
      })

      if (selectedEvents.includes(String(event.id))) {
        const result = selectedEvents.reduce(
          (promise, nextEvent, index, array) => {
            const isLast = index + 1 === array.length
            return promise.then(() => {
              const findIndex = booked.findIndex(
                item => item.id === parseInt(nextEvent, 10)
              )

              booked[findIndex] = {
                ...booked[findIndex],
                attendant,
                backgroundColor: attendant.color,
              }

              return dispatch(
                updateServiceItem(
                  booked[findIndex].id,
                  booked[findIndex],
                  isLast
                )
              )
            })
          },
          Promise.resolve()
        )

        result.then(() => {
          setBookedEvents(booked)
          setAttendant(null)
          setBackdrop(false)
        })

        setSelectedEvents([])
      }
    }

    const customEventRender = info => {
      const { id } = info.event
      const index = selectedEvents.indexOf(id)
      if (index > -1) {
        info.el.style.opacity = 0.4
      } else {
        info.el.style.opacity = 1
      }
    }

    const changeViewToDate = useCallback(date => {
      const momentDate = moment(date).format()

      const calendarApi = calendarComponentRef.current.getApi()
      calendarApi.changeView('timeGridDay', momentDate)
    }, [])

    const getBgColor = color => {
      if (color) return color
    }

    return (
      <>
        <Backdrop open={backdrop} />
        {fetchingReorder && (
          <BackdropProgress
            progress={progress}
            text={textFinishedReorder}
            color={messageColor}
          />
        )}
        {renderRoute(['create-calendar-detached'], permissions) && (
          <DetachedForm
            open={detachedOpen}
            attendants={attendants}
            handleClose={handleDetachedClose}
            reloadBookedEvents={reloadBookedEvents}
          />
        )}

        {renderRoute(['create-calendar-detached'], permissions) && (
          <Reorder
            open={reorderOpen}
            attendants={attendants}
            handleClose={handleReopenClose}
            reloadBookedEvents={reloadBookedEvents}
            changeViewToDate={changeViewToDate}
            isReordering={isReordering}
          />
        )}
        <Modal
          open={modalOpen}
          handleClose={handleClose}
          attendants={attendants}
          currentAttendant={currentAttendant}
          currentEvent={currentEvent}
          changeAttendant={changeAttendant}
          handleCancel={handleCancel}
        />
        <Grid container spacing={1}>
          <Slide
            direction="right"
            in={collapse}
            timeout={350}
            className={classes.calendarEventsContainer}
          >
            {collapse ? (
              <Grid item xs={3}>
                <Paper className={classes.paper} elevation={2} ref={eventsRef}>
                  <TextField
                    onChange={handleChangeFilter}
                    placeholder="Filtro..."
                    margin="dense"
                    className={classes.inputFilter}
                    variant="outlined"
                    fullWidth
                  />
                  {unbooked.map(event => {
                    const { id } = event
                    return (
                      <Grid container spacing={1} key={event.id}>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checkeds.indexOf(id) > -1}
                                onChange={handleChecked}
                                name={`${id}`}
                              />
                            }
                          />
                        </Grid>
                        <Grid item xs>
                          <div
                            className="fc-event"
                            title={event.service?.name}
                            id={id}
                            key={id}
                            duration={event.final_time}
                            attendant={event.attendant}
                            client_name={event.client.name || 'Não definido'}
                            schedulingInformation={event.schedulingInformation}
                            style={{
                              cursor: 'pointer',
                              backgroundColor: getBgColor(event.service?.color),
                              padding: '5px',
                            }}
                          >
                            {`Bairro: ${event.client?.bairro}`} <br />
                            {`Item: ${event.service?.name}`} <br />
                            {`Cliente: ${event.client?.name}`} <br />
                            {event.vendedor?.name
                              ? `Vendedor: ${event.vendedor?.name}`
                              : ''}
                            <br />
                            {event.bar_code
                              ? `Etiqueta: ${event.bar_code}`
                              : ''}
                          </div>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Paper>
              </Grid>
            ) : (
              <div>
                <span ref={eventsRef} />
              </div>
            )}
          </Slide>
          <Grid item xs>
            <Accordion
              expanded={expanded}
              onChange={() => setExpanded(!expanded)}
              className={classNames({
                [classes.chipdsDefaultPannel]: !expanded,
                [classes.chipdsDefaultPannelExpanded]: expanded,
              })}
            >
              <Box className={classes.boxIcons}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={collapse}
                      onChange={handleChangeCollapse}
                    />
                  }
                  label="Mostrar Eventos"
                  color="primary"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={showCurrent}
                      onChange={handleShowCurrent}
                    />
                  }
                  label="Somente desta OS"
                  color="primary"
                />
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  classes={{ content: classes.content }}
                  style={{
                    width: '245px',
                    marginTop: '1px',
                  }}
                >
                  <Typography className={classes.headingChips}>
                    Técnicos Disponíveis
                  </Typography>
                </AccordionSummary>
              </Box>
              <AccordionDetails className={classes.accordionChips}>
                <Chips
                  {...props}
                  attendants={attendants}
                  filterAttendant={filterAttendant}
                  attendant={attendant}
                  handleSelectAllFromAttendant={handleSelectAllFromAttendant}
                />
              </AccordionDetails>
            </Accordion>
            <div className={classes.calendarBody}>
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay',
                }}
                height="parent"
                header={{
                  left: `prev,next today ${isReordering ? '' : `reorder`} ${
                    renderRoute(['toogle-next-day-open'], permissions)
                      ? 'nextDayOpen'
                      : ''
                  }`,
                  center: 'title',
                  right:
                    'detached,dayGridMonth,timeGridWeek,timeGridDay,listWeek',
                }}
                defaultView="timeGridDay"
                locale={ptLocale}
                customButtons={{
                  detached: {
                    text: 'Avulso',
                    click() {
                      setDetachedOpen(true)
                    },
                  },
                  reorder: {
                    text: 'Reordenar',
                    click: () => {
                      setReorderOpen(true)
                    },
                  },
                  nextDayOpen: {
                    text: nextDayCalendarOpen
                      ? `Fechar a visualização do dia ${format(
                          addDays(new Date(), 1),
                          'dd/MM/yyyy'
                        )}`
                      : `Abrir a visualização do dia ${format(
                          addDays(new Date(), 1),
                          'dd/MM/yyyy'
                        )}`,
                    click: async () => {
                      const resp = await dispatch(toogleOpenNextDay())
                      if (resp?.status === 200) {
                        setNextDayCalendarOpen(!nextDayCalendarOpen)
                      }
                    },
                  },
                }}
                editable
                defaultDate={new Date()}
                droppable
                ref={calendarComponentRef}
                slotDuration="00:15:00"
                slotLabelInterval="01:00:00"
                slotLabelFormat={{
                  hour: 'numeric',
                  minute: '2-digit',
                  omitZeroMinute: false,
                  meridiem: 'short',
                }}
                scrollTime="07:00:00"
                navLinks
                weekNumberCalculation="ISO"
                eventDurationEditable={false}
                selectMirror
                dayMaxEvents
                events={bookedFiltered}
                drop={onDrop}
                eventRender={customEventRender}
                eventDrop={handleeventDrop}
                eventClick={handleEventClick}
                allDayText="Dia Todo"
                // dateClick={handleDateClick}
              />
            </div>
          </Grid>
        </Grid>
      </>
    )
  }
  return <div />
}

export default withStyles(styles)(Calendar)
