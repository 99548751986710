import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const REMOVE_BILLING = createAsyncConst('REMOVE_BILLING')
export const SUBMIT_BILLING = createAsyncConst('SUBMIT_BILLING')
export const FETCH_BILLING = createAsyncConst('FETCH_BILLING')
export const EDIT_BILLING = createAsyncConst('EDIT_BILLING')
export const BILL_PAID = createAsyncConst('BILL_PAID')

export function fetchBilling(filters = {}, rows, page) {
  return dispatch => {
    dispatch(action(FETCH_BILLING.ACTION))
    return axios
      .get(`${config.API_URL}/billing`, {
        params: {
          filters: JSON.stringify(filters),
          rows,
          page: page || 1,
        },
      })
      .then(resp => {
        dispatch(action(FETCH_BILLING.SUCCESS, { data: resp.data, filters }))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_BILLING, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_BILLING.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/billing/${id}`)
      .then(resp => {
        return resp.data.data
      })
      .then(data => dispatch(action(EDIT_BILLING.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(EDIT_BILLING, e)))
  }
}

function submit(values, method) {
  const id = values.id ? `/${values.id}` : ''

  return dispatch => {
    dispatch(action(SUBMIT_BILLING.ACTION))
    return axios[method](`${config.API_URL}/billing${id}`, values)
      .then(resp => resp)
      .then(data => {
        dispatch(action(SUBMIT_BILLING.SUCCESS, data))
        dispatch(fetchBilling())
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_BILLING, e)))
  }
}

export function post(values) {
  return submit(values, 'post')
}

export function update(values) {
  return submit(values, 'put')
}

export function remove(id) {
  return dispatch => {
    dispatch(action(REMOVE_BILLING.ACTION))
    return axios
      .delete(`${config.API_URL}/billing/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(REMOVE_BILLING.SUCCESS, data))
        dispatch(fetchBilling())
      })
      .catch(e => dispatch(actionFailed(REMOVE_BILLING, e)))
  }
}

export function billPaid(id, values) {
  return dispatch => {
    dispatch(action(BILL_PAID.ACTION))

    return axios
      .post(`${config.API_URL}/billing/${id}/change-paid`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(BILL_PAID.SUCCESS, data))
        dispatch(fetchBilling())
        return data
      })
      .catch(e => dispatch(actionFailed(BILL_PAID, e)))
  }
}

export function clearFilter() {
  return {
    type: 'CLEAR_BILLING_FILTERS',
  }
}

export function clearBilling() {
  return {
    type: 'CLEAR_BILLING',
  }
}
