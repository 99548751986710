import React from 'react'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
  Grid,
} from '@material-ui/core'
import InputMask from 'react-input-mask'
import { BiCalculator } from 'react-icons/bi'

import { duplicateTime, exportFormatedMoney } from '../../../util/utils'
import NumberFormatCustom from '../../components/NumberFormat'

function TotalTable({
  form,
  classes,
  setForm,
  noDiscount,
  onChangeFinalValue,
  setGeneralFinalAltered,
}) {
  const times = []
  let hasType2 = 0
  form.services?.map(item => {
    if (item.measureType?.id === 1) {
      if (item.final_time.match(/(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/)) {
        times.push(duplicateTime([item.final_time], 1))
      }
    } else {
      if (hasType2 === 0) {
        times.push(duplicateTime([item.execution_time], 1))
      }
      hasType2++
    }
  })

  return (
    <>
      <TableContainer className={classes.totalTableRoot}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Total Horas</TableCell>
              <TableCell>Desconto Geral</TableCell>
              <TableCell>Total geral S/ Desconto</TableCell>
              <TableCell>Total geral C/ Desconto</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{duplicateTime(times, 1)}</TableCell>
              <TableCell>
                <InputMask
                  mask="?????"
                  style={{ maxWidth: '100px' }}
                  formatChars={{ 9: '[0-9]', '?': '[0-9/.]' }}
                  maskChar=""
                  value={form.general_discount}
                  placeholder="0%"
                  onChange={e => {
                    setGeneralFinalAltered(true)
                    setForm({ ...form, general_discount: e.target.value })
                  }}
                  inputProps={{ style: { textAlign: 'center' } }}
                >
                  {inputProps => <TextField {...inputProps} fullWidth />}
                </InputMask>
              </TableCell>
              <TableCell>{exportFormatedMoney(noDiscount || 0)}</TableCell>
              <TableCell>
                <TextField
                  placeholder="Valor Final"
                  value={form.final_value_discount}
                  onChange={e =>
                    setForm({ ...form, final_value_discount: e.target.value })
                  }
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <IconButton
                  color="inherit"
                  onClick={() => onChangeFinalValue()}
                  aria-label="Close"
                >
                  <BiCalculator />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container>
        <Grid item xs>
          <TextField
            placeholder="Observações Gerais"
            value={form.general_observations}
            onChange={e =>
              setForm({ ...form, general_observations: e.target.value })
            }
            variant="outlined"
            multiline
            fullWidth
            rows={5}
            rowsMax={10}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default TotalTable
