import { FETCH_FINANCIAL_RELATORY_INIT } from './FinancialRelatoryActions'

const INITIAL_STATE = {
  expenses: [],
  receipts: [],
  expense_subtotal: '',
  receipt_subtotal: '',
  total: '',
  filters: [],
  billing_types: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FINANCIAL_RELATORY_INIT.SUCCESS:
      return {
        ...state,
        expenses: action.payload.data.data,
        receipts: action.payload.data.receipt,
        expense_subtotal: action.payload.data.expense_subtotal,
        receipt_subtotal: action.payload.data.receipt_subtotal,
        total: action.payload.data.total,
        filters: action.payload.filters,
        billing_types: action.payload.data.billing_types
      }
    case "CLEAR_ACTION_FINANCIAL":
      return {
        ...state,
        filters: []
      }
    default:
      return state
  }
}

export const getFinancialRelatoryListData = state => {
  return {
    expenses: state.financial_relatory.expenses,
    receipts: state.financial_relatory.receipts,
    expense_subtotal: state.financial_relatory.expense_subtotal,
    receipt_subtotal: state.financial_relatory.receipt_subtotal,
    total: state.financial_relatory.total,
    filters: state.financial_relatory.filters,
    billing_types: state.financial_relatory.billing_types
  }
}