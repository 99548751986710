import React from 'react'
import { DialogActions } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import { Field, Formik, Form } from 'formik'
import { TextField } from 'formik-material-ui'
import * as yup from 'yup'

function RestorationForm(props) {
  const { classes, data, onClose, onSubmit } = props

  const handleClose = () => onClose && onClose()

  const initialValues = data || {
    id: '',
    bar_code: '',
  }

  const schema = yup.object().shape({
    bar_code: yup.string().required('Obrigatório'),
  })

  return (
    <DialogContent>
      <Formik
        // key={initialValues.id}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false)
          onSubmit && onSubmit(values)
        }}
      >
        {({ submitForm, isSubmitting, touched, errors }) => (
          <Form>
            <Grid container spacing={1}>
              <Grid item xs>
                <Field
                  component={TextField}
                  name="bar_code"
                  label="Etiqueta"
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  error={touched.bar_code && !!errors.bar_code}
                  helperText={touched.bar_code && errors.bar_code}
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Salvar
              </Button>
              <Button onClick={handleClose}>Cancelar</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </DialogContent>
  )
}

export default RestorationForm
