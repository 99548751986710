import {
  Checkbox,
  DialogActions,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUp'
import { ColorBox } from 'material-ui-color'
import React, { useEffect, useMemo, useState } from 'react'
import InputMask from 'react-input-mask'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useSelector } from 'react-redux'

import NumberFormat from '../../components/NumberFormat'
import SelectField from '../../components/SelectField'

function Form(props) {
  const { classes, data, onClose, onSubmit } = props
  const measureType = useSelector(state => state.serviceType.measureType)
  const serviceCategory = useSelector(
    state => state.serviceType.serviceCategory
  )

  const attendenceTypes = useSelector(
    state => state.serviceType.attendancesTypes
  )

  const [form, updateForm] = useState({
    id: '',
    name: '',
    status: 1,
    measureType: '',
    serviceCategory: '',
    attendanceTypeid: '',
    execution_time: '',
    default_value: '',
    color: '',
    edit_final_value: 0,
  })

  const updateField = React.useCallback(name => e => {
    updateForm({
      ...form,
      [name]: e.target.value,
    })
  })

  const updateAttendance = e => {
    const { name, value } = e.target

    updateForm({
      ...form,
      [name]: value,
    })
  }

  const updateFun = React.useCallback(
    (name, value) => {
      updateForm({
        ...form,
        [name]: value,
      })
    },
    [form]
  )

  const handleClose = () => onClose && onClose()

  const onSubmitForm = e => {
    e.preventDefault()
    const send = { ...form, status: form.status || 0 }
    onSubmit && onSubmit(send)
  }

  useEffect(() => {
    updateForm({ ...form, ...data })
  }, [data])

  const readOnly = useMemo(() => form.id === 1, [form.id])

  return (
    <ValidatorForm onSubmit={onSubmitForm}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextValidator
              validators={['required']}
              errorMessages={['Campo Obrigatório']}
              onChange={updateField('name')}
              label="Nome"
              fullWidth
              value={form.name}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectField
              options={measureType}
              setForm={updateFun}
              data={form.measureType}
              name="measureType"
              label="Tipo de Cobrança"
            />
          </Grid>

          <Grid item xs={12}>
            <SelectField
              options={serviceCategory}
              setForm={updateFun}
              data={form.serviceCategory}
              name="serviceCategory"
              label="Categoria"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">
                Tipo de atendimento
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={updateAttendance}
                value={form.attendanceTypeid}
                name="attendanceTypeid"
                label="Tipo de Atendimento"
              >
                {attendenceTypes?.map(attendance => {
                  return (
                    <MenuItem key={attendance.value} value={attendance.value}>
                      {attendance.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <InputMask
              mask="99:99"
              formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
              maskChar=""
              value={form.execution_time}
              onChange={updateField('execution_time')}
            >
              {inputProps => (
                <TextValidator
                  {...inputProps}
                  label="Tempo para execução"
                  fullWidth
                  validators={['matchRegexp:^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$']}
                  errorMessages={['Formato inválido']}
                />
              )}
            </InputMask>
          </Grid>

          <Grid item xs={6}>
            <TextField
              className={classes.formControl}
              label="Valor base"
              value={form.default_value}
              onChange={updateField('default_value')}
              InputProps={{
                inputComponent: NumberFormat,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              className={classes.formControl}
              label="Valor do Técnico"
              value={form.technician_value}
              onChange={updateField('technician_value')}
              InputProps={{
                inputComponent: NumberFormat,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(form.edit_final_value)}
                  onChange={e =>
                    updateForm({ ...form, edit_final_value: e.target.checked })
                  }
                  value={form.edit_final_value}
                />
              }
              label="Valor final editável na venda?"
            />
          </Grid>

          <Grid item xs={12}>
            <ColorBox
              value={form.color}
              disableAlpha
              hideTextfield
              onChange={color =>
                updateForm(prvState => ({
                  ...prvState,
                  color: `#${color.hex}`,
                }))
              }
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<ThumbDownAltOutlinedIcon />}
                  checkedIcon={<ThumbUpAltOutlinedIcon color="secondary" />}
                  checked={Boolean(form.status)}
                  onChange={e =>
                    updateForm({ ...form, status: e.target.checked })
                  }
                  value={form.status}
                />
              }
              label="Ativo?"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" type="submit">
          Salvar
        </Button>
        <Button onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </ValidatorForm>
  )
}

export default Form
