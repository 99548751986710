import { FETCH_SCHEDULES_RELATORY_INIT } from './SchedulesRelatoryActions'

const INITIAL_STATE = {
  items: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  filters: [],
  equips: [],
  servicesTypes: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SCHEDULES_RELATORY_INIT.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        totalSize: action.payload.data.meta.total,
        page: action.payload.data.meta.current_page,
        sizePerPage: action.payload.data.meta.per_page,
        filters: action.payload.filters,
        equips: action.payload.data.equips,
        servicesTypes: action.payload.data.servicesTypes,
        companies: action.payload.data.companies,
      }
    case "CLEAR_ACTION_SCHEDULES":
      return {
        ...state,
        filters: []
      }
    default:
      return state
  }
}

export const getSchedulesRelatoryListData = state => {
  return {
    items: state.schedules_relatory.items,
    page: state.schedules_relatory.page,
    sizePerPage: state.schedules_relatory.sizePerPage,
    totalSize: state.schedules_relatory.totalSize,
    filters: state.schedules_relatory.filters
  }
}