import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const REMOVE_PAYMENT_METHOD = createAsyncConst('REMOVE_PAYMENT_METHOD')
export const SUBMIT_PAYMENT_METHOD = createAsyncConst('SUBMIT_PAYMENT_METHOD')
export const FETCH_PAYMENT_METHOD = createAsyncConst('FETCH_PAYMENT_METHOD')
export const EDIT_PAYMENT_METHOD = createAsyncConst('EDIT_PAYMENT_METHOD')
export const MAKE_PAYMENT_METHODS_RELATORY = createAsyncConst('MAKE_PAYMENT_METHODS_RELATORY')

export function fetchPaymentMethod(filters = {}) {
  return dispatch => {
    dispatch(action(FETCH_PAYMENT_METHOD.ACTION))
    return axios
      .get(`${config.API_URL}/payment-method`, {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => {
        dispatch(action(FETCH_PAYMENT_METHOD.SUCCESS, {data: resp.data, filters: filters}))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_PAYMENT_METHOD, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_PAYMENT_METHOD.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/payment-method/${id}`)
      .then(resp => {
        return resp.data.data
      })
      .then(data => dispatch(action(EDIT_PAYMENT_METHOD.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(EDIT_PAYMENT_METHOD, e)))
  }
}

function submit(values, method) {
  const id = values.id ? `/${values.id}` : ''

  return dispatch => {
    dispatch(action(SUBMIT_PAYMENT_METHOD.ACTION))
    return axios[method](`${config.API_URL}/payment-method${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_PAYMENT_METHOD.SUCCESS, data))
        dispatch(fetchPaymentMethod())
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_PAYMENT_METHOD, e)))
  }
}

export function post(values) {
  return submit(values, 'post')
}

export function update(values) {
  return submit(values, 'put')
}

export function remove(id) {
  return dispatch => {
    dispatch(action(REMOVE_PAYMENT_METHOD.ACTION))
    return axios
      .delete(`${config.API_URL}/payment-method/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(REMOVE_PAYMENT_METHOD.SUCCESS, data))
        dispatch(fetchPaymentMethod())
      })
      .catch(e => actionFailed(REMOVE_PAYMENT_METHOD.FAILURE, e))
  }
}

export function makeReport(e){
    return dispatch => { 
      return axios({
      url:
        `${config.API_URL}/payment-method-report`,
        method: 'POST',
        responseType: 'blob',
        headers: { 'Accept': '*/*' },
        params: {
          filters: JSON.stringify(e),
        }
      })
      .then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        
        link.href = url
        link.setAttribute('download', 'PaymentMethods.xlsx')
        document.body.appendChild(link)
        link.click()
        return resp
      })
    .catch(e => dispatch({
      type: SNACKBAR.HARDFAIL,
      error: { message: 'Erro na geração do arquivo.' },
    }))
    }       



}
