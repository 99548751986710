import TablePagination from '@material-ui/core/TablePagination'
import React from 'react'

function Pagination(props) {
  const {
    rowsPerPage,
    rows,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props

  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      rowsPerPage={rowsPerPage}
      count={rows}
      page={page}
      component="div"
      labelRowsPerPage="Linhas Por Página"
      labelDisplayedRows={({ from, to, count }) =>
        `Mostrando linhas ${from}-${to} de ${count}`
      }
      backIconButtonProps={{
        'aria-label': 'Previous Page',
      }}
      nextIconButtonProps={{
        'aria-label': 'Next Page',
      }}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      nextIconButtonText="Próxima Página"
      backIconButtonText="Página Anterior"
    />
  )
}

export default Pagination
