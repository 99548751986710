/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable import/order */
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'

import EditIcon from '@material-ui/icons/EditOutlined'
import DoneIcon from '@material-ui/icons/DoneAllTwoTone'
import RevertIcon from '@material-ui/icons/NotInterestedOutlined'
import SwapVertIcon from '@material-ui/icons/SwapVert'

import styles from '../../../resources/theme/global'
import {
  deleteItem,
  edit,
  exportOSsRelatory,
  toogleUrgent,
  update,
  toogleShowImages,
  toogleIsRework,
  toogleIsPersonalized,
} from '../AttendanceLaundryActions'
import {
  Slide,
  DialogContent,
  Grid,
  Chip,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableCell,
  TextField,
  Button,
  Checkbox,
  Collapse,
  Box,
  Tooltip,
} from '@material-ui/core'
import {
  exportFormatedMoney,
  renderRoute,
  renderRouteRole,
} from '../../../util/utils'
import NumberFormatCustom from '../../components/NumberFormat'
import { useConfirm } from 'material-ui-confirm'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Filter from './DetailsFilter'
import { useParams } from 'react-router'
import ClientAddress from './ClientAddress'
import RIESelect from '../../components/RIESelect'
import ChangeOsModal from './ChangeOsModal'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

function Details(props) {
  const { classes } = props
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const permissions = useSelector(state => state.auth.permissions)
  const statuses = useSelector(state => state.attendanceLaundry.statuses)
  const role = useSelector(state => state.auth.role)
  const [open, setOpen] = useState([])
  const { id } = useParams()

  useEffect(() => {
    dispatch(edit(id))
  }, [id])

  const details = useSelector(
    state => state.attendanceLaundry.showingAttendance
  )

  const [state, setState] = useState(null)
  const [previous, setPrevious] = useState([])
  const [adressModal, setAddressModal] = useState(false)
  const [currentID, setCurrentID] = useState(null)
  const [currentAddress, setCurrentAddress] = useState(null)
  const [changeOsOpen, setChangeOsOpen] = useState(false)
  const [itemCurrentId, setItemCurrentId] = useState(null)

  useEffect(() => {
    setState(details)
  }, [details])

  const changeOsModalHandleClose = () => {
    setItemCurrentId(null)
    setChangeOsOpen(false)
  }

  const changeOsModalHandleOpen = currentId => {
    setItemCurrentId(currentId)
    setChangeOsOpen(true)
  }

  const onChange = (e, row, optionName = null) => {
    if (!previous[row.id]) {
      setPrevious(state => ({ ...state, [row.id]: row }))
    }

    const optionsFields = ['base', 'altura', 'diametro', 'description']

    const { value, name } = e.target
    const { id } = row

    setState(state => {
      return {
        ...state,
        items: state.items.map(row => {
          if (row.id === id) {
            if (optionsFields.includes(optionName)) {
              return {
                ...row,
                options: { ...row.options, [optionName]: value },
              }
            }
            return { ...row, [name || optionName]: value }
          }
          return row
        }),
      }
    })
  }

  const onClose = () => {
    props.history.push('/laundry-os')
  }

  const buildDescription = options => {
    if (!options) return '-'
    if (options?.type === 1) {
      return `Tapete quadrado. Base: ${options.base} Altura: ${options.altura}`
    }

    return `Tapete redondo. Diâmetro ${options.diametro}`
  }

  const buildFieldsToSize = item => {
    if (item.isEditMode) {
      if (item.options.type === 1) {
        return (
          <>
            <TextField
              className={classes.formControl}
              value={item.options.base}
              onChange={e => onChange(e, item, 'base')}
              style={{ maxWidth: '120px' }}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              inputProps={{ style: { textAlign: 'center' } }}
            />
            X
            <TextField
              className={classes.formControl}
              value={item.options.altura}
              onChange={e => onChange(e, item, 'altura')}
              style={{ maxWidth: '120px' }}
              InputProps={{
                inputComponent: NumberFormatCustom,
                style: { textAlign: 'center' },
              }}
              inputProps={{ style: { textAlign: 'center' } }}
            />
          </>
        )
      }

      return (
        <TextField
          className={classes.formControl}
          value={item.options.diametro}
          onChange={e => onChange(e, item, 'diametro')}
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
          inputProps={{ style: { textAlign: 'center' } }}
        />
      )
    }

    return buildDescription(item.options)
  }

  const onToggleEditMode = (id, save = false) => {
    setState(state => ({
      ...state,
      items: state.items.map(row => {
        if (row.id === id) {
          if (save)
            dispatch(update(id, row)).then(() =>
              dispatch(edit(props.match.params.id))
            )
          return { ...row, isEditMode: !row.isEditMode }
        }
        return row
      }),
    }))
  }

  const onRevert = id => {
    setState(state => {
      return {
        ...state,
        items: state.items.map(row => {
          if (row.id === id) {
            return previous[id] ? previous[id] : row
          }
          return row
        }),
      }
    })
    setPrevious(state => {
      delete state[id]
      return state
    })
    onToggleEditMode(id)
  }

  const handleChangeUrgent = async id => {
    const resp = await dispatch(toogleUrgent(id))
    if (resp?.status === 200) {
      dispatch(edit(props.match.params.id))
    }
  }

  const handleChangeShowImages = async id => {
    await dispatch(toogleShowImages(id))
  }

  const handleChangeIsRework = async id => {
    const resp = await dispatch(toogleIsRework(id))

    if (resp.status === 200) {
      await dispatch(edit(props.match.params.id))
    }
  }
  const handleChangeIsPersonalized = async id => {
    const resp = await dispatch(toogleIsPersonalized(id))

    if (resp.status === 200) {
      await dispatch(edit(props.match.params.id))
    }
  }

  const handleChangeIsDelivery = async (e, address, id) => {
    setCurrentID(id)
    setAddressModal(true)
    setCurrentAddress(address)
  }

  const confirmDelete = async id => {
    confirm({
      description: 'Deseja excluir este item?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(async () => {
      const resp = await dispatch(deleteItem(id))
      if (resp.status === 200) {
        await dispatch(edit(props.match.params.id))
      }
    })
  }

  const handleOpen = key => {
    const newOpen = [...open]

    newOpen[key] = !newOpen[key]

    setOpen(newOpen)
  }

  const updateFieldSelect = (name, value, key) => {
    const items = [...state.items]

    items[key].status = value

    setState({ ...state, items })
  }

  return (
    <>
      <ChangeOsModal
        open={changeOsOpen}
        handleClose={changeOsModalHandleClose}
        currentItemId={itemCurrentId}
      />
      <ClientAddress
        open={adressModal}
        setOpen={setAddressModal}
        itemId={currentID}
        data={currentAddress}
      />
      <Dialog
        fullScreen
        open={state !== null}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Items da OS
            </Typography>
            <Grid item>
              <Filter classes={classes} />
            </Grid>
            <Button
              style={{ color: '#fff' }}
              type="button"
              onClick={() =>
                dispatch(
                  exportOSsRelatory(
                    null,
                    `laundryService/os-report/items/${props.match.params.id}`
                  )
                )
              }
            >
              <NoteAddIcon className={classes.btnIcon} /> Relatório
            </Button>
            <IconButton color="inherit" onClick={onClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent style={{ margin: '10px' }}>
          <Grid container spacing={1}>
            <Grid item xs>
              <Chip
                color="secondary"
                label={`Mês de referência: ${details?.month}`}
                className={classes.chip}
                variant="outlined"
              />
            </Grid>
            <Grid item xs>
              <Chip
                color="secondary"
                label={`Nome: ${details?.laundry?.name}`}
                className={classes.chip}
                variant="outlined"
              />
            </Grid>
            <Grid item xs>
              <Chip
                color="secondary"
                label={`items: ${details?.items.length}`}
                className={classes.chip}
                variant="outlined"
              />
            </Grid>
            <Grid item xs>
              <Chip
                color="secondary"
                label={`Total M2 no mês: ${details?.totalM2} metros`}
                className={classes.chip}
                variant="outlined"
              />
            </Grid>
            <Grid item xs>
              <Chip
                color="secondary"
                label={`Valor: ${exportFormatedMoney(
                  details?.total_with_discount
                )}`}
                className={classes.chip}
                variant="outlined"
              />
            </Grid>
            <Grid item xs>
              <Chip
                color="secondary"
                label={`Restaurações: ${exportFormatedMoney(
                  details?.total_restoration
                )}`}
                className={classes.chip}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <TableContainer
            style={{ height: 'calc(100vh - 140px)' }}
            className={classes.tableContainer}
          >
            <Table stickyHeader size="medium" className={classes.tableStyle}>
              <TableHead>
                <TableRow>
                  <TableCell />
                  {!state?.isLaundryUser && <TableCell />}
                  <TableCell>ID</TableCell>
                  <TableCell>Etiqueta</TableCell>
                  <TableCell>Medidas</TableCell>
                  <TableCell>Tamanho</TableCell>
                  <TableCell>Valor Padrão</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Coletado em</TableCell>
                  <TableCell>Previsão de entrega</TableCell>
                  <TableCell>Entregue em</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>OBS Importantes</TableCell>

                  <TableCell>É Urgente?</TableCell>
                  <TableCell>É retrabalho?</TableCell>
                  <TableCell>É Delivery?</TableCell>
                  <TableCell>Preço Customizado?</TableCell>
                  {!state?.isLaundryUser && (
                    <TableCell>Lavanderia vê as fotos?</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {state?.items.map((item, key) => (
                  <React.Fragment key={item.id}>
                    <TableRow>
                      <TableCell>
                        {item.restorationItem && (
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleOpen(key)}
                          >
                            {open[key] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        )}
                      </TableCell>
                      {!state.isLaundryUser && (
                        <TableCell>
                          {renderRoute(['update-laundry-os'], permissions) &&
                            !item.isEditMode && (
                              <IconButton
                                aria-label="done"
                                onClick={() => confirmDelete(item.id)}
                              >
                                <DeleteOutlined />
                              </IconButton>
                            )}
                          {item.isEditMode ? (
                            <>
                              <IconButton
                                aria-label="done"
                                onClick={() => onToggleEditMode(item.id, true)}
                              >
                                <DoneIcon />
                              </IconButton>
                              <IconButton
                                aria-label="revert"
                                onClick={() => onRevert(item.id)}
                              >
                                <RevertIcon />
                              </IconButton>

                              <IconButton
                                aria-label="change-os"
                                onClick={() => changeOsModalHandleOpen(item.id)}
                              >
                                <SwapVertIcon />
                              </IconButton>
                            </>
                          ) : (
                            <IconButton
                              aria-label="delete"
                              onClick={() => onToggleEditMode(item.id)}
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      )}
                      <TableCell>{item.id}</TableCell>
                      <TableCell>
                        {item.isEditMode ? (
                          <TextField
                            type="text"
                            name="bar_code"
                            autoComplete="etiqueta"
                            value={item.bar_code}
                            onChange={e => onChange(e, item)}
                            style={{ maxWidth: '60px' }}
                          />
                        ) : (
                          item.bar_code
                        )}
                      </TableCell>
                      <TableCell>{buildFieldsToSize(item)}</TableCell>
                      <TableCell>{`${item.quantity} metros`}</TableCell>
                      <TableCell>
                        {item.isEditMode &&
                        (item.is_delivery || item.is_personalized) ? (
                          <TextField
                            type="text"
                            className={classes.formControl}
                            value={item.default_value}
                            onChange={e => onChange(e, item, 'default_value')}
                            style={{ maxWidth: '120px' }}
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                            }}
                            inputProps={{ style: { textAlign: 'center' } }}
                          />
                        ) : (
                          exportFormatedMoney(item.default_value)
                        )}
                      </TableCell>
                      <TableCell>
                        {exportFormatedMoney(item.final_value)}
                      </TableCell>
                      <TableCell>{item.created_at}</TableCell>
                      <TableCell>{item.deadline_delivery}</TableCell>
                      <TableCell>{item.delivered_at}</TableCell>
                      <TableCell style={{ minWidth: '180px' }}>
                        {item.isEditMode &&
                        renderRouteRole(['administrador'], role) ? (
                          <RIESelect
                            options={statuses}
                            setForm={updateFieldSelect}
                            data={item.status || null}
                            chave={key}
                            name="status"
                            margin="dense"
                            variant="outlined"
                          />
                        ) : (
                          item.status?.name
                        )}
                      </TableCell>
                      <TableCell>
                        {item.isEditMode ? (
                          <TextField
                            type="text"
                            name="description"
                            value={item.options.description}
                            onChange={e => onChange(e, item, 'description')}
                          />
                        ) : item.options?.description ? (
                          item.options?.description
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      {!state.isLaundryUser ? (
                        <>
                          <TableCell>
                            <Checkbox
                              checked={Boolean(item.is_urgent)}
                              onChange={() => handleChangeUrgent(item.id)}
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={Boolean(item.is_rework)}
                              onChange={() => handleChangeIsRework(item.id)}
                            />
                          </TableCell>
                          <Tooltip
                            title={
                              item.clientAddress
                                ? `Endereço: ${item.clientAddress?.street}, ${
                                    item.clientAddress?.number
                                  } 
                                ${item.clientAddress?.complement || ''}, ${
                                    item.clientAddress?.neighborhood
                                  } - ${item.clientAddress?.city}`
                                : ''
                            }
                            aria-label="tooltip"
                            placement="right"
                          >
                            <TableCell>
                              <Checkbox
                                checked={Boolean(item.is_delivery)}
                                onChange={e =>
                                  handleChangeIsDelivery(
                                    e,
                                    item.clientAddress,
                                    item.id
                                  )
                                }
                              />
                            </TableCell>
                          </Tooltip>
                          <TableCell>
                            <Checkbox
                              checked={Boolean(item.is_personalized)}
                              onChange={() =>
                                handleChangeIsPersonalized(item.id)
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={Boolean(item.show_images)}
                              onChange={() => handleChangeShowImages(item.id)}
                            />
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell>
                            {item.is_urgent ? 'SIM' : 'NÃO'}
                          </TableCell>
                          <TableCell>
                            {item.is_rework ? 'SIM' : 'NÃO'}
                          </TableCell>
                          <TableCell>
                            {item.is_delivery ? 'SIM' : 'NÃO'}
                          </TableCell>
                          <TableCell>
                            {item.is_personalized ? 'SIM' : 'NÃO'}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                    <>
                      {item.restorationItem && (
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={15}
                          >
                            <Collapse
                              in={open[key]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box margin={1}>
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  component="div"
                                >
                                  Restaurações
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>ID</TableCell>
                                      <TableCell align="right">Valor</TableCell>
                                      <TableCell align="right">
                                        Data da venda
                                      </TableCell>
                                      <TableCell align="right">
                                        Etiqueta
                                      </TableCell>
                                      <TableCell align="right">
                                        Descrição do reparo
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        {item.restorationItem.id}
                                      </TableCell>
                                      <TableCell align="right">
                                        {exportFormatedMoney(
                                          item.restorationItem.final_value
                                        )}
                                      </TableCell>
                                      <TableCell align="right">
                                        {item.restorationItem.created_at}
                                      </TableCell>
                                      <TableCell align="right">
                                        {item.bar_code}
                                      </TableCell>
                                      <TableCell align="right">
                                        {item.restoration_comments || '-'}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default withStyles(styles)(Details)
