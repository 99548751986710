import {
  Checkbox,
  DialogActions,
  FormControlLabel,
  TextField,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import InputMask from 'react-input-mask'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch } from 'react-redux'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUp'
import React, { useEffect, useState } from 'react'

import NumberFormatCustomPercentage from '../../components/NumberFormatPercentage'
import { findCep } from '../SupplierActions'

function Form(props) {
  const { data, onClose, onSubmit, notShowPost, children } = props
  const dispatch = useDispatch()

  const [form, updateForm] = useState({
    id: '',
    name: '',
    description: '',
    status: 1,
    cep: '',
    street: '',
    complement: '',
    number: '',
    city: '',
    uf: '',
    neighborhood: '',
    phone: '',
    email: '',
    cpf_cnpj: '',
    commission: '',
  })

  const updateField = React.useCallback(name => e => {
    updateForm({
      ...form,
      [name]: e.target.value,
    })
  })

  const handleClose = () => onClose && onClose()

  const onSubmitForm = e => {
    e.preventDefault()
    const send = { ...form, status: form.status || 0 }
    onSubmit && onSubmit(send)
  }

  useEffect(() => {
    updateForm({ ...form, ...data })
  }, [data])

  const handleRadio = e => {
    updateForm({
      ...form,
      person_type: e.target.value,
    })
  }

  const onSubmitAndRedirect = e => {
    e.preventDefault()
    onSubmit && onSubmit(form, true)
  }

  const buscaCEP = () => {
    const cep = form.cep?.replace(/[^\d]/g, '')
    cep &&
      dispatch(findCep(cep)).then(resp => {
        if (resp && resp.code === 200) {
          const { items } = resp.data
          if (items) {
            updateForm({
              ...form,
              neighborhood: items?.bairro,
              city: items?.cidade,
              uf: items?.uf,
              street: items?.logradouro,
            })
          }
        }
      })
  }

  return (
    <ValidatorForm /* onSubmit={onSubmitForm} */>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12} lg={8}>
            <TextValidator
              validators={['required']}
              errorMessages={['Campo Obrigatório']}
              onChange={updateField('name')}
              label="Nome"
              fullWidth
              value={form.name}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={4}>
            <InputMask
              mask="99.999-999"
              maskChar=""
              value={form.cep}
              onChange={updateField('cep')}
              onBlur={() => buscaCEP()}
            >
              {inputProps => (
                <TextField {...inputProps} label="CEP" fullWidth />
              )}
            </InputMask>
          </Grid>
          <Grid item sm={6} xs={12} lg={8}>
            <TextField
              onChange={updateField('street')}
              label="Endereço"
              fullWidth
              value={form.street}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={2}>
            <TextField
              onChange={updateField('number')}
              label="Número"
              fullWidth
              value={form.number}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={2}>
            <TextField
              onChange={updateField('complement')}
              label="Complemento"
              fullWidth
              value={form.complement}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={4}>
            <TextField
              onChange={updateField('neighborhood')}
              label="Bairro"
              fullWidth
              value={form.neighborhood}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={4}>
            <TextField
              onChange={updateField('city')}
              label="Cidade"
              fullWidth
              value={form.city}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={2}>
            <TextField
              onChange={updateField('uf')}
              label="UF"
              fullWidth
              value={form.uf}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={2} s>
            <TextField
              label="Comissão"
              value={form.commission}
              onChange={e =>
                updateForm({ ...form, commission: e.target.value })
              }
              InputProps={{
                inputComponent: NumberFormatCustomPercentage,
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={4}>
            <InputMask
              mask={
                form.phone?.replace(/[^\d]/g, '').length <= 10
                  ? '(99) 9999-9999?'
                  : '(99) 99999-9999'
              }
              formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
              maskChar=""
              value={form.phone}
              onChange={e => {
                updateForm({
                  ...form,
                  phone: e.target.value.replace(/[^\d]/g, ''),
                })
              }}
            >
              {inputProps => (
                <TextValidator
                  {...inputProps}
                  label="Telefone"
                  fullWidth
                  validators={[
                    'matchRegexp:^\\(?[0-9]{2,3}\\)? ?[0-9]{4,5}-?[0-9]{4}$',
                  ]}
                  errorMessages={['Telefone inválido']}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item sm={6} xs={12} lg={4}>
            <TextValidator
              validators={['isEmail']}
              errorMessages={['O campo não representa um email válido']}
              onChange={updateField('email')}
              label="E-mail"
              fullWidth
              value={form.email}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={4}>
            <InputMask
              mask={
                form.cpf_cnpj?.replace(/[^\d]/g, '').length <= 11
                  ? '999.999.999-99?'
                  : '99.999.999/9999-99'
              }
              maskChar=""
              formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
              value={form.cpf_cnpj}
              onChange={updateField('cpf_cnpj')}
            >
              {inputProps => (
                <TextValidator
                  {...inputProps}
                  validators={[
                    'matchRegexp:^([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})$',
                  ]}
                  errorMessages={['CPF/CNPJ Inválido']}
                  label="CPF/CNPJ"
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <TextField
            placeholder="Descrição"
            value={form.description}
            onChange={updateField('description')}
            variant="outlined"
            multiline
            fullWidth
            rows={5}
            rowsMax={10}
          />
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<ThumbDownAltOutlinedIcon />}
                  checkedIcon={<ThumbUpAltOutlinedIcon color="secondary" />}
                  checked={Boolean(form.status)}
                  onChange={e =>
                    updateForm({ ...form, status: e.target.checked })
                  }
                  value={form.status}
                />
              }
              label="Ativo?"
            />
          </Grid>
        </Grid>

        <>{children}</>
      </DialogContent>

      <DialogActions>
        {notShowPost && (
          <Button color="primary" type="button" onClick={onSubmitAndRedirect}>
            Salvar e agendar
          </Button>
        )}
        <Button color="primary" onClick={onSubmitForm}>
          Salvar
        </Button>
        {!notShowPost && <Button onClick={handleClose}>Cancelar</Button>}
      </DialogActions>
    </ValidatorForm>
  )
}

export default Form
