import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import styles from '../../../resources/theme/users'
import EnhancedComponent from '../../components/EnhancedComponent'
import { post } from '../UserActions'

import Form from './Form'

function Transition(props) {
  return <Slide direction="down" {...props} />
}

class NewUser extends EnhancedComponent {
  componentDidMount() {}

  onSubmit = values => {
    this.props.post(values).then(data => {
      if (data && data.code === 200) {
        this.props.history.push('/users')
      }
    })
  }

  onClose = () => {
    this.props.history.push('/users')
  }

  render() {
    const { classes } = this.props
    return (
      <Dialog
        open
        // fullWidth
        fullScreen
        // maxWidth="md"
        onClose={this.onClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Novo Usuário
            </Typography>
            <IconButton
              color="inherit"
              onClick={this.onClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Form onClose={this.onClose} onSubmit={this.onSubmit} />
      </Dialog>
    )
  }
}

NewUser.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  roles: state.user.roles || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      post,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NewUser))
