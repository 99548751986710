/* eslint-disable import/order */
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import styles from '../../../resources/theme/global'
import { renderRouteRole } from '../../../util/utils'
import { fetchRoles } from '../RolesAction'
import { getRolesListData } from '../RolesReducer'
import Duplicate from './Duplicate'
import EditRole from './Edit'
import List from './List'
import NewRole from './New'

class Role extends Component {
  componentDidMount() {
    this.props.fetchRoles()
  }

  render() {
    const { classes, items, role } = this.props

    return (
      <>
        <Grid
          container
          className={classes.pageHead}
          spacing={1}
          alignItems="center"
        >
          <Grid item xs>
            <Typography variant="h4" color="secondary">
              Regras de usuário
            </Typography>
          </Grid>
          <Grid item>
            {renderRouteRole(['administrador'], role) && (
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to="/roles/new"
              >
                <AddCircleOutlineIcon className={classes.btnIcon} /> Cadastrar
              </Button>
            )}
          </Grid>
        </Grid>

        {renderRouteRole(['administrador'], role) && (
          <>
            <Route path="/roles/new" component={NewRole} />
            <Route path="/roles/:id/edit" component={EditRole} />
            <Route path="/roles/:id/duplicate" component={Duplicate} />
          </>
        )}

        <List classe={classes} items={items} role={role} />
      </>
    )
  }
}

Role.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  ...getRolesListData(state),
  role: state.auth.role || {},
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchRoles,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Role))
