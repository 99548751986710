/* eslint-disable no-nested-ternary */
import Badge from '@material-ui/core/Badge'
import React, { useEffect, useState } from 'react'

export default function BadgeGlobal(props) {
  const [filter, setFilters] = useState(0)
  const { form, items, children } = props

  function renderBadge() {
    let i = 0
    Object.values(form).map(item => {
      if (item?.length) {
        i += 1
      } else if (item) {
        i += 1
      } else if (item === 0) {
        i += 1
      }
      return ''
    })
    setFilters(i)
  }

  useEffect(() => {
    renderBadge()
  }, [items])

  return (
    <Badge
      color="secondary"
      badgeContent={filter}
      showZero
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {children}
    </Badge>
  )
}
