import { FETCH_SERVICES_RELATORY_INIT } from './ServicesRelatoryActions'

const INITIAL_STATE = {
  items: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  filters: [],
  attendances: [],
  services_status: [],
  companies: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SERVICES_RELATORY_INIT.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        totalSize: action.payload.data.meta.total,
        page: action.payload.data.meta.current_page,
        sizePerPage: action.payload.data.meta.per_page,
        filters: action.payload.filters,
        attendances: action.payload.data.attendances,
        services_status: action.payload.data.services_status,
        companies: action.payload.data.companies,
      }
    case 'CLEAR_ACTION_SERVICES':
      return {
        ...state,
        filters: [],
      }
    default:
      return state
  }
}

export const getServicesRelatoryListData = state => {
  return {
    items: state.services_relatory.items,
    page: state.services_relatory.page,
    sizePerPage: state.services_relatory.sizePerPage,
    totalSize: state.services_relatory.totalSize,
    filters: state.services_relatory.filters,
  }
}
