import axios from 'axios/index'

import createAsyncConst from '../../../../util/createAsyncConst'
import action, { actionFailed } from '../../../../util/action'
import config from '../../../../config'

export const FETCH_REPORT = createAsyncConst('FETCH_REPORT')
export const MAKE_RELATORY = createAsyncConst('MAKE_RELATORY')

export function fetchLeadsReport(filters = {}) {
  return dispatch => {
    dispatch(action(FETCH_REPORT.ACTION))
    return axios
      .get(`${config.API_URL}/crm-report/leads`, {
        params: filters,
      })
      .then(resp => {
        dispatch(action(FETCH_REPORT.SUCCESS, { data: resp.data, filters }))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_REPORT, e)))
  }
}

export function makeRelatory(data) {
  return dispatch => {
    return axios({
      url: `${config.API_URL}/crm-report/leads`,
      method: 'GET',
      responseType: 'blob',
      headers: { Accept: 'application/vnd.ms-excel' },
      params: { ...data, download: true },
    })
      .then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')

        link.href = url
        link.setAttribute('download', 'leads_report.xlsx')
        document.body.appendChild(link)
        link.click()
        return resp
      })
      .catch(e => dispatch(actionFailed(MAKE_RELATORY, e)))
  }
}
