import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { create, update } from '../../LossReasonActions'

const Form = ({ edit = false, data = {}, id = null }) => {
  const triggerTypes = useSelector(state => state.triggers.triggerTypes)

  const history = useHistory()
  const dispatch = useDispatch()

  const [form, setForm] = useState({
    reason: '',
    days: '',
    type: '',
  })

  const handleClose = () => {
    history.push('/crm/loss-reason')
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const formData = e.currentTarget
    const data = new FormData(formData)
    const entries = data.entries()
    const values = Object.fromEntries(entries)

    try {
      if (edit) {
        const resp = await dispatch(update(values, id))

        if (resp) {
          handleClose()
        }
      } else {
        const resp = await dispatch(create(values))

        if (resp) {
          handleClose()
        }
      }
    } catch (e) {
      //
    }
  }

  useEffect(() => {
    if (edit && data) {
      setForm({
        ...form,
        ...data,
      })
    }
  }, [edit, data])

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              type="text"
              name="description"
              variant="standard"
              label="Descrição"
              defaultValue={form.description}
              key={`input-description-${form.description}`}
              required
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button color="primary" type="submit">
          Salvar
        </Button>
      </DialogActions>
    </form>
  )
}

export default Form
