/* eslint-disable no-shadow */
import { Grid } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from 'react-google-maps'

import styles from '../../../resources/theme/global'
import SelectField from '../../components/SelectField'
import { fetchLaundyMap } from '../MapAction'
import img from '../../../../public/img/google-maps.svg'
import config from '../../../config'

import Infos from './Infos'

const getCoordinates = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject)
  })
}

const MapWithAMakredInfoWindow = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{
        lat: parseFloat(props.markers[0]?.lat) || props.latitude,
        lng: parseFloat(props.markers[0]?.lng) || props.longitude,
      }}
    >
      {props.markers.map(marker => {
        return (
          <Marker
            icon={{
              url: img,
              scaledSize: { width: 50, height: 50 },
            }}
            key={marker.id}
            position={{
              lat: parseFloat(marker.lat),
              lng: parseFloat(marker.lng),
            }}
            onClick={() => props.onClickMarker(marker.id)}
          >
            {marker.isOpen && (
              <InfoWindow onCloseClick={() => props.onClickMarker(marker.id)}>
                <Infos marker={marker} />
              </InfoWindow>
            )}
          </Marker>
        )
      })}
    </GoogleMap>
  ))
)

function Map({ classes }) {
  const dispatch = useDispatch()
  const [currentAttendant, setCurrentAttendant] = useState(null)
  const [latitude, setLatitude] = useState(-28.33701202574296)
  const [longitude, setLongitude] = useState(-48.699216069443764)
  const attendants = useSelector(state => state.mapLaundry.attendants)
  const loading = useSelector(state => state.mapLaundry.loading)

  const [markers, setMarkers] = useState([])

  const updateField = (name, value) => {
    setCurrentAttendant(value)
  }

  useEffect(() => {
    async function initPosition() {
      const position = await getCoordinates()
      setLatitude(position.coords.latitude)
      setLongitude(position.coords.longitude)
    }
    initPosition()
  }, [])

  useEffect(() => {
    const filters = { attendant_id: currentAttendant?.id }
    dispatch(fetchLaundyMap(filters)).then(resp => setMarkers(resp.data.data))
  }, [currentAttendant])

  const onClickMarker = id => {
    const markersCopy = [...markers]
    const item = markersCopy.findIndex(item => item.id === id)

    markersCopy[item] = {
      ...markersCopy[item],
      isOpen: !markersCopy[item].isOpen,
    }

    setMarkers(markersCopy)
  }

  const countLaundries = attendantId => {
    return markers.reduce((total, item) => {
      if (item.attendent_id === attendantId) return total + 1
      return total
    }, 0)
  }

  return (
    <>
      <Grid
        container
        className={classes.pageHead}
        spacing={1}
        alignItems="center"
      >
        <Grid item xs>
          <Typography variant="h4" color="secondary">
            Mapa de Lavanderias
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <SelectField
            options={attendants}
            setForm={updateField}
            data={currentAttendant}
            name="status"
            margin="dense"
            variant="outlined"
            label="Atendente"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid
          item
          lg={9}
          md={9}
          xs={12}
          style={{ position: 'relative', height: '80vh' }}
        >
          <MapWithAMakredInfoWindow
            markers={markers}
            latitude={latitude}
            longitude={longitude}
            onClickMarker={onClickMarker}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.GOOGLE_KEY}&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: '85%' }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </Grid>

        <Grid item lg={3} md={3} xs={12}>
          <div className={classes.listContainer}>
            <p className={classes.listContainerTitle}>
              Lavanderias por atendente:
            </p>
            <ul className={classes.listStyles}>
              {attendants.map(attendant => {
                return attendant.id ? (
                  <li key={String(attendant.id)}>{`${
                    attendant.name
                  }: ${countLaundries(attendant.id)}`}</li>
                ) : (
                  ''
                )
              })}
            </ul>

            <p className={classes.listContainerTotal}>
              Total: {markers.length}
            </p>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles)(Map)
