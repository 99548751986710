import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import styles from '../../../resources/theme/global'
import { renderRoute } from '../../../util/utils'
import { fetchBilling } from '../BillingActions'
import { getBillingListData } from '../BillingReducer'

import Edit from './Edit'
import Filter from './Filter'
import List from './List'
import New from './New'
import CloseDetails from './CloseDetails'

class Billing extends Component {
  componentDidMount() {}

  render() {
    const { classes, items, permissions } = this.props

    return (
      <>
        <Grid
          container
          className={classes.pageHead}
          spacing={1}
          alignItems="center"
        >
          <Grid item xs>
            <Typography variant="h4" color="secondary">
              Lançamento de contas
            </Typography>
          </Grid>
          <Grid item>
            <Filter classes={classes} />
          </Grid>
          <Grid item>
            {renderRoute(['create-billing'], permissions) && (
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to="/billing/new"
              >
                <AddCircleOutlineIcon className={classes.btnIcon} /> Cadastrar
              </Button>
            )}
          </Grid>
        </Grid>

        {renderRoute(['create-billing'], permissions) && (
          <Route path="/billing/new" component={New} />
        )}

        {renderRoute(['update-billing'], permissions) && (
          <Route path="/billing/:id/edit" component={Edit} />
        )}

        {renderRoute(['read-billing'], permissions) && (
          <Route path="/billing/:id/close" component={CloseDetails} />
        )}

        <List permissions={permissions} classes={classes} items={items} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...getBillingListData(state),
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchBilling,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Billing))
