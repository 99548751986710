/* eslint-disable react/prop-types */
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import { KeyboardDatePicker } from '@material-ui/pickers'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useState } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
import { fetchDashboard } from '../RestorationActions'

export default function Filter(props) {
  const items = useSelector(state => state.restoration.items)

  const dispatch = useDispatch()
  const { classes } = props
  const [form, setForm] = useState({
    bar_code: '',
    client: '',
    date: null,
    date_to: null,
    authorized: '',
  })

  const onSubmit = e => {
    e.preventDefault()
    dispatch(fetchDashboard(null, form))
  }

  return (
    <Tooltip title="Filter list">
      <PopupState variant="popover" popupId="demo-popup-popover">
        {popupState => (
          <div>
            <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
              <BadgeGlobal form={form} items={items}>
                <FilterListIcon />
              </BadgeGlobal>
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2} className={classes.popover}>
                <div className={classes.root}>
                  <ValidatorForm className={classes.form} onSubmit={onSubmit}>
                    <Grid
                      container
                      className={classes.gridContainer}
                      spacing={1}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={6}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, bar_code: e.target.value })
                          }
                          label="Etiqueta"
                          margin="dense"
                          className={classes.inputFilter}
                          variant="outlined"
                          fullWidth
                          value={form.bar_code}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, client: e.target.value })
                          }
                          label="Cliente/Lavanderia"
                          margin="dense"
                          className={classes.inputFilter}
                          variant="outlined"
                          fullWidth
                          value={form.client}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <KeyboardDatePicker
                          clearable
                          value={form.date}
                          label="Data da coleta (de)"
                          fullWidth
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          margin="dense"
                          inputVariant="outlined"
                          onChange={date => setForm({ ...form, date })}
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <KeyboardDatePicker
                          clearable
                          value={form.date_to}
                          label="Data da coleta (até)"
                          fullWidth
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          margin="dense"
                          inputVariant="outlined"
                          onChange={date_to => setForm({ ...form, date_to })}
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl
                          variant="outlined"
                          margin="dense"
                          className={classes.formControl}
                        >
                          <InputLabel>Autorizado</InputLabel>
                          <Select
                            labelId="authorized-select-label"
                            id="authorized-select"
                            fullWidth
                            label="Autorizado"
                            value={form.authorized}
                            onChange={e =>
                              setForm({
                                ...form,
                                authorized: e.target.value,
                              })
                            }
                          >
                            <MenuItem value={0}>Selecione...</MenuItem>
                            <MenuItem value={1}>Sim</MenuItem>
                            <MenuItem value={2}>Não</MenuItem>
                            <MenuItem value={3}>Sem Contato</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs>
                        <Button
                          type="submit"
                          color="primary"
                          fullWidth
                          variant="contained"
                          className={classes.submit}
                          onClick={popupState.close}
                        >
                          <SearchIcon />
                          {` Filtrar`}
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                </div>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </Tooltip>
  )
}
