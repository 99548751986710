import { FETCH_SERVICE_CATEGORIES_RELATORY_INIT, FETCH_SERVICE_CATEGORIES_RELATORY_DETAILS } from './ServiceCategoriesRelatoryActions'


const INITIAL_STATE = {
  items: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  filters: [],
  services_type: [],
  services_status: [],
  services_categories: [],
  details: [],
  selected_category: 1,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SERVICE_CATEGORIES_RELATORY_INIT.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        totalSize: action.payload.data.meta.total,
        page: action.payload.data.meta.current_page,
        sizePerPage: action.payload.data.meta.per_page,
        filters: action.payload.filters,
        services_type: action.payload.data.services_type,
        services_status: action.payload.data.services_status,
        services_categories: action.payload.data.services_categories
      }
    case FETCH_SERVICE_CATEGORIES_RELATORY_DETAILS.SUCCESS:
      return {
        ...state,
        details: action.payload.data.data,
        selected_category: action.payload.data.selected_category,
      }
    case "CLEAR_ACTION_SERVICE_CATEGORIES":
      return {
        ...state,
        filters: []
      }
    default:
      return state
  }
}

export const getServiceCategoriesRelatoryListData = state => {
  return {
    items: state.service_categories_relatory.items,
    page: state.service_categories_relatory.page,
    sizePerPage: state.service_categories_relatory.sizePerPage,
    totalSize: state.service_categories_relatory.totalSize,
    filters: state.service_categories_relatory.filters,
    details: state.service_categories_relatory.details,
    selected_category: state.service_categories_relatory.selected_category
  }
}