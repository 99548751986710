import { FETCH_BANKS } from './BankReportActions'

const INITIAL_STATE = {
  items: [],
  balance: [],
  graph: [],
  filters: {},
  allBillingTypes : {},
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_BANKS.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        balance: action.payload.data.balance,
        graph: action.payload.data.graph,
        filters: action.payload.filters,
        allBillingTypes : action.payload.data.allBillingTypes
      }
    default:
      return state
  }
}

export const getBankReportListData = state => {
  return {
    items: state.bankReport.items,
    balance: state.bankReport.balance,
    graph: state.bankReport.graph,
    allBillingTypes : state.bankReport.allBillingTypes,
    filters: state.bankReport.filters
  }
}
