import {
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'

import AutoComplete from '../../components/Autocomplete'

function Users({
  users,
  updateFunction,
  handleChangeUsers,
  classes,
  checkAllServices,
  usersList,
  removeItem,
}) {
  const [filter, setFilter] = useState('')
  const [checkAll, setCheckAll] = useState(false)

  const toggleChecked = e => {
    if (e.target.checked) checkAllServices(true, 'users')
    else checkAllServices(false, 'users')

    setCheckAll(e.target.checked)
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs>
          <AutoComplete
            options={usersList}
            label="Técnicos"
            data={users}
            updateFunction={updateFunction}
            limitTags={8}
            name="users"
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} justify="space-between">
        <Grid item xs={12} md={6} lg={3} style={{ margin: '10px' }}>
          <TextField
            placeholder="Pesquisar..."
            onChange={e => setFilter(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={3} style={{ marginTop: 'auto' }}>
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(checkAll)}
                onChange={toggleChecked}
                value={checkAll}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Marcar Todas"
          />
        </Grid>
      </Grid>

      <Grid container>
        {users.map((user, key) => {
          return (
            user.name.toLowerCase().includes(filter.toLowerCase()) && (
              <Grid
                item
                lg={4}
                md={6}
                xs={12}
                key={String(user.id)}
                className={classes.item}
              >
                <IconButton
                  className={classes.closeButton}
                  onClick={() => removeItem(user.id, 'users')}
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  variant="inherit"
                  color="inherit"
                  className={classes.userName}
                >
                  {`Técnico: ${user.name}`}
                </Typography>
                <Switch
                  checked={Boolean(users[key].status)}
                  onChange={handleChangeUsers(key)}
                  value={user.status}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Grid>
            )
          )
        })}
      </Grid>
    </>
  )
}

export default Users
