import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { DialogContent } from '@material-ui/core'
import { useSelector } from 'react-redux'

import Form from './Form'
import ServiceTypes from './ServiceTypes'
import Users from './Users'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const useStyles = makeStyles(theme => ({
  '@keyframes fade': {
    '0%': { opacity: 0, transform: 'scale(0.5)' },
    '40%': { transform: 'scale(1.1)' },
    '75%': { transform: 'scale(0.9)' },
    '90%': { transform: 'scale(1.05)' },
    '100%': { opacity: 1, transform: 'scale(1)' },
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    margin: theme.spacing(3),
  },
  item: {
    margin: '10px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    border: `2px solid #ccc`,
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0px 6px 6px #888, 0px -6px 6px #888',
    position: 'relative',
    animation: '$fade',
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.18, 0.89, 0.38, 1.35)',
  },
  price: {
    position: 'absolute',
    top: '5px',
    left: '5px',
    color: 'palevioletred',
  },
  closeButton: {
    position: 'absolute',
    top: '-8px',
    right: '0px',
  },
  userName: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
}))

export default function SimpleTabs(props) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const { onClose, onSubmit, data } = props
  const servicesTypeList = useSelector(state => state.company.serviceTypes)
  const usersList = useSelector(state => state.company.users)

  const handleClose = () => onClose && onClose()

  const [form, setForm] = useState({
    name: '',
    host: '',
    port: '',
    username: '',
    password: '',
    files: [],
    services: [],
    users: [],
    orcamentoModel: [],
    hide_values: 0,
    is_know_way_required: 0,
    is_email_required: 0,
  })

  const updateFunction = useCallback(
    (areas, array) => {
      setForm({
        ...form,
        [array]: areas.sort((a, b) => a.name.localeCompare(b.name)),
      })
    },
    [form]
  )

  useEffect(() => {
    setForm({ ...form, ...data })
  }, [data])

  const updateField = name => e => {
    setForm({
      ...form,
      [name]: e.target.value,
    })
  }

  const handleChangeFiles = files => {
    setForm({
      ...form,
      files,
    })
  }

  const handleChangeOrcamento = orcamentoModel => {
    setForm({
      ...form,
      orcamentoModel,
    })
  }

  const handleCheckbox = name => {
    setForm(previousState => ({
      ...form,
      [name]: !previousState[name],
    }))
  }

  const onSubmitForm = e => {
    e.preventDefault()
    onSubmit && onSubmit(form)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeServices = (name, index) => event => {
    const { services } = form
    if (name === 'percent') {
      services[index].percent = event.target.value
    } else {
      services[index].status = !services[index].status
    }

    setForm({
      ...form,
      services,
    })
  }

  const handleChangeUsers = index => event => {
    const { users } = form
    users[index].status = !users[index].status

    setForm({
      ...form,
      users,
    })
  }

  const checkAllServices = (isChecked, array = 'services') => {
    let localItems = [...form[array]]

    if (isChecked) {
      const list = array === 'services' ? servicesTypeList : usersList
      list.map(item => {
        const find = localItems.find(service => service.id == item.id)

        if (!find) localItems.push(item)
      })
    } else localItems = []

    setForm({
      ...form,
      [array]: localItems,
    })
  }

  const removeItem = (id, array = 'services') => {
    setForm(prevState => ({
      ...prevState,
      [array]: prevState[array].filter(item => item.id !== id),
    }))
  }

  return (
    <div className={classes.root}>
      <DialogContent>
        <AppBar position="static" color="transparent">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple company tabs"
          >
            <Tab label="Informações" {...a11yProps(0)} />
            <Tab label="Tipos de Serviço" {...a11yProps(1)} />
            <Tab label="Técnicos" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Form
            handleClose={handleClose}
            classes={classes}
            updateField={updateField}
            form={form}
            handleChangeFiles={handleChangeFiles}
            onSubmitForm={onSubmitForm}
            handleCheckbox={handleCheckbox}
            handleChangeOrcamento={handleChangeOrcamento}
            data={data}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ServiceTypes
            updateFunction={updateFunction}
            services={form.services}
            handleChangeServices={handleChangeServices}
            classes={classes}
            servicesTypeList={servicesTypeList}
            checkAllServices={checkAllServices}
            removeItem={removeItem}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Users
            updateFunction={updateFunction}
            users={form.users}
            handleChangeUsers={handleChangeUsers}
            classes={classes}
            usersList={usersList}
            checkAllServices={checkAllServices}
            removeItem={removeItem}
          />
        </TabPanel>
      </DialogContent>
    </div>
  )
}
