import { EDIT_ROLE, FETCH_ROLE } from './RolesAction'

const INITIAL_STATE = {
  items: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  filters: [],
  editingRole: null,
  permissions: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ROLE.SUCCESS:
      return {
        ...state,
        items: action.payload.data,
        filters: action.payload.data.filters,
        permissions: action.payload.permissions,
      }
    case EDIT_ROLE.SUCCESS:
      return {
        ...state,
        editingRole: action.payload,
      }
    default:
      return state
  }
}

export const getRolesListData = state => {
  return {
    items: state.role.items,
  }
}

export const getEditingRole = state => {
  return state.role.editingRole
}
