import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const GET_DASHBOARD_DATA = createAsyncConst('GET_DASHBOARD_DATA')

export function fetchDashboard(filters = {}) {
  return dispatch => {
    dispatch(action(GET_DASHBOARD_DATA.ACTION))
    return axios
      .get(`${config.API_URL}/leads-dashboard`, {
        params: {
          filters: JSON.stringify({ ...filters }),
        },
      })
      .then(resp => {
        dispatch(
          action(GET_DASHBOARD_DATA.SUCCESS, { data: resp.data, filters })
        )
        return resp.data
      })
      .catch(e => dispatch(actionFailed(GET_DASHBOARD_DATA, e)))
  }
}
