import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputMask from 'react-input-mask'
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core'

import Metro2 from './Metro2Modal'

function ModalGetInfos(props) {
  const {
    open,
    handleClose,
    classes,
    updateChildren,
    currentIndex,
    services,
    changeOptionType,
  } = props

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="modal-get-infos-title"
      >
        <DialogTitle id="modal-get-infos-title">
          Informações do Tapete
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs>
              <FormControl className={classes.formControl}>
                <InputLabel>Tipo de tapete</InputLabel>
                <Select
                  native
                  value={services[currentIndex]?.options.type || 1}
                  fullWidth
                  onChange={e => {
                    changeOptionType(currentIndex, parseInt(e.target.value, 10))
                  }}
                >
                  <option value={1}>Quadrado</option>
                  <option value={2}>Redondo</option>
                </Select>
              </FormControl>
            </Grid>

            {services[currentIndex]?.options.type === 1 && (
              <Grid item xs>
                <Metro2
                  updateChildren={updateChildren}
                  chave={currentIndex}
                  item={services[currentIndex]}
                />
              </Grid>
            )}

            {services[currentIndex]?.options.type === 2 && (
              <Grid item xs>
                <InputMask
                  mask="????.??"
                  formatChars={{ 9: '[0-9]', '?': '[0-9/.]', '.': '[.]' }}
                  maskChar=""
                  value={services[currentIndex].options.diametro}
                  onChange={e =>
                    updateChildren(
                      currentIndex,
                      e.target.value,
                      'diametro',
                      true
                    )
                  }
                  inputProps={{ style: { textAlign: 'center' } }}
                >
                  {inputProps => (
                    <TextField
                      {...inputProps}
                      label="Diametro"
                      validators={['required']}
                      errorMessages={['Campo necessário']}
                      fullWidth
                    />
                  )}
                </InputMask>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default React.memo(ModalGetInfos)
