import {
  FETCH_ATTENDANCE_LAUNDRY,
  EDIT_ATTENDANCE_LAUNDRY,
  CLOSE_LAUNDRY_OS,
  TOOGLE_SHOW_IMAGES,
  SEND_MAIL_BILLING,
  REOPEN_LAUNDRY_OS,
} from './AttendanceLaundryActions'

const INITIAL_STATE = {
  items: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  filters: {},
  showingAttendance: null,
  totals: {},
  today: {},
  statuses: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ATTENDANCE_LAUNDRY.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        totalSize: action.payload.data.meta.total,
        page: action.payload.data.meta.current_page,
        sizePerPage: action.payload.data.meta.per_page,
        filters: action.payload.filters,
        totals: action.payload.data.totals,
        today: action.payload.data.today,
        statuses: action.payload.data.statuses,
      }
    case EDIT_ATTENDANCE_LAUNDRY.SUCCESS:
      return {
        ...state,
        showingAttendance: action.payload.data,
      }
    case CLOSE_LAUNDRY_OS.SUCCESS:
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.payload.data.id) {
            return action.payload.data
          }

          return item
        }),
      }
    case REOPEN_LAUNDRY_OS.SUCCESS:
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.payload.id) {
            return action.payload.data.data
          }

          return item
        }),
      }
    case TOOGLE_SHOW_IMAGES.SUCCESS:
      const attendances = { ...state.showingAttendance }
      attendances.items.map(item => {
        if (item.id === action.payload.id) {
          item.show_images = !item.show_images
        }

        return item
      })
      return {
        ...state,
        showingAttendance: attendances,
      }
    case SEND_MAIL_BILLING.SUCCESS:
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.payload.id) item.bill_sended = 1

          return item
        }),
      }
    case 'ATTENDANCE_LAUNDRY_CLEAR_FILTERS':
      return { ...state, filters: {} }
    default:
      return state
  }
}

export const getAttendanceListData = state => {
  return {
    items: state.attendanceLaundry.items,
    page: state.attendanceLaundry.page,
    sizePerPage: state.attendanceLaundry.sizePerPage,
    totalSize: state.attendanceLaundry.totalSize,
  }
}

export const getEditingAttendance = state => {
  return state.attendanceLaundry.showingAttendance
}
