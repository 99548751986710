import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useState } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../../components/Badge'
import SelectField from '../../../components/SelectField'

const Filter = ({ classes, form = {}, setForm = () => {} }) => {
  const items = useSelector(state => state.triggers.items)
  const triggerTypes = useSelector(state => state.triggers.triggerTypes)

  const handleSubmit = e => {
    e.preventDefault()

    const formData = e.currentTarget
    const data = new FormData(formData)
    const entries = data.entries()
    const values = Object.fromEntries(entries)

    setForm({
      ...form,
      ...values,
    })
  }

  return (
    <Tooltip title="Filter list">
      <PopupState variant="popover" popupId="demo-popup-popover">
        {popupState => (
          <div>
            <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
              <FilterListIcon />
              {/* <BadgeGlobal form={{}} items={items}>
              </BadgeGlobal> */}
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2} className={classes.popover}>
                <div className={classes.root}>
                  <form className={classes.form} onSubmit={handleSubmit}>
                    <Grid
                      container
                      className={classes.gridContainer}
                      spacing={1}
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        <TextField
                          label="Motivo"
                          margin="dense"
                          className={classes.inputFilter}
                          variant="outlined"
                          fullWidth
                          name="reason"
                          key={`textField-reason-${form?.reason}`}
                          defaultValue={form?.reason}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          select
                          label="Filtre por Tipo"
                          margin="dense"
                          className={classes.inputFilter}
                          variant="outlined"
                          fullWidth
                          name="type"
                          key={`textField-type-${form?.type}`}
                          defaultValue={form?.type}
                        >
                          <MenuItem key="" value="">
                            Selecione...
                          </MenuItem>
                          {triggerTypes?.map((trigger, index) => (
                            <MenuItem key={index} value={trigger?.var_id}>
                              {trigger?.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs>
                        <Button
                          type="submit"
                          color="primary"
                          fullWidth
                          variant="contained"
                          className={classes.submit}
                          onClick={popupState.close}
                        >
                          <SearchIcon />
                          {` Filtrar`}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </Tooltip>
  )
}

export default Filter
