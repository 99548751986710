import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useState } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
import { fetchServiceType } from '../ServiceTypeActions'
import SelectField from '../../components/SelectField'

function Filter(props) {
  const items = useSelector(state => state.serviceType.items)
  const measureType = useSelector(state => state.serviceType.measureType)
  const serviceCategory = useSelector(
    state => state.serviceType.serviceCategory
  )

  const attendenceTypes = useSelector(
    state => state.serviceType.attendancesTypes
  )

  const dispatch = useDispatch()
  const { classes } = props
  const [form, setForm] = useState({
    name: '',
    status: '',
    measureType: '',
    serviceCategory: '',
    attendanceTypeid: '',
  })

  const updateFun = React.useCallback(
    (name, value) => {
      console.log(name, value)
      setForm({
        ...form,
        [name]: value,
      })
    },
    [form]
  )

  const onSubmit = e => {
    e.preventDefault()
    dispatch(fetchServiceType(form))
  }

  return (
    <Tooltip title="Filter list">
      <PopupState variant="popover" popupId="demo-popup-popover">
        {popupState => (
          <div>
            <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
              <BadgeGlobal form={form} items={items}>
                <FilterListIcon />
              </BadgeGlobal>
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2} className={classes.popover}>
                <div className={classes.root}>
                  <ValidatorForm className={classes.form} onSubmit={onSubmit}>
                    <Grid
                      container
                      className={classes.gridContainer}
                      spacing={1}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={6}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, name: e.target.value })
                          }
                          label="Nome"
                          margin="dense"
                          className={classes.inputFilter}
                          variant="outlined"
                          fullWidth
                          value={form.name}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, status: e.target.value })
                          }
                          select
                          label="Status"
                          margin="dense"
                          className={classes.inputFilter}
                          variant="outlined"
                          fullWidth
                          value={form.status}
                        >
                          <MenuItem key="" value="">
                            Selecione...
                          </MenuItem>
                          <MenuItem key={0} value={0}>
                            Inativo
                          </MenuItem>
                          <MenuItem key={1} value={1}>
                            Ativo
                          </MenuItem>
                        </TextField>
                      </Grid>

                      <Grid item xs={12}>
                        <SelectField
                          options={measureType}
                          setForm={updateFun}
                          data={form.measureType}
                          name="measureType"
                          variant="outlined"
                          margin="dense"
                          label="Tipo de Cobrança"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <SelectField
                          options={attendenceTypes}
                          setForm={updateFun}
                          data={form.attendanceTypeid}
                          name="attendanceTypeid"
                          label="Tipo de Atendimento"
                          variant="outlined"
                          margin="dense"
                        />
                        {/* 
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="demo-simple-select-label">
                            Tipo de atendimento
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            onChange={updateAttendance}
                            value={form.attendanceTypeid}
                            name="attendanceTypeid"
                            label="Tipo de Atendimento"
                          >
                            {attendenceTypes?.map(attendance => {
                              return (
                                <MenuItem
                                  key={attendance.id}
                                  value={attendance.id}
                                >
                                  {attendance.name}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl> */}
                      </Grid>

                      <Grid item xs={12}>
                        <SelectField
                          options={serviceCategory}
                          setForm={updateFun}
                          data={form.serviceCategory}
                          name="serviceCategory"
                          label="Categoria"
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs>
                        <Button
                          type="submit"
                          color="primary"
                          fullWidth
                          variant="contained"
                          className={classes.submit}
                          onClick={popupState.close}
                        >
                          <SearchIcon />
                          {` Filtrar`}
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                </div>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </Tooltip>
  )
}

export default Filter
