import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Grid, TextField } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { changeItemFromOs, edit } from '../AttendanceLaundryActions'

function ChangeOsModal(props) {
  const { open, handleClose, currentItemId } = props
  const [newOs, setNewOs] = useState('')
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch()
  const { id } = useParams()

  const handleCloseModal = () => {
    setHasError(false)
    setNewOs('')
    handleClose()
  }

  const changeOs = async () => {
    if (newOs.length === 0) {
      setHasError(true)
      setErrorMessage('Campo obrigatório')
      return
    }

    if (!/^\d+$/.test(newOs)) {
      setHasError(true)
      setErrorMessage('Digite somente números')
      return
    }

    const resp = await dispatch(changeItemFromOs(currentItemId, newOs))

    if (resp?.status === 200) {
      await dispatch(edit(id))
      handleCloseModal()
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={handleCloseModal}
        aria-labelledby="modal-change-os"
      >
        <DialogTitle id="modal-change-os">Mudar OS do tapete</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs>
              <TextField
                error={hasError}
                helperText={errorMessage}
                onChange={e => setNewOs(e.target.value)}
                label="Numero da nova OS"
                margin="dense"
                fullWidth
                value={newOs}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Fechar
          </Button>
          <Button onClick={changeOs} color="primary">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default React.memo(ChangeOsModal)
