import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const REMOVE_MEASURE_TYPE = createAsyncConst('REMOVE_MEASURE_TYPE')
export const SUBMIT_MEASURE_TYPE = createAsyncConst('SUBMIT_MEASURE_TYPE')
export const FETCH_MEASURE_TYPE = createAsyncConst('FETCH_MEASURE_TYPE')
export const EDIT_MEASURE_TYPE = createAsyncConst('EDIT_MEASURE_TYPE')

export function fetchMeasureType(filters = []) {
  return dispatch => {
    dispatch(action(FETCH_MEASURE_TYPE.ACTION))
    return axios
      .get(`${config.API_URL}/measure-type`, {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => {
        dispatch(action(FETCH_MEASURE_TYPE.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_MEASURE_TYPE, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_MEASURE_TYPE.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/measure-type/${id}`)
      .then(resp => {
        return resp.data.data
      })
      .then(data => dispatch(action(EDIT_MEASURE_TYPE.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(EDIT_MEASURE_TYPE, e)))
  }
}

function submit(values, method) {
  const id = values.id ? `/${values.id}` : ''

  return dispatch => {
    dispatch(action(SUBMIT_MEASURE_TYPE.ACTION))
    return axios[method](`${config.API_URL}/measure-type${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_MEASURE_TYPE.SUCCESS, data))
        dispatch(fetchMeasureType())
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_MEASURE_TYPE, e)))
  }
}

export function post(values) {
  return submit(values, 'post')
}

export function update(values) {
  return submit(values, 'put')
}

export function remove(id) {
  return dispatch => {
    dispatch(action(REMOVE_MEASURE_TYPE.ACTION))
    return axios
      .delete(`${config.API_URL}/measure-type/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(REMOVE_MEASURE_TYPE.SUCCESS, data))
        dispatch(fetchMeasureType())
      })
      .catch(e => actionFailed(REMOVE_MEASURE_TYPE.FAILURE, e))
  }
}
