import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import VisibilityIcon from '@material-ui/icons/Visibility'
import EventIcon from '@material-ui/icons/Event'
import classNames from 'classnames'

import consts from '../../../util/consts'
import { getSorting, stableSort } from '../../../util/TableUtils'
import { exportFormatedMoney, renderRoute } from '../../../util/utils'
import Paginate from '../../components/Pagination'
import EnhancedTableHead from '../../components/TableHead'
import { clearFilters, fetchDashboard } from '../LaundryDashboardActions'

function List(props) {
  const dispatch = useDispatch()
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('')
  const filters = useSelector(state => state.laundryDashboard.filters)
  const [rowsPerPage, setRowsPerPage] = useState(consts.DEFAULT_PAGINATION)
  const permissions = useSelector(state => state.auth.permissions)
  const { classes, items, totalSize, page } = props

  const headCells = [
    {
      id: 'id',
      label: 'ID',
    },
    {
      id: 'bar_code',
      label: 'Código de barras',
    },
    {
      id: 'laundry',
      label: 'Lavanderia',
    },
    {
      id: 'value',
      label: 'Valor',
    },
    {
      id: 'collected_at',
      label: 'Coletado em',
    },
    {
      id: 'deadline_delivery',
      label: 'Previsão de entrega',
    },
    {
      id: 'delivered_at',
      label: 'Entregue em',
    },
    {
      id: 'type',
      label: 'Tipo',
    },
    {
      id: 'measures',
      label: 'Medidas',
    },
    {
      id: 'quantity',
      label: 'M2',
    },
    {
      id: 'status',
      label: 'Status',
    },
    {
      id: 'rework',
      label: 'Retrabalho',
    },
    {
      id: 'urgent',
      label: 'Urgente',
    },
    {
      id: 'actions',
      label: '',
    },
  ]

  const onMovePage = (event, newPage) => {
    dispatch(fetchDashboard(newPage + 1, filters, rowsPerPage))
  }

  useEffect(() => {
    onMovePage()
  }, [rowsPerPage])

  useEffect(() => {
    return () => dispatch(clearFilters())
  }, [])

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  return (
    <Paper>
      <TableContainer
        className={classNames({
          [classes.tableContainer]: true,
          [classes.tableStickHeader]: true,
        })}
      >
        <Table stickyHeader size="medium" className={classes.tableStyle}>
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={items.length}
            headCells={headCells}
            renderFirstTH={false}
          />
          <TableBody>
            {stableSort(items, getSorting(order, orderBy)).map(item => {
              return (
                <TableRow key={item.id}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.bar_code}</TableCell>
                  <TableCell>{item.laundryName}</TableCell>
                  <TableCell>{exportFormatedMoney(item.final_value)}</TableCell>
                  <TableCell>{item.collected_at}</TableCell>
                  <TableCell>{item.deadline_delivery}</TableCell>
                  <TableCell>{item.delivered_at}</TableCell>
                  <TableCell>{item.type}</TableCell>
                  <TableCell>{item.measures}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>{item.status}</TableCell>
                  <TableCell>
                    {item.is_rework && item.is_rework != '0' ? 'Sim' : 'Não'}
                  </TableCell>
                  <TableCell>
                    {item.is_urgent && item.is_urgent != '' ? 'Sim' : 'Não'}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      component={Link}
                      to={`laundry-dashboard/${item.id}`}
                    >
                      <VisibilityIcon />
                    </IconButton>

                    {renderRoute(['read-schedule'], permissions) && (
                      <IconButton
                        aria-label="edit"
                        component={Link}
                        to={`calendar?ositem=${item.id}`}
                      >
                        <EventIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginate
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        rows={totalSize}
        page={page - 1}
        handleChangePage={onMovePage}
      />
    </Paper>
  )
}

export default List
