import { EDIT_MEASURE_TYPE, FETCH_MEASURE_TYPE } from './MeasureTypeActions'

const INITIAL_STATE = {
  items: [],
  filters: [],
  editingMeasureType: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_MEASURE_TYPE.SUCCESS:
      return {
        ...state,
        items: action.payload.data,
      }
    case EDIT_MEASURE_TYPE.SUCCESS:
      return {
        ...state,
        editingMeasureType: action.payload,
      }
    default:
      return state
  }
}

export const getMeasureTypeListData = state => {
  return {
    items: state.measureType.items,
  }
}

export const getEditingMeasureType = state => {
  return state.measureType.editingMeasureType
}
