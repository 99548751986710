import { FETCH_REPORT } from './LeadsReportsActions'

const INITIAL_STATE = {
  items: [],
  meta: {},
  filters: {},
  attendants: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_REPORT.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        meta: action.payload.data.meta,
        attendants: action.payload.data.attendants,
      }
    default:
      return state
  }
}
