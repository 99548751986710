import React, { useEffect, useState } from 'react'
import { Button, Grid, Typography, withStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Filter from './Filter'
import { fetchLeadsReport, makeRelatory } from '../LeadsReportsActions'
import List from './List'
import styles from '../../../../../resources/theme/global'
import Icon from '@mdi/react'
import { mdiMicrosoftExcel } from '@mdi/js'
import NoteAddIcon from '@material-ui/icons/NoteAdd'

const LeadsReport = props => {
  const items = useSelector(state => state.leadsReport.items)
  const dispatch = useDispatch()

  const [form, setForm] = useState({
    per_page: 10,
    page: 1,
    attendant_id: '',
  })

  const { classes } = props

  useEffect(() => {
    dispatch(fetchLeadsReport(form))
  }, [form])

  const handleExport = () => {
    dispatch(makeRelatory(form))
  }

  return (
    <>
      <Grid
        container
        className={classes.pageHead}
        spacing={1}
        alignItems="center"
      >
        <Grid item xs>
          <Typography variant="h4" color="secondary">
            Relatório de Leads
          </Typography>
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" onClick={handleExport}>
            <NoteAddIcon className={classes.btnIcon} /> Relatório
          </Button>
        </Grid>
        <Grid item>
          <Filter classes={classes} form={form} setForm={setForm} />
        </Grid>
        <Grid item xs={12}>
          <List classes={classes} setForm={setForm} form={form} />
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles)(LeadsReport)
