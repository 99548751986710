import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Collapse from '@material-ui/core/Collapse'
import { red } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PencilIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

import { renderRouteRole } from '../../util/utils'

const useStyles = makeStyles(theme => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  description: {
    height: 60,
    maxHeight: 250,
    overflowY: 'auto',
  },
}))

export default function Cartao(props) {
  const { item, role } = props

  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h6">{item.name}</Typography>

        <Typography
          variant="overline"
          display="block"
          color="textSecondary"
          gutterBottom
        >
          {item.display_name}
        </Typography>

        <Typography
          className={classes.description}
          variant="body2"
          color="textSecondary"
          gutterBottom
        >
          {item.description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {renderRouteRole(['administrador'], role) && (
          <IconButton
            aria-label="Edit"
            component={Link}
            to={`/roles/${item.id}/edit`}
          >
            <PencilIcon />
          </IconButton>
        )}

        {renderRouteRole(['administrador'], role) && (
          <IconButton
            aria-label="duplicate"
            component={Link}
            to={`/roles/${item.id}/duplicate`}
          >
            <FileCopyIcon />
          </IconButton>
        )}
        <IconButton
          className={classNames(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Permissões da Regra:</Typography>
          <ul>
            <Typography paragraph>
              {item.permissions.map(permission => (
                <li key={permission.display_name}>{permission.display_name}</li>
              ))}
            </Typography>
          </ul>
        </CardContent>
      </Collapse>
    </Card>
  )
}
