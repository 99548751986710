/* eslint-disable object-shorthand */
import axios from 'axios/index'

import config from '../../../config'
import action, { actionFailed } from '../../../util/action'
import createAsyncConst from '../../../util/createAsyncConst'

export const FETCH_LEADS = createAsyncConst('FETCH_LEADS')
export const FETCH_LEAD = createAsyncConst('FETCH_LEAD')
export const CAPTURE_LEAD = createAsyncConst('CAPTURE_LEAD')
export const CREATE_LEAD_DESCRIPTION = createAsyncConst(
  'CREATE_LEAD_DESCRIPTION'
)
export const TRANSFER_LEAD = createAsyncConst('TRANSFER_LEAD')

export function fetchLeads(filters = {}) {
  return dispatch => {
    dispatch(action(FETCH_LEADS.ACTION))
    return axios
      .get(`${config.API_URL}/crm-leads`, {
        params: filters,
      })
      .then(resp => {
        dispatch(
          action(FETCH_LEADS.SUCCESS, {
            data: resp.data,
            filters: filters,
          })
        )
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_LEADS, e)))
  }
}

export function fetchLead(id) {
  return dispatch => {
    dispatch(action(FETCH_LEAD.ACTION))
    return axios
      .get(`${config.API_URL}/crm-leads/${id}`)
      .then(resp => {
        dispatch(
          action(FETCH_LEAD.SUCCESS, {
            data: resp.data,
          })
        )
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_LEAD, e)))
  }
}

export function captureLead(id) {
  return dispatch => {
    dispatch(action(CAPTURE_LEAD.ACTION))
    return axios
      .put(`${config.API_URL}/crm-leads/${id}`)
      .then(resp => {
        dispatch(action(CAPTURE_LEAD.SUCCESS, resp.data))
        dispatch(fetchLeads({}))
        return true
      })
      .catch(e => dispatch(actionFailed(CAPTURE_LEAD, e)))
  }
}

export function updateLead(id, form) {
  return dispatch => {
    dispatch(action(CAPTURE_LEAD.ACTION))
    return axios
      .put(`${config.API_URL}/crm-leads/update/${id}`, form)
      .then(resp => {
        dispatch(action(CAPTURE_LEAD.SUCCESS, resp.data))
        dispatch(fetchLeads({ state: 20 }))
        return true
      })
      .catch(e => dispatch(actionFailed(CAPTURE_LEAD, e)))
  }
}

export function createDescription(id, form) {
  return dispatch => {
    dispatch(action(CREATE_LEAD_DESCRIPTION.ACTION))
    return axios
      .post(`${config.API_URL}/crm-leads/description/${id}`, form)
      .then(resp => {
        dispatch(action(CREATE_LEAD_DESCRIPTION.SUCCESS, resp.data))
        dispatch(fetchLead(id))
        return true
      })
      .catch(e => dispatch(actionFailed(CREATE_LEAD_DESCRIPTION, e)))
  }
}

export function transferLead(id, form) {
  return dispatch => {
    dispatch(action(TRANSFER_LEAD.ACTION))
    return axios
      .put(`${config.API_URL}/crm-leads/transfer-os/${id}`, form)
      .then(resp => {
        dispatch(action(TRANSFER_LEAD.SUCCESS, resp.data))
        dispatch(fetchLeads({ state: 20 }))
        return true
      })
      .catch(e => dispatch(actionFailed(TRANSFER_LEAD, e)))
  }
}

export function closeLead(id, form) {
  return dispatch => {
    dispatch(action(TRANSFER_LEAD.ACTION))
    return axios
      .put(`${config.API_URL}/crm-leads/close/${id}`, form)
      .then(resp => {
        dispatch(action(TRANSFER_LEAD.SUCCESS, resp.data))
        dispatch(fetchLeads({ state: 20 }))
        return true
      })
      .catch(e => dispatch(actionFailed(TRANSFER_LEAD, e)))
  }
}

export function createNewOrcament(id) {
  return dispatch => {
    dispatch(action(TRANSFER_LEAD.ACTION))
    return axios
      .put(`${config.API_URL}/crm-leads/new-orcament/${id}`)
      .then(resp => {
        dispatch(action(TRANSFER_LEAD.SUCCESS, resp.data))
        dispatch(fetchLeads({ state: 20 }))
        return true
      })
      .catch(e => dispatch(actionFailed(TRANSFER_LEAD, e)))
  }
}
