import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import VisibilityIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/Edit'
import classNames from 'classnames'

import consts from '../../../util/consts'
import { getSorting, stableSort } from '../../../util/TableUtils'
import Paginate from '../../components/Pagination'
import EnhancedTableHead from '../../components/TableHead'
import { fetchDashboard } from '../RestorationActions'

import ModalColectValue from './ModalColectValue'

function List(props) {
  const dispatch = useDispatch()
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('')
  const filters = useSelector(state => state.restoration.filters)
  const [rowsPerPage, setRowsPerPage] = useState(consts.DEFAULT_PAGINATION)
  const [open, setOpen] = useState(false)
  const [currentId, setCurrentId] = useState(null)
  const { classes, items, totalSize, page } = props

  const headCells = [
    {
      id: 'id',
      label: 'ID',
    },
    {
      id: 'bar_code',
      label: 'Etiqueta',
    },
    {
      id: 'laundry',
      label: 'Lavanderia/Cliente',
    },
    {
      id: 'collected_at',
      label: 'Coletado em',
    },
    {
      id: 'type',
      label: 'Tipo',
    },
    {
      id: 'measures',
      label: 'Medidas',
    },
    {
      id: 'quantity',
      label: 'M2',
    },
    {
      id: 'authorized',
      label: 'Autorizado',
    },
    {
      id: 'actions',
      label: '',
    },
  ]

  const onMovePage = (event, newPage) => {
    dispatch(fetchDashboard(newPage + 1, filters, rowsPerPage))
  }

  useEffect(() => {
    onMovePage()
  }, [rowsPerPage])

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  // const handleChangeAuthorized = async (event, id) => {
  //   if (event.target.value === 1) {
  //     setOpen(true)
  //     setCurrentId(id)
  //   } else if (event.target.value === 2) {
  //     const resp = await dispatch(update(id, { authorized: 2 }))
  //     if (resp?.code === 200) dispatch(fetchDashboard())
  //   }
  // }

  const handleClickEdit = item => {
    setOpen(true)
    setCurrentId(item)
  }

  const handleClose = () => setOpen(false)

  return (
    <>
      <ModalColectValue
        open={open}
        handleClose={handleClose}
        item={currentId}
      />
      <Paper>
        <TableContainer
          className={classNames({
            [classes.tableContainer]: true,
            [classes.tableStickHeader]: true,
          })}
        >
          <Table stickyHeader size="medium" className={classes.tableStyle}>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={items.length}
              headCells={headCells}
              renderFirstTH={false}
            />
            <TableBody>
              {stableSort(items, getSorting(order, orderBy)).map(item => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.bar_code}</TableCell>
                    <TableCell>{item.laundryName}</TableCell>
                    <TableCell>{item.collected_at}</TableCell>
                    <TableCell>{item.type}</TableCell>
                    <TableCell>{item.measures}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>{item.restoration_autorized_text}</TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        component={Link}
                        to={`restoration-items/${item.id}/edit`}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleClickEdit(item)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Paginate
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          rows={totalSize}
          page={page - 1}
          handleChangePage={onMovePage}
        />
      </Paper>
    </>
  )
}

export default List
