import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import classNames from 'classnames'
import { green, red } from '@material-ui/core/colors'

import { dateFormatedPT, exportFormatedMoney } from '../../../util/utils'

const columns = [
  { id: 'id', label: 'ID', minWidth: 170 },
  {
    id: 'date',
    label: 'Vencimento',
    minWidth: 120,
    format: value => value && dateFormatedPT(value),
  },
  {
    id: 'paymentDate',
    label: 'Data da Baixa',
    minWidth: 170,
    format: value => value && dateFormatedPT(value),
  },
  {
    id: 'actualPaymentDate',
    label: 'Data do Pagamento',
    minWidth: 170,
    format: value => value && dateFormatedPT(value),
  },
  {
    id: 'supplier',
    label: 'Cont. Financeiro',
    minWidth: 170,
  },
  {
    id: 'billingType',
    label: 'Tipo de Conta',
    minWidth: 170,
  },
  {
    id: 'billingTypeId',
    label: 'Tipo',
    minWidth: 170,
    format: value => (value === 1 ? 'Despesa' : 'Receita'),
  },
  {
    id: 'value',
    label: 'Valor',
    minWidth: 170,
    format: value => exportFormatedMoney(value),
  },
]

function createData(
  id,
  date,
  paymentDate,
  actualPaymentDate,
  supplier,
  billingType,
  billingTypeId,
  value
) {
  return {
    id,
    date,
    paymentDate,
    actualPaymentDate,
    supplier,
    billingType,
    billingTypeId,
    value,
  }
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 650,
  },
  green: {
    color: green[700],
  },
  red: {
    color: red[700],
  },
})

export default function BankTable({ historic }) {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const rows = []

  historic.map(item =>
    rows.push(
      createData(
        item.id,
        item.date,
        item.paymentDate,
        item.actualPaymentDate,
        item.supplier,
        item.billingType?.name,
        item.billingType?.type,
        item.value
      )
    )
  )

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map(column => {
                      const value = row[column.id]
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={classNames({
                            [classes.green]:
                              column.id === 'value' && row.billingTypeId === 2,
                            [classes.red]:
                              column.id === 'value' && row.billingTypeId === 1,
                          })}
                        >
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
