import React from 'react'
import { Chip, Grid, Avatar } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

function Chips(props) {
  const {
    classes,
    attendants,
    filterAttendant,
    attendant,
    handleSelectAllFromAttendant,
  } = props

  return (
    <Grid
      className={classes.paper}
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      style={{ marginBottom: '0px', paddingBottom: '0px' }}
    >
      {attendants.length ? (
        <>
          <Grid item lg={2} sm={6} md={2} xs={12} key={0}>
            <Chip
              variant="outlined"
              avatar={<Avatar style={{ backgroundColor: '#3788d8' }}>T</Avatar>}
              label="Todos Atendentes"
              clickable
              style={{
                color: '#3788d8',
                borderColor: '#3788d8',
                backgroundColor:
                  attendant === '' ? 'rgba(83, 159, 221, 0.3)' : '',
                width: '90%',
              }}
              color="primary"
              onClick={() => filterAttendant('')}
            />
          </Grid>
          {attendants.map(item => {
            return (
              <Grid item lg={2} sm={6} md={2} xs={12} key={item.id}>
                <Chip
                  variant="outlined"
                  avatar={
                    <Avatar
                      src={item.link}
                      style={{ backgroundColor: item.color }}
                    >
                      {item.name.charAt(0).toUpperCase()}
                    </Avatar>
                  }
                  label={item.name}
                  clickable
                  style={{
                    color: item.color,
                    borderColor: item.color,
                    backgroundColor:
                      attendant === item?.id ? 'rgba(83, 159, 221, 0.3)' : '',
                    width: '90%',
                    margin: '2px',
                  }}
                  color="primary"
                  onClick={() => filterAttendant(item.id)}
                  onDoubleClick={() => handleSelectAllFromAttendant(item.id)}
                />
              </Grid>
            )
          })}
        </>
      ) : (
        <Skeleton variant="rect" className={classes.skeleton} />
      )}
    </Grid>
  )
}

export default React.memo(Chips, (prevProps, nextProps) => {
  return (
    prevProps.collapse === nextProps.collapse &&
    prevProps.attendants === nextProps.attendants &&
    prevProps.attendant === nextProps.attendant &&
    prevProps.showCurrent === nextProps.showCurrent
  )
})
