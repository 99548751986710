import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const FETCH_BANKS = createAsyncConst('FETCH_BANKS')

export function fetchBanks(filters = { graph: 'months' }) {
  return dispatch => {
    dispatch(action(FETCH_BANKS.ACTION))
    return axios
      .get(`${config.API_URL}/bank-report`, {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => {
        dispatch(action(FETCH_BANKS.SUCCESS, { data: resp.data, filters }))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_BANKS, e)))
  }
}

export function makeReport(data, id) {
  return dispatch => {
    return axios({
      url:
        `${config.API_URL}/bank-report-export/`+id,
        method: 'POST',
        responseType: 'blob',
        headers: { Accept: 'application/vnd.ms-excel' },
        params: {
          filters: JSON.stringify(data),
        }
      })
      .then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        
        link.href = url
        link.setAttribute('download', 'bank_report.xlsx')
        document.body.appendChild(link)
        link.click()
        return resp
      })
    .catch(e => dispatch(actionFailed(FETCH_BANKS, e)))
  }
}
