import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles'
import { DialogActions } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import DialogContent from '@material-ui/core/DialogContent'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import styles from '../../../resources/theme/users'

// import InputMask from 'react-input-mask'
// import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'

import { useSelector } from 'react-redux'

function Form(props) {
  const { permissions } = props

  const [form, updateForm] = useState({
    id: '',
    name: '',
    display_name: '',
    description: '',
    permissions: []
  })
  const readOnly = props.see

  const handleChange = id => event => {
    if (event.target.checked) {
      updateForm({ ...form, permissions: [...form.permissions, id] })
    } else {
      updateForm({
        ...form, permissions: form.permissions.filter(function (perm) {
          return perm !== id
        })
      });
    }
  };

  const updateField = React.useCallback(name => e =>
    updateForm({
      ...form,
      [name]: e.target.value
    })
  )
  const handleClose = () => props.onClose && props.onClose()

  const onSubmit = e => {
    e.preventDefault()
    props.onSubmit && props.onSubmit(form)
  }

  useEffect(() => {
    updateForm({ ...form, ...props.data })
  }, [props.data]);

  return (
    <form onSubmit={onSubmit}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs>
            <TextField
              onChange={updateField('name')}
              label="Nome"
              required
              fullWidth
              disabled={form.id}
              value={form.name}
              disabled={readOnly}
            />
          </Grid>

          <Grid item xs>
            <TextField
              onChange={updateField('display_name')}
              label="Display Name"
              required
              fullWidth
              value={form.display_name}
              disabled={readOnly}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs>
            <TextField
              fullWidth
              onChange={updateField('description')}
              label="Descrição"
              required
              value={form.description}
              disabled={readOnly}
            />
          </Grid>
        </Grid>
        <Typography variant="h6" color="inherit" component="p">
          Permissões
          </Typography>
        <Grid container spacing={2}>
          <Grid item xs>
            <FormGroup row>
              {permissions.map(permission => (
                <FormControlLabel key={String(permission.id)}
                  control={
                    <Checkbox disabled={readOnly} checked={form.permissions.indexOf(permission.id) > -1} onChange={handleChange(permission.id)} value={permission.id} />
                  }
                  label={permission.display_name}
                />
              ))}
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>
      {!readOnly && <DialogActions>
        <Button color="primary" type="submit">
          Salvar
          </Button>
        <Button onClick={handleClose}>Cancelar</Button>
      </DialogActions>}
    </form>
  )
}

export default (withStyles(styles)(Form))
