import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { exportFormatedMoney, dateFormatedPT } from '../../../util/utils'
import consts from '../../../util/consts'
import Paginate from '../../components/Pagination'
import { fetchServicesRelatoryInit } from '../CommissionReportActions'

function List(props) {
  const dispatch = useDispatch()
  const filters = useSelector(state => state.services_relatory.filters)
  const [rowsPerPage, setRowsPerPage] = useState(consts.DEFAULT_PAGINATION)
  const { classes, items, totalSize, page } = props

  const onMovePage = (event, newPage) => {
    dispatch(fetchServicesRelatoryInit(newPage + 1, filters, rowsPerPage))
  }

  useEffect(() => {
    onMovePage()
  }, [rowsPerPage])

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  return (
    <Paper>
      <TableContainer
        className={classNames({
          [classes.tableContainer]: true,
          [classes.tableStickHeader]: true,
        })}
      >
        <Table stickyHeader size="medium" className={classes.tableStyle}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Serviço</TableCell>
              <TableCell>Contato Fin.</TableCell>
              <TableCell>OS</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>% Comissão</TableCell>
              <TableCell>$ Comissão</TableCell>
              <TableCell>Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(item => (
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.service_name}</TableCell>
                <TableCell>{item.supplier_name}</TableCell>
                <TableCell>{item.attendance_id}</TableCell>
                <TableCell>{exportFormatedMoney(item.final_value)}</TableCell>
                <TableCell>{item.supplier_commission}%</TableCell>
                <TableCell>
                  {exportFormatedMoney(item.item_commission)}
                </TableCell>
                <TableCell>{dateFormatedPT(item.created_at)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginate
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        rows={totalSize}
        page={page - 1}
        handleChangePage={onMovePage}
      />
    </Paper>
  )
}

export default List
