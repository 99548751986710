import axios from 'axios/index'
import { format } from 'date-fns'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const REMOVE_LAUNDRY = createAsyncConst('REMOVE_LAUNDRY')
export const SUBMIT_LAUNDRY = createAsyncConst('SUBMIT_LAUNDRY')
export const FETCH_LAUNDRY = createAsyncConst('FETCH_LAUNDRY')
export const FETCH_LAUNDRY_FILTERS = createAsyncConst('FETCH_LAUNDRY_FILTERS')
export const EDIT_LAUNDRY = createAsyncConst('EDIT_LAUNDRY')
export const NEW_DETACHED = createAsyncConst('NEW_DETACHED')
export const GET_DETACHEDS = createAsyncConst('GET_DETACHEDS')
export const REMOVE_DETACHEDS = createAsyncConst('REMOVE_DETACHEDS')
export const GET_REPORT = createAsyncConst('GET_REPORT')

export function fetchLaundries(page, filters = []) {
  return dispatch => {
    dispatch(action(FETCH_LAUNDRY.ACTION))
    return axios
      .get(`${config.API_URL}/laundry`, {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => {
        dispatch(action(FETCH_LAUNDRY.SUCCESS, {data: resp.data, filters: filters}))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_LAUNDRY, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_LAUNDRY.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/laundry/${id}`)
      .then(resp => {
        return resp.data
      })
      .then(data => dispatch(action(EDIT_LAUNDRY.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(EDIT_LAUNDRY, e)))
  }
}

function submit(values, method) {
  const id = values.id ? `/${values.id}` : ''

  return dispatch => {
    dispatch(action(SUBMIT_LAUNDRY.ACTION))
    return axios[method](`${config.API_URL}/laundry${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_LAUNDRY.SUCCESS, data))
        dispatch(fetchLaundries())
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_LAUNDRY, e)))
  }
}

export function post(values) {
  return submit(values, 'post')
}

export function update(values) {
  return submit(values, 'put')
}

export function remove(id) {
  return dispatch => {
    dispatch(action(REMOVE_LAUNDRY.ACTION))
    return axios
      .delete(`${config.API_URL}/laundry/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(REMOVE_LAUNDRY.SUCCESS, data))
        dispatch(fetchLaundries())
      })
      .catch(e => dispatch(actionFailed(REMOVE_LAUNDRY, e)))
  }
}

export function newDetached(
  id,
  date,
  detachedUser,
  isConfirmed = false,
  pass = false,
  time
) {
  return dispatch => {
    dispatch(action(NEW_DETACHED.ACTION, { id }))
    return axios
      .post(`${config.API_URL}/laundry/new-detached/${id}`, {
        date: format(date, 'yyyy-MM-dd HH:mm:ss'),
        isConfirmed,
        detachedUser,
        pass,
        time,
      })
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(NEW_DETACHED.SUCCESS, data))
        return data
      })
      .catch(e => dispatch(actionFailed(NEW_DETACHED, e)))
  }
}

export function getDetacheds(id) {
  return dispatch => {
    dispatch(action(GET_DETACHEDS.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/laundry/detacheds/${id}`)
      .then(resp => {
        return resp.data
      })
      .then(data => dispatch(action(GET_DETACHEDS.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(GET_DETACHEDS, e)))
  }
}

export function removeDetacheds(id) {
  return dispatch => {
    dispatch(action(REMOVE_DETACHEDS.ACTION, { id }))
    return axios
      .delete(`${config.API_URL}/laundry/detacheds/remove/${id}`)
      .then(resp => {
        return resp.data
      })
      .then(data => dispatch(action(REMOVE_DETACHEDS.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(REMOVE_DETACHEDS, e)))
  }
}

export function makeReport(filters) {
  return dispatch => {
    return axios({
      url:
        `${config.API_URL}/laundry/laundry-registry-report`,
        method: 'POST',
        responseType: 'blob',
        headers: { Accept: 'application/vnd.ms-excel' },
        params: {
          filters: JSON.stringify(filters),
        }
      })
      .then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        
        link.href = url
        link.setAttribute('download', 'laundry_report.xlsx')
        document.body.appendChild(link)
        link.click()
        return resp
      })
    .catch(e => dispatch(actionFailed(GET_REPORT, e)))
  }
}