/* eslint-disable react/prop-types */
import { MenuItem } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import { KeyboardDatePicker } from '@material-ui/pickers'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useCallback, useState } from 'react'
import InputMask from 'react-input-mask'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import { renderRouteRole } from '../../../util/utils'
import BadgeGlobal from '../../components/Badge'
import SelectField from '../../components/SelectField'
import { fetchAttendanceInit } from '../AttendanceActions'

export default function Filter(props) {
  const items = useSelector(state => state.attendance.items)
  const statuses = useSelector(state => state.attendance.status)
  const attendants = useSelector(state => state.attendance.attendants)
  const companies = useSelector(state => state.attendance.companies)
  const role = useSelector(state => state.auth.role)
  const sizePerPage = useSelector(state => state.attendance.sizePerPage)

  const dispatch = useDispatch()
  const { classes } = props
  const [form, setForm] = useState({
    id: '',
    name: '',
    phone: '',
    status: '',
    attendant: '',
    date: null,
    has_billing: '',
    company: '',
  })

  const onSubmit = e => {
    e.preventDefault()
    dispatch(fetchAttendanceInit(form, sizePerPage))
  }

  const updateField = useCallback(
    (name, value) => {
      setForm({ ...form, [name]: value })
    },
    [form]
  )

  return (
    <Tooltip title="Filter list">
      <PopupState variant="popover" popupId="demo-popup-popover">
        {popupState => (
          <div>
            <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
              <BadgeGlobal form={form} items={items}>
                <FilterListIcon />
              </BadgeGlobal>
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2} className={classes.popover}>
                <div className={classes.root}>
                  <ValidatorForm className={classes.form} onSubmit={onSubmit}>
                    <Grid
                      container
                      className={classes.gridContainer}
                      spacing={1}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item sm={12} xs={12} lg={6}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, id: e.target.value })
                          }
                          label="Numero da OS"
                          margin="dense"
                          className={classes.inputFilter}
                          variant="outlined"
                          fullWidth
                          value={form.id}
                        />
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, name: e.target.value })
                          }
                          label="Nome"
                          margin="dense"
                          className={classes.inputFilter}
                          variant="outlined"
                          fullWidth
                          value={form.name}
                        />
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <InputMask
                          mask={
                            form.phone?.replace(/[^\d]/g, '').length <= 10
                              ? '(99) 9999-9999?'
                              : '(99) 99999-9999'
                          }
                          formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                          maskChar=""
                          value={form.phone}
                          onChange={e => {
                            setForm({
                              ...form,
                              phone: e.target.value.replace(/[^\d]/g, ''),
                            })
                          }}
                        >
                          {inputProps => (
                            <TextValidator
                              {...inputProps}
                              label="Telefone"
                              fullWidth
                              margin="dense"
                              variant="outlined"
                              validators={[
                                'matchRegexp:^\\(?[0-9]{2,3}\\)? ?[0-9]{4,5}-?[0-9]{4}$',
                              ]}
                              errorMessages={['Telefone inválido']}
                            />
                          )}
                        </InputMask>
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <SelectField
                          options={statuses}
                          setForm={updateField}
                          data={form.status || null}
                          name="status"
                          margin="dense"
                          variant="outlined"
                          label="Status"
                        />
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <SelectField
                          options={attendants}
                          setForm={updateField}
                          data={form.attendant || null}
                          name="attendant"
                          margin="dense"
                          variant="outlined"
                          label="Atendente"
                        />
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <KeyboardDatePicker
                          clearable
                          value={form.date}
                          label="Data"
                          fullWidth
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          margin="dense"
                          inputVariant="outlined"
                          onChange={date =>
                            setForm({
                              ...form,
                              date,
                            })
                          }
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      {renderRouteRole(['administrador'], role) && (
                        <Grid item sm={12} xs={12} lg={6}>
                          <SelectField
                            options={companies}
                            setForm={updateField}
                            data={form.company || null}
                            name="company"
                            margin="dense"
                            variant="outlined"
                            label="Empresa"
                          />
                        </Grid>
                      )}

                      <Grid item sm={12} xs={12} lg={6}>
                        <TextField
                          select
                          label="Possui pagamento?"
                          className={classes.inputFilter}
                          fullWidth
                          onChange={e =>
                            setForm({ ...form, has_billing: e.target.value })
                          }
                          margin="dense"
                          variant="outlined"
                          value={form.has_billing}
                        >
                          <MenuItem key="" value="">
                            Selecione...
                          </MenuItem>
                          <MenuItem key={1} value={1}>
                            Com pagamento
                          </MenuItem>
                          <MenuItem key={2} value={2}>
                            Sem pagamento
                          </MenuItem>
                          <MenuItem key={3} value={3}>
                            Com pagamento parcial
                          </MenuItem>
                        </TextField>
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, bar_code: e.target.value })
                          }
                          label="Etiqueta"
                          margin="dense"
                          className={classes.inputFilter}
                          variant="outlined"
                          fullWidth
                          value={form.bar_code}
                        />
                      </Grid>

                      <Grid item xs>
                        <Button
                          type="submit"
                          color="primary"
                          fullWidth
                          variant="contained"
                          className={classes.submit}
                          onClick={popupState.close}
                        >
                          <SearchIcon />
                          {` Filtrar`}
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                </div>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </Tooltip>
  )
}
