import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import consts from '../../util/consts'
import createAsyncConst from '../../util/createAsyncConst'

export const SUBMIT_PAYMENT_VERIFICATION = createAsyncConst(
  'SUBMIT_PAYMENT_VERIFICATION'
)
export const FETCH_PAYMENT_VERIFICATION = createAsyncConst(
  'FETCH_PAYMENT_VERIFICATION'
)
export const EDIT_PAYMENT_VERIFICATION = createAsyncConst(
  'EDIT_PAYMENT_VERIFICATION'
)

export function fetchPaymentVerification(
  page,
  filters = { status: 0 },
  rows = consts.DEFAULT_PAGINATION
) {
  return dispatch => {
    dispatch(action(FETCH_PAYMENT_VERIFICATION.ACTION))
    return axios
      .get(`${config.API_URL}/paymentVerification`, {
        params: {
          filters: JSON.stringify(filters),
          rows: rows || consts.DEFAULT_PAGINATION,
          page: page || 1,
        },
      })
      .then(resp => {
        dispatch(
          action(FETCH_PAYMENT_VERIFICATION.SUCCESS, {
            data: resp.data,
            filters,
          })
        )
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_PAYMENT_VERIFICATION, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_PAYMENT_VERIFICATION.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/paymentVerification/${id}`)
      .then(resp => {
        return resp.data.data
      })
      .then(data => dispatch(action(EDIT_PAYMENT_VERIFICATION.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(EDIT_PAYMENT_VERIFICATION, e)))
  }
}

function submit(values, method) {
  const id = values.id ? `/${values.id}` : ''

  return dispatch => {
    dispatch(action(SUBMIT_PAYMENT_VERIFICATION.ACTION))
    return axios[method](`${config.API_URL}/paymentVerification${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_PAYMENT_VERIFICATION.SUCCESS, data))
        dispatch(fetchPaymentVerification())
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_PAYMENT_VERIFICATION, e)))
  }
}

export function update(values) {
  return submit(values, 'put')
}
