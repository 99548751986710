/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useState } from 'react'
import InputMask from 'react-input-mask'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
import { fetchClients } from '../ClientActions'

export default function Filter(props) {
  const items = useSelector(state => state.client.items)

  const dispatch = useDispatch()
  const { classes } = props
  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
  })

  const onSubmit = e => {
    e.preventDefault()
    dispatch(fetchClients(null, form))
  }

  return (
    <Tooltip title="Filter list">
      <PopupState variant="popover" popupId="demo-popup-popover">
        {popupState => (
          <div>
            <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
              <BadgeGlobal form={form} items={items}>
                <FilterListIcon />
              </BadgeGlobal>
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2} className={classes.popover}>
                <div className={classes.root}>
                  <ValidatorForm className={classes.form} onSubmit={onSubmit}>
                    <Grid
                      container
                      className={classes.gridContainer}
                      spacing={1}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={6}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, name: e.target.value })
                          }
                          label="Nome"
                          margin="dense"
                          className={classes.inputFilter}
                          variant="outlined"
                          fullWidth
                          value={form.name}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, email: e.target.value })
                          }
                          label="Email"
                          margin="dense"
                          className={classes.inputFilter}
                          variant="outlined"
                          fullWidth
                          value={form.email}
                        />
                      </Grid>

                      <Grid item xs>
                        <InputMask
                          mask={
                            form.phone?.replace(/[^\d]/g, '').length <= 10
                              ? '(99) 9999-9999?'
                              : '(99) 99999-9999'
                          }
                          formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                          maskChar=""
                          margin="dense"
                          value={form.phone}
                          onChange={e => {
                            setForm({
                              ...form,
                              phone: e.target.value.replace(/[^\d]/g, ''),
                            })
                          }}
                        >
                          {inputProps => (
                            <TextValidator
                              {...inputProps}
                              label="Telefone"
                              fullWidth
                              variant="outlined"
                              validators={[
                                'matchRegexp:^\\(?[0-9]{2,3}\\)? ?[0-9]{4,5}-?[0-9]{4}$',
                              ]}
                              errorMessages={['Telefone inválida']}
                            />
                          )}
                        </InputMask>
                      </Grid>

                      <Grid item xs>
                        <Button
                          type="submit"
                          color="primary"
                          fullWidth
                          variant="contained"
                          className={classes.submit}
                          onClick={popupState.close}
                        >
                          <SearchIcon />
                          {` Filtrar`}
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                </div>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </Tooltip>
  )
}
