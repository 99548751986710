import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useDispatch } from 'react-redux'
import { Grid, MenuItem, TextField, Select } from '@material-ui/core'

import { fetchDashboard, update } from '../RestorationActions'
import NumberFormatCustom from '../../components/NumberFormat'

function ModalColectValue({ open, handleClose, item }) {
  const dispatch = useDispatch()
  const [form, setForm] = useState({
    restoration_autorized: 0,
    restoration_value: 0,
    restoration_comments: '',
  })

  const onSubmit = () => {
    dispatch(update(item?.id, form)).then(() => {
      dispatch(fetchDashboard())
      handleClose()
    })
  }

  useEffect(() => {
    setForm({ ...item })
  }, [item])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        aria-labelledby="form-value-restoration-title"
      >
        <DialogTitle id="form-value-restoration-title">
          Digite o valor a ser praticado
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Select
                labelId="authorized-select-label"
                id="authorized-select"
                fullWidth
                value={form.restoration_autorized}
                onChange={e =>
                  setForm({ ...form, restoration_autorized: e.target.value })
                }
              >
                <MenuItem value={1}>Sim</MenuItem>
                <MenuItem value={2}>Não</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={form.restoration_value}
                placeholder="Valor a ser praticado"
                fullWidth
                onChange={e =>
                  setForm({ ...form, restoration_value: e.target.value })
                }
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder="Descrição do reparo..."
                value={form.restoration_comments}
                variant="outlined"
                margin="dense"
                multiline
                fullWidth
                rows={3}
                rowsMax={10}
                onChange={e =>
                  setForm({ ...form, restoration_comments: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={onSubmit} color="primary">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default React.memo(ModalColectValue)
