import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const REMOVE_SERVICE_CATEGORY = createAsyncConst(
  'REMOVE_SERVICE_CATEGORY'
)
export const SUBMIT_SERVICE_CATEGORY = createAsyncConst(
  'SUBMIT_SERVICE_CATEGORY'
)
export const FETCH_ELETRONIC_NFSE = createAsyncConst('FETCH_ELETRONIC_NFSE')
export const FETCH_ELETRONIC_COMPANIES = createAsyncConst(
  'FETCH_ELETRONIC_COMPANIES'
)
export const EDIT_ELETRONIC_INVOICE = createAsyncConst('EDIT_ELETRONIC_INVOICE')

export function fetchNfseCompanies(filters = []) {
  return dispatch => {
    dispatch(action(FETCH_ELETRONIC_NFSE.ACTION))
    return axios
      .get(`${config.API_URL}/nfse-company`, { params: { ...filters } })
      .then(resp => {
        dispatch(action(FETCH_ELETRONIC_NFSE.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_ELETRONIC_NFSE, e)))
  }
}

export function update(values, id) {
  return dispatch => {
    dispatch(action(EDIT_ELETRONIC_INVOICE.ACTION))
    return axios
      .put(`${config.API_URL}/nfse-company/${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(EDIT_ELETRONIC_INVOICE.SUCCESS, data))
        dispatch(fetchNfseCompanies())
        return true
      })
      .catch(e => dispatch(actionFailed(EDIT_ELETRONIC_INVOICE, e)))
  }
}

export function store(values, id) {
  return dispatch => {
    dispatch(action(EDIT_ELETRONIC_INVOICE.ACTION))
    return axios
      .post(`${config.API_URL}/nfse-company`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(EDIT_ELETRONIC_INVOICE.SUCCESS, data))
        dispatch(fetchNfseCompanies())
        return true
      })
      .catch(e => dispatch(actionFailed(EDIT_ELETRONIC_INVOICE, e)))
  }
}

export function fetchCompaniesAvaliable(filters = []) {
  return dispatch => {
    dispatch(action(FETCH_ELETRONIC_COMPANIES.ACTION))
    return axios
      .get(`${config.API_URL}/eletronic-invoice/companies/avaliable`)
      .then(resp => {
        dispatch(action(FETCH_ELETRONIC_COMPANIES.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_ELETRONIC_COMPANIES, e)))
  }
}
