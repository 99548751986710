import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import IconButton from '@material-ui/core/IconButton'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import consts from '../../../util/consts'
import Paginate from '../../components/Pagination'
import { fetchTeamProdutivityRelatoryInit } from '../TeamProdutivityRelatoryActions'
import { exportFormatedMoney } from '../../../util/utils'

function List(props) {
  const dispatch = useDispatch()
  const filters = useSelector(state => state.team_produtivity_relatory.filters)
  const [rowsPerPage, setRowsPerPage] = useState(consts.DEFAULT_PAGINATION)
  const { classes, items, totalSize, page } = props

  const onMovePage = (event, newPage) => {
    dispatch(
      fetchTeamProdutivityRelatoryInit(newPage + 1, filters, rowsPerPage)
    )
  }

  useEffect(() => {
    onMovePage()
  }, [rowsPerPage])

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  return (
    <Paper>
      <TableContainer
        className={classNames({
          [classes.tableContainer]: true,
          [classes.tableStickHeader]: true,
        })}
      >
        <Table stickyHeader size="medium" className={classes.tableStyle}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Equipe</TableCell>
              <TableCell>Quantidade</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Valor do Técnico</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(item => (
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.team}</TableCell>
                <TableCell>{item.qty}</TableCell>
                <TableCell>{exportFormatedMoney(item.value)}</TableCell>
                <TableCell>
                  {exportFormatedMoney(item.technician_value)}
                </TableCell>
                <TableCell>
                  <IconButton
                    component={Link}
                    to={`/relatory-team-produtivity/${item.id}`}
                  >
                    <ZoomInIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginate
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        rows={totalSize}
        page={page - 1}
        handleChangePage={onMovePage}
      />
    </Paper>
  )
}

export default List
