import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from '../../../resources/theme/users'
import { edit, update } from '../ConfigActions'

import Form from './Form'

function Transition(props) {
  return <Slide direction="down" {...props} />
}

function Edit(props) {
  const { classes } = props
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(edit(props.match.params.id))
  }, [])

  const config = useSelector(state => state.config.editingConfig)

  const onSubmit = values => {
    dispatch(update(values)).then(data => {
      if (data?.status === 204) {
        props.history.push('/config')
      }
    })
  }

  const onClose = () => {
    props.history.push('/config')
  }

  return (
    <Dialog
      open={config !== null}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Editar Configuração
          </Typography>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Form data={config} onClose={onClose} onSubmit={onSubmit} />
    </Dialog>
  )
}

export default withStyles(styles)(Edit)
