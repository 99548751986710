/* eslint-disable import/order */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import axios from 'axios/index'
import {
  Box,
  Divider,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import config from '../../../config'
import styles from '../../../resources/theme/global'

import { Filter } from './Filter'
import { format } from 'date-fns'
import CardSection from './CardSection'
import { useDispatch } from 'react-redux'
import action from '../../../util/action'
import { fetchDashboard } from '../DashboardLeadsActions'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const LeadsDashboard = styles => {
  const dispatch = useDispatch()
  const props = useStyles()
  const { classes } = styles

  const date = new Date()

  const getFirstDayOfPeriod = date => {
    const [year, month] = [date.getFullYear(), date.getMonth()]
    return new Date(year, month, 1)
  }

  const getLastDayOfPeriod = date => {
    const lastDayOfMonth = new Date(date)
    lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1)
    lastDayOfMonth.setDate(0)
    return lastDayOfMonth
  }

  const [filters, setFilters] = useState({
    initialDate: format(getFirstDayOfPeriod(date), 'yyyy-MM-dd '),
    finalDate: format(getLastDayOfPeriod(date), 'yyyy-MM-dd '),
    selectedLaundry: '',
    selectedAttendance: '',
  })

  const [data, setData] = useState([])

  const fetchDashboardData = async event => {
    const resp = await dispatch(fetchDashboard(filters))

    if (resp) {
      setData(resp)
    }
  }

  useEffect(() => {
    fetchDashboardData()
  }, [filters])

  return (
    <Box className={props.root}>
      <Box className={props.header}>
        <Typography variant="h4" color="secondary">
          Dashboard leads
        </Typography>
        <Filter
          filters={filters}
          setFilters={setFilters}
          getFirstDayOfPeriod={getFirstDayOfPeriod}
          getLastDayOfPeriod={getLastDayOfPeriod}
          date={date}
        />
      </Box>
      <Divider variant="middle" style={{ margin: '50px' }} />
      <CardSection
        data={data?.leads_today}
        title="Leads hoje"
        isMonetary
        isLinkCard
        classes={classes}
        selectedAttendance={filters.selectedAttendance}
        selectedLaundry={filters.selectedLaundry}
      />
      <Divider variant="middle" style={{ margin: '50px' }} />
      <CardSection
        data={data?.leads_month}
        title="Leads no período"
        isMonetary
        isLinkCard
        classes={classes}
        finalDate={filters.finalDate}
        startDate={filters.initialDate}
        selectedAttendance={filters.selectedAttendance}
        selectedLaundry={filters.selectedLaundry}
      />
      <Divider variant="middle" style={{ margin: '50px' }} />
      <CardSection
        data={data?.origin_leads_month}
        title="Origem de leads no período"
        classes={classes}
        selectedAttendance={filters.selectedAttendance}
        selectedLaundry={filters.selectedLaundry}
      />
      <Divider variant="middle" style={{ margin: '50px' }} />
      <CardSection
        data={data?.agendaments}
        title="Agendamentos no período"
        isMonetary
        isLinkRelatory
        classes={classes}
        finalDate={filters.finalDate}
        startDate={filters.initialDate}
        selectedAttendance={filters.selectedAttendance}
        selectedLaundry={filters.selectedLaundry}
      />
      <Divider variant="middle" style={{ margin: '50px' }} />
    </Box>
  )
}

export default withStyles(styles)(LeadsDashboard)
