import React from 'react'

import GlobalList from '../../../components/GlobalList'
import { exportFormatedMoney, renderRouteRole } from '../../../../util/utils'
import { useDispatch, useSelector } from 'react-redux'
import {
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import classNames from 'classnames'
import { update } from '../TriggersActions'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import PencilIcon from '@material-ui/icons/Edit'

const List = ({ classes = {}, form = {}, setForm = () => {} }) => {
  const role = useSelector(state => state.auth.role)
  const items = useSelector(state => state.triggers.items)
  const meta = useSelector(state => state.triggers.meta)
  const dispatch = useDispatch()

  const handleChangePage = newPage => {
    setForm({
      ...form,
      page: newPage,
    })
  }

  const handleChangeRowsPerPage = event => {
    setForm({
      ...form,
      per_page: event.target.value,
    })
  }

  const handleChangeTriggerStatus = async (trigger, status) => {
    const data = {
      ...trigger,
      type: trigger?.type?.var_id,
      active: status,
    }

    dispatch(update(data, trigger?.id))
  }

  return (
    <Paper>
      <TableContainer
        className={classNames({
          [classes.tableContainer]: true,
          [classes.tableStickHeader]: true,
        })}
      >
        <Table stickyHeader size="medium" className={classes.tableStyle}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Motivo</TableCell>
              <TableCell align="center">Dias</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell align="center">Situação</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((trigger, index) => (
              <TableRow key={index}>
                <TableCell>{trigger?.id}</TableCell>
                <TableCell>{trigger?.reason}</TableCell>
                <TableCell align="center">{trigger?.days}</TableCell>
                <TableCell>{trigger?.type?.value}</TableCell>
                <TableCell align="center">
                  {renderRouteRole(['administrador', 'lavanderia'], role) ? (
                    <Switch
                      checked={trigger?.active}
                      onChange={e =>
                        handleChangeTriggerStatus(trigger, !trigger.active)
                      }
                    />
                  ) : (
                    <Switch checked={trigger?.active} />
                  )}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    component={Link}
                    to={`/crm/triggers/create-edit/${trigger.id}`}
                  >
                    <PencilIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={Number(meta?.total)}
          rowsPerPage={Number(meta?.per_page)}
          page={Number(meta?.current_page) - 1}
          labelRowsPerPage="Linhas por Página"
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          labelDisplayedRows={({ from, to, count }) =>
            `Mostrando linhas ${from}-${to} de ${count}`
          }
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onPageChange={(e, page) => handleChangePage(page + 1)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Paper>
  )
}

export default List
