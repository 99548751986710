import {
  GET_OPERADORES_RUA,
  GET_SERVICES_UNBOOKED,
  REORDER,
} from './CalendarActions'

const INITIAL_STATE = {
  attendants: [],
  unbookedServices: [],
  fetching: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_OPERADORES_RUA.SUCCESS:
      return {
        ...state,
        attendants: action.payload,
      }
    case GET_SERVICES_UNBOOKED.SUCCESS:
      return {
        ...state,
        unbookedServices: action.payload,
      }
    case REORDER.ACTION:
      return {
        ...state,
        fetching: true,
      }
    case REORDER.SUCCESS:
    case REORDER.FAILURE:
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
