import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import consts from '../../util/consts'
import createAsyncConst from '../../util/createAsyncConst'

export const FETCH_RESTORATION_ITEMS = createAsyncConst(
  'FETCH_RESTORATION_ITEMS'
)

export const UPDATE_RESTORATION = createAsyncConst('UPDATE_RESTORATION')
export const SUBMIT_RESTORATION = createAsyncConst('SUBMIT_RESTORATION')
export const RESTORATION_REPORT = createAsyncConst('RESTORATION_REPORT')

export function fetchDashboard(page, filters = {}, rows) {
  return dispatch => {
    dispatch(action(FETCH_RESTORATION_ITEMS.ACTION))
    return axios
      .get(`${config.API_URL}/restoration`, {
        params: {
          filters: JSON.stringify(filters),
          rows: rows || consts.DEFAULT_PAGINATION,
          page: page || 1,
        },
      })
      .then(resp => {
        dispatch(
          action(FETCH_RESTORATION_ITEMS.SUCCESS, { data: resp.data, filters })
        )
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_RESTORATION_ITEMS, e)))
  }
}

export function update(id, values) {
  return dispatch => {
    dispatch(action(UPDATE_RESTORATION.ACTION))
    return axios
      .put(`${config.API_URL}/restoration/${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(UPDATE_RESTORATION.SUCCESS, data))
        return data
      })
      .catch(e => dispatch(actionFailed(UPDATE_RESTORATION, e)))
  }
}

function submit(values, method) {
  const id = values.id ? `/${values.id}` : ''

  return dispatch => {
    dispatch(action(SUBMIT_RESTORATION.ACTION))
    return axios[method](`${config.API_URL}/restoration${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_RESTORATION.SUCCESS, data))
        dispatch(fetchDashboard())
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_RESTORATION, e)))
  }
}

export function post(values) {
  return submit(values, 'post')
}

export function report(data) {
  return dispatch => {
    dispatch(action(RESTORATION_REPORT.ACTION))
    return axios({
      url: `${config.API_URL}/restoration/report`,
      method: 'GET',
      responseType: 'blob',
      headers: { Accept: 'application/vnd.ms-excel' },
      params: {
        filters: JSON.stringify(data),
      },
    })
      .then(resp => {
        dispatch(action(RESTORATION_REPORT.SUCCESS, resp))
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')

        link.href = url
        link.setAttribute('download', 'restoration_report.xlsx')
        document.body.appendChild(link)
        link.click()
        return resp
      })
      .catch(e => dispatch(actionFailed(RESTORATION_REPORT, e)))
  }
}
