import {
  DialogActions,
  TextField,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import { KeyboardDatePicker } from '@material-ui/pickers'
import React, { useEffect, useState, useMemo } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useSelector } from 'react-redux'
import format from 'date-fns/format'

import styles from '../../../resources/theme/users'
import SelectField from '../../components/SelectField'
import AsyncRequest from '../../components/AsyncRequest'
import NumberFormatCustom from '../../components/NumberFormat'
import NumberFormatCustomNoDecimals from '../../components/NumberFormatNoDecimals'

function BillingForm(props) {
  const { data, onClose, onSubmit, classes } = props
  const billingTypes = useSelector(state => state.billing.billingTypes)
  const paymentMethods = useSelector(state => state.billing.paymentMethods)
  const banks = useSelector(state => state.billing.banks)

  const [form, updateForm] = useState({
    id: '',
    date: null,
    person: '',
    billing_type: '',
    description: '',
    value: '',
    payment_method: '',
    is_paid: false,
    replicate: 1,
    actual_payment_date: null,
  })

  const updateFields = React.useCallback(
    (name, value) => {
      updateForm({
        ...form,
        [name]: value,
      })
    },
    [form]
  )

  const handleClose = () => onClose && onClose()

  const onSubmitForm = e => {
    e.preventDefault()
    const data = {
      ...form,
      date: form.date ? format(new Date(form.date), 'yyyy-MM-dd') : '',
      actual_payment_date: form.actual_payment_date
        ? format(new Date(form.actual_payment_date), 'yyyy-MM-dd')
        : '',
    }
    onSubmit && onSubmit(data)
  }

  useEffect(() => {
    updateForm({ ...form, ...data })
  }, [data])

  const readOnly = useMemo(() => data?.service, [data])

  return (
    <ValidatorForm onSubmit={onSubmitForm}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12} lg={3}>
            <AsyncRequest
              label="Pessoa"
              link="client-filter"
              setForm={updateFields}
              data={form.person}
              name="person"
              disabled={readOnly}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={3}>
            <SelectField
              style={{ marginTop: '0px' }}
              options={billingTypes}
              setForm={updateFields}
              data={form.billing_type || null}
              name="billing_type"
              label="Tipo de conta"
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={3}>
            <SelectField
              options={paymentMethods}
              setForm={updateFields}
              data={form.payment_method}
              name="payment_method"
              label="Método de pagamento"
              disabled={form.id}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={3}>
            <SelectField
              style={{ marginTop: '0px' }}
              options={banks}
              setForm={updateFields}
              data={form.bank || null}
              name="bank"
              label="Banco"
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={4}>
            <TextField
              select
              label="Tipo"
              className={classes.inputFilter}
              fullWidth
              value={form.billing_type?.type || ''}
              disabled
            >
              <MenuItem key="" value="">
                Selecione...
              </MenuItem>
              <MenuItem key={1} value={1}>
                Despesa
              </MenuItem>
              <MenuItem key={2} value={2}>
                Receita
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item sm={6} xs={12} lg={2}>
            <TextField
              className={classes.formControl}
              label="Valor"
              value={form.value}
              onChange={e => updateForm({ ...form, value: e.target.value })}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs lg={2}>
            <KeyboardDatePicker
              clearable
              value={form.date}
              label="Data do vencimento"
              fullWidth
              clearLabel="Limpar"
              invalidDateMessage="Data inválida"
              autoOk
              onChange={date => updateForm({ ...form, date })}
              maxDate={new Date('2300-01-01')}
              format="dd/MM/yyyy"
            />
            <FormHelperText>
              *Caso não seja fornecida, será utilizada a data atual.
            </FormHelperText>
          </Grid>
          <Grid item xs lg={2}>
            <KeyboardDatePicker
              clearable
              value={form.actual_payment_date}
              label="Data do Pagamento"
              fullWidth
              clearLabel="Limpar"
              invalidDateMessage="Data inválida"
              autoOk
              onChange={date =>
                updateForm({ ...form, actual_payment_date: date })
              }
              maxDate={new Date('2300-01-01')}
              format="dd/MM/yyyy"
            />
            <FormHelperText>
              *Caso não seja fornecida, será utilizada a data atual.
            </FormHelperText>
          </Grid>
          <Grid item sm={6} xs={12} lg={2}>
            <TextField
              type="text"
              pattern="[0-9]*"
              className={classes.formControl}
              label="Replicar por mês"
              value={form.replicate}
              onChange={e => updateForm({ ...form, replicate: e.target.value })}
              InputProps={{
                inputComponent: NumberFormatCustomNoDecimals,
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs>
            <FormControlLabel
              control={
                <Switch
                  checked={Boolean(form.is_paid)}
                  onChange={e =>
                    updateForm({ ...form, is_paid: e.target.checked })
                  }
                  value={form.is_paid}
                />
              }
              label={
                form.id
                  ? 'Baixar pagamento'
                  : 'Criar com o pagamento realizado.'
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              placeholder="Descrição"
              value={form.description}
              onChange={e =>
                updateForm({ ...form, description: e.target.value })
              }
              variant="outlined"
              multiline
              fullWidth
              rows={5}
              rowsMax={10}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color="primary" type="submit">
          Salvar
        </Button>
        <Button onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </ValidatorForm>
  )
}

export default withStyles(styles)(BillingForm)
