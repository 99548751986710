import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'
import { SNACKBAR } from '../main/MainActions'

export const REMOVE_KNOW_WAY = createAsyncConst('REMOVE_KNOW_WAY')
export const SUBMIT_KNOW_WAY = createAsyncConst('SUBMIT_KNOW_WAY')
export const FETCH_KNOW_WAY = createAsyncConst('FETCH_SERVICE_CATEGORY')
export const EDIT_KNOW_WAY = createAsyncConst('EDIT_KNOW_WAY')

export function fetchKnowWay(filters = []) {
  return dispatch => {
    dispatch(action(FETCH_KNOW_WAY.ACTION))
    return axios
      .get(`${config.API_URL}/knowWay`, {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => {
        dispatch(action(FETCH_KNOW_WAY.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_KNOW_WAY, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_KNOW_WAY.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/knowWay/${id}`)
      .then(resp => {
        return resp.data.data
      })
      .then(data => dispatch(action(EDIT_KNOW_WAY.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(EDIT_KNOW_WAY, e)))
  }
}

function submit(values, method) {
  const id = values.id ? `/${values.id}` : ''

  return dispatch => {
    dispatch(action(SUBMIT_KNOW_WAY.ACTION))
    return axios[method](`${config.API_URL}/knowWay${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_KNOW_WAY.SUCCESS, data))
        dispatch(fetchKnowWay())
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_KNOW_WAY, e)))
  }
}

export function post(values) {
  return submit(values, 'post')
}

export function update(values) {
  return submit(values, 'put')
}

export function remove(id) {
  return dispatch => {
    return axios
      .delete(`${config.API_URL}/knowWay/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(fetchKnowWay())
        dispatch({
          type: SNACKBAR.SOFTSUCCESS,
          success: { message: 'Indicação deletada com sucesso' },
        })
      })
      .catch(e =>  dispatch({
        type: SNACKBAR.HARDFAIL,
        error: { message: 'Erro ao deletar Indicação. Alguma OS ainda veinculada? (Erro: ' + e + ' )' },
      }))
  }
}
