import React from 'react'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
  Box,
} from '@material-ui/core'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import classnames from 'classnames'
import CountUp from 'react-countup'

import { exportFormatedMoney } from '../../../util/utils'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1),
  },
  differenceValueNegative: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
  },
  noDifference: {
    color: colors.grey[900],
    marginRight: theme.spacing(1),
  },
}))

const SummaryCard = ({ total, tipo, positive,}) => {
  const classes = useStyles()

  const contUp = money => {
    return (
      <CountUp
        end={money}
        duration={0.7}
        decimals={2}
        decimal=","
        prefix="R$"
        separator="."
      />
    )
  }


  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              {tipo}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
              className={classnames({
                [classes.differenceValue]: positive,
                [classes.differenceValueNegative]: !positive,
              })}
            >
              {contUp(total)}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default SummaryCard
