/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import { MenuItem } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useState } from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'
import {
  endOfDay,
  endOfMonth,
  format,
  startOfDay,
  startOfMonth,
} from 'date-fns'

import BadgeGlobal from '../../components/Badge'
import { fetchFinancialRelatoryInit } from '../FinancialRelatoryActions'

export default function Filter(props) {
  const expenses = useSelector(state => state.financial_relatory.expenses)
  const billing_types = useSelector(
    state => state.financial_relatory.billing_types
  )

  const dispatch = useDispatch()
  const { classes } = props
  const [form, setForm] = useState({
    date_start: startOfMonth(new Date()),
    date_end: endOfMonth(new Date()),
    payment_date_start: null,
    payment_date_end: null,
    launch_type: '3',
    baixa: '',
    billing_type: '',
  })

  const onSubmit = e => {
    e.preventDefault()
    const data = { ...form }
    if (data.date_end)
      data.date_end = format(new Date(data.date_end), 'yyyy-MM-dd HH:mm')
    if (data.date_start)
      data.date_start = format(new Date(data.date_start), 'yyyy-MM-dd HH:mm')
    if (data.payment_date_start)
      data.payment_date_start = format(
        startOfDay(new Date(data.payment_date_start)),
        'yyyy-MM-dd HH:mm'
      )
    if (data.payment_date_end)
      data.payment_date_end = format(
        endOfDay(new Date(data.payment_date_end)),
        'yyyy-MM-dd HH:mm'
      )
    dispatch(fetchFinancialRelatoryInit(data))
  }

  return (
    <Tooltip title="Filter list">
      <PopupState variant="popover" popupId="demo-popup-popover">
        {popupState => (
          <div>
            <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
              <BadgeGlobal form={form} items={expenses}>
                <FilterListIcon />
              </BadgeGlobal>
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={3} className={classes.popover}>
                <div className={classes.root}>
                  <ValidatorForm className={classes.form} onSubmit={onSubmit}>
                    <Grid
                      container
                      className={classes.gridContainer}
                      spacing={1}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={4}>
                        <KeyboardDatePicker
                          clearable
                          value={form.date_start}
                          label="Data do venc. inicial"
                          fullWidth
                          margin="dense"
                          inputVariant="outlined"
                          views={['date']}
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          onChange={date =>
                            setForm({ ...form, date_start: date })
                          }
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <KeyboardDatePicker
                          clearable
                          value={form.date_end}
                          label="Data do venc. final"
                          fullWidth
                          margin="dense"
                          inputVariant="outlined"
                          views={['date']}
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          onChange={date =>
                            setForm({ ...form, date_end: date })
                          }
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, launch_type: e.target.value })
                          }
                          select
                          label="Tipo de Lançamento"
                          margin="dense"
                          variant="outlined"
                          className={classes.inputFilter}
                          fullWidth
                          value={form.launch_type}
                        >
                          <MenuItem key="3" value="3">
                            Ambos
                          </MenuItem>
                          <MenuItem key="1" value="1">
                            Despesas
                          </MenuItem>
                          <MenuItem key="2" value="2">
                            Receitas
                          </MenuItem>
                        </TextField>
                      </Grid>

                      <Grid item xs={6}>
                        <KeyboardDatePicker
                          clearable
                          value={form.payment_date_start}
                          label="Data do pagamento inicial"
                          fullWidth
                          margin="dense"
                          inputVariant="outlined"
                          views={['date']}
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          onChange={date =>
                            setForm({ ...form, payment_date_start: date })
                          }
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <KeyboardDatePicker
                          clearable
                          value={form.payment_date_end}
                          label="Data do pagamento final"
                          fullWidth
                          margin="dense"
                          inputVariant="outlined"
                          views={['date']}
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          onChange={date =>
                            setForm({ ...form, payment_date_end: date })
                          }
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, baixa: e.target.value })
                          }
                          select
                          label="Baixado"
                          margin="dense"
                          variant="outlined"
                          className={classes.inputFilter}
                          fullWidth
                          value={form.baixa}
                        >
                          <MenuItem key="" value="">
                            Selecione...
                          </MenuItem>
                          <MenuItem key="1" value="1">
                            Não
                          </MenuItem>
                          <MenuItem key="2" value="2">
                            Sim
                          </MenuItem>
                        </TextField>
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, billing_type: e.target.value })
                          }
                          select
                          label="Tipo de Conta"
                          margin="dense"
                          variant="outlined"
                          className={classes.inputFilter}
                          fullWidth
                          value={form.billing_type}
                        >
                          <MenuItem key="" value="">
                            Selecione...
                          </MenuItem>
                          {billing_types?.map(billing_type => (
                            <MenuItem
                              key={billing_type.id}
                              value={billing_type.id}
                            >
                              {billing_type.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs>
                        <Button
                          type="submit"
                          color="primary"
                          fullWidth
                          variant="contained"
                          className={classes.submit}
                          onClick={popupState.close}
                        >
                          <SearchIcon />
                          {` Filtrar`}
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                </div>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </Tooltip>
  )
}
