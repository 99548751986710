import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const FETCH_MONITORING_MAP = createAsyncConst('FETCH_MONITORING_MAP')
export const FETCH_ATTENDANTS = createAsyncConst('FETCH_ATTENDANTS')
export const FETCH_MONITORING_REPORT = createAsyncConst(
  'FETCH_MONITORING_REPORT'
)

export function fetchMonitoringMap(filters = {}) {
  return dispatch => {
    dispatch(action(FETCH_MONITORING_MAP.ACTION, {}))
    return axios
      .get(`${config.API_URL}/attendant-coordinates`, {
        params: filters,
      })
      .then(resp => {
        dispatch(action(FETCH_MONITORING_MAP.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_MONITORING_MAP, e)))
  }
}

export function fetchAttendantsList() {
  return dispatch => {
    dispatch(action(FETCH_ATTENDANTS.ACTION, {}))
    return axios
      .get(`${config.API_URL}/attendant-coordinates/attendants-list`)
      .then(resp => {
        dispatch(action(FETCH_ATTENDANTS.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_ATTENDANTS, e)))
  }
}

export function exportMonitoring(filters) {
  return dispatch => {
    dispatch(action(FETCH_MONITORING_REPORT.ACTION, {}))
    return axios
      .get(`${config.API_URL}/attendant-coordinates/export`, {
        params: filters,
      })
      .then(response => {
        dispatch(action(FETCH_MONITORING_REPORT.SUCCESS, response.data))
        return response
      })
      .catch(e => dispatch(actionFailed(FETCH_MONITORING_REPORT, e)))
  }
}
