import {
  FETCH_LAUNDRY_DASHBOARD,
  FETCH_HISTORIC_ITEM_LAUNDRY,
} from './LaundryDashboardActions'

const INITIAL_STATE = {
  items: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  filters: {},
  laundries: [],
  editingLaundry: null,
  itemHistoric: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LAUNDRY_DASHBOARD.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        totalSize: action.payload.data.meta.total,
        page: action.payload.data.meta.current_page,
        sizePerPage: action.payload.data.meta.per_page,
        laundries: action.payload.data.laundries,
        filters: action.payload.filters,
        statuses: action.payload.data.statuses,
      }
    case FETCH_HISTORIC_ITEM_LAUNDRY.SUCCESS:
      return {
        ...state,
        itemHistoric: action.payload.data,
      }
    case 'LAUNDRY_DASHBOARD_CLEAR_FILTERS':
      return { ...state, filters: {} }
    default:
      return state
  }
}

export const getLaundryListData = state => {
  return {
    items: state.laundryDashboard.items,
    page: state.laundryDashboard.page,
    sizePerPage: state.laundryDashboard.sizePerPage,
    totalSize: state.laundryDashboard.totalSize,
  }
}
