import React from 'react'

import { remove } from '../LaundryActions'
import GlobalList from '../../components/GlobalList'

function createData(id, name, contactName, email) {
  return {
    id,
    name,
    contactName,
    email,
    update: 'update-laundry',
    delete: 'delete-laundry',
    readOnly: 'read-laundry',
    link_read: `/laundry/${id}/read-only`,
    link_update: `/laundry/${id}/edit`,
  }
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'contactName',
    numeric: true,
    disablePadding: false,
    label: 'Responsável',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'E-mail',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Ações',
  },
]

function List(props) {
  const { items } = props
  const rows = []

  items.map(item => {
    return rows.push(
      createData(item.id, item.name, item.contact_name, item.email)
    )
  })

  return (
    <GlobalList {...props} headCells={headCells} rows={rows} remove={remove} />
  )
}

export default List
