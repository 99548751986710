import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import consts from '../../util/consts'
import createAsyncConst from '../../util/createAsyncConst'

export const SUBMIT_CLIENT = createAsyncConst('SUBMIT_CLIENT')
export const FETCH_CLIENT = createAsyncConst('FETCH_CLIENT')
export const EDIT_CLIENT = createAsyncConst('EDIT_CLIENT')
export const FETCH_CEP = createAsyncConst('FETCH_CEP')

export function fetchClients(page, filters = [], rows) {
  return dispatch => {
    dispatch(action(FETCH_CLIENT.ACTION))
    return axios
      .get(`${config.API_URL}/clients`, {
        params: {
          filters: JSON.stringify(filters),
          rows: rows || consts.DEFAULT_PAGINATION,
          page: page || 1,
        },
      })
      .then(resp => {
        dispatch(action(FETCH_CLIENT.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_CLIENT, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_CLIENT.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/clients/${id}`)
      .then(resp => {
        return resp.data.data
      })
      .then(data => dispatch(action(EDIT_CLIENT.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(EDIT_CLIENT, e)))
  }
}

function submit(values, method) {
  const id = values.id ? `/${values.id}` : ''

  return dispatch => {
    dispatch(action(SUBMIT_CLIENT.ACTION))
    return axios[method](`${config.API_URL}/clients${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_CLIENT.SUCCESS, data))
        dispatch(fetchClients())
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_CLIENT, e)))
  }
}

export function post(values) {
  return submit(values, 'post')
}

export function update(values) {
  return submit(values, 'put')
}

export function findCep(cep) {
  return dispatch => {
    dispatch(action(FETCH_CEP.ACTION))
    return axios
      .get(`${config.API_URL}/buscaCep/${cep}`)
      .then(resp => {
        dispatch(action(FETCH_CEP.SUCCESS, resp.data))
        return resp.data
      })
      .catch(e => dispatch(actionFailed(FETCH_CEP, e)))
  }
}
