import {
  Box,
  Card,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
// import CardLead from './CardLead'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLeads } from '../PanelActions'
import { useConfirm } from 'material-ui-confirm'
import { renderRoute, renderRouteRole } from '../../../../util/utils'
// import LeadForm from './lead'
import { Route } from 'react-router-dom/cjs/react-router-dom'
import styles from '../../../../resources/theme/global'
import Filter from './Filter'
import CardLead from './CardLead'
import colors from '../../../../assets/colors'
import LeadForm from './lead'

const useStyles = makeStyles({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  cardBox: {
    height: '100%',
    padding: '20px 8px 8px 0',
    maxHeight: 'calc(100vh - 230px)',
    marginTop: '16px',
    overflowY: 'auto',
    overflowX: 'hidden',
    animation: '$fadeIn .4s ease-in-out',
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '8px',
      backgroundColor: colors.graylight,
    },
    '&::-webkit-scrollbar-thumb': {
      background: colors.secondary,
    },
  },
})

const CrmPanel = props => {
  const leads = useSelector(state => state.crmPanel.leads)
  const role = useSelector(state => state.auth.role)

  const dispatch = useDispatch()
  const leadClasses = useStyles()

  const { classes } = props

  const [value, setValue] = useState(0)

  const [form, setForm] = useState({
    client: '',
    os: '',
    trigger_id: '',
    state: '10',
  })

  useEffect(() => {
    dispatch(fetchLeads(form))
  }, [form])

  useEffect(() => {
    setForm({
      ...form,
      state: value == 0 ? 10 : 20,
    })
  }, [value])

  return (
    <Box
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        maxHeight: 'calc(100vh - 80px)',
      }}
    >
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h4" color="secondary">
            Painel
          </Typography>
        </Grid>
        <Grid item>
          <Filter form={form} setForm={setForm} classes={classes} />
        </Grid>
      </Grid>

      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          flex: 1,
        }}
      >
        <Paper square>
          <Tabs value={value} onChange={(e, newValue) => setValue(newValue)}>
            <Tab label="Entrada" tabIndex={10} color="secondary" />
            <Tab label="Cadenciamento" tabIndex={20} />
          </Tabs>
        </Paper>

        {value == 0 && (
          <Grid container spacing={4} className={leadClasses.cardBox}>
            {leads?.map((lead, index) => (
              <CardLead
                key={`crm-lead-${index}`}
                state={form.state}
                triggerType={lead?.trigger_type}
                clientName={lead?.client_name}
                schedulingDate={lead?.scheduling_date}
                company={lead?.company}
                reason={lead?.reason}
                leadId={lead?.id}
              />
            ))}
          </Grid>
        )}

        {value == 1 && (
          <Grid container spacing={4} className={leadClasses.cardBox}>
            {leads?.map((lead, index) => (
              <CardLead
                key={`crm-lead-${index}`}
                state={form.state}
                triggerType={lead?.trigger_type}
                clientName={lead?.client_name}
                schedulingDate={lead?.scheduling_date}
                company={lead?.company}
                reason={lead?.reason}
                leadId={lead?.id}
              />
            ))}
          </Grid>
        )}
      </Box>

      {renderRouteRole(
        [
          'administrador',
          'lavanderia',
          'atendente',
          'AtendenteLavanderia',
          'atendente_crm',
        ],
        role
      ) && <Route path="/crm/panel/lead/:id" component={LeadForm} />}
    </Box>
  )
}

export default withStyles(styles)(CrmPanel)
