import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import { useDispatch, useSelector } from 'react-redux'
import { format, parseISO } from 'date-fns'
import { useParams } from 'react-router'
import { useConfirm } from 'material-ui-confirm'

import { getDetacheds, removeDetacheds } from '../LaundryActions'

function Appointment({ isReadnOnly }) {
  const appointments = useSelector(state => state.laundry.appointments)
  const dispatch = useDispatch()
  const { id } = useParams()
  const confirm = useConfirm()

  const remove = serviceId => {
    confirm({
      description: `Deseja realmente excluir o compromisso?`,
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      dispatch(removeDetacheds(serviceId)).then(() =>
        dispatch(getDetacheds(id))
      )
    })
  }

  return (
    <div>
      {appointments.length > 0 && (
        <List dense>
          {appointments.map(item => (
            <ListItem style={{ marginTop: '5px' }}>
              <ListItemText
                primary={`Atendente: ${item.attendant} - Data: ${format(
                  parseISO(item.date),
                  'dd-MM-yyyy'
                )}`}
              />
              <ListItemSecondaryAction>
                {!isReadnOnly && !item.isDeleted && (
                  <IconButton edge="end" aria-label="delete">
                    <DeleteIcon onClick={() => remove(item.id)} />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </div>
  )
}

export default Appointment
