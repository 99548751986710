import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'

import Card from './Card'
import CardService from './CardServices'

function Cards(props) {
  const { classes } = props
  const items = useSelector(state => state.dashboard.items)
  const serviceItems = useSelector(state => state.dashboard.serviceItems)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={6} sm={12} xl={6}>
        <Typography variant="h6" color="secondary">
          Status de Items
        </Typography>
        {items.map((item, key) => (
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
            style={{ marginTop: '10px' }}
            key={String(key)}
          >
            <Card classes={classes} item={item} />
          </Grid>
        ))}
      </Grid>
      <Grid item xs>
        <Typography variant="h6" color="secondary">
          Status de OS
        </Typography>
        {serviceItems.map((item, key) => (
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
            style={{ marginTop: '10px' }}
            key={String(key)}
          >
            <CardService
              {...props}
              classes={classes}
              item={item}
              notShowToday
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default Cards
