import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBackIos'
import classNames from 'classnames'
import NoteAddIcon from '@material-ui/icons/NoteAdd'

import styles from '../../../resources/theme/global'
import { dateFormatedFullDate, exportFormatedMoney } from '../../../util/utils'
import { details, late, makeReport } from '../HomeActions'

import Filter from './DetailsFilter'

function Details(props) {
  const dispatch = useDispatch()
  const filters = useSelector(state => state.dashboard.filterDetails)
  const { match, classes, location } = props

  const isLate = useMemo(() => location.pathname.includes('late'))

  useEffect(() => {
    if (location.pathname.includes('late')) {
      dispatch(late())
    } else {
      dispatch(details(match.params.id))
    }
  }, [])

  const items = useSelector(state => state.dashboard.details)

  const renderOsNumber = item => {
    if (item.service?.laundry) {
      return <TableCell>{item.service?.id}</TableCell>
    }

    return (
      <TableCell component={Link} to={`/attendance/${item.service?.id}/edit`}>
        {item.service?.id}
      </TableCell>
    )
  }

  return (
    <>
      <Grid
        container
        className={classes.pageHead}
        spacing={1}
        alignItems="center"
      >
        <Grid item xs>
          <Typography variant="h4" color="secondary">
            Items {isLate ? 'Atrasados' : items[0]?.status?.name}
          </Typography>
        </Grid>
        <Grid item>
          <Filter classes={classes} />
        </Grid>
        <Grid item>
          <Button
            color="primary"
            type="button"
            onClick={() => dispatch(makeReport(match.params.id, filters))}
          >
            <NoteAddIcon className={classes.btnIcon} /> Relatório
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" component={Link} to="/">
            <ArrowBack className={classes.btnIcon} /> Voltar
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs>
          <Paper elevation={1}>
            <TableContainer className={classes.tableContainer}>
              <Table size="medium" className={classes.tableStyle}>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Etiqueta</TableCell>
                    <TableCell>Numero da OS</TableCell>
                    <TableCell>Atendente</TableCell>
                    <TableCell>Tipo de serviço</TableCell>
                    <TableCell>Cliente</TableCell>
                    <TableCell>Lavanderia</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Data da Coleta</TableCell>
                    <TableCell>Status da Os</TableCell>
                    <TableCell>Valor do item</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map(item => (
                    <TableRow
                      key={item.id}
                      className={classNames({
                        [classes.rowLate]: isLate || item.is_late,
                      })}
                    >
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.bar_code || '-'}</TableCell>
                      {renderOsNumber(item)}
                      <TableCell>{item.service?.user?.name || '-'}</TableCell>
                      <TableCell>{item.service_type?.name}</TableCell>
                      <TableCell>
                        {item.service?.client?.name
                          ? item.service?.client?.name
                          : '-'}
                      </TableCell>
                      <TableCell>
                        {item.service?.laundry?.name
                          ? item.service?.laundry?.name
                          : '-'}
                      </TableCell>
                      <TableCell>{item.status?.name}</TableCell>
                      <TableCell>
                        {dateFormatedFullDate(item.data_coleta) || '-'}
                      </TableCell>
                      <TableCell>{item.service?.status?.name}</TableCell>
                      <TableCell>
                        {exportFormatedMoney(item.final_value)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles)(Details)
