import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from '../../../resources/theme/users'
import { post } from '../RolesAction'
import Form from './Form'

function Transition(props) {
  return <Slide direction="up" {...props} />
}

function NewRole(props) {
  const { classes } = props
  const dispatch = useDispatch()
  const permissions = useSelector(state => state.role.permissions)

  const onSubmit = values => {
    dispatch(post(values)).then(data => {
      if (data && data.code === 200) {
        props.history.push('/roles')
      }
    })
  }

  const onClose = () => {
    props.history.push('/roles')
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullScreen
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Nova Regra
            </Typography>
          <IconButton
            color="inherit"
            onClick={onClose}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Form onClose={onClose} permissions={permissions} onSubmit={onSubmit} />
    </Dialog>
  )
}

export default (withStyles(styles)(NewRole))
