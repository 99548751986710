import { Grid, IconButton, TextField } from '@material-ui/core'
import React from 'react'
import InputMask from 'react-input-mask'
import { useDispatch } from 'react-redux'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

import { findCep } from '../../clients/ClientActions'

function DeliveryAddress({
  name,
  address,
  updateField,
  updateDeliveryCep,
  handleClick,
}) {
  const dispatch = useDispatch()

  const buscaCEP = () => {
    const cep = address.cep?.replace(/[^\d]/g, '')
    cep &&
      dispatch(findCep(cep)).then(resp => {
        if (resp && resp.code === 200) {
          const { items } = resp.data
          if (items) {
            updateDeliveryCep(name, items)
          }
        }
      })
  }

  return (
    <>
      <Grid container spacing={1}>
        <IconButton
          aria-label="calendar"
          color="primary"
          onClick={() => handleClick(name)}
        >
          {address ? <RemoveCircleIcon /> : <AddCircleIcon />}
        </IconButton>
      </Grid>
      {address && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={4}>
            <InputMask
              mask="99.999-999"
              maskChar=""
              value={address?.cep}
              onChange={e => updateField(name, 'cep', e.target.value)}
              onBlur={() => buscaCEP()}
            >
              {inputProps => (
                <TextField {...inputProps} label="CEP" fullWidth />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              onChange={e => updateField(name, 'street', e.target.value)}
              label="Endereço"
              fullWidth
              value={address?.street}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              onChange={e => updateField(name, 'number', e.target.value)}
              label="Número"
              fullWidth
              value={address?.number}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              onChange={e => updateField(name, 'neighborhood', e.target.value)}
              label="Bairro"
              fullWidth
              value={address?.neighborhood}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              onChange={e => updateField(name, 'complement', e.target.value)}
              label="Complemento"
              fullWidth
              value={address?.complement}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              onChange={e => updateField(name, 'city', e.target.value)}
              label="Cidade"
              fullWidth
              value={address?.city}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              onChange={e => updateField(name, 'uf', e.target.value)}
              label="UF"
              fullWidth
              value={address?.uf}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default React.memo(DeliveryAddress)
