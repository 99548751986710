import {
  FETCH_DASHBOARD,
  GET_DETAILS,
  GET_DETAILS_LATE,
  GET_DETAILS_READY,
  GET_DETAILS_LAUNDRY_READY,
} from './HomeActions'

const INITIAL_STATE = {
  items: [],
  details: [],
  filters: { date: new Date() },
  filterDetails: {},
  serviceItems: [],
  refreshing: false,
  readyCount: 0,
  readyLaundryCount: 0,
  readyItems: [],
  readyItemsLaundry: [],
  statuses: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD.SUCCESS:
      return {
        ...state,
        items: action.payload.data,
        serviceItems: action.payload.services,
        refreshing: false,
        readyCount: action.payload.readyItems,
        readyLaundryCount: action.payload.readyItemsLaundry,
      }
    case FETCH_DASHBOARD.ACTION:
      return {
        ...state,
        refreshing: true,
      }
    case FETCH_DASHBOARD.FAILURE:
      return {
        ...state,
        refreshing: false,
      }
    case GET_DETAILS.SUCCESS:
      return {
        ...state,
        details: action.payload.data.data,
        statuses: action.payload.data.statuses,
        filterDetails: action.payload.filters,
      }
    case GET_DETAILS_LATE.SUCCESS:
      return {
        ...state,
        details: action.payload.data.data,
        statuses: action.payload.data.statuses,
        filterDetails: action.payload.filters,
      }
    case GET_DETAILS_READY.SUCCESS:
      return {
        ...state,
        readyItems: action.payload,
      }
    case GET_DETAILS_LAUNDRY_READY.SUCCESS:
      return {
        ...state,
        readyItemsLaundry: action.payload,
      }
    case 'SAVE_DASHBOARD_FILTERS':
      return {
        ...state,
        filters: action.payload,
      }
    case 'ERASE_DASHBOARD_FILTERS':
      return {
        ...state,
        filters: { date: new Date() },
      }
    default:
      return state
  }
}
