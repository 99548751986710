export default theme => ({
  '@keyframes circle': {
    from: {
      transform: 'rotate(360deg)',
    },
    to: {
      transform: 'rotate(0deg)',
    },
  },
  pageHead: {
    marginBottom: 8,
  },
  root: {
    width: '100%',
    marginBottom: 20,
  },
  grow: {
    flex: 1,
  },
  gridContainer: {
    width: '100%',
  },
  btnIcon: {
    marginRight: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    // minHeight: '70vh',
  },
  btnMore: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  // paper: {
  //   padding: theme.spacing(3),
  // },
  formControl: {
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    width: '100%',
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  item: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    padding: '10px',
    height: '100%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
  popover: {
    width: 600,
  },
  box: {
    maxWidth: 700,
    maxHeight: '100vh',
    minHeight: '50px',
    overflow: 'auto',
    margin: '15px auto',
    padding: '10px',
    borderRadius: 4,
    backgroundColor: theme.palette.primary.light,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  filterIcon: {
    marginRight: '20px',
  },
  window: {
    height: '100vh',
  },
  margin: {
    marginTop: theme.spacing(3),
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  gridAreas: {
    marginBottom: theme.spacing(2),
  },
  select: {
    marginTop: theme.spacing(2),
  },
  blue: {
    backgroundColor: 'blue',
  },
  textAreaMargin: {
    marginTop: '25px',
  },
  centerButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  inputWidth: {
    width: '100%',
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tableStyle: {
    backgroundColor: theme.gray2Color,
  },
  skeleton: {
    width: '100%',
    backgroundColor: theme.palette.secondary.light,
    height: '100%',
  },
  btnRefreshing: {
    animationName: '$circle',
    animationDuration: '1.3s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  amanha: { color: '#3f51b5', borderColor: '#3f51b5', width: '100%' },
  hoje: { color: '#4caf50', borderColor: '#4caf50', width: '100%' },
  finalizado: { color: '#4caf50', borderColor: '#4caf50', width: '100%' },
  cancelado: { color: '#f3c623', borderColor: '#f3c623', width: '100%' },
  other: { color: '#f44336', borderColor: '#f44336', width: '100%' },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  btnRefresh: {},
  tableRowMov: {
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.008)',
      transitionDuration: '0.2s',
    },
  },
  rowCanCatch: {
    backgroundColor: '#e8eaf6',
    borderLeft: '10px solid',
    borderLeftColor: '#00a8ff',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  dropzone: {
    backgroundColor: 'rgba(204, 204, 204, .5)',
    border: 'none',
    minHeight: '140px',
  },
  listTimeModal: {
    fontWeight: 'bold',
    fontSize: '1.1em',
  },
  rowLate: {
    backgroundColor: '#ffebee',
    borderLeft: '10px solid',
    borderLeftColor: '#e84118',
  },
  buttonDanger: {
    color: theme.palette.danger.main,
    borderColor: theme.palette.danger.light,
    marginRight: theme.spacing(3),
  },
  listContainer: {
    padding: '15px',
    background: '#eee',
  },
  listStyles: {
    listStyle: 'none',
    padding: 0,
  },
  listContainerTitle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
  },
  listContainerTotal: {
    fontSize: '1.1em',
  },
  appointments: {
    backgroundColor: '#eee',
    maxHeight: '200px',
    overflow: 'auto',
  },
  totalTableRoot: {
    overflow: 'hidden',
  },
  '@keyframes enter': {
    from: { opacity: 0, transform: 'translateX(-35%)' },
    to: { opacity: 1, transform: 'translateX(0%)' },
  },
  '@keyframes enterRight': {
    from: { opacity: 0, transform: 'translateX(35%)' },
    to: { opacity: 1, transform: 'translateX(0%)' },
  },
  '@keyframes enterY': {
    from: { opacity: 0, transform: 'translateY(-50%)' },
    to: { opacity: 1, transform: 'translateY(0%)' },
  },
  '@keyframes removeItem': {
    from: { opacity: 1, transform: 'translateY(0%)' },
    to: { opacity: 0, transform: 'translateY(50%)' },
  },
  financialCard: {
    animationTimingFunction: 'ease-in',
    '& > :nth-child(1)': {
      animation: '$enter',
      animationDuration: '500ms',
    },
    '& > :nth-child(2)': {
      animation: '$enter',
      animationDuration: '500ms',
      animationDelay: '200ms',
      animationFillMode: 'backwards',
    },
    '& > :nth-child(3)': {
      animation: '$enter',
      animationDuration: '500ms',
      animationDelay: '400ms',
      animationFillMode: 'backwards',
    },
  },
  laundryOsCard: {
    animationTimingFunction: 'ease-in',
    '& > :nth-child(1)': {
      animation: '$enterY',
      animationDuration: '500ms',
    },
    '& > :nth-child(2)': {
      animation: '$enterY',
      animationDuration: '500ms',
      animationDelay: '200ms',
      animationFillMode: 'backwards',
    },
    '& > :nth-child(3)': {
      animation: '$enterY',
      animationDuration: '500ms',
      animationDelay: '400ms',
      animationFillMode: 'backwards',
    },
  },
  calendarBody: {
    height: `calc(100vh - 120px)`,
  },
  chipdsDefaultPannel: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  chipdsDefaultPannelExpanded: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  ehadingChips: {
    fontSize: theme.typography.pxToRem(15),
  },
  chevronIcon: {
    position: 'absolute',
    marginLeft: '12px',
    animation: '$arrow',
    animationDuration: '650ms',
    animationTimingFunction: 'ease',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
  },
  '@keyframes arrow': {
    to: {
      transform: 'translateX(-8px)',
    },
  },
  laundryTechniciansContainer: {
    margin: '20px 0px',
    // border: '1px solid #ccc',
    padding: '15px',
    // borderRadius: '10px',
  },
  attendanceRowLeft: {
    animation: '$enter',
    animationDuration: '500ms',
  },
  attendanceRowRight: {
    animation: '$enterRight',
    animationDuration: '500ms',
  },
  removeAttendanceLine: {
    animation: '$removeItem',
    animationDuration: '500ms',
  },
  hoverRestoration: {
    backgroundColor: theme.palette.primary.light,
    transition: 'background-color 0.3s linear',
  },
  tabsheader: {
    marginTop: theme.spacing(1),
  },
  accordionChips: {
    marginBottom: '0px',
    paddingBottom: '0px',
  },
  boxIcons: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '10px',
    marginTop: '3px',
    marginBottom: '3px',
  },
  calendarEventsContainer: {
    height: '95vh',
    overflow: 'auto',
  },
  tableStickHeader: {
    maxHeight: 'calc(100vh - 200px)',
  },
})
