export default {
  title: 'Sistema Grupo Clean',
  storagePrefix: '_tapetex',

  API_URL: process.env.RAZZLE_API_URL,
  API_CLIENT: process.env.RAZZLE_API_CLIENT,
  API_SECRET: process.env.RAZZLE_API_SECRET,
  API_GRANT: 'password',

  PUSHER_KEY: process.env.RAZZLE_PUSHER_KEY,
  CLUSTER: process.env.RAZZLE_CLUSTER,

  GOOGLE_KEY: process.env.RAZZLE_GOOGLE_KEY,
}
