/* eslint-disable react/prop-types */
import React from 'react'
import { TextValidator } from 'react-material-ui-form-validator'

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.value === nextProps.value
}

function CustomTextfield(props) {
  const {
    style,
    onChange,
    label,
    fullWidth,
    autoComplete,
    value,
    disabled,
    required,
    validators,
    errorMessages,
    type,
    ...rest
  } = props

  return (
    <TextValidator
      {...rest}
      validators={validators}
      errorMessages={errorMessages}
      onChange={onChange}
      required={required}
      label={label}
      fullWidth={fullWidth}
      autoComplete={autoComplete}
      value={value}
      disabled={disabled}
      style={style}
      type={type}
    />
  )
}

export default React.memo(CustomTextfield, comparisonFn)
