import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const FETCH_LAUNDRY_MAP = createAsyncConst('FETCH_LAUNDRY_MAP')

export function fetchLaundyMap(filters = {}) {
  return dispatch => {
    dispatch(action(FETCH_LAUNDRY_MAP.ACTION, {}))
    return axios
      .get(`${config.API_URL}/laundry/map-info`, {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => {
        dispatch(action(FETCH_LAUNDRY_MAP.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_LAUNDRY_MAP, e)))
  }
}
