import { EDIT_CONFIG, FETCH_CONFIG } from './ConfigActions'

const INITIAL_STATE = {
  items: [],
  filters: [],
  editingConfig: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CONFIG.SUCCESS:
      return {
        ...state,
        items: action.payload.data,
      }
    case EDIT_CONFIG.SUCCESS:
      return {
        ...state,
        editingConfig: action.payload,
      }
    default:
      return state
  }
}

export const getConfigListData = state => {
  return {
    items: state.config.items,
  }
}
