import axios from 'axios/index'

import createAsyncConst from '../../../util/createAsyncConst'
import action, { actionFailed } from '../../../util/action'
import config from '../../../config'

// export const REMOVE_BANK = createAsyncConst('REMOVE_BANK')
export const SUBMIT_TRIGGER = createAsyncConst('SUBMIT_TRIGGER')
export const EDIT_TRIGGER = createAsyncConst('EDIT_TRIGGER')
export const FETCH_TRIGGER = createAsyncConst('FETCH_TRIGGER')

export function fetchTriggers(filters = {}) {
  return dispatch => {
    dispatch(action(FETCH_TRIGGER.ACTION))
    return axios
      .get(`${config.API_URL}/triggers`, {
        params: { ...filters },
      })
      .then(resp => {
        dispatch(action(FETCH_TRIGGER.SUCCESS, { data: resp.data, filters }))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_TRIGGER, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_TRIGGER.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/triggers/${id}`)
      .then(resp => {
        return resp.data.data
      })
      .then(data => dispatch(action(EDIT_TRIGGER.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(EDIT_TRIGGER, e)))
  }
}

export function update(values, id) {
  return dispatch => {
    dispatch(action(SUBMIT_TRIGGER.ACTION))
    return axios
      .put(`${config.API_URL}/triggers/${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_TRIGGER.SUCCESS, data))
        dispatch(fetchTriggers())
        return true
      })
      .catch(e => dispatch(actionFailed(SUBMIT_TRIGGER, e)))
  }
}

export function create(values) {
  return dispatch => {
    dispatch(action(SUBMIT_TRIGGER.ACTION))
    return axios
      .post(`${config.API_URL}/triggers`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_TRIGGER.SUCCESS, data))
        dispatch(fetchTriggers())
        return true
      })
      .catch(e => dispatch(actionFailed(SUBMIT_TRIGGER, e)))
  }
}
