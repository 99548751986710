import { FETCH_BILLING_TYPE, EDIT_BILLING_TYPE } from './BillingTypeActions'

const INITIAL_STATE = {
  items: [],
  filters: [],
  editingBillingType: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_BILLING_TYPE.SUCCESS:
      return {
        ...state,
        items: action.payload.data,
      }
    case EDIT_BILLING_TYPE.SUCCESS:
      return {
        ...state,
        editingBillingType: action.payload,
      }
    default:
      return state
  }
}

export const getBillingTypeListData = state => {
  return {
    items: state.billingType.items,
  }
}

export const getEditingBillingType = state => {
  return state.billingType.editingBillingType
}
