import React, { useMemo } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField as MuiTextField,
  DialogTitle,
  Slide,
  Grid,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import InputMask from 'react-input-mask'
import { TextField } from 'formik-material-ui'
import { useParams } from 'react-router'

import { findCep } from '../../clients/ClientActions'
import { edit, toogleIsDelivery } from '../AttendanceLaundryActions'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ClientAddress = props => {
  const { data, open, setOpen, itemId } = props
  const dispatch = useDispatch()
  const { id } = useParams()

  const schema = yup.object().shape({
    cep: yup.string().required('Obrigatório'),
    street: yup.string().required('Obrigatório'),
    number: yup.string().required('Obrigatório'),
    neighborhood: yup.string().required('Obrigatório'),
    city: yup.string().required('Obrigatório'),
    uf: yup.string().required('Obrigatório'),
  })

  const initialValues = data || {
    name: '',
    cep: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    uf: '',
  }

  const handleClose = () => setOpen(false)
  const disabled = useMemo(() => data, [data])

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="modal-client-address-value-title"
        aria-describedby="modal-client-address-value-description"
        style={{ zIndex: '1301' }}
      >
        <DialogTitle id="modal-client-address-value-title">
          Endereço do cliente
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={schema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true)
              const resp = await dispatch(toogleIsDelivery(itemId, values))

              if (resp.status === 200) {
                await dispatch(edit(id))
              }
              handleClose()
              resetForm({})
            }}
          >
            {({
              submitForm,
              isSubmitting,
              touched,
              errors,
              handleChange,
              setFieldValue,
            }) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs>
                    <Field
                      component={TextField}
                      name="name"
                      label="Nome do Cliente"
                      fullWidth
                      error={touched.name && !!errors.name}
                      helperText={touched.name && errors.name}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs>
                    <Field name="cep">
                      {({ field }) => (
                        <InputMask
                          {...field}
                          mask="99.999-999"
                          formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                          maskChar=""
                          onChange={handleChange}
                          disabled={disabled}
                          onBlur={e => {
                            const cep = e.currentTarget.value.replace(
                              /[^\d]/g,
                              ''
                            )
                            cep &&
                              !disabled &&
                              dispatch(findCep(cep)).then(resp => {
                                if (resp && resp.code === 200) {
                                  const { items } = resp.data
                                  if (items) {
                                    setFieldValue('street', items.logradouro)
                                    setFieldValue('neighborhood', items.bairro)
                                    setFieldValue('city', items.cidade)
                                    setFieldValue('uf', items.uf)
                                  }
                                }
                              })
                          }}
                        >
                          {inputProps => (
                            <MuiTextField
                              {...inputProps}
                              label="CEP"
                              fullWidth
                              error={touched.cep && !!errors.cep}
                              helperText={touched.cep && errors.cep}
                            />
                          )}
                        </InputMask>
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs>
                    <Field
                      component={TextField}
                      name="street"
                      label="RUA"
                      fullWidth
                      error={touched.street && !!errors.street}
                      helperText={touched.street && errors.street}
                      disabled={disabled}
                    />
                  </Grid>

                  <Grid item xs>
                    <Field
                      component={TextField}
                      name="number"
                      label="Numero"
                      fullWidth
                      error={touched.number && !!errors.number}
                      helperText={touched.number && errors.number}
                      disabled={disabled}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs>
                    <Field
                      component={TextField}
                      name="neighborhood"
                      label="Bairro"
                      fullWidth
                      error={touched.neighborhood && !!errors.neighborhood}
                      helperText={touched.neighborhood && errors.neighborhood}
                      disabled={disabled}
                    />
                  </Grid>

                  <Grid item xs>
                    <Field
                      component={TextField}
                      name="city"
                      label="Cidade"
                      fullWidth
                      error={touched.city && !!errors.city}
                      helperText={touched.city && errors.city}
                      disabled={disabled}
                    />
                  </Grid>

                  <Grid item xs>
                    <Field
                      component={TextField}
                      name="uf"
                      label="UF"
                      fullWidth
                      error={touched.uf && !!errors.uf}
                      helperText={touched.uf && errors.uf}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs>
                    <Field
                      component={TextField}
                      name="complement"
                      label="Complemento"
                      fullWidth
                      disabled={disabled}
                    />
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button onClick={() => handleClose()} color="primary">
                    Fechar
                  </Button>

                  <Button
                    disabled={isSubmitting}
                    onClick={submitForm}
                    color="primary"
                  >
                    {data ? 'Apagar' : 'Cadastrar'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default React.memo(ClientAddress)
