/* eslint-disable react/prop-types */
import { MenuItem } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useState } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
import ReactInputMask from 'react-input-mask'
// import { fetchServiceCategory } from '../ServiceCategoryActions'

const Filter = ({ classes, form, setForm = () => {} }) => {
  const [currentForm, setCurrentForm] = useState({
    cnpj: '',
  })

  const dispatch = useDispatch()

  const onSubmit = e => {
    e.preventDefault()

    const formData = e.currentTarget
    const data = new FormData(formData)
    const entries = data.entries()
    const values = Object.fromEntries(entries)

    setForm({
      ...form,
      ...currentForm,
      ...values,
    })
  }

  return (
    <Tooltip title="Filter list">
      <PopupState variant="popover" popupId="demo-popup-popover">
        {popupState => (
          <div>
            <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
              <FilterListIcon />
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2} className={classes.popover}>
                <div className={classes.root}>
                  <form className={classes.form} onSubmit={onSubmit}>
                    <Grid
                      container
                      className={classes.gridContainer}
                      spacing={1}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        <ReactInputMask
                          mask="99.999.999/9999-99"
                          maskChar={null}
                          name="cnpj"
                          value={currentForm?.cnpj}
                          onChange={e =>
                            setCurrentForm({
                              ...currentForm,
                              cnpj: e.target.value,
                            })
                          }
                        >
                          {inputProps => (
                            <TextField
                              {...inputProps}
                              label="CNPJ"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        </ReactInputMask>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label="Nome"
                          margin="dense"
                          className={classes.inputFilter}
                          variant="outlined"
                          fullWidth
                          name="company"
                          defaultValue={form?.company}
                          key={`input-name-${form?.company}`}
                        />
                      </Grid>

                      <Grid item xs>
                        <Button
                          type="submit"
                          color="primary"
                          fullWidth
                          variant="contained"
                          className={classes.submit}
                          onClick={popupState.close}
                        >
                          <SearchIcon />
                          {` Filtrar`}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </Tooltip>
  )
}

export default Filter
