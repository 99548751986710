/* eslint-disable no-else-return */
import React, { useCallback, useEffect } from 'react'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TextField,
  Divider,
  Tooltip,
  Checkbox,
} from '@material-ui/core'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import HistoryIcon from '@material-ui/icons/History'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import LaunchIcon from '@material-ui/icons/Launch'
import { useConfirm } from 'material-ui-confirm'
import InputMask from 'react-input-mask'
import { TextValidator } from 'react-material-ui-form-validator'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import RIESelect from '../../components/RIESelect'
import RIESelect2 from '../../components/RIESelect2'
import { exportFormatedMoney, renderRouteRole } from '../../../util/utils'
import NumberFormatCustom from '../../components/NumberFormat'

import TimeModalInfo from './TimeModalInfo'
import ItemHistory from './ItemHistory'

function TableServices(props) {
  const [open, setOpen] = React.useState(false)
  const [info, setInfo] = React.useState('')
  const [removing, setRemoving] = React.useState(null)
  const [hoverId, setHoverId] = React.useState(null)

  const [itemHistoryOpen, setItemHistoryOpen] = React.useState(false)
  const [item, setItem] = React.useState('')

  const role = useSelector(state => state.auth.role)
  const itemStatuses = useSelector(state => state.attendance.itemStatuses)
  const suppliers = useSelector(state => state.attendance.suppliers)

  const {
    classes,
    items,
    removeChildren,
    updateChildren,
    discountDisabled,
    openEdit,
    updateFinalTime,
    formId,
    updateFieldSelect,
    updateFieldSelectSuppliers,
  } = props
  const confirm = useConfirm()

  const confirmRemove = id => {
    confirm({
      description: 'Deseja remover o serviço da lista?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      setRemoving(id)
      setTimeout(() => {
        removeChildren(id)
        setRemoving(null)
      }, 400)
    })
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const openTimeInfoModal = id => {
    setInfo(id)
    handleClickOpen()
  }

  const handleItemHistoryOpen = () => {
    setItemHistoryOpen(true)
  }

  const handleItemHistoryClose = useCallback(() => {
    setItemHistoryOpen(false)
  }, [])

  const openHistoryItem = id => {
    setItem(id)
    handleItemHistoryOpen()
  }

  const renderTooltip = index => {
    const { options } = items[index]
    if (options.type === 1) {
      return `Base: ${options.base} - Altura: ${options.altura}`
    } else if (options.type === 2) {
      return `Diâmetro: ${options.diametro}`
    }

    return ''
  }

  function canEditSupplier(statusid) {
    if (
      renderRouteRole(['administrador'], role) ||
      (renderRouteRole(['atendente'], role) && statusid != 11)
    ) {
      return true
    }
    return false
  }

  return (
    <>
      <TimeModalInfo
        handleClose={handleClose}
        open={open}
        items={items}
        info={info}
        classes={classes}
        setHoverId={setHoverId}
        updateChildren={updateChildren}
      />

      <ItemHistory
        item={item}
        handleClose={handleItemHistoryClose}
        open={itemHistoryOpen}
      />

      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader size="small" style={{ overflow: 'hidden' }}>
          <TableHead>
            <TableRow>
              <TableCell>Descrição</TableCell>
              <TableCell style={{ minWidth: '30px' }}>Qtde</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Hr</TableCell>
              <TableCell>Tot. Hr</TableCell>
              <TableCell>Valor</TableCell>
              {renderRouteRole(['administrador'], role) && (
                <TableCell>Valor do Tec.</TableCell>
              )}
              <TableCell>Desc.%</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Status Atual</TableCell>
              <TableCell>Comissionado</TableCell>
              <TableCell>Retrabalho?</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((item, key) => {
              return (
                <TableRow
                  key={item.dateTime || item.id}
                  className={classNames({
                    [classes.attendanceRowLeft]: key % 2 === 0,
                    [classes.attendanceRowRight]: key % 2 === 1,
                    [classes.removeAttendanceLine]: removing === key,
                    [classes.hoverRestoration]:
                      hoverId && hoverId === item.bar_code,
                  })}
                >
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    {item.measureType?.id === 1 ? (
                      <TextField
                        onChange={event =>
                          updateChildren(key, event.target.value, 'quantity')
                        }
                        fullWidth
                        type="number"
                        value={item.quantity}
                        inputProps={{
                          min: '0',
                          style: { textAlign: 'center' },
                        }}
                      />
                    ) : (
                      <Tooltip title={renderTooltip(key)} aria-label="tooltip">
                        <TextField
                          fullWidth
                          disabled
                          value={item.quantity}
                          inputProps={{
                            min: '0',
                            style: { textAlign: 'center' },
                          }}
                        />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>{item.measureType?.name}</TableCell>
                  <TableCell>{item.execution_time}</TableCell>
                  <TableCell>
                    {item.measureType?.id === 2 ? (
                      item.execution_time
                    ) : (
                      <>
                        <InputMask
                          style={{ width: '60px' }}
                          size
                          mask="99:99:99"
                          formatChars={{ 9: '[0-9]', '?': '[0-9/.]' }}
                          maskChar=""
                          value={item.final_time}
                          placeholder="00:30:00"
                          onChange={event =>
                            updateFinalTime(key, event.target.value)
                          }
                        >
                          {inputProps => (
                            <TextValidator
                              {...inputProps}
                              fullWidth
                              validators={[
                                'matchRegexp:^(?:[01]\\d|2[0-3]):(?:[0-5]\\d):(?:[0-5]\\d)$',
                              ]}
                              errorMessages={['Formato Inválido']}
                            />
                          )}
                        </InputMask>
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    {item?.serviceType?.edit_final_value ||
                    item?.edit_final_value ? (
                      <TextField
                        className={classes.formControl}
                        value={item.default_value}
                        onChange={e =>
                          updateChildren(key, e.target.value, 'default_value')
                        }
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                    ) : (
                      exportFormatedMoney(item.default_value)
                    )}
                  </TableCell>

                  {renderRouteRole(['administrador'], role) && (
                    <TableCell style={{ minWidth: '140px' }}>
                      <TextField
                        value={item.technician_value}
                        onChange={e =>
                          updateChildren(
                            key,
                            e.target.value,
                            'technician_value'
                          )
                        }
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        fullWidth
                      />
                    </TableCell>
                  )}
                  <TableCell>
                    <InputMask
                      mask="?????"
                      disabled={discountDisabled}
                      formatChars={{ 9: '[0-9]', '?': '[0-9/.]' }}
                      maskChar=""
                      value={item.discount}
                      placeholder="0%"
                      onChange={event =>
                        updateChildren(key, event.target.value, 'discount')
                      }
                      inputProps={{
                        style: { textAlign: 'center' },
                      }}
                    >
                      {inputProps => <TextField {...inputProps} fullWidth />}
                    </InputMask>
                  </TableCell>
                  <TableCell style={{ minWidth: '110px' }}>
                    {exportFormatedMoney(item.final_value)}
                  </TableCell>
                  <TableCell style={{ minWidth: '250px' }}>
                    {renderRouteRole(['administrador'], role) ? (
                      <RIESelect
                        options={itemStatuses}
                        setForm={updateFieldSelect}
                        data={item.status || null}
                        chave={key}
                        name="status"
                        margin="dense"
                        variant="outlined"
                      />
                    ) : (
                      item.status?.name || '-'
                    )}
                  </TableCell>

                  <TableCell style={{ minWidth: '200px' }}>
                    {canEditSupplier(item.status?.id) ? (
                      <RIESelect2
                        options={suppliers}
                        setForm={updateFieldSelectSuppliers}
                        placeholder="Cont. Financeiro"
                        data={item.supplier || null}
                        chave={key}
                        name="supplier"
                        margin="dense"
                        variant="outlined"
                      />
                    ) : (
                      item.supplier?.name || '-'
                    )}
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      name="is_rework"
                      checked={Boolean(item.is_rework)}
                      onChange={e =>
                        updateChildren(key, e.target.checked, 'is_rework')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => confirmRemove(key)}>
                      <RemoveCircleOutlineIcon fontSize="small" />
                    </IconButton>

                    {item.measureType?.id === 2 && (
                      <IconButton onClick={() => openEdit(key)}>
                        <LaunchIcon />
                      </IconButton>
                    )}

                    {formId && (
                      <IconButton onClick={() => openTimeInfoModal(item.id)}>
                        <AccessTimeIcon fontSize="small" />
                      </IconButton>
                    )}

                    {formId && (
                      <IconButton onClick={() => openHistoryItem(item.id)}>
                        <HistoryIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider variant="fullWidth" />
    </>
  )
}

export default TableServices
