import React from 'react'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
} from '@material-ui/core'
import classNames from 'classnames'
import CountUp from 'react-countup'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  positive: {
    color: colors.green[900],
    marginRight: theme.spacing(1),
  },
  negative: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
  },
  zero: {
    color: colors.grey[900],
    marginRight: theme.spacing(1),
  },
}))

const BankCard = ({ total, tipo, decimals = 2 }) => {
  const classes = useStyles()

  const contUpMoney = money => {
    return (
      <CountUp
        end={money}
        duration={0.7}
        decimals={decimals}
        decimal=","
        prefix="R$"
        separator="."
      />
    )
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              {tipo}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
              className={classNames({
                [classes.positive]: total > 0,
                [classes.negative]: total < 0,
                [classes.zero]: total === 0,
              })}
            >
              {contUpMoney(total)}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default BankCard
