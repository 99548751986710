import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const FETCH_DASHBOARD = createAsyncConst('FETCH_DASHBOARD')
export const GET_DETAILS = createAsyncConst('GET_DETAILS')
export const GET_DETAILS_LATE = createAsyncConst('GET_DETAILS_LATE')
export const GET_DETAILS_READY = createAsyncConst('GET_DETAILS_READY')
export const GET_DETAILS_LAUNDRY_READY = createAsyncConst(
  'GET_DETAILS_LAUNDRY_READY'
)
export const MAKE_REPORT = createAsyncConst('MAKE_REPORT')

export function fetchDashboard(filters = {}) {
  return dispatch => {
    dispatch(action(FETCH_DASHBOARD.ACTION))
    return axios
      .get(`${config.API_URL}/dashboard`, {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => {
        dispatch(action(FETCH_DASHBOARD.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_DASHBOARD, e)))
  }
}

export function details(id, filters = {}) {
  return dispatch => {
    dispatch(action(GET_DETAILS.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/dashboard/${id}`, {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => {
        return resp.data
      })
      .then(data => dispatch(action(GET_DETAILS.SUCCESS, { data, filters })))
      .catch(e => dispatch(actionFailed(GET_DETAILS, e)))
  }
}

export function late(filters = {}) {
  return dispatch => {
    dispatch(action(GET_DETAILS_LATE.ACTION))
    return axios
      .get(`${config.API_URL}/dashboard/late`, {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => {
        return resp.data
      })
      .then(data =>
        dispatch(action(GET_DETAILS_LATE.SUCCESS, { data, filters }))
      )
      .catch(e => dispatch(actionFailed(GET_DETAILS_LATE, e)))
  }
}

export function ready(filters = {}) {
  return dispatch => {
    dispatch(action(GET_DETAILS_READY.ACTION))
    return axios
      .get(`${config.API_URL}/dashboard/ready`, {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => {
        return resp.data.data
      })
      .then(data => dispatch(action(GET_DETAILS_READY.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(GET_DETAILS_READY, e)))
  }
}

export function readyLaundry(filters = {}) {
  return dispatch => {
    dispatch(action(GET_DETAILS_LAUNDRY_READY.ACTION))
    return axios
      .get(`${config.API_URL}/dashboard/laundry/ready`, {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => {
        return resp.data.data
      })
      .then(data => dispatch(action(GET_DETAILS_LAUNDRY_READY.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(GET_DETAILS_LAUNDRY_READY, e)))
  }
}

export function makeReport(id, filters = {}) {
  return dispatch => {
    dispatch(action(MAKE_REPORT.ACTION))
    return axios({
      url: `${config.API_URL}/dashboard/${id}/report`,
      method: 'GET',
      responseType: 'blob',
      headers: { Accept: 'application/vnd.ms-excel' },
      params: {
        filters: JSON.stringify(filters),
      },
    })
      .then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')

        link.href = url
        link.setAttribute('download', 'relatorio_dashboard.xlsx')
        document.body.appendChild(link)
        link.click()
        return resp
      })
      .then(() => dispatch(action(MAKE_REPORT.SUCCESS, {})))
      .catch(e => dispatch(actionFailed(MAKE_REPORT, e)))
  }
}

export function saveDashboardFilters(filters) {
  return { type: 'SAVE_DASHBOARD_FILTERS', payload: filters }
}

export function eraseDashboardFilters() {
  return { type: 'ERASE_DASHBOARD_FILTERS' }
}
