import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Grid, Slide } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { KeyboardDatePicker } from 'formik-material-ui-pickers'
import { Autocomplete } from 'formik-material-ui-lab'
import MuiTextField from '@material-ui/core/TextField'
import { useDispatch } from 'react-redux'
import { format } from 'date-fns'

import { reorder } from '../CalendarActions'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

function ReorderForm({
  open,
  handleClose,
  attendants,
  reloadBookedEvents,
  changeViewToDate,
  isReordering,
}) {
  const dispatch = useDispatch()

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        aria-labelledby="form-detached-callendar-title"
        TransitionComponent={Transition}
      >
        <DialogTitle id="form-detached-callendar-title">Reordenar</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              attendant: null,
              date: new Date(),
            }}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false)
              const data = {
                ...values,
                date: format(values.date, 'yyyy-MM-dd HH:mm'),
              }

              dispatch(reorder(data)).then(() => {
                handleClose()
                reloadBookedEvents()
                changeViewToDate(data?.date)
              })
            }}
            validate={values => {
              const errors = {}
              if (!values.attendant) {
                errors.attendant = 'Obrigatório'
              }
              if (!values.date) {
                errors.date = 'Obrigatório'
              }
              return errors
            }}
          >
            {({ submitForm, isSubmitting, touched, errors }) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={6}>
                    <Field
                      name="attendant"
                      component={Autocomplete}
                      options={[
                        { id: 0, name: 'Todos os atendentes' },
                        ...attendants,
                      ]}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={option =>
                        typeof option === 'string' ? option : option.name
                      }
                      renderInput={params => (
                        <MuiTextField
                          {...params}
                          error={touched.attendant && !!errors.attendant}
                          helperText={touched.attendant && errors.attendant}
                          label="Atendente"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <Field
                      component={KeyboardDatePicker}
                      name="date"
                      clearable
                      minDateMessage="A data mínima é a atual"
                      clearLabel="Limpar"
                      disablePast
                      label="Data"
                      autoOk
                      fullWidth
                      ampm={false}
                      format="dd/MM/yyyy"
                    />
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    onClick={submitForm}
                    color="primary"
                    disabled={isSubmitting || isReordering}
                  >
                    Salvar
                  </Button>
                  <Button onClick={handleClose}>Cancelar</Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default React.memo(ReorderForm)
