/* eslint-disable import/order */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import '../../resources/less/style.less'

import AppBar from '@material-ui/core/AppBar'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import Menus from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import MenuIcon from '@material-ui/icons/Menu'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { destroyCookie, parseCookies } from 'nookies'

import { logout } from '../auth/AuthActions'
import Icons from './Icons'
import ThemeSwitcher from './ThemeSwitcher'
import { returnFirstLetters } from '../../util/utils'
import { Button } from '@material-ui/core'

const drawerWidth = 250

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  appBar: {
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.gray2Color,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  btnAvatar: {
    padding: 0,
    marginLeft: theme.spacing(1),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  btnFavorite: {
    marginRight: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: theme.gray2Color,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: theme.gray2Color,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 1,
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    backgroundColor: theme.gray2Color,
    //borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  listDrawer: {
    paddingTop: 0,
  },
  popover: {
    maxHeight: '280px',
    maxWidth: '400px',
  },
  starButton: {
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
    '&:hover': {
      transform: 'scale(1.3)',
      cursor: 'pointer',
    },
  },
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      // backgroundColor: '#ebfff3',
      backgroundColor: theme.palette.primary.light,
    },
  },
  iconRI: {
    color: theme.palette.secondary.main,
  },
  imgContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

class Menu extends Component {
  constructor(props) {
    super(props)

    this.handleMenu = this.handleMenu.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleClickRelatory = this.handleClickRelatory.bind(this)
    this.handleClickCrm = this.handleClickCrm.bind(this)
    this.state = {
      open: false,
      anchorEl: false,
      openRelatory: false,
      openFinance: false,
      openCrm: false,
    }
  }

  handleClick = () => {
    this.setState(state => ({ open: !state.open }))
  }

  handleClickRelatory = () => {
    this.setState(state => ({ openRelatory: !state.openRelatory }))
  }

  handleClickFinance = () => {
    this.setState(state => ({ openFinance: !state.openFinance }))
  }

  handleClickCrm = () => {
    this.setState(state => ({ openCrm: !state.openCrm }))
  }

  handleMenu(event) {
    this.setState({ ...this.state, anchorEl: event.currentTarget })
  }

  handleClose() {
    this.setState({ ...this.state, anchorEl: null })
  }

  render() {
    const {
      classes,
      theme,
      open,
      handleDrawerOpen,
      handleDrawerClose,
      loading,
      user,
      logout,
      toogleTheme,
    } = this.props

    const active = loading || ''

    const { anchorEl } = this.state

    const cookies = parseCookies()

    return (
      <div>
        <div className={`progress ${active}`}>
          <div className={classes.imgContainer}>
            <div>
              <img
                className={classes.logo}
                src="/img/loading.gif"
                alt="Loading"
              />
            </div>
          </div>
          {/* <div className="content">
            <div />
            <div />
            <div />
          </div> */}
        </div>
        <AppBar
          color="inherit"
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar variant="dense">
            <IconButton
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              className={classNames(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon color="primary" />
            </IconButton>
            <div className={classes.grow} />
            <div className={classes.buttons}>
              {/* <Button
                onClick={() => {
                  const crmUrl = process.env.RAZZLE_CRM_URL
                  console.log(crmUrl)
                  window.open(crmUrl, '_self')
                }}
                style={{
                  color: 'white',
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: '20px',
                  paddingInline: '20px',
                }}
              >
                Acessar o CRM
              </Button> */}
              <ThemeSwitcher
                classes={classes}
                theme={theme}
                toogleTheme={toogleTheme}
              />
              {/* <Notifications classes={classes} /> */}
              <IconButton
                aria-label="Account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                className={classes.btnAvatar}
                onClick={this.handleMenu}
              >
                <Avatar className={classes.avatar}>
                  {user.name && returnFirstLetters(user.name).toUpperCase()}
                </Avatar>
              </IconButton>
            </div>
            <Menus
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              <MenuItem
                onClick={() => {
                  logout()
                  Object.keys(cookies).forEach(cookieName => {
                    destroyCookie(null, cookieName)
                  })
                }}
              >
                Logout
              </MenuItem>
            </Menus>
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <Icons
            classes={classes}
            open={this.state.open}
            openRelatory={this.state.openRelatory}
            openFinance={this.state.openFinance}
            openCrm={this.state.openCrm}
            handleDrawerClose={handleDrawerClose}
            handleClick={this.handleClick}
            handleClickRelatory={this.handleClickRelatory}
            handleClickFinance={this.handleClickFinance}
            handleClickCrm={this.handleClickCrm}
          />
        </Drawer>
      </div>
    )
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
  anchorEl: PropTypes.object,
}

const mapStateToProps = state => ({
  user: state.auth.user || [],
  loading: state.main.loadingCircle,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(Menu)))
