import { EDIT_LAUNDRY, FETCH_LAUNDRY, GET_DETACHEDS } from './LaundryActions'

const INITIAL_STATE = {
  items: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  filters: [],
  editingLaundry: null,
  permissions: [],
  paymentMethods: [],
  streetAttendants: [],
  weekDays: [],
  appointments: [],
  companies: [],
  filters: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LAUNDRY.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        paymentMethods: action.payload.data.paymentMethods,
        streetAttendants: action.payload.data.streetAttendants,
        weekDays: action.payload.data.weekDays,
        companies: action.payload.data.companies,
        filters: action.payload.filters,
      }
    case EDIT_LAUNDRY.SUCCESS:
      return {
        ...state,
        editingLaundry: {
          ...action.payload.data,
          dateTime: null,
          detachedAttendant: null,
        },
        appointments: action.payload.appointments,
      }
    case GET_DETACHEDS.SUCCESS:
      return {
        ...state,
        appointments: action.payload.appointments,
      }
    default:
      return state
  }
}

export const getLaundryListData = state => {
  return {
    items: state.laundry.items,
    filters: state.laundry.filters
  }
}

export const getEditingLaundry = state => {
  return state.laundry.editingLaundry
}
