import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import consts from '../../util/consts'
import createAsyncConst from '../../util/createAsyncConst'

export const REMOVE_USER = createAsyncConst('REMOVE_USER')
export const SUBMIT_USER = createAsyncConst('SUBMIT_USER')
export const FETCH_USER = createAsyncConst('FETCH_USER')
export const EDIT_USER = createAsyncConst('EDIT_USER')
export const FETCH_NOTIFICATIONS = createAsyncConst('FETCH_NOTIFICATIONS')
export const MARK_ALL_AS_READ = createAsyncConst('MARK_ALL_AS_READ')
export const GET_REPORT = createAsyncConst('GET_REPORT')

export function fetchUsers(page, filters = [], rows) {
  console.log('fetch')
  return dispatch => {
    dispatch(action(FETCH_USER.ACTION))
    return axios
      .get(`${config.API_URL}/users`, {
        params: {
          filters: JSON.stringify(filters),
          rows: rows || consts.DEFAULT_PAGINATION,
          page,
        },
      })
      .then(resp => {
        dispatch(action(FETCH_USER.SUCCESS, {data: resp.data, filters: filters}))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_USER, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_USER.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/users/${id}`)
      .then(resp => {
        return resp.data.data
      })
      .then(data => dispatch(action(EDIT_USER.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(EDIT_USER, e)))
  }
}

export function post(values) {
  return submit(values, 'post')
}

export function update(values) {
  return submit(values, 'put')
}

function submit(values, method) {
  const id = values.id ? `/${values.id}` : ''

  return dispatch => {
    dispatch(action(SUBMIT_USER.ACTION))
    return axios[method](`${config.API_URL}/users${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_USER.SUCCESS, data))
        dispatch(fetchUsers())
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_USER, e)))
  }
}

export function remove(id) {
  return dispatch => {
    dispatch(action(REMOVE_USER.ACTION))
    return axios
      .delete(`${config.API_URL}/user/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(REMOVE_USER.SUCCESS, data))
        dispatch(fetchUsers())
      })
      .catch(e => actionFailed(REMOVE_USER.FAILURE, e))
  }
}

export function fetchNotifications() {
  return dispatch => {
    dispatch(action(FETCH_NOTIFICATIONS.ACTION, {}, false, false))
    return axios
      .get(`${config.API_URL}/notifications`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(FETCH_NOTIFICATIONS.SUCCESS, data, false, false))
        return data
      })
      .catch(e => actionFailed(FETCH_NOTIFICATIONS.FAILURE, e, false))
  }
}

export function markAllAsRead() {
  return dispatch => {
    dispatch(action(MARK_ALL_AS_READ.ACTION, {}, false, false))
    return axios
      .put(`${config.API_URL}/readAllNotifications`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(MARK_ALL_AS_READ.SUCCESS, data, false, false))
        return data
      })
      .catch(e => actionFailed(MARK_ALL_AS_READ.FAILURE, e, false))
  }
}

export function makeReport(filters) {
  return dispatch => {
    return axios({
      url:
        `${config.API_URL}/user/fetch-users-report`,
        method: 'POST',
        responseType: 'blob',
        headers: { Accept: 'application/vnd.ms-excel' },
        params: {
          filters: JSON.stringify(filters),
        }
      })
      .then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        
        link.href = url
        link.setAttribute('download', 'users_report.xlsx')
        document.body.appendChild(link)
        link.click()
        return resp
      })
    .catch(e => dispatch(actionFailed(GET_REPORT, e)))
  }
}
