/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import TextField from '@material-ui/core/TextField'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import React, { useState } from 'react'

function ComboBox(props) {
  const {
    style,
    options,
    setForm,
    placeholder,
    name,
    data,
    uniqueState,
    disabled,
    error,
    helperText,
    variant,
    margin,
    limit,
    chave,
  } = props

  const filterOptions = createFilterOptions({
    limit: limit || null,
  })

  const [blur, setBlur] = useState(false)

  return !blur ? (
    <div style={{ cursor: 'pointer' }} onClick={() => setBlur(true)}>
      {data?.name || ''}
    </div>
  ) : (
    <Autocomplete
      id={String(name) || String(Math.floor(Math.random() * 100))}
      options={options}
      noOptionsText="Sem opções"
      disabled={disabled || false}
      openText="Abrir"
      filterOptions={filterOptions}
      // disableClearable={true}
      blurOnSelect
      clearText="Limpar"
      clearOnEscape
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.name
      }
      onBlur={() => setBlur(false)}
      onChange={(event, value) =>
        uniqueState ? setForm(value) : setForm(name, value, chave)
      }
      getOptionSelected={(option, value) => option.name === value.name}
      value={data || null}
      renderInput={params => (
        <TextField
          {...params}
          // label={`${label}` || ''}
          placeholder={placeholder || ''}
          fullWidth
          error={error || false}
          helperText={helperText || ''}
          // variant={variant || `standard`}
          // margin={margin || 'none'}
          style={style}
        />
      )}
    />
  )
}

export default React.memo(ComboBox)
