import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { TimePicker } from '@material-ui/pickers'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { setHours, setMinutes } from 'date-fns'

import { getDetacheds, newDetached } from '../LaundryActions'

function FormTime({ open, handleClose, date, detachedUser }) {
  const [time, setTime] = React.useState(new Date())
  const dispatch = useDispatch()
  const { id } = useParams()

  const onSubmit = () => {
    const result = setMinutes(
      setHours(date, time.getHours()),
      time.getMinutes()
    )

    dispatch(newDetached(id, result, detachedUser.id, true)).then(() => {
      dispatch(getDetacheds(id))
      handleClose()
    })
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-time-title"
      >
        <DialogTitle id="form-time-title">
          Entre com a hora do atendimento
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            O agendamento foi identificado para hoje, Entre com a hora.
          </DialogContentText>
          <TimePicker
            clearable
            ampm={false}
            label="Hora do atendimento"
            value={time}
            onChange={setTime}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={onSubmit} color="primary">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default React.memo(FormTime)
