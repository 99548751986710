import React from 'react'
import NumberFormat from 'react-number-format'

const NumberFormatCustomPercentage = React.memo(propis => {
  const { inputRef, onChange, prefix, ...other } = propis
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      fixedDecimalScale
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
      decimalSeparator="."
      isNumericString
      decimalScale={2}
      prefix={prefix}
      suffix= "%"
    />
  )
})

export default NumberFormatCustomPercentage
