/* eslint-disable import/order */
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from '../../../resources/theme/users'
import Transiction from '../../components/Transition'
import { edit, update } from '../ServiceCategoryActions'
import Form from './Form'

const Transition = React.forwardRef((props, ref) => {
  return <Transiction propis={props} refi={ref} />
})

function EditType(props) {
  const { classes } = props
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(edit(props.match.params.id))
  }, [])

  const service = useSelector(
    state => state.serviceCategory.editingServiceCategory
  )

  const onSubmit = values => {
    dispatch(update(values)).then(data => {
      if (data && data.code === 200) {
        props.history.push('/service-category')
      }
    })
  }

  const onClose = () => {
    props.history.push('/service-category')
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={service !== null}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Editar Cliente
          </Typography>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Form data={service} onClose={onClose} onSubmit={onSubmit} />
    </Dialog>
  )
}

export default withStyles(styles)(EditType)
