import {
  Box,
  DialogActions,
  FormControlLabel,
  Paper,
  TextField as TextFieldOr,
  Typography,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useCallback } from 'react'
import InputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field, FieldArray } from 'formik'
import { Checkbox, Switch, TextField } from 'formik-material-ui'
import MuiTextField from '@material-ui/core/TextField'
import { Autocomplete } from 'formik-material-ui-lab'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUp'
import { KeyboardDatePicker } from 'formik-material-ui-pickers'
import { useConfirm } from 'material-ui-confirm'
import { useParams } from 'react-router'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import { format } from 'date-fns'
import AddIcon from '@material-ui/icons/Add'
import * as yup from 'yup'

import styles from '../../../resources/theme/global'
import { findCep } from '../../clients/ClientActions'
import { getDetacheds, newDetached } from '../LaundryActions'
import NumberFormatCustom from '../../components/NumberFormat'

import FormTime from './FormTime'
import Appointment from './Appointment'

function Formulario(props) {
  const { data, onClose, onSubmit, classes, isReadnOnly } = props
  const { id } = useParams()
  const dispatch = useDispatch()
  const paymentMethods = useSelector(state => state.laundry.paymentMethods)
  const streetAttendants = useSelector(state => state.laundry.streetAttendants)
  const companies = useSelector(state => state.laundry.companies)
  const weekDays = useSelector(state => state.laundry.weekDays)
  const confirm = useConfirm()
  const [open, setOpen] = React.useState(false)
  const [currentDate, setCurrentDate] = React.useState(null)
  const [currentDetached, setCurrentDetached] = React.useState(null)

  const closeTimeModal = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const handleClose = () => onClose && onClose()

  const confirmNewAvulso = (date, detachedAttendant) => {
    confirm({
      description: `Deseja gerar um atendimento avulso para a data ${format(
        date,
        'dd/MM/yyyy'
      )}?`,
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      dispatch(newDetached(id, date, detachedAttendant.id)).then(resp => {
        if (resp?.askConfirmation) {
          setCurrentDate(date)
          setCurrentDetached(detachedAttendant)
          setOpen(true)
        } else {
          dispatch(getDetacheds(id))
        }
      })
    })
  }

  const initialValues = data || {
    id: '',
    name: '',
    company_name: '',
    contact_name: '',
    email: '',
    cep: '',
    street: '',
    number: '',
    complement: '',
    observations: '',
    neighborhood: '',
    city: '',
    uf: '',
    cnpj: '',
    phone: '',
    phone2: '',
    status: true,
    paymentMethod: '',
    attendantsWeekdays: [
      {
        streetAttendant: '',
        weekDays: [],
      },
    ],
    dateTime: null,
    default_value: '',
    urgent_value: '',
    company: '',
  }

  const schema = yup.object().shape({
    email: yup.string().required('Obrigatório').email('Digite um email Válido'),
    paymentMethod: yup.object().nullable().required('Obrigatório'),
    phone: yup.string().required('Obrigatório'),
    contact_name: yup.string().required('Obrigatório'),
    name: yup.string().required('Obrigatório'),
    company: yup.object().nullable().required('Obrigatório'),
    attendantsWeekdays: yup
      .array()
      .of(
        yup.object().shape({
          streetAttendant: yup.object().nullable(),
          weekDays: yup.array().of(yup.object().nullable()),
        })
      )
      .nullable(),
  })

  return (
    <>
      <FormTime
        open={open}
        handleClose={closeTimeModal}
        date={currentDate}
        detachedUser={currentDetached}
      />
      <DialogContent>
        <Formik
          key={initialValues.id}
          initialValues={initialValues}
          enableReinitialize
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false)
            onSubmit && onSubmit(values)
          }}
        >
          {({
            submitForm,
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue,
            touched,
            errors,
            values,
          }) => (
            <Form>
              <Grid container spacing={1}>
                <Grid item xs>
                  <Field
                    component={TextField}
                    name="name"
                    label="Nome"
                    fullWidth
                    error={touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs>
                  <Field
                    component={TextField}
                    name="company_name"
                    label="Razão Social"
                    fullWidth
                  />
                </Grid>
                <Grid item xs>
                  <Field
                    component={TextField}
                    name="contact_name"
                    label="Nome do contato"
                    fullWidth
                    error={touched.contact_name && !!errors.contact_name}
                    helperText={touched.contact_name && errors.contact_name}
                  />
                </Grid>
                <Grid item xs>
                  <Field
                    component={TextField}
                    name="email"
                    label="E-mail"
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs>
                  <Field name="cep">
                    {({ field }) => (
                      <InputMask
                        {...field}
                        mask="99.999-999"
                        formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                        maskChar=""
                        onChange={handleChange}
                        onBlur={e => {
                          const cep = e.currentTarget.value.replace(
                            /[^\d]/g,
                            ''
                          )
                          cep &&
                            dispatch(findCep(cep)).then(resp => {
                              if (resp && resp.code === 200) {
                                const { items } = resp.data
                                if (items) {
                                  setFieldValue('street', items.logradouro)
                                  setFieldValue('neighborhood', items.bairro)
                                  setFieldValue('city', items.cidade)
                                  setFieldValue('uf', items.uf)
                                }
                              }
                            })
                        }}
                      >
                        {inputProps => (
                          <TextFieldOr {...inputProps} label="CEP" fullWidth />
                        )}
                      </InputMask>
                    )}
                  </Field>
                </Grid>

                <Grid item xs>
                  <Field
                    component={TextField}
                    name="street"
                    label="RUA"
                    fullWidth
                  />
                </Grid>

                <Grid item xs>
                  <Field
                    component={TextField}
                    name="number"
                    label="Numero"
                    fullWidth
                  />
                </Grid>

                <Grid item xs>
                  <Field
                    component={TextField}
                    name="complement"
                    label="Complemento"
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs>
                  <Field
                    component={TextField}
                    name="neighborhood"
                    label="Bairro"
                    fullWidth
                  />
                </Grid>

                <Grid item xs>
                  <Field
                    component={TextField}
                    name="city"
                    label="Cidade"
                    fullWidth
                  />
                </Grid>

                <Grid item xs>
                  <Field component={TextField} name="uf" label="UF" fullWidth />
                </Grid>

                <Grid item xs>
                  <Field name="cnpj">
                    {({ field }) => (
                      <InputMask
                        {...field}
                        mask="99.999.999/9999-99"
                        formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                        maskChar=""
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {inputProps => (
                          <TextFieldOr {...inputProps} label="CNPJ" fullWidth />
                        )}
                      </InputMask>
                    )}
                  </Field>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs>
                  <Field name="phone">
                    {({ field }) => (
                      <InputMask
                        {...field}
                        mask={
                          field.value?.replace(/[^\d]/g, '').length <= 10
                            ? '(99) 9999-9999?'
                            : '(99) 99999-9999'
                        }
                        formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                        maskChar=""
                        onChange={handleChange}
                      >
                        {inputProps => (
                          <TextFieldOr
                            {...inputProps}
                            label="Telefone 1"
                            fullWidth
                            error={touched.phone && !!errors.phone}
                            helperText={touched.phone && errors.phone}
                          />
                        )}
                      </InputMask>
                    )}
                  </Field>
                </Grid>

                <Grid item xs>
                  <Field name="phone2">
                    {({ field }) => (
                      <InputMask
                        {...field}
                        mask={
                          field.value?.replace(/[^\d]/g, '').length <= 10
                            ? '(99) 9999-9999?'
                            : '(99) 99999-9999'
                        }
                        formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                        maskChar=""
                        onChange={handleChange}
                      >
                        {inputProps => (
                          <TextFieldOr
                            {...inputProps}
                            label="Telefone 2"
                            fullWidth
                          />
                        )}
                      </InputMask>
                    )}
                  </Field>
                </Grid>
                <Grid item xs>
                  <Field
                    name="paymentMethod"
                    component={Autocomplete}
                    options={paymentMethods}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={option =>
                      typeof option === 'string' ? option : option.name
                    }
                    renderInput={params => (
                      <MuiTextField
                        {...params}
                        error={touched.paymentMethod && !!errors.paymentMethod}
                        helperText={
                          touched.paymentMethod && errors.paymentMethod
                        }
                        label="Método de pagamento"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs>
                  <Field name="default_value">
                    {({ field }) => (
                      <TextFieldOr
                        {...field}
                        label="Valor para m² do tapete"
                        onChange={handleChange('default_value')}
                        fullWidth
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs>
                  <Field name="urgent_value">
                    {({ field }) => (
                      <TextFieldOr
                        {...field}
                        label="Valor urgente para m² do tapete"
                        onChange={handleChange('urgent_value')}
                        fullWidth
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs>
                  <Field
                    name="company"
                    component={Autocomplete}
                    options={companies}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={option =>
                      typeof option === 'string' ? option : option.name
                    }
                    renderInput={params => (
                      <MuiTextField
                        {...params}
                        error={touched.company && !!errors.company}
                        helperText={touched.company && errors.company}
                        label="Empresa"
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Paper elevation={5}>
                <FieldArray name="attendantsWeekdays">
                  {({ push, remove }) => (
                    <div className={classes.laundryTechniciansContainer}>
                      <Grid container spacing={1}>
                        <Grid item xs>
                          <Typography variant="h6">
                            Técnicos da lavanderia
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            onClick={() =>
                              push({ streetAttendant: {}, weekDays: [] })
                            }
                          >
                            <AddIcon />
                          </Button>
                        </Grid>
                      </Grid>
                      {values.attendantsWeekdays?.map((item, index) => (
                        <Box key={String(index)}>
                          <Grid
                            container
                            spacing={1}
                            style={{ margin: '10px 0px' }}
                          >
                            <Grid item xs={6} style={{ alignSelf: 'flex-end' }}>
                              <Field
                                name={`attendantsWeekdays[${index}].streetAttendant`}
                                component={Autocomplete}
                                options={streetAttendants}
                                getOptionSelected={(option, value) =>
                                  option.id === value.id
                                }
                                getOptionLabel={option =>
                                  typeof option === 'string'
                                    ? option
                                    : option.name
                                }
                                renderInput={params => (
                                  <MuiTextField
                                    {...params}
                                    error={
                                      touched.attendantsWeekdays?.length &&
                                      !!errors.attendantsWeekdays?.length &&
                                      !!errors.attendantsWeekdays[index]
                                        .streetAttendant
                                    }
                                    helperText={
                                      touched.attendantsWeekdays?.length &&
                                      errors.attendantsWeekdays?.length &&
                                      errors.attendantsWeekdays[index]
                                        .streetAttendant
                                    }
                                    label="Técnico"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <Field
                                name={`attendantsWeekdays[${index}].weekDays`}
                                component={Autocomplete}
                                options={weekDays}
                                multiple
                                filterSelectedOptions
                                getOptionSelected={(option, value) =>
                                  option.id === value.id
                                }
                                getOptionLabel={option =>
                                  typeof option === 'string'
                                    ? option
                                    : option.name
                                }
                                renderInput={params => (
                                  <MuiTextField
                                    {...params}
                                    error={
                                      touched.attendantsWeekdays?.length &&
                                      !!errors.attendantsWeekdays?.length &&
                                      !!errors.attendantsWeekdays[index]
                                        .weekDays
                                    }
                                    helperText={
                                      touched.attendantsWeekdays?.length &&
                                      errors.attendantsWeekdays?.length &&
                                      errors.attendantsWeekdays[index].weekDays
                                    }
                                    label="Dias da Semana"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs style={{ alignSelf: 'flex-end' }}>
                              <Button
                                color="inherit"
                                onClick={() => remove(index)}
                                aria-label="Close"
                              >
                                <RemoveCircleOutlineIcon />
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                    </div>
                  )}
                </FieldArray>
              </Paper>
              <Grid container spacing={1}>
                <Grid item xs>
                  <Field
                    component={TextField}
                    name="observations"
                    label="Observações"
                    multiline
                    rows={5}
                    rowsMax={10}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs>
                  <FormControlLabel
                    control={
                      <Field
                        icon={<ThumbDownAltOutlinedIcon />}
                        checkedIcon={
                          <ThumbUpAltOutlinedIcon color="secondary" />
                        }
                        component={Checkbox}
                        type="checkbox"
                        name="status"
                      />
                    }
                    label="Ativo?"
                  />
                </Grid>
              </Grid>
              {id && (
                <Grid container alignItems="flex-end" spacing={2}>
                  <Box style={{ marginRight: '20px' }}>
                    <Typography variant="h6" color="secondary">
                      Agendamento Avulso
                    </Typography>
                  </Box>

                  <Box style={{ marginRight: '20px' }}>
                    <Field
                      component={KeyboardDatePicker}
                      name="dateTime"
                      clearable
                      minDateMessage="A data mínima é a atual"
                      clearLabel="Limpar"
                      disablePast
                      label="Data"
                      autoOk
                      format="dd/MM/yyyy"
                    />
                  </Box>

                  <Box style={{ marginRight: '20px', minWidth: '220px' }}>
                    <Field
                      name="detachedAttendant"
                      component={Autocomplete}
                      options={streetAttendants}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={option =>
                        typeof option === 'string' ? option : option.name
                      }
                      renderInput={params => (
                        <MuiTextField {...params} label="Técnico" />
                      )}
                    />
                  </Box>

                  <Box>
                    {!isReadnOnly && (
                      <Button
                        color="primary"
                        disabled={!values.dateTime || !values.detachedAttendant}
                        onClick={() =>
                          confirmNewAvulso(
                            values.dateTime,
                            values.detachedAttendant
                          )
                        }
                      >
                        Novo Agendamento Avulso
                      </Button>
                    )}
                  </Box>
                </Grid>
              )}
              <Grid
                container
                alignItems="center"
                spacing={1}
                style={{ marginTop: '25px' }}
              >
                <Grid item xs={3}>
                  <Typography color="primary" variant="h6" gutterBottom>
                    Últimos atendimentos:
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Box className={classes.appointments}>
                    <Appointment isReadnOnly={isReadnOnly} />
                  </Box>
                </Grid>
              </Grid>
              <DialogActions>
                {!isReadnOnly && (
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    Salvar
                  </Button>
                )}
                <Button onClick={handleClose}>Cancelar</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </>
  )
}

export default withStyles(styles)(Formulario)
