import { EDIT_BANK, FETCH_BANK } from './BankActions'

const INITIAL_STATE = {
  items: [],
  filters: {},
  editingBank: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_BANK.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        filters: action.payload.filters,
      }
    case EDIT_BANK.SUCCESS:
      return {
        ...state,
        editingBank: action.payload,
      }
    default:
      return state
  }
}

export const getBankListData = state => {
  return {
    items: state.bank.items,
  }
}
