import {
  FETCH_ELETRONIC_COMPANIES,
  FETCH_ELETRONIC_NFSE,
} from './EletronicNfseActions'

const INITIAL_STATE = {
  items: [],
  meta: {},
  filters: [],
  editingServiceCategory: null,
  companiesAvaliable: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ELETRONIC_NFSE.SUCCESS:
      return {
        ...state,
        items: action.payload.data,
        meta: action.payload.meta,
      }
    case FETCH_ELETRONIC_COMPANIES.SUCCESS:
      return {
        ...state,
        companiesAvaliable: action.payload.data,
      }
    default:
      return state
  }
}
