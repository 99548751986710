/* eslint-disable no-shadow */
/* eslint-disable simple-import-sort/sort */
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import styles from '../../../resources/theme/global'
import { renderRoute } from '../../../util/utils'
import { fetchClients } from '../ClientActions'
import { getClientListData } from '../ClientReducer'

import Filter from './Filter'
import List from './List'
import NewClient from './New'
import EditClient from './Edit'

class Clients extends Component {
  componentDidMount() {
    // const { fetchClients } = this.props
    // fetchClients()
  }

  render() {
    const { classes, permissions } = this.props
    return (
      <>
        <Grid
          container
          className={classes.pageHead}
          spacing={1}
          alignItems="center"
        >
          <Grid item xs>
            <Typography variant="h4" color="secondary">
              Clientes
            </Typography>
          </Grid>
          <Grid item>
            <Filter classes={classes} />
          </Grid>
          <Grid item>
            {renderRoute(['create-client'], permissions) && (
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to="/clients/new"
              >
                <AddCircleOutlineIcon className={classes.btnIcon} /> Cadastrar
              </Button>
            )}
          </Grid>
        </Grid>

        {renderRoute(['create-client'], permissions) && (
          <Route path="/clients/new" component={NewClient} />
        )}
        {renderRoute(['update-client'], permissions) && (
          <Route path="/clients/:id/edit" component={EditClient} />
        )}

        <List {...this.props} classes={classes} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...getClientListData(state),
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchClients,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Clients))
