/* eslint-disable import/order */
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import styles from '../../../resources/theme/users'
import { removeEmpty } from '../../../util/utils'
import EnhancedComponent from '../../components/EnhancedComponent'
import { edit, fetchUsers, update } from '../UserActions'
import Form from './Form'

function Transition(props) {
  return <Slide direction="down" {...props} />
}

class EditUser extends EnhancedComponent {
  componentDidMount() {
    this.props.edit(this.props.match.params.id)
  }

  onSubmit = values => {
    this.props.update(values).then(data => {
      if (data && data.code === 200) {
        this.props.history.push('/users')
      }
    })
  }

  onClose = () => {
    this.props.history.push('/users')
  }

  render() {
    const { classes, user } = this.props
    const { see } = this.props.match.params

    return (
      <Dialog
        // fullWidth
        // maxWidth="sm"
        fullScreen
        open={user !== null}
        onClose={this.onClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {`${see ? 'Visualizar' : 'Editar'} Usuário`}
            </Typography>
            <IconButton
              color="inherit"
              onClick={this.onClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Form
          data={user}
          see={see}
          onClose={this.onClose}
          onSubmit={this.onSubmit}
        />
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.editingUser || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      update,
      edit,
      fetchUsers,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditUser))
