import React from 'react'
import Transiction from '../../components/Transition'
import { useHistory, useParams } from 'react-router'
import {
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  withStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useDispatch } from 'react-redux'

import styles from '../../../resources/theme/global'
import { duplicateRole } from '../RolesAction'

import FormDuplicate from './FormDuplicate'

const Transition = React.forwardRef((props, ref) => {
  return <Transiction propis={props} refi={ref} />
})

function Duplicate(props) {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { classes } = props
  const history = useHistory()

  const onClose = () => {
    history.push('/roles')
  }

  const onSubmit = values => {
    dispatch(duplicateRole(values, id)).then(resp => {
      if (resp && resp.code === 201) {
        history.push('/roles')
      }
    })
  }

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      TransitionComponent={Transition}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Duplicar Regra
          </Typography>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <FormDuplicate classes={classes} onSubmit={onSubmit} onClose={onClose} />
    </Dialog>
  )
}

export default withStyles(styles)(Duplicate)
