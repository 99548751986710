import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import {
  DialogContentText,
  FormHelperText,
  InputAdornment,
} from '@material-ui/core'
import Monetization from '@material-ui/icons/MonetizationOn'
import { useDispatch } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'

import NumberFormatCustom from '../../components/NumberFormat'
import { closeLaundryOS } from '../AttendanceLaundryActions'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Modal = props => {
  const { open, handleClose, id } = props
  const dispatch = useDispatch()

  // const schema = yup.object().shape({
  //   defaultValue: yup.number().required('Obrigatório'),
  // })

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="modal-change-default-value-title"
        aria-describedby="modal-change-default-value-description"
      >
        <DialogTitle id="modal-change-default-value-title">
          Valor do M2
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Digite o valor que dejesa praticar para esta lavanderia.
          </DialogContentText>
          <Formik
            initialValues={{ defaultValue: '' }}
            enableReinitialize
            // validationSchema={schema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(false)
              await dispatch(closeLaundryOS(id, values))
              handleClose(false)
              resetForm({})
            }}
          >
            {({ submitForm, isSubmitting, touched, errors, handleChange }) => (
              <Form>
                <Field name="defaultValue">
                  {({ field }) => (
                    <>
                      <TextField
                        {...field}
                        onChange={handleChange('defaultValue')}
                        fullWidth
                        placeholder="11,90"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Monetization />
                            </InputAdornment>
                          ),
                        }}
                        error={touched.defaultValue && !!errors.defaultValue}
                        helperText={touched.defaultValue && errors.defaultValue}
                      />
                      <FormHelperText>
                        *Caso queira manter o valor, deixe em banco.
                      </FormHelperText>
                    </>
                  )}
                </Field>
                <DialogActions>
                  <Button onClick={() => handleClose(false)} color="primary">
                    Fechar
                  </Button>

                  <Button
                    disabled={isSubmitting}
                    onClick={submitForm}
                    color="primary"
                  >
                    Confirmar
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default React.memo(Modal)
