import { FETCH_SERVICES_RELATORY_INIT } from './CommissionReportActions'

const INITIAL_STATE = {
  id: "",
  service_name: "",
  supplier_name: "",
  attendance: "",
  final_value: "",
  supplier_commission: "",
  item_commission: "",
  created_at: "",
  filters: [],
  suppliers: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SERVICES_RELATORY_INIT.SUCCESS:
      return {
        ...state,
        id: action.payload.data.id,
        service_name: action.payload.data.service_name,
        supplier_name: action.payload.data.supplier_name,
        attendance_id: action.payload.data.attendance_id,
        final_value: action.payload.data.final_value,
        supplier_commission: action.payload.data.supplier_commission,
        item_commission: action.payload.data.item_commission,
        created_at: action.payload.data.created_at,
        filters: action.payload.filters,
        suppliers: action.payload.suppliers,

      }
    case 'CLEAR_ACTION_SERVICES':
      return {
        ...state,
        filters: [],
      }
    default:
      return state
  }
}

export const getServicesRelatoryListData = state => {
  return {
    items: state.services_relatory.items,
    page: state.services_relatory.page,
    sizePerPage: state.services_relatory.sizePerPage,
    totalSize: state.services_relatory.totalSize,
    filters: state.services_relatory.filters,
  }
}
