/* eslint-disable global-require */
import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { IconButton, Button } from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import { useDispatch, useSelector } from 'react-redux'
import ptLocale from '@fullcalendar/core/locales/pt-br'

import {
  findAttendantes,
  getServicesBooked,
} from '../../callendar/CalendarActions'
import Chips from '../../callendar/pages/chips'

function DialogCalendar(props) {
  const { classes } = props
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const [bookedEvents, setBookedEvents] = useState([])
  const [bookedFiltered, setBookedFiltered] = useState(bookedEvents)
  const [attendant, setAttendant] = useState('')
  const attendants = useSelector(state => state.calendar.attendants)

  if (typeof window !== 'undefined') {
    const { default: FullCalendar } = require('@fullcalendar/react')
    const { default: dayGridPlugin } = require('@fullcalendar/daygrid')
    const { default: timeGridPlugin } = require('@fullcalendar/timegrid')
    const { default: interactionPlugin } = require('@fullcalendar/interaction')

    const filterAttendant = id => {
      setAttendant(id)
    }

    const initialLoad = async () => {
      const respBooked = await dispatch(getServicesBooked(true))

      if (respBooked?.code === 200) {
        const { data } = respBooked

        setBookedEvents(data)
      }
    }

    useEffect(() => {
      if (!attendants.length) dispatch(findAttendantes())

      if (open === true) initialLoad()
    }, [open])

    useEffect(() => {
      window.Echo.channel(`Calendar`).listen(
        `CalendarEventHasUpdatedEvent`,
        () => {
          initialLoad()
        }
      )

      return () => window.Echo.leaveChannel(`Calendar`)
    }, [])

    useEffect(() => {
      if (attendant) {
        setBookedFiltered(
          bookedEvents.filter(item => item.attendant?.id === attendant)
        )
      } else {
        setBookedFiltered(bookedEvents)
      }
    }, [bookedEvents, attendant])

    const handleClickOpen = () => {
      setOpen(true)
    }

    const handleClose = () => {
      setOpen(false)
    }

    return (
      <>
        <IconButton
          aria-label="calendar"
          color="primary"
          onClick={handleClickOpen}
        >
          <EventIcon />
        </IconButton>
        <Dialog
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            Calendário da semana
          </DialogTitle>
          <DialogContent>
            <Chips
              classes={classes}
              attendants={attendants}
              filterAttendant={filterAttendant}
            />
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay',
              }}
              header={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
              }}
              defaultView="timeGridDay"
              locale={ptLocale}
              editable={false}
              defaultDate={new Date()}
              droppable
              scrollTime="08:00:00"
              minTime="06:00:00"
              slotDuration="00:30:00"
              slotLabelInterval="02:00:00"
              slotLabelFormat={{
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: false,
                meridiem: 'short',
              }}
              navLinks
              weekNumberCalculation="ISO"
              eventDurationEditable={false}
              selectMirror
              dayMaxEvents
              events={bookedFiltered}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default React.memo(DialogCalendar)
