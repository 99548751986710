import React from 'react'
import { DropzoneArea } from 'material-ui-dropzone'
import {
  AttachFile,
  Audiotrack,
  Description,
  PictureAsPdf,
  Theaters,
} from '@material-ui/icons'

import { convertBytesToMbs } from '../../util/utils'

function GlobalDropzone(props) {
  const handlePreviewIcon = (fileObject, classes) => {
    const { type } = fileObject.file
    const iconProps = {
      className: classes.image,
    }

    if (type.startsWith('video/')) return <Theaters {...iconProps} />
    if (type.startsWith('audio/')) return <Audiotrack {...iconProps} />

    switch (type) {
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <Description {...iconProps} />
      case 'application/pdf':
        return <PictureAsPdf {...iconProps} />
      default:
        return <AttachFile {...iconProps} />
    }
  }

  const {
    handleChangeFiles,
    initialfiles = [],
    classes,
    limit,
    text,
    mimeTypes,
  } = props
  return (
    <DropzoneArea
      style={{ height: '140px' }}
      onChange={handleChangeFiles}
      getPreviewIcon={handlePreviewIcon}
      initialFiles={initialfiles}
      dropzoneClass={classes.dropzone}
      maxFileSize={2000000}
      filesLimit={limit}
      acceptedFiles={mimeTypes}
      dropzoneText={text}
      showFileNames
      // showPreviews
      // showPreviewsInDropzone={false}
      getFileAddedMessage={fileName => `Arquivo ${fileName} adicionado.`}
      getFileRemovedMessage={fileName => `Arquivo ${fileName} removido.`}
      getFileLimitExceedMessage={filesLimit =>
        `Múmero máximo de arquivos excedido. São permitidos ${filesLimit} arquivos`
      }
      getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
        let message = `Arquivo ${rejectedFile.name} não aceito. `
        if (!acceptedFiles.includes(rejectedFile.type)) {
          message += 'O tipo do arquivo não é suportado. '
        }
        if (rejectedFile.size > maxFileSize) {
          message += `O arquivo é muito grande. O tamanho limite é
            ${convertBytesToMbs(maxFileSize)}.`
        }
        return message
      }}
    />
  )
}

export default GlobalDropzone
