import React from 'react'
import filterStylesComponents from '../../../../../assets/filterComponentsStyles'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  TextField,
  TextareaAutosize,
  Typography,
} from '@material-ui/core'
import {
  closeLead,
  createDescription,
  createNewOrcament,
  transferLead,
} from '../../PanelActions'
import colors from '../../../../../assets/colors'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { mdiClose } from '@mdi/js'
import Icon from '@mdi/react'

const ModalNewOrcament = ({
  open = false,
  handleOpen = () => {},
  leadId = '',
}) => {
  const filterStyles = filterStylesComponents()
  const dispatch = useDispatch()
  const history = useHistory()

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      const resp = await dispatch(createNewOrcament(leadId))

      if (resp) {
        history.push('/crm/panel')
      }
    } catch (e) {
      //
    }
  }

  return (
    <Dialog open={open} onClose={handleOpen} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography style={{ fontSize: '20px' }}>
              Abrir novo orçamento
            </Typography>
          </Grid>

          <Grid item>
            <IconButton onClick={handleOpen}>
              <Icon path={mdiClose} size={1} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Typography
            style={{
              fontSize: '18px',
              color: colors.primary,
              textAlign: 'center',
              width: ' 100%',
            }}
          >
            Deseja concluir essa OS e abrir <br /> um novo atendimento?
          </Typography>
        </DialogContent>

        <DialogActions>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <Button
                type="submit"
                className={filterStyles.btnAdd}
                style={{ textTransform: 'none' }}
              >
                SIM
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ModalNewOrcament
