import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import VisibilityIcon from '@material-ui/icons/Visibility'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import classNames from 'classnames'

import consts from '../../../util/consts'
import Paginate from '../../components/Pagination'
import { exportFormatedMoney } from '../../../util/utils'

import Details from './Details'

function List(props) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = id => {
    props.fetchServiceCategoriesRelatoryDetailsInit(id, 1, filters)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const filters = useSelector(
    state => state.service_categories_relatory.filters
  )
  const [rowsPerPage, setRowsPerPage] = useState(consts.DEFAULT_PAGINATION)
  const { classes, items, totalSize, page } = props

  const onMovePage = (event, newPage) => {
    props.fetchServiceCategoriesRelatoryInit(newPage + 1, filters, rowsPerPage)
  }

  useEffect(() => {
    onMovePage()
  }, [rowsPerPage])

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  return (
    <Paper>
      <TableContainer
        className={classNames({
          [classes.tableContainer]: true,
          [classes.tableStickHeader]: true,
        })}
      >
        <Table stickyHeader size="medium" className={classes.tableStyle}>
          <TableHead>
            <TableRow>
              <TableCell>Tipo de Categoria</TableCell>
              <TableCell>Quantidade</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, key) => (
              <TableRow key={key}>
                <TableCell>{item.service_category}</TableCell>
                <TableCell>{item.qty}</TableCell>
                <TableCell>{exportFormatedMoney(item.value)}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleClickOpen(item.service_category_id)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
                <Details
                  open={open}
                  close={handleClose}
                  detailsReport={props.makeDetailsRelatory}
                  serviceId={props.selected_category}
                  details={props.details}
                  filters={props.filters}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginate
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        rows={totalSize}
        page={page - 1}
        handleChangePage={onMovePage}
      />
    </Paper>
  )
}

export default List
