/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
import { Component } from 'react'

import { cpfMask } from '../../util/utils'

export default class EnhancedComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dirty: false,
      fields: {},
    }
  }

  onChange = name => event => {
    this.setState({
      dirty: true,
      fields: {
        ...this.state.fields,
        [name]: this.functionToCall(name, event.target.value),
      },
    })
  }

  functionToCall(name, value) {
    switch (name) {
      case 'cpf':
        return cpfMask(value)
      default:
        return value
    }
  }
}
