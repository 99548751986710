/* eslint-disable import/order */
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { exportFormatedMoney } from '../../../util/utils'
import consts from '../../../util/consts'
import Paginate from '../../components/Pagination'
import { fetchServicesRelatoryInit } from '../ServicesRelatoryActions'
import { format } from 'date-fns'
import queryString from 'query-string'

function List(props) {
  const dispatch = useDispatch()
  const filters = useSelector(state => state.services_relatory.filters)
  const [rowsPerPage, setRowsPerPage] = useState(consts.DEFAULT_PAGINATION)
  const { classes, items, totalSize, page } = props

  const onMovePage = (event, newPage) => {
    // eslint-disable-next-line react/destructuring-assignment
    const values = queryString.parse(props.location.search)

    const filtersLeads = {
      type: 'dashboardLeads',
      status: values?.status,
      finalDate: values?.finalDate,
      attendance: values?.attendance,
      laundry: values?.laundry,
      startDate: values?.startDate,
      filters: {
        date: format(new Date(), 'yyyy-MM-dd HH:mm'),
      },
    }

    if (values?.status) {
      dispatch(
        fetchServicesRelatoryInit(newPage + 1, filtersLeads, rowsPerPage)
      )
    } else {
      dispatch(fetchServicesRelatoryInit(newPage + 1, filters, rowsPerPage))
    }
  }

  useEffect(() => {
    onMovePage()
  }, [rowsPerPage])

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  return (
    <Paper>
      <TableContainer
        className={classNames({
          [classes.tableContainer]: true,
          [classes.tableStickHeader]: true,
        })}
      >
        <Table stickyHeader size="medium" className={classes.tableStyle}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Atendente</TableCell>
              <TableCell>Empresa</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Telefone</TableCell>
              <TableCell>Bairro</TableCell>
              <TableCell>Status do Atendimento</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>Hora</TableCell>
              <TableCell>Valor Total</TableCell>
              <TableCell>Como Soube</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(item => (
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.attendent_name}</TableCell>
                <TableCell>{item.company_name}</TableCell>
                <TableCell>{item.client_name}</TableCell>
                <TableCell>{item.phone}</TableCell>
                <TableCell>{item.neighborhood}</TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>{item.date}</TableCell>
                <TableCell>{item.hour}</TableCell>
                <TableCell>{exportFormatedMoney(item.total)}</TableCell>
                <TableCell>{item.know_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginate
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        rows={totalSize}
        page={page - 1}
        handleChangePage={onMovePage}
      />
    </Paper>
  )
}

export default List
