import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete'
import PencilIcon from '@material-ui/icons/Edit'
import { useConfirm } from 'material-ui-confirm'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import { getSorting, stableSort } from '../../../util/TableUtils'
import { renderRoute } from '../../../util/utils'
import EnhancedTableHead from '../../components/TableHead'
import { remove } from '../KnowWayActions'

function createData(id, name, status, status_text) {
  return { id, name, status, status_text }
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Indicação',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Ações',
  },
]

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableStickHeader: {
    maxHeight: 'calc(100vh - 200px)',
  },
}))

function List(props) {
  const { permissions } = props
  const classes = useStyles()
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('value')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const rows = []

  props.items.map(item => {
    rows.push(createData(item.id, item.name, item.status, item.status_text))
  })

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const confirmDelete = id => {
    confirm({
      description: 'Após ser apagado o conteúdo não poderá ser recuperado?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      dispatch(remove(id))
    })
  }

  return (
    <Paper>
      <TableContainer className={classes.tableStickHeader}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          stickyHeader
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell padding="checkbox" />
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.id}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.status_text}</TableCell>
                    <TableCell>
                      {renderRoute(['update-know-way'], permissions) && (
                        <IconButton
                          aria-label="edit"
                          component={Link}
                          to={`/knowWay/${row.id}/edit`}
                        >
                          <PencilIcon />
                        </IconButton>
                      )}
                      {renderRoute(['delete-know-way'], permissions) && (
                        <IconButton>
                          <DeleteIcon onClick={() => confirmDelete(row.id)} />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage="Linhas por Página"
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        labelDisplayedRows={({ from, to, count }) =>
          `Mostrando linhas ${from}-${to} de ${count}`
        }
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default withRouter(List)
