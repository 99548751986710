import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'

import styles from '../../../resources/theme/users'
import { billPaid, edit } from '../BillingActions'

import Form from './FormClose'

function Transition(props) {
  return <Slide direction="up" {...props} />
}

function CloseDetails(props) {
  const { classes, match, history } = props
  const dispatch = useDispatch()

  const onSubmit = values => {
    const { id } = match.params
    const data = {
      ...values,
      actual_payment_date: values.actual_payment_date
        ? format(new Date(values.actual_payment_date), 'yyyy-MM-dd')
        : '',
    }
    dispatch(billPaid(id, data)).then(data => {
      if (data && data.code === 200) {
        history.push('/billing')
      }
    })
  }

  const onClose = () => {
    history.push('/billing')
  }

  useEffect(() => {
    dispatch(edit(match.params.id))
  }, [])

  const item = useSelector(state => state.billing.editingBilling)

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Detalhes do pagamento
          </Typography>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Form onClose={onClose} onSubmit={onSubmit} item={item} />
    </Dialog>
  )
}

export default withStyles(styles)(CloseDetails)
