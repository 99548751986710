import React from 'react'

function Infos({ marker }) {
  return (
    <>
      <p>{`Cliente: ${marker.client || ''}`}</p>
      <p>{`Tipo da coleta: ${marker.type}`}</p>
      <p>{`Latitude: ${marker.latitude}`}</p>
      <p>{`Longitude: ${marker.longitude}`}</p>
      <p>{`Data: ${marker.date}`}</p>
    </>
  )
}

export default Infos
