import {
  DialogActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import { DatePicker } from '@material-ui/pickers'
import { format, parseISO } from 'date-fns'
import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch } from 'react-redux'

import styles from '../../../resources/theme/users'
import { findCep } from '../ClientActions'

function Form(props) {
  const { data, onClose, onSubmit, notShowPost, children } = props
  const dispatch = useDispatch()

  const [form, updateForm] = useState({
    id: '',
    name: '',
    person_type: '',
    birthday: null,
    cep: '',
    street: '',
    complement: '',
    number: '',
    city: '',
    uf: '',
    neighborhood: '',
    phone: '',
    phone2: '',
    email: '',
    cpf_cnpj: '',
    client_since: null,
    // knowWay: '',
    profile: '',
  })

  const updateField = React.useCallback(name => e => {
    updateForm({
      ...form,
      [name]: e.target.value,
    })
  })

  const handleRadio = e => {
    updateForm({
      ...form,
      person_type: e.target.value,
    })
  }

  const handleClose = () => onClose && onClose()

  const onSubmitForm = e => {
    e.preventDefault()
    onSubmit && onSubmit(form)
  }

  const onSubmitAndRedirect = e => {
    e.preventDefault()
    onSubmit && onSubmit(form, true)
  }

  const buscaCEP = () => {
    const cep = form.cep?.replace(/[^\d]/g, '')
    cep &&
      dispatch(findCep(cep)).then(resp => {
        if (resp && resp.code === 200) {
          const { items } = resp.data
          if (items) {
            updateForm({
              ...form,
              neighborhood: items?.bairro,
              city: items?.cidade,
              uf: items?.uf,
              street: items?.logradouro,
            })
          }
        }
      })
  }

  useEffect(() => {
    updateForm({
      ...form,
      ...data,
      birthday: data.birthday ? parseISO(data.birthday) : null,
    })
  }, [data])

  return (
    <ValidatorForm /* onSubmit={onSubmitForm} */>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12} lg={4}>
            <TextValidator
              validators={['required']}
              errorMessages={['Campo Obrigatório']}
              onChange={updateField('name')}
              label="Nome"
              fullWidth
              value={form.name}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            lg={4}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <FormControl component="fieldset">
              <FormLabel component="legend">Tipo do Cliente</FormLabel>
              <div>
                <RadioGroup
                  row
                  aria-label="position"
                  onChange={handleRadio}
                  value={form.person_type}
                  name="position"
                  defaultValue="top"
                >
                  <FormControlLabel
                    value="pj"
                    control={<Radio color="primary" />}
                    label="Pessoa Juridica"
                  />
                  <FormControlLabel
                    value="pf"
                    control={<Radio color="primary" />}
                    label="Pessoa Física"
                  />
                </RadioGroup>
              </div>
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={12} lg={4}>
            <DatePicker
              clearable
              value={form.birthday}
              label="Aniversário"
              fullWidth
              views={['date', 'month']}
              clearLabel="Limpar"
              invalidDateMessage="Data inválida"
              autoOk
              onChange={date => updateForm({ ...form, birthday: date })}
              format="d MMMM"
              // format="dd/MM/yyyy"
              // formatDate={date => format(new Date(date), 'MM/dd/yyyy H:i:s')}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={4}>
            <InputMask
              mask="99.999-999"
              maskChar=""
              value={form.cep}
              onChange={updateField('cep')}
              onBlur={() => buscaCEP()}
            >
              {inputProps => (
                <TextField {...inputProps} label="CEP" fullWidth />
              )}
            </InputMask>
          </Grid>
          <Grid item sm={6} xs={12} lg={4}>
            <TextField
              onChange={updateField('street')}
              label="Endereço"
              fullWidth
              value={form.street}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={4}>
            <TextField
              onChange={updateField('number')}
              label="Número"
              fullWidth
              value={form.number}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={4}>
            <TextField
              onChange={updateField('neighborhood')}
              label="Bairro"
              fullWidth
              value={form.neighborhood}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={4}>
            <TextField
              onChange={updateField('complement')}
              label="Complemento"
              fullWidth
              value={form.complement}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={4}>
            <TextField
              onChange={updateField('city')}
              label="Cidade"
              fullWidth
              value={form.city}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={4}>
            <TextField
              onChange={updateField('uf')}
              label="UF"
              fullWidth
              value={form.uf}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={4}>
            <InputMask
              mask={
                form.phone?.replace(/[^\d]/g, '').length <= 10
                  ? '(99) 9999-9999?'
                  : '(99) 99999-9999'
              }
              formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
              maskChar=""
              value={form.phone}
              onChange={e => {
                updateForm({
                  ...form,
                  phone: e.target.value.replace(/[^\d]/g, ''),
                })
              }}
            >
              {inputProps => (
                <TextValidator
                  {...inputProps}
                  label="Telefone 1"
                  fullWidth
                  validators={[
                    'matchRegexp:^\\(?[0-9]{2,3}\\)? ?[0-9]{4,5}-?[0-9]{4}$',
                  ]}
                  errorMessages={['Telefone inválido']}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item sm={6} xs={12} lg={4}>
            <InputMask
              mask={
                form.phone2?.replace(/[^\d]/g, '').length <= 10
                  ? '(99) 9999-9999?'
                  : '(99) 99999-9999'
              }
              formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
              maskChar=""
              value={form.phone2}
              onChange={e => {
                updateForm({
                  ...form,
                  phone2: e.target.value.replace(/[^\d]/g, ''),
                })
              }}
            >
              {inputProps => (
                <TextValidator
                  {...inputProps}
                  label="Telefone 2"
                  fullWidth
                  validators={[
                    'matchRegexp:^\\(?[0-9]{2,3}\\)? ?[0-9]{4,5}-?[0-9]{4}$',
                  ]}
                  errorMessages={['Telefone inválido']}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item sm={6} xs={12} lg={4}>
            <TextValidator
              validators={['isEmail']}
              errorMessages={['O campo não representa um email válido']}
              onChange={updateField('email')}
              label="E-mail"
              fullWidth
              value={form.email}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={4}>
            <InputMask
              mask={
                form.cpf_cnpj?.replace(/[^\d]/g, '').length <= 11
                  ? '999.999.999-99?'
                  : '99.999.999/9999-99'
              }
              maskChar=""
              formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
              value={form.cpf_cnpj}
              onChange={updateField('cpf_cnpj')}
            >
              {inputProps => (
                <TextValidator
                  {...inputProps}
                  validators={[
                    'matchRegexp:^([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})$',
                  ]}
                  errorMessages={['CPF/CNPJ Inválido']}
                  label="CPF/CNPJ"
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>
          <Grid item sm={6} xs={12} lg={4}>
            <DatePicker
              clearable
              value={form.client_since}
              label="Cliente desde"
              fullWidth
              orientation="landscape"
              views={['date', 'month', 'year']}
              clearLabel="Limpar"
              invalidDateMessage="Data inválida"
              autoOk
              onChange={date => updateForm({ ...form, client_since: date })}
              format="dd/MM/yyyy"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder="Perfil do Cliente"
              value={form.profile}
              onChange={updateField('profile')}
              variant="outlined"
              multiline
              fullWidth
              rows={5}
              rowsMax={10}
            />
          </Grid>
        </Grid>

        <>{children}</>
      </DialogContent>

      <DialogActions>
        {notShowPost && (
          <Button color="primary" type="button" onClick={onSubmitAndRedirect}>
            Salvar e agendar
          </Button>
        )}
        <Button color="primary" onClick={onSubmitForm}>
          Salvar
        </Button>
        {!notShowPost && <Button onClick={handleClose}>Cancelar</Button>}
      </DialogActions>
    </ValidatorForm>
  )
}

export default withStyles(styles)(Form)
