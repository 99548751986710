import React from 'react'
import { Backdrop, withStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

import styles from '../../resources/theme/global'

function useBackdrop({ classes, open }) {
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default React.memo(withStyles(styles)(useBackdrop))
