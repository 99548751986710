import { IconButton } from '@material-ui/core'
import Brightness4 from '@material-ui/icons/Brightness4'
import Brightness7 from '@material-ui/icons/Brightness7'
import React from 'react'

function themeSwitcher(props) {
  const { theme, toogleTheme } = props
  return (
    <IconButton
      color="secondary"
      aria-label="switch-thepe"
      component="span"
      onClick={toogleTheme}
    >
      {theme?.icon === 'theme' ? <Brightness4 /> : <Brightness7 />}
    </IconButton>
  )
}

export default themeSwitcher
