import { FETCH_MONITORING_MAP, FETCH_ATTENDANTS } from './MonitoringAction'

const INITIAL_STATE = {
  attendants: [],
  loading: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_MONITORING_MAP.SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case FETCH_ATTENDANTS.SUCCESS:
      return {
        ...state,
        attendants: action.payload.attendants,
        loading: false,
      }
    case FETCH_MONITORING_MAP.ACTION:
      return {
        ...state,
      }
    case FETCH_MONITORING_MAP.FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
