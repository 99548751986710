import {
  Badge,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SyncIcon from '@material-ui/icons/Sync'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { fetchDashboard, eraseDashboardFilters } from '../HomeActions'
import styles from '../../../resources/theme/global'
import { renderRoute } from '../../../util/utils'

import Cards from './Cards'
import Filter from './Filters'

function Home(props) {
  const { classes } = props
  const refreshing = useSelector(state => state.dashboard.refreshing)
  const filters = useSelector(state => state.dashboard.filters)
  const readyItems = useSelector(state => state.dashboard.readyCount)
  const readyItemsLaundry = useSelector(
    state => state.dashboard.readyLaundryCount
  )
  const permissions = useSelector(state => state.auth.permissions)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchDashboard())
    dispatch(eraseDashboardFilters())

    return () => {
      dispatch(eraseDashboardFilters())
    }
  }, [])

  const refresh = () => {
    dispatch(fetchDashboard(filters))
  }

  return (
    <>
      <Grid
        container
        className={classes.pageHead}
        spacing={1}
        alignItems="center"
      >
        <Grid item xs>
          <Typography variant="h4" color="secondary">
            Dashboard
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            component={Link}
            to="/details/late"
            className={classes.buttonDanger}
          >
            Items Atrasados
          </Button>
        </Grid>

        {renderRoute(['read-ready-items-laundry'], permissions) && (
          <Grid item>
            <Badge
              badgeContent={readyItemsLaundry}
              showZero="false"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              color="primary"
              max={9999}
            >
              <Button
                variant="outlined"
                component={Link}
                to="/details/laundry/ready"
                color="primary"
              >
                Items de lavanderia
              </Button>
            </Badge>
          </Grid>
        )}
        {renderRoute(['read-ready-items'], permissions) && (
          <Grid item>
            <Badge
              badgeContent={readyItems}
              showZero="false"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              color="primary"
              max={9999}
            >
              <Button
                variant="outlined"
                component={Link}
                to="/details/ready"
                color="primary"
              >
                OSs prontas para entrega
              </Button>
            </Badge>
          </Grid>
        )}
        <Grid item>
          <Filter classes={classes} />
        </Grid>
        <Grid item>
          <Tooltip title="Atualizar">
            <IconButton color="secondary" onClick={refresh}>
              <SyncIcon
                className={classNames({
                  [classes.btnRefreshing]: refreshing,
                })}
              />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Cards {...props} />
    </>
  )
}

export default withStyles(styles)(Home)
