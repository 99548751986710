import React from 'react'
import { Grid, TextField } from '@material-ui/core'
import InputMask from 'react-input-mask'

function Metro2({ updateChildren, chave, item }) {
  return (
    <>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={4}>
          <InputMask
            mask="????.??"
            style={{ maxWidth: '100px' }}
            formatChars={{ 9: '[0-9]', '?': '[0-9/.]', '.': '[.]' }}
            maskChar=""
            value={item.options.base}
            onChange={e => updateChildren(chave, e.target.value, 'base', true)}
            inputProps={{ style: { textAlign: 'center' } }}
          >
            {inputProps => (
              <TextField
                {...inputProps}
                label="Base"
                validators={['required']}
                errorMessages={['Campo necessário']}
                fullWidth
              />
            )}
          </InputMask>
        </Grid>
        {/* X */}
        <Grid item xs={4}>
          <InputMask
            mask="????.??"
            style={{ maxWidth: '100px' }}
            formatChars={{ 9: '[0-9]', '?': '[0-9/.]', '.': '[.]' }}
            maskChar=""
            value={item.options.altura}
            onChange={e =>
              updateChildren(chave, e.target.value, 'altura', true)
            }
            inputProps={{ style: { textAlign: 'center' } }}
          >
            {inputProps => (
              <TextField
                {...inputProps}
                label="Altura"
                validators={['required']}
                errorMessages={['Campo necessário']}
                fullWidth
              />
            )}
          </InputMask>
        </Grid>
      </Grid>
    </>
  )
}

export default Metro2
