/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import { MenuItem } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useState } from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'
import { endOfDay, format, startOfDay } from 'date-fns'

import BadgeGlobal from '../../components/Badge'
import { fetchClientsRelatoryInit } from '../ClientsRelatoryActions'

export default function Filter(props) {
  const items = useSelector(state => state.clients_relatory.items)
  const companies = useSelector(state => state.clients_relatory.companies)

  const dispatch = useDispatch()
  const { classes } = props
  const [form, setForm] = useState({
    company: '',
    name: '',
    last_service_start: null,
    last_service_end: null,
  })

  const onSubmit = e => {
    e.preventDefault()
    const lastServiceStart = form.last_service_start
      ? format(
          startOfDay(new Date(form.last_service_start)),
          'yyyy-MM-dd HH:mm:ss'
        )
      : null
    const lastServiceEnd = form.last_service_end
      ? format(endOfDay(new Date(form.last_service_end)), 'yyyy-MM-dd HH:mm:ss')
      : null

    const data = {
      ...form,
      last_service_start: lastServiceStart,
      last_service_end: lastServiceEnd,
    }
    dispatch(fetchClientsRelatoryInit(null, data, null))
  }

  return (
    <Tooltip title="Filter list">
      <PopupState variant="popover" popupId="demo-popup-popover">
        {popupState => (
          <div>
            <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
              <BadgeGlobal form={form} items={items}>
                <FilterListIcon />
              </BadgeGlobal>
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2} className={classes.popover}>
                <div className={classes.root}>
                  <ValidatorForm className={classes.form} onSubmit={onSubmit}>
                    <Grid
                      container
                      className={classes.gridContainer}
                      spacing={1}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={6}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, name: e.target.value })
                          }
                          margin="dense"
                          variant="outlined"
                          label="Nome"
                          className={classes.inputFilter}
                          fullWidth
                          value={form.name}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, company: e.target.value })
                          }
                          select
                          label="Empresa"
                          margin="dense"
                          variant="outlined"
                          className={classes.inputFilter}
                          fullWidth
                          value={form.company}
                        >
                          <MenuItem key="" value="">
                            Selecione...
                          </MenuItem>
                          {companies?.map(company => (
                            <MenuItem key={company.id} value={company.id}>
                              {company.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={6}>
                        <KeyboardDatePicker
                          clearable
                          value={form.last_service_start}
                          label="Último Serviço Inicial"
                          fullWidth
                          margin="dense"
                          inputVariant="outlined"
                          views={['date']}
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          onChange={date =>
                            setForm({ ...form, last_service_start: date })
                          }
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <KeyboardDatePicker
                          clearable
                          value={form.last_service_end}
                          label="Último Serviço Final"
                          fullWidth
                          margin="dense"
                          inputVariant="outlined"
                          views={['date']}
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          onChange={date =>
                            setForm({ ...form, last_service_end: date })
                          }
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item xs>
                        <Button
                          type="submit"
                          color="primary"
                          fullWidth
                          variant="contained"
                          className={classes.submit}
                          onClick={popupState.close}
                        >
                          <SearchIcon />
                          {` Filtrar`}
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                </div>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </Tooltip>
  )
}
