import React, { useEffect, useState } from 'react'
import { Button, Grid, Typography, withStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLossReason } from '../LossReasonActions'
import { renderRouteRole } from '../../../../util/utils'
import { Route } from 'react-router-dom/cjs/react-router-dom'
import styles from '../../../../resources/theme/global'
import Filter from './Filter'
import List from './List'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import createEdit from './create-edit'

const LossReason = props => {
  const { classes } = props

  const role = useSelector(state => state.auth.role)
  const dispatch = useDispatch()

  const [form, setForm] = useState({
    per_page: 10,
    page: 1,
    description: '',
    status: '',
  })

  useEffect(() => {
    dispatch(fetchLossReason(form))
  }, [form])

  return (
    <>
      <Grid
        container
        className={classes.pageHead}
        spacing={1}
        alignItems="center"
      >
        <Grid item xs>
          <Typography variant="h4" color="secondary">
            Motivo de cancelamento
          </Typography>
        </Grid>
        <Grid item>
          {renderRouteRole(['lavanderia', 'administrador'], role) && (
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to="/crm/loss-reason/create-edit/new"
            >
              <AddCircleOutlineIcon className={classes.btnIcon} /> Cadastrar
            </Button>
          )}
        </Grid>
        <Grid item>
          <Filter classes={classes} form={form} setForm={setForm} />
        </Grid>
        <Grid item xs={12}>
          <List classes={classes} setForm={setForm} form={form} />
        </Grid>
      </Grid>

      {renderRouteRole(['lavanderia', 'administrador'], role) && (
        <Route path="/crm/loss-reason/create-edit/:id" component={createEdit} />
      )}
    </>
  )
}

export default withStyles(styles)(LossReason)
