import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const FETCH_HISTORIC = createAsyncConst('FETCH_HISTORIC')

export function fetch(barCode) {
  return dispatch => {
    dispatch(action(FETCH_HISTORIC.ACTION))
    return axios
      .get(`${config.API_URL}/service-item/get-by-barcode/${barCode}`)
      .then(resp => {
        dispatch(action(FETCH_HISTORIC.SUCCESS, { data: resp.data }))
        return resp.data
      })
      .catch(e => dispatch(actionFailed(FETCH_HISTORIC, e)))
  }
}
