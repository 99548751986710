import { FETCH_SUPPLIER, EDIT_SUPPLIER } from './SupplierActions'

const INITIAL_STATE = {
  items: [],
  roles: [],
  filters: [],
  editingSupplier: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SUPPLIER.SUCCESS:
      return {
        ...state,
        items: action.payload.data,
      }
    case EDIT_SUPPLIER.SUCCESS:
      return {
        ...state,
        editingSupplier: action.payload,
      }
    default:
      return state
  }
}

export const getSupplierListData = state => {
  return {
    items: state.supplier.items,
  }
}

export const getEditingSupplier = state => {
  return state.supplier.editingSupplier
}
