import React, { useMemo } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import IconButton from '@material-ui/core/IconButton'
import RestoreIcon from '@material-ui/icons/Restore'
import Tooltip from '@material-ui/core/Tooltip'
import { DialogContentText } from '@material-ui/core'
import { Link } from 'react-router-dom'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Modal = props => {
  const {
    open,
    handleClose,
    attendants,
    currentAttendant,
    currentEvent,
    changeAttendant,
    handleCancel,
  } = props

  // console.log(currentEvent)

  const disabled = useMemo(() => {
    if (!currentEvent?.copy) return currentEvent?.lastStatus?.status_id > 3
    return currentEvent?.lastStatus?.status_id > 8
  }, currentEvent)

  const renderOsButton = () => {
    return (
      <Button
        component={Link}
        to={
          currentEvent.isLaundry
            ? `/laundry-os/${currentEvent.serviceId}/details`
            : `/attendance/${currentEvent.serviceId}/edit`
        }
        color="primary"
      >
        {`OS ${currentEvent.serviceId}`}
      </Button>
    )
  }
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="modal-change-owner-title"
        aria-describedby="modal-change-owner-description"
      >
        <DialogTitle id="modal-change-owner-title">
          Editar agendamento
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {currentEvent?.title}
            {currentEvent?.schedulingInformation && (
              <p>{`Inf. do agendamento: ${currentEvent?.schedulingInformation}`}</p>
            )}
          </DialogContentText>
          <Autocomplete
            id="edit-responsable"
            options={attendants}
            disableClearable
            disabled={disabled}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={option => option.name}
            value={currentAttendant || null}
            renderInput={params => (
              <TextField {...params} label="Atendente" variant="outlined" />
            )}
            onChange={(event, newValue) => {
              changeAttendant(currentEvent, newValue)
            }}
          />
        </DialogContent>
        <DialogActions>
          {currentEvent?.serviceId && renderOsButton()}
          <Tooltip title="Restaurar" aria-label="Restaurar" placement="left">
            <IconButton
              color="inherit"
              onClick={() =>
                handleCancel(currentEvent.id, currentEvent.copy || null)
              }
              aria-label="Close"
              disabled={disabled}
            >
              <RestoreIcon />
            </IconButton>
          </Tooltip>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default React.memo(Modal)
