import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import {
  Slide,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableCell,
  Button,
  TableContainer,
} from '@material-ui/core'

import { dateFormatedPT, exportFormatedMoney } from '../../../util/utils'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  tableStickHeader: {
    maxHeight: 'calc(100vh - 100px)',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function FullScreenDialog(props) {
  const { open, close, serviceId, details, filters, detailsReport } = props
  const classes = useStyles()

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Relatório detalhado
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => detailsReport(filters, serviceId)}
          >
            Relatório
          </Button>
        </Toolbar>
      </AppBar>
      <TableContainer className={classes.tableStickHeader}>
        <Table stickyHeader size="medium" className={classes.tableStyle}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>ID item</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>OS</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Tipo de Serviço</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Técnico</TableCell>
              <TableCell>Atendente</TableCell>
              <TableCell>Empresa</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Array.isArray(details) &&
              details.map(item => (
                <React.Fragment key={item.service_items_id}>
                  <TableRow>
                    <TableCell />
                    <TableCell>{item.service_items_id}</TableCell>
                    <TableCell>{dateFormatedPT(item.date)}</TableCell>
                    <TableCell>{item.attendance_id}</TableCell>
                    <TableCell>{item.client_name}</TableCell>
                    <TableCell>{item.service_type_name}</TableCell>
                    <TableCell>
                      {item.final_value
                        ? exportFormatedMoney(item.final_value)
                        : '-'}
                    </TableCell>
                    <TableCell>{item.tec_name}</TableCell>
                    <TableCell>{item.attendant_name}</TableCell>
                    <TableCell>{item.company_name}</TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  )
}
