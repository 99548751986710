import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const REMOVE_USER = createAsyncConst('REMOVE_USER')
export const SUBMIT_ROLE = createAsyncConst('SUBMIT_ROLE')
export const FETCH_ROLE = createAsyncConst('FETCH_ROLE')
export const EDIT_ROLE = createAsyncConst('EDIT_ROLE')
export const DUPLICATE_ROLE = createAsyncConst('DUPLICATE_ROLE')

export function fetchRoles(page, filters = []) {
  return dispatch => {
    dispatch(action(FETCH_ROLE.ACTION))
    return axios
      .get(`${config.API_URL}/roles`, {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => {
        dispatch(action(FETCH_ROLE.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_ROLE, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_ROLE.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/roles/${id}`)
      .then(resp => {
        return resp.data.data
      })
      .then(data => dispatch(action(EDIT_ROLE.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(EDIT_ROLE, e)))
  }
}

function submit(values, method) {
  const id = values.id ? `/${values.id}` : ''

  return dispatch => {
    dispatch(action(SUBMIT_ROLE.ACTION))
    return axios[method](`${config.API_URL}/roles${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_ROLE.SUCCESS, data))
        dispatch(fetchRoles())
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_ROLE, e)))
  }
}

export function post(values) {
  return submit(values, 'post')
}

export function update(values) {
  return submit(values, 'put')
}

export function remove(id) {
  return dispatch => {
    dispatch(action(REMOVE_USER.ACTION))
    return axios
      .delete(`${config.API_URL}/roles/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(REMOVE_USER.SUCCESS, data))
        dispatch(fetchRoles())
      })
      .catch(e => actionFailed(REMOVE_USER.FAILURE, e))
  }
}

export function duplicateRole(values, id) {
  return dispatch => {
    dispatch(action(DUPLICATE_ROLE.ACTION))
    return axios
      .post(`${config.API_URL}/roles/duplicate/${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(DUPLICATE_ROLE.SUCCESS, data))
        dispatch(fetchRoles())
        return data
      })
      .catch(e => dispatch(actionFailed(DUPLICATE_ROLE, e)))
  }
}
