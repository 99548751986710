import React from 'react'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
  Link,
} from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import classnames from 'classnames'
import CountUp from 'react-countup'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link as RouterLink } from 'react-router-dom'

import styles from '../../../resources/theme/global'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1),
  },
  differenceValueNegative: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
  },
  noDifference: {
    color: colors.grey[900],
    marginRight: theme.spacing(1),
  },
}))

const ReceiptCard = ({
  tipo,
  positive,
  val1,
  val2,
  val3,
  link1,
  link2,
  link3,
}) => {
  const classes = useStyles()

  const contUp = money => {
    return (
      <CountUp
        end={money}
        duration={0.7}
        decimals={2}
        decimal=","
        prefix="R$"
        separator="."
      />
    )
  }

  return (
    <Card className={classes.root} item>
      <CardContent>
        <Typography color="secondary" gutterBottom variant="h6">
          {tipo}
        </Typography>

        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Link
              component={RouterLink}
              to={link1}
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              Em atraso
            </Link>
            <Typography
              color="textPrimary"
              variant="h5"
              className={classnames({
                [classes.differenceValue]: positive,
                [classes.differenceValueNegative]: !positive,
              })}
            >
              {contUp(val1)}
            </Typography>
          </Grid>

          <Divider orientation="vertical" flexItem />

          <Grid item>
            <Link
              component={RouterLink}
              to={link2}
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              A Vencer
            </Link>
            <Typography
              color="textPrimary"
              variant="h5"
              className={classnames({
                [classes.differenceValue]: positive,
                [classes.differenceValueNegative]: !positive,
              })}
            >
              {contUp(val2)}
            </Typography>
          </Grid>

          <Divider orientation="vertical" flexItem />

          <Grid item>
            <Link
              component={RouterLink}
              to={link3}
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              Total
            </Link>
            <Typography
              color="textPrimary"
              variant="h5"
              className={classnames({
                [classes.differenceValue]: positive,
                [classes.differenceValueNegative]: !positive,
              })}
            >
              {contUp(val3)}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(ReceiptCard)
