import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import NoteAddIcon from '@material-ui/icons/NoteAdd'

import styles from '../../../resources/theme/global'
import { renderRoute } from '../../../util/utils'
import { fetchServiceType, makeRelatory } from '../ServiceTypeActions'
import { getServiceTypeListData } from '../ServiceTypeReducer'

import Edit from './Edit'
import Filter from './Filter'
import List from './List'
import New from './New'

class ServiceCategory extends Component {
  componentDidMount() {
    const { fetchServiceType } = this.props
    fetchServiceType()
  }

  render() {
    const { classes, permissions, filters, makeRelatory } = this.props

    return (
      <>
        <Grid
          container
          className={classes.pageHead}
          spacing={1}
          alignItems="center"
        >
          <Grid item xs>
            <Typography variant="h4" color="secondary">
              Tipos de Serviço
            </Typography>
          </Grid>
          <Grid item>
            <Filter classes={classes} />
          </Grid>
          <Grid item>
            {renderRoute(['create-service-types'], permissions) && (
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to="/service-type/new"
              >
                <AddCircleOutlineIcon className={classes.btnIcon} /> Cadastrar
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button
              color="primary"
              type="button"
              onClick={() => makeRelatory(filters)}
            >
              <NoteAddIcon className={classes.btnIcon} /> Relatório
            </Button>
          </Grid>
        </Grid>

        {renderRoute(['create-service-types'], permissions) && (
          <Route path="/service-type/new" component={New} />
        )}
        {renderRoute(['update-service-types'], permissions) && (
          <Route path="/service-type/:id/edit" component={Edit} />
        )}

        <List {...this.props} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...getServiceTypeListData(state),
  filters: state.serviceType.filters,
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchServiceType,
      makeRelatory,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ServiceCategory))
