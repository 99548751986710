/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useState } from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'
import { endOfDay, format, startOfDay } from 'date-fns'

import BadgeGlobal from '../../components/Badge'
import { fetchProspectsRelatoryInit } from '../ProspectsRelatoryActions'
import SelectField from '../../components/SelectField'

export default function Filter(props) {
  const items = useSelector(state => state.prospects_relatory.items)

  const services_type = useSelector(
    state => state.prospects_relatory.services_type
  )

  const dispatch = useDispatch()
  const { classes } = props
  const [form, setForm] = useState({
    client_name: '',
    service_type: '',
    date_start: null,
    date_end: null,
  })

  const updateFun = React.useCallback(
    (name, value) => {
      setForm({
        ...form,
        [name]: value,
      })
    },
    [form]
  )

  const onSubmit = e => {
    e.preventDefault()
    const dateStart = form.date_start
      ? format(startOfDay(new Date(form.date_start)), 'yyyy-MM-dd HH:mm:ss')
      : null
    const dateEnd = form.date_end
      ? format(endOfDay(new Date(form.date_end)), 'yyyy-MM-dd HH:mm:ss')
      : null

    const data = {
      ...form,
      service_type: form.service_type?.id,
      date_start: dateStart,
      date_end: dateEnd,
    }
    dispatch(fetchProspectsRelatoryInit(null, data, null))
  }

  return (
    <Tooltip title="Filter list">
      <PopupState variant="popover" popupId="demo-popup-popover">
        {popupState => (
          <div>
            <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
              <BadgeGlobal form={form} items={items}>
                <FilterListIcon />
              </BadgeGlobal>
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2} className={classes.popover}>
                <div className={classes.root}>
                  <ValidatorForm className={classes.form} onSubmit={onSubmit}>
                    <Grid
                      container
                      className={classes.gridContainer}
                      spacing={1}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, client_name: e.target.value })
                          }
                          label="Nome do Cliente"
                          className={classes.inputFilter}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          value={form.client_name}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <SelectField
                          options={services_type}
                          setForm={updateFun}
                          data={form.service_type}
                          name="service_type"
                          variant="outlined"
                          margin="dense"
                          label="Tipo de Serviço"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <KeyboardDatePicker
                          clearable
                          value={form.date_start}
                          label="Data Inicial"
                          fullWidth
                          margin="dense"
                          inputVariant="outlined"
                          views={['date']}
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          onChange={date =>
                            setForm({ ...form, date_start: date })
                          }
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <KeyboardDatePicker
                          clearable
                          value={form.date_end}
                          label="Data Final"
                          fullWidth
                          margin="dense"
                          inputVariant="outlined"
                          views={['date']}
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          onChange={date =>
                            setForm({ ...form, date_end: date })
                          }
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item xs>
                        <Button
                          type="submit"
                          color="primary"
                          fullWidth
                          variant="contained"
                          className={classes.submit}
                          onClick={popupState.close}
                        >
                          <SearchIcon />
                          {` Filtrar`}
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                </div>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </Tooltip>
  )
}
