import React from 'react'

import GlobalList from '../../components/GlobalList'

function createData(id, name, default_value, value) {
  return {
    id,
    name,
    default_value,
    value,
    update: 'update-settings',
    link_update: `/config/${id}/edit`,
  }
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'default_value',
    numeric: true,
    disablePadding: false,
    label: 'Valor Padrão',
  },
  {
    id: 'value',
    numeric: true,
    disablePadding: false,
    label: 'Valor Atual',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Ações',
  },
]

function List(props) {
  const { items } = props
  const rows = []

  items.map(item => {
    return rows.push(createData(item.id, item.slug, item.default, item.value))
  })

  return (
    <GlobalList {...props} headCells={headCells} rows={rows} orderField="id" />
  )
}

export default List
