import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { format } from 'date-fns'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useState } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

const Filter = ({ classes, form = {}, setForm = () => {} }) => {
  const triggers = useSelector(state => state.crmPanel.triggers)

  const [filter, setFilter] = useState({
    limit_date: new Date(),
  })

  const handleChangeDate = date => {
    setFilter({
      ...form,
      limit_date: date,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    const formData = e.currentTarget
    const data = new FormData(formData)

    data.append('date_end', format(filter.limit_date, 'yyyy-MM-dd'))

    const entries = data.entries()
    const values = Object.fromEntries(entries)

    setForm({
      ...form,
      ...values,
    })
  }

  return (
    <Tooltip title="Filter list">
      <PopupState variant="popover" popupId="demo-popup-popover">
        {popupState => (
          <div>
            <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
              <FilterListIcon />
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2} className={classes.popover}>
                <div className={classes.root}>
                  <form className={classes.form} onSubmit={handleSubmit}>
                    <Grid
                      container
                      className={classes.gridContainer}
                      spacing={1}
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        <TextField
                          label="Cliente"
                          margin="dense"
                          className={classes.inputFilter}
                          variant="outlined"
                          fullWidth
                          name="client"
                          key={`textField-client-${form?.client}`}
                          defaultValue={form?.client}
                        />
                      </Grid>

                      {/* <Grid item xs={12}>
                        <TextField
                          label=""
                          type="number"
                          margin="dense"
                          className={classes.inputFilter}
                          variant="outlined"
                          fullWidth
                          name="os"
                          key={`textField-os-${form?.os}`}
                          defaultValue={form?.os}
                        />
                      </Grid> */}

                      <Grid item xs={12}>
                        <TextField
                          select
                          label="Filtre por Tipo"
                          margin="dense"
                          className={classes.inputFilter}
                          variant="outlined"
                          fullWidth
                          name="trigger_id"
                          key={`textField-trigger_id-${form?.trigger_id}`}
                          defaultValue={form?.trigger_id}
                        >
                          <MenuItem key="" value="">
                            Selecione...
                          </MenuItem>
                          {triggers?.map((trigger, index) => (
                            <MenuItem key={index} value={trigger?.id}>
                              {trigger?.reason}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={12}>
                        <KeyboardDatePicker
                          clearable
                          label="Data Limite"
                          fullWidth
                          value={filter.limit_date}
                          onChange={handleChangeDate}
                          name="limit_date"
                          views={['date']}
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item xs>
                        <Button
                          type="submit"
                          color="primary"
                          fullWidth
                          variant="contained"
                          className={classes.submit}
                          onClick={popupState.close}
                        >
                          <SearchIcon />
                          {` Filtrar`}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </Tooltip>
  )
}

export default Filter
