import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import FaceIcon from '@material-ui/icons/Face'
import BusinessIcon from '@material-ui/icons/Business'
import PaymentIcon from '@material-ui/icons/Payment'
import ListAltIcon from '@material-ui/icons/ListAlt'
import LockOpen from '@material-ui/icons/LockOpen'
import PersonIcon from '@material-ui/icons/Person'
import PostAddIcon from '@material-ui/icons/PostAdd'
import AddBoxIcon from '@material-ui/icons/AddBox'
import CakeIcon from '@material-ui/icons/Cake'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import PeopleIcon from '@material-ui/icons/People'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg'
import DateRangeIcon from '@material-ui/icons/DateRange'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import {
  AiFillSignal,
  AiOutlineAim,
  AiOutlineDashboard,
  AiOutlineRise,
} from 'react-icons/ai'
import ClassIcon from '@material-ui/icons/Class'
import PhoneIcon from '@material-ui/icons/Phone'
import LaundryIcon from '@material-ui/icons/LocalLaundryService'
import ContactsIcon from '@material-ui/icons/Contacts'
import RoomServiceIcon from '@material-ui/icons/RoomService'
import MoneyIcon from '@material-ui/icons/Money'
import DescriptionIcon from '@material-ui/icons/Description'
import TrackChangesIcon from '@material-ui/icons/TrackChanges'
import HowToRegRoundedIcon from '@material-ui/icons/HowToRegRounded'
import CameraAltIcon from '@material-ui/icons/CameraAlt'
import React, { useMemo } from 'react'
import { FaTape } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import EventIcon from '@material-ui/icons/Event'
import {
  GiMoneyStack,
  GiTakeMyMoney,
  GiRolledCloth,
  GiBank,
} from 'react-icons/gi'
import AttachMoney from '@material-ui/icons/AttachMoney'
import SettingsIcon from '@material-ui/icons/Settings'
import MapIcon from '@material-ui/icons/Map'
import Restore from '@material-ui/icons/RestorePageOutlined'

import { renderRoute } from '../../util/utils'

import ItemMenu from './ListItem'
import {
  Assessment,
  Dashboard,
  DeleteSweep,
  Receipt,
  TableChart,
} from '@material-ui/icons'

function Icons(props) {
  const permissions = useSelector(state => state.auth.permissions)
  const role = useSelector(state => state.auth.role) || {}
  const {
    classes,
    open,
    openRelatory,
    openFinance,
    openCrm,
    handleDrawerClose,
    handleClick,
    handleClickRelatory,
    handleClickFinance,
    handleClickCrm,
  } = props

  function renderIcons() {
    const items = [
      {
        route: '/knowWay',
        icon: <QuestionAnswerIcon color="secondary" />,
        title: 'Como Conheceu',
        role: ['read-know-way'],
      },
      {
        route: '/users',
        icon: <PersonIcon color="secondary" />,
        title: 'Usuários',
        role: ['read-users'],
      },
      {
        route: '/clients',
        icon: <FaceIcon color="secondary" />,
        title: 'Clientes',
        role: ['read-client'],
      },
      {
        route: '/roles',
        icon: <LockOpen color="secondary" />,
        title: 'Regras de usuário',
        userRoles: ['administrador'],
        role: ['wwre'],
      },
      {
        route: '/service-category',
        icon: <ListAltIcon color="secondary" />,
        title: 'Categorias de serviço',
        role: ['read-service-categories'],
      },
      {
        route: '/company-nfse',
        icon: <Receipt color="secondary" />,
        title: 'Lavanderias NFSe',
        role: ['read-service-categories'],
      },
      {
        route: '/measure-type',
        icon: <FaTape color="secondary" size={24} className={classes.iconRI} />,
        title: 'Unidades de medida',
        role: ['read-measure-types'],
      },
      {
        route: '/service-type',
        icon: <RoomServiceIcon color="secondary" />,
        title: 'Tipos de serviço',
        role: ['read-service-types'],
      },
      {
        route: '/payment-method',
        icon: <PaymentIcon color="secondary" />,
        title: 'Formas de pagamento',
        role: ['read-payment-methods'],
      },
      {
        route: '/laundry',
        icon: <LaundryIcon color="secondary" />,
        title: 'Lavanderias',
        role: ['read-laundry'],
      },
      {
        route: '/config',
        icon: <SettingsIcon color="secondary" />,
        title: 'Configurações',
        role: ['update-settings'],
      },
      {
        route: '/company',
        icon: <BusinessIcon color="secondary" />,
        title: 'Empresas',
        role: ['read-companies'],
      },
      {
        route: '/map',
        icon: <MapIcon color="secondary" />,
        title: 'Mapa de Lavanderia',
        role: ['read-laundry-map'],
      },
    ]

    const sorted = useMemo(
      () => items.sort((a, b) => a.title.localeCompare(b.title)),
      [items]
    )

    return sorted.map((item, i) => {
      return (
        (permissions.find(x => x.name == item.role[0]) ||
          (item.userRoles &&
            item.userRoles.filter(userRole => role.name === userRole).length >
              0)) && (
          <ItemMenu
            key={i}
            route={item.route}
            handle={handleDrawerClose}
            icon={item.icon}
            title={item.title}
          />
        )
      )
    })
  }

  function renderCrm() {
    const items = [
      {
        route: '/crm/triggers',
        icon: <LockOpen color="secondary" />,
        title: 'Gatilhos',
        userRoles: ['administrador', 'lavanderia'],
        role: ['wwre'],
      },
      {
        route: '/crm/loss-reason',
        icon: <DeleteSweep color="secondary" />,
        title: 'Mot. Cancelamento',
        role: ['read-attendances'],
      },
      {
        route: '/crm/panel',
        icon: <Dashboard color="secondary" />,
        title: 'Painel',
        userRoles: [
          'administrador',
          'lavanderia',
          'atendente',
          'AtendendeLavanderia',
          'atendente_crm',
        ],
        role: ['wwre'],
      },
      {
        route: '/crm/report/gerency',
        icon: <Assessment color="secondary" />,
        title: 'Relatório Gerencial',
        userRoles: [
          'administrador',
          'lavanderia',
          'atendente',
          'AtendendeLavanderia',
          'atendente_crm',
        ],
        role: ['wwre'],
      },
      {
        route: '/crm/report/leads',
        icon: <TableChart color="secondary" />,
        title: 'Relatório Leads',
        userRoles: [
          'administrador',
          'lavanderia',
          'atendente',
          'AtendendeLavanderia',
          'atendente_crm',
        ],
        role: ['wwre'],
      },
    ]

    const sorted = useMemo(
      () => items.sort((a, b) => a.title.localeCompare(b.title)),
      [items]
    )

    return sorted.map((item, i) => {
      return (
        (permissions.find(x => x.name == item.role[0]) ||
          (item.userRoles &&
            item.userRoles.filter(userRole => role.name === userRole).length >
              0)) && (
          <ItemMenu
            key={i}
            route={item.route}
            handle={handleDrawerClose}
            icon={item.icon}
            title={item.title}
          />
        )
      )
    })
  }

  function renderRelatories() {
    const items = [
      {
        route: '/relatory-birth',
        icon: <CakeIcon color="secondary" />,
        title: 'Rel. Aniversariantes',
        role: ['read-birth-relatory'],
      },
      {
        route: '/relatory-clients',
        icon: <PeopleIcon color="secondary" />,
        title: 'Rel. Clientes',
        role: ['read-clients-relatory'],
      },
      {
        route: '/relatory-prospects',
        icon: <FolderOpenIcon color="secondary" />,
        title: 'Rel. Prospects',
        role: ['read-prospects-relatory'],
      },
      {
        route: '/relatory-services',
        icon: <PermPhoneMsgIcon color="secondary" />,
        title: 'Rel. Atendimentos',
        role: ['read-attendances-relatory'],
      },
      {
        route: '/relatory-schedules',
        icon: <DateRangeIcon color="secondary" />,
        title: 'Rel. Agendamento',
        role: ['read-schedule-relatory'],
      },
      {
        route: '/relatory-service-category',
        icon: <ClassIcon color="secondary" />,
        title: 'Rel. Cat. Serviços',
        role: ['read-service-category-relatory'],
      },
      {
        route: '/relatory-team-produtivity',
        icon: <PeopleOutlineIcon color="secondary" />,
        title: 'Rel. Prod. Equipe',
        role: ['read-team-produtivity-relatory'],
      },
      {
        route: '/commission-report',
        icon: <HowToRegRoundedIcon color="secondary" />,
        title: 'Rel. Comissões',
        role: ['read-commission-report'],
      },
      {
        route: '/relatory-financial',
        icon: <AccountBalanceIcon color="secondary" />,
        title: 'Rel. Financeiro',
        role: ['read-financial-relatory'],
      },
      {
        route: '/bank-report',
        icon: <MoneyIcon color="secondary" />,
        title: 'Rel. Bancário',
        role: ['read-bank-report'],
      },
    ]

    const sorted = useMemo(
      () => items.sort((a, b) => a.title.localeCompare(b.title)),
      [items]
    )

    return sorted.map((item, i) => {
      return (
        permissions.find(x => x.name == item.role[0]) && (
          <ItemMenu
            key={i}
            route={item.route}
            handle={handleDrawerClose}
            icon={item.icon}
            title={item.title}
          />
        )
      )
    })
  }

  function renderFinance() {
    const items = [
      {
        route: '/supplier',
        icon: <ContactsIcon color="secondary" />,
        title: 'Contatos Financeiros',
        role: ['read-supplier'],
      },
      {
        route: '/billing-type',
        icon: <GiMoneyStack size={25} className={classes.iconRI} />,
        title: 'Tipos de conta',
        role: ['read-billing-type'],
      },
      {
        route: '/billing',
        icon: <GiTakeMyMoney size={25} className={classes.iconRI} />,
        title: 'Lançamento de contas',
        role: ['read-billing'],
      },
      {
        route: '/bank',
        icon: <GiBank size={25} className={classes.iconRI} />,
        title: 'Bancos',
        role: ['read-bank'],
      },
      // {
      //   route: '/payment-verification',
      //   icon: <GiReceiveMoney size={25} className={classes.iconRI} />,
      //   title: 'Verificar pagamentos',
      //   role: ['read-payment-verification'],
      // },
    ]

    const sorted = useMemo(
      () => items.sort((a, b) => a.title.localeCompare(b.title)),
      [items]
    )

    return sorted.map((item, i) => {
      return (
        permissions.find(x => x.name == item.role[0]) && (
          <ItemMenu
            key={i}
            route={item.route}
            handle={handleDrawerClose}
            icon={item.icon}
            title={item.title}
          />
        )
      )
    })
  }
  return (
    <List className={classes.listDrawer}>
      {renderRoute(['read-dashboard'], permissions) && (
        <ItemMenu
          route="/"
          handle={handleDrawerClose}
          icon={<AiOutlineDashboard size={25} className={classes.iconRI} />}
          title="Dashboard"
        />
      )}

      {renderRoute(['read-dashboard-leads'], permissions) && (
        <ItemMenu
          route="/dashboard-leads"
          handle={handleDrawerClose}
          icon={<AiOutlineRise size={25} className={classes.iconRI} />}
          title="Dashb. Leads"
        />
      )}

      {renderRoute(['read-financial-dashboard'], permissions) && (
        <ItemMenu
          route="/financial-dashboard"
          handle={handleDrawerClose}
          icon={<MonetizationOnIcon color="secondary" />}
          title="Dashb. Financeira"
        />
      )}

      {renderRoute(['create-attendances'], permissions) && (
        <ItemMenu
          route="/attendance"
          handle={handleDrawerClose}
          icon={<PhoneIcon color="secondary" />}
          title="Atendimentos"
        />
      )}

      {renderRoute(['read-schedule'], permissions) && (
        <ItemMenu
          route="/calendar"
          handle={handleDrawerClose}
          icon={<EventIcon color="secondary" />}
          title="Calendário"
        />
      )}

      {renderRoute(['read-laundry-os'], permissions) && (
        <ItemMenu
          route="/laundry-os"
          handle={handleDrawerClose}
          icon={<DescriptionIcon color="secondary" />}
          title="OSs de Lavanderia"
        />
      )}

      {renderRoute(['coordinates-list'], permissions) && (
        <ItemMenu
          route="/attendant-monitoring"
          handle={handleDrawerClose}
          icon={<TrackChangesIcon color="secondary" />}
          title="Monitoramento"
        />
      )}

      {renderRoute(['read-service-item-screen'], permissions) && (
        <ItemMenu
          route="/service-item-history"
          handle={handleDrawerClose}
          icon={<CameraAltIcon color="secondary" />}
          title="Fotos de tapete"
        />
      )}

      {renderRoute(['read-laundry-dashboard'], permissions) && (
        <ItemMenu
          route="/laundry-dashboard"
          handle={handleDrawerClose}
          icon={<GiRolledCloth size={25} className={classes.iconRI} />}
          title="Dashb. de lavanderias"
        />
      )}

      {renderRoute(['update-restoration-items'], permissions) && (
        <ItemMenu
          route="/restoration-items"
          handle={handleDrawerClose}
          icon={<Restore color="secondary" />}
          title="Tapetes de Rest."
        />
      )}

      <ItemMenu
        handle={handleClickCrm}
        icon={<Dashboard color="secondary" />}
        title="CRM"
        expand={openCrm ? <ExpandLess /> : <ExpandMore />}
      />

      <Collapse in={openCrm} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {renderCrm()}
        </List>
      </Collapse>

      <ItemMenu
        handle={handleClick}
        icon={<AddBoxIcon color="secondary" />}
        title="Cadastros Básicos"
        expand={open ? <ExpandLess /> : <ExpandMore />}
      />

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {renderIcons()}
        </List>
      </Collapse>

      <ItemMenu
        handle={handleClickRelatory}
        icon={<PostAddIcon color="secondary" />}
        title="Relatórios"
        expand={openRelatory ? <ExpandLess /> : <ExpandMore />}
      />

      <Collapse in={openRelatory} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {renderRelatories()}
        </List>
      </Collapse>

      <ItemMenu
        handle={handleClickFinance}
        icon={<AttachMoney color="secondary" />}
        title="Modulo financeiro"
        expand={openFinance ? <ExpandLess /> : <ExpandMore />}
      />

      <Collapse in={openFinance} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {renderFinance()}
        </List>
      </Collapse>
    </List>
  )
}

export default React.memo(Icons)
