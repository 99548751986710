/* eslint-disable camelcase */
import { Grid, Snackbar, SnackbarContent } from '@material-ui/core'
import green from '@material-ui/core/colors/green'
import blue from '@material-ui/core/colors/blue'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import withStyles from '@material-ui/core/styles/withStyles'
import CloseIcon from '@material-ui/icons/Close'
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'

import { clearSnackbar } from '../main/MainActions'
import { getSnackbar } from '../main/MainReducer'

const AUTOCLOSE_DURATION = 1000 * 4

const styles = theme => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  success: {
    backgroundColor: green[700],
  },
  info: {
    backgroundColor: blue[700],
  },
})

function Transition(props) {
  return <Slide {...props} direction="up" />
}

class Snackbars extends Component {
  constructor(props) {
    super(props)
    this.state = {
      message: null,
      open: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.snackbar) {
      this.setState({
        open: true,
        message: nextProps.snackbar.message,
        success: nextProps.snackbar.success,
        info: nextProps.snackbar.info,
      })
    }
  }

  handleClose = () => {
    const { doClear } = this.props
    this.setState({
      open: false,
    })
    doClear()
  }

  rendedrMessageIcon = (message, type) => {
    return (
      <Grid container spacing={1}>
        <Grid item xs>
          {type === 'success' ? (
            <CheckCircleOutlinedIcon />
          ) : (
            <InfoOutlinedIcon />
          )}
        </Grid>
        <Grid item style={{ marginTop: '2px' }}>
          {message}
        </Grid>
      </Grid>
    )
  }

  render() {
    const { classes } = this.props
    const { message, success, info, open } = this.state

    if (!message) {
      return null
    }

    const severity = info ? 'info' : success ? 'success' : 'error'

    return (
      <Snackbar
        key={message}
        disableWindowBlurListener
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={AUTOCLOSE_DURATION}
        onClose={this.handleClose}
        TransitionComponent={Transition}
      >
        <SnackbarContent
          className={classes[severity]}
          message={this.rendedrMessageIcon(message, severity)}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    )
  }
}

Snackbars.propTypes = {
  doClear: PropTypes.func.isRequired,
  snackbar: PropTypes.object,
  classes: PropTypes.object,
}

const mapStateToProps = store => ({
  snackbar: getSnackbar(store),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doClear: clearSnackbar,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Snackbars))
