import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'
import { SNACKBAR } from '../main/MainActions'

export const REMOVE_ATTENDANCE = createAsyncConst('REMOVE_ATTENDANCE')
export const SUBMIT_ATTENDANCE = createAsyncConst('SUBMIT_ATTENDANCE')
export const FETCH_ATTENDANCE_INIT = createAsyncConst('FETCH_ATTENDANCE_INIT')
export const FIND_ATTENDANCE_CLIENT = createAsyncConst('FIND_ATTENDANCE_CLIENT')
export const EDIT_ATTENDANCE = createAsyncConst('EDIT_ATTENDANCE')
export const SEND_MAIL = createAsyncConst('SEND_MAIL')
export const GENERATE_PAYMENT = createAsyncConst('GENERATE_PAYMENT')
export const FETCH_HISTORIC = createAsyncConst('FETCH_HISTORIC')
export const FETCH_ITEM_HISTORIC = createAsyncConst('FETCH_ITEM_HISTORIC')
export const ARCHIVE = createAsyncConst('ARCHIVE')
export const FETCH_ATTENDANCE_COMMENTS = createAsyncConst(
  'FETCH_ATTENDANCE_COMMENTS'
)
export const DOWNLOAD_ORCAMENTO = createAsyncConst('DOWNLOAD_ORCAMENTO')
export const FETCH_PAYMENT_INFORMATION = createAsyncConst(
  'FETCH_PAYMENT_INFORMATION'
)
export const CREATE_NFSE = createAsyncConst('CREATE_NFSE')

export function fetchAttendanceInit(filters = {}, rows, page) {
  return dispatch => {
    dispatch(action(FETCH_ATTENDANCE_INIT.ACTION))
    return axios
      .get(`${config.API_URL}/attendance`, {
        params: {
          filters: JSON.stringify(filters),
          rows,
          page: page || 1,
        },
      })
      .then(resp => {
        dispatch(
          action(FETCH_ATTENDANCE_INIT.SUCCESS, { data: resp.data, filters })
        )
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_ATTENDANCE_INIT, e)))
  }
}

export function findClient(id) {
  return dispatch => {
    dispatch(action(FIND_ATTENDANCE_CLIENT.ACTION))
    return axios
      .get(`${config.API_URL}/clients/${id}`, {
        params: {
          showAll: true,
        },
      })
      .then(resp => resp.data)
      .then(resp => {
        dispatch(action(FIND_ATTENDANCE_CLIENT.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(FIND_ATTENDANCE_CLIENT, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_ATTENDANCE.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/attendance/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(EDIT_ATTENDANCE.SUCCESS, data))
        return data
      })
      .catch(e => dispatch(actionFailed(EDIT_ATTENDANCE, e)))
  }
}

function submit(values, method) {
  const id = values.id ? `/${values.id}` : ''

  return (dispatch, getState) => {
    dispatch(action(SUBMIT_ATTENDANCE.ACTION))
    return axios[method](`${config.API_URL}/attendance${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_ATTENDANCE.SUCCESS, data))
        const rowsPerPage = getState().attendance.sizePerPage
        dispatch(fetchAttendanceInit(null, rowsPerPage))
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_ATTENDANCE, e)))
  }
}

export function post(values) {
  return submit(values, 'post')
}

export function update(values) {
  return submit(values, 'put')
}

export function remove(id, lossReasonId, closeModalDelete) {
  return (dispatch, getState) => {
    dispatch(action(REMOVE_ATTENDANCE.ACTION))
    return axios
      .delete(`${config.API_URL}/attendance/${id}`, {
        params: {
          loss_reason_id: lossReasonId || null,
        },
      })
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(REMOVE_ATTENDANCE.SUCCESS, data))
        const rowsPerPage = getState().attendance.sizePerPage
        dispatch(fetchAttendanceInit(null, rowsPerPage))
        closeModalDelete()
      })
      .catch(e => {
        dispatch(actionFailed(REMOVE_ATTENDANCE, e))
        closeModalDelete()
      })
  }
}

export function sendMail(id) {
  return dispatch => {
    dispatch(action(SEND_MAIL.ACTION))
    return axios
      .get(`${config.API_URL}/send-mail/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SEND_MAIL.SUCCESS, data))
      })
      .catch(e => dispatch(actionFailed(SEND_MAIL, e)))
  }
}

export function downloadOrcamento(id, type) {
  return dispatch => {
    dispatch(action(DOWNLOAD_ORCAMENTO.ACTION))
    return axios({
      url: `${config.API_URL}/get-orcamento/${id}/${type}`,
      method: 'GET',
      responseType: 'blob',
      headers: { Accept: 'application/pdf' },
    })
      .then(response => {
        dispatch(action(DOWNLOAD_ORCAMENTO.SUCCESS, {}))
        const blob = new Blob([response.data], { type: response.data.type })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        const contentDisposition = response.headers['content-disposition']
        let fileName = 'unknown'
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename=(.+)/)
          if (fileNameMatch.length === 2) {
            fileName = fileNameMatch[1].replaceAll('"', '')
          }
        }
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
      })
      .catch(() => {
        dispatch({
          type: SNACKBAR.HARDFAIL,
          error: { message: 'Erro na geração do arquivo.' },
        })
        dispatch(action(DOWNLOAD_ORCAMENTO.SUCCESS, {}))
      })
  }
}

export function generatePayment(id, values) {
  return (dispatch, getState) => {
    dispatch(action(GENERATE_PAYMENT.ACTION, { id }))

    return axios
      .post(`${config.API_URL}/billing/generate-billing-client/${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(GENERATE_PAYMENT.SUCCESS, data))
        const rowsPerPage = getState().attendance.sizePerPage
        dispatch(fetchAttendanceInit(null, rowsPerPage))
        return data
      })
      .catch(e => dispatch(actionFailed(GENERATE_PAYMENT, e)))
  }
}

export function saveFilters(filters) {
  return { type: 'SAVE_FILTERS', payload: filters }
}

export function eraseFilters() {
  return { type: 'ERASE_FILTERS' }
}

export function getHistory(id) {
  return dispatch => {
    dispatch(action(FETCH_HISTORIC.ACTION))
    return axios
      .get(`${config.API_URL}/client/history/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(FETCH_HISTORIC.SUCCESS, data))
        return data
      })
      .catch(e => dispatch(actionFailed(FETCH_HISTORIC, e)))
  }
}

export function getItemHistory(id) {
  return dispatch => {
    dispatch(action(FETCH_ITEM_HISTORIC.ACTION))
    return axios
      .get(`${config.API_URL}/service-item/history/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(FETCH_ITEM_HISTORIC.SUCCESS, data))
        return data
      })
      .catch(e => dispatch(actionFailed(FETCH_ITEM_HISTORIC, e)))
  }
}

export function getAttendanceComments(id) {
  return dispatch => {
    dispatch(action(FETCH_ATTENDANCE_COMMENTS.ACTION))
    return axios
      .get(`${config.API_URL}/attendance/${id}/comments`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(FETCH_ATTENDANCE_COMMENTS.SUCCESS, data))
        return data
      })
      .catch(e => dispatch(actionFailed(FETCH_ATTENDANCE_COMMENTS, e)))
  }
}

export function getPaymentInformation(id) {
  return dispatch => {
    dispatch(action(FETCH_PAYMENT_INFORMATION.ACTION))
    return axios
      .get(`${config.API_URL}/attendance/${id}/payment-information`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(FETCH_PAYMENT_INFORMATION.SUCCESS, data))
        return data
      })
      .catch(e => dispatch(actionFailed(FETCH_PAYMENT_INFORMATION, e)))
  }
}

export function archive(form, id) {
  return dispatch => {
    dispatch(action(ARCHIVE.ACTION))
    return axios
      .put(`${config.API_URL}/attendance/${id}/archive`, form)
      .then(data => {
        dispatch(action(ARCHIVE.SUCCESS, data))
        return true
      })
      .catch(e => dispatch(actionFailed(ARCHIVE, e)))
  }
}

export function createNfse(id, form) {
  return dispatch => {
    dispatch(action(CREATE_NFSE.ACTION))
    return axios
      .post(`${config.API_URL}/attendance/nfse/${id}`, form)
      .then(data => {
        dispatch(action(CREATE_NFSE.SUCCESS, data))
        dispatch(fetchAttendanceInit(null, 10))
        return true
      })
      .catch(e => dispatch(actionFailed(CREATE_NFSE, e)))
  }
}
