/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useRef, useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import { useDispatch } from 'react-redux'

import { dateFormatedFullDate } from '../../../util/utils'
import { fetch } from '../ServiceItemRelatoryActions'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  table: {
    padding: theme.spacing(2),
  },
  centerImage: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    height: 'auto',
    maxWidth: '100%',
    cursor: 'pointer',
  },
  containerImage: {
    padding: '16px',
  },
}))

function ServiceItemHistory() {
  const [barCode, setBarCode] = useState('')
  const [item, setItem] = useState(null)
  const dispatch = useDispatch()
  const classes = useStyles()

  const modalRef = useRef()
  const modalImgRef = useRef()
  const captionTextRef = useRef()

  const clickImg = e => {
    modalRef.current.style.display = 'block'
    modalImgRef.current.src = e.target.src
    captionTextRef.current.innerHTML = e.target.alt
  }

  const closesModal = () => {
    modalRef.current.style.display = 'none'
  }

  const search = async e => {
    e.preventDefault()
    const resp = await dispatch(fetch(barCode))
    if (resp) {
      setItem(resp.data)
    }
  }

  return (
    <>
      <Grid
        container
        className={classes.pageHead}
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={8}>
          <Typography variant="h4" color="secondary">
            Histórico de tapetes
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <form onSubmit={search}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={6}>
                <TextField
                  id="search"
                  label="Pesquisar"
                  variant="outlined"
                  margin="dense"
                  value={barCode}
                  fullWidth
                  onChange={e => setBarCode(e.target.value)}
                />
              </Grid>
              <Grid item xs>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={search}
                >
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        {item && (
          <Grid
            container
            spacing={1}
            alignItems="center"
            style={{ marginTop: '10px' }}
          >
            <Grid item xs={12}>
              <Paper elevation={1}>
                <Grid container spacing={1}>
                  <Grid item xs={item?.documents?.length ? 6 : 12}>
                    <TableContainer /* className={classes.table} */>
                      <Table aria-label="collapsible table">
                        <TableHead>
                          <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Usuário</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Data</TableCell>
                            <TableCell>O que foi alterado</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {item?.itemStatus?.map(item => (
                            <TableRow key={item.id}>
                              <TableCell>{item.id}</TableCell>
                              <TableCell>{item.user.name}</TableCell>
                              <TableCell>{item.status.name}</TableCell>
                              <TableCell>
                                {dateFormatedFullDate(item.created_at)}
                              </TableCell>
                              <TableCell>{item.whatChanged}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  {item?.documents?.length > 0 && (
                    <Grid item xs={6} style={{ padding: '16px' }}>
                      <Carousel autoPlay={false} navButtonsAlwaysVisible>
                        {item?.documents?.map((item, key) => (
                          <img
                            src={item.link}
                            key={item.link}
                            alt={`Foto ${key + 1}`}
                            className={classes.centerImage}
                            onClick={clickImg}
                          />
                        ))}
                      </Carousel>
                    </Grid>
                  )}
                </Grid>
                <>
                  <div id="myModal" ref={modalRef} className="modal">
                    <span className="close" onClick={closesModal}>
                      &times;
                    </span>

                    <img
                      className="modal-content"
                      ref={modalImgRef}
                      id="img01"
                    />

                    <div id="caption" ref={captionTextRef} />
                  </div>
                </>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default ServiceItemHistory
