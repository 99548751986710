import React from 'react'
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
  IconButton,
} from '@material-ui/core'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import NullIcon from '@material-ui/icons/Remove'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56,
  },
  differenceIcon: {
    color: colors.green[600],
  },
  nullIcon: {
    color: colors.grey[600],
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1),
  },
  noDifference: {
    color: colors.grey[900],
    marginRight: theme.spacing(1),
  },
}))

const DashboardCard = ({ item }) => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              {item?.status?.name}
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {item?.qtd}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              variant="contained"
              component={Link}
              to={`/details/${item.status_id}`}
            >
              <VisibilityIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box mt={2} display="flex" alignItems="center">
          {item.today > 0 ? (
            <ArrowUpward className={classes.differenceIcon} />
          ) : (
            <NullIcon className={classes.nullIcon} />
          )}
          <Typography
            className={
              item.today > 0 ? classes.differenceValue : classes.noDifference
            }
            variant="body2"
          >
            {item.today}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            Hoje
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default DashboardCard
