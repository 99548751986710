import { EDIT_USER, FETCH_NOTIFICATIONS, FETCH_USER } from './UserActions'

const INITIAL_STATE = {
  items: [],
  roles: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  filters: [],
  editingUser: null,
  notifications: [],
  areas: [],
  companies: [],
  laundries: [],
  weekdays: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_USER.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        roles: action.payload.data.filterRoles,
        filters: action.payload.filters,
        areas: action.payload.data.areas,
        companies: action.payload.data.companies,
        laundries: action.payload.data.laundries,
        weekdays: action.payload.data.weekdays,
      }
    case EDIT_USER.SUCCESS:
      const data = action.payload
      data.userWeekdays.map(item => {
        item.from = item.from
          ? new Date().setHours(
              item.from.split(':')[0],
              item.from.split(':')[1]
            )
          : null

        item.to = item.to
          ? new Date().setHours(item.to.split(':')[0], item.to.split(':')[1])
          : null

        return item
      })

      return {
        ...state,
        editingUser: data,
      }
    case FETCH_NOTIFICATIONS.SUCCESS:
      return {
        ...state,
        notifications: action.payload.data,
      }
    default:
      return state
  }
}

export const getUserListData = state => {
  return {
    items: state.user.items,
    roles: state.user.roles,
    filters: state.user.filters,
  }
}

export const getEditingUser = state => {
  return state.user.editingUser
}
