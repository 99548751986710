import { FETCH_LAUNDRY_MAP } from './MapAction'

const INITIAL_STATE = {
  laundries: [],
  attendants: [],
  loading: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LAUNDRY_MAP.SUCCESS:
      return {
        ...state,
        laundries: action.payload.data,
        attendants: action.payload.attendants,
        loading: false,
      }
    case FETCH_LAUNDRY_MAP.ACTION:
      return {
        ...state,
        loading: true,
      }
    case FETCH_LAUNDRY_MAP.FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
