/* eslint-disable react/prop-types */
import { Tooltip } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import React from 'react'
import { Link } from 'react-router-dom'

export default props => {
  const { route, handle, expand, title, icon } = props
  return (
    <Tooltip title={!props.open ? props.title : ''} arrow placement="left">
      <ListItem
        button
        component={route ? Link : null}
        to={route}
        onClick={handle}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
        {expand}
      </ListItem>
    </Tooltip>
  )
}
