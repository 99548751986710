/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useSelector } from 'react-redux'

export default function PusherNotification() {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [title, setTitle] = useState(false)
  const user = useSelector(state => state.auth.user)

  useEffect(() => {
    if (user?.id) {
      window.Echo.private(`App.Models.User.${user.id}`).notification(
        notification => {
          setMessage(notification.message)
          setTitle(notification.title)
          setOpen(true)
        }
      )
    }
  }, [user])

  return (
    <div>
      <Dialog maxWidth="md" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span dangerouslySetInnerHTML={{ __html: message }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary" autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
