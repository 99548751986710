import { EDIT_TRIGGER, FETCH_TRIGGER } from './TriggersActions'

const INITIAL_STATE = {
  items: [],
  meta: {},
  filters: {},
  editingTrigger: null,
  triggerTypes: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TRIGGER.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        meta: action.payload.data.meta,
        triggerTypes: action.payload.data.types,
      }
    case EDIT_TRIGGER.SUCCESS:
      return {
        ...state,
        editingTrigger: action.payload,
      }
    default:
      return state
  }
}
