import { EDIT_COMPANY, FETCH_COMPANY } from './CompaniesActions'

const INITIAL_STATE = {
  items: [],
  filters: [],
  editingCompany: null,
  serviceTypes: [],
  users: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_COMPANY.SUCCESS:
      return {
        ...state,
        items: action.payload.data,
        serviceTypes: action.payload.serviceTypes.map(serviceType => ({
          ...serviceType,
          status: 1,
        })),
        users: action.payload.users.map(user => ({
          ...user,
          status: 1,
        })),
      }
    case EDIT_COMPANY.SUCCESS:
      return {
        ...state,
        editingCompany: action.payload,
      }
    default:
      return state
  }
}

export const getCompanyListData = state => {
  return {
    items: state.company.items,
  }
}

export const getEditingCompany = state => {
  return state.company.editingCompany
}
