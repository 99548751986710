import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import styles from '../../../resources/theme/auth'
import EnhancedComponent from '../../components/EnhancedComponent'
import * as AuthActions from '../AuthActions'

class ValidatePassword extends EnhancedComponent {
  state = {
    fields: {
      email: '',
      password: '',
      password_confirmation: '',
      token: '',
    },
  }

  componentDidMount() {
    const { token } = this.props.match.params
    this.setState(prevState => ({ fields: { ...prevState.fields, token } }))
  }

  onSubmit = event => {
    event.preventDefault()
    this.props.validatePassword(this.state.fields).then(resp => {
      if (resp && resp.code === 200) {
        this.props.history.push('/')
      }
    })
  }

  render() {
    const { classes } = this.props
    return (
      <form id="new-password" onSubmit={this.onSubmit} className={classes.main}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5" color="primary">
            Validar Cadastro
          </Typography>

          <TextField
            type="email"
            label="E-mail"
            required
            autoComplete="email"
            className={classes.field}
            onChange={this.onChange('email')}
          />

          <TextField
            type="password"
            label="Senha"
            required
            autoComplete="current-password"
            className={classes.field}
            onChange={this.onChange('password')}
            value={this.state.fields.password}
          />

          <TextField
            type="password"
            label="Confirmar Senha"
            required
            autoComplete="current-password"
            className={classes.field}
            onChange={this.onChange('password_confirmation')}
            value={this.state.fields.password_confirmation}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Enviar
          </Button>

          <Link to="/" align="left" className={classes.link}>
            Voltar ao Login
          </Link>
        </Paper>
      </form>
    )
  }
}

ValidatePassword.propTypes = {
  login: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(AuthActions, dispatch)
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ValidatePassword))
