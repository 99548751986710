import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import {
  fetchCompaniesAvaliable,
  fetchNfseCompanies,
  store,
} from '../../EletronicNfseActions'
// import { create, update } from '../../TriggersActions'

const Form = ({ edit = false, data = {}, id = null }) => {
  const companiesAvaliable = useSelector(
    state => state.eletronicNfse.companiesAvaliable
  )

  const history = useHistory()
  const dispatch = useDispatch()

  const handleClose = () => {
    history.push('/company-nfse')
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const formData = e.currentTarget
    const data = new FormData(formData)
    const entries = data.entries()
    const values = Object.fromEntries(entries)

    let company = companiesAvaliable?.find(
      company => company.id == values.company_id
    )

    company = {
      ...company,
      eletronic_invoice_company_id: company?.id,
    }

    try {
      const resp = await dispatch(store(company))

      if (resp) {
        handleClose()
      }
    } catch (e) {
      //
    }
  }

  useEffect(() => {
    dispatch(fetchCompaniesAvaliable())
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              select
              variant="standard"
              fullWidth
              label="Compania"
              name="company_id"
              required
            >
              <MenuItem value="">Selecione...</MenuItem>

              {companiesAvaliable?.map((company, index) => (
                <MenuItem value={company?.id} key={index}>
                  {company?.company_name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button color="primary" type="submit">
          Salvar
        </Button>
      </DialogActions>
    </form>
  )
}

export default Form
