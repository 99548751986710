import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

import Progress from './progress'

function BackdropProgress({ progress, text, color }) {
  const useStyles = makeStyles(theme => ({
    container: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'fixed',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      zIndex: 10000,
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '3fr 1fr',
      gridAutoFlow: 'row',
      justifyItems: 'center',
      alignItems: 'center',
      gridTemplateAreas: '"loading" "."',
    },
    loading: {
      gridArea: 'loading',
    },
    '@keyframes enterY': {
      from: { opacity: 1, transform: 'translateY(50px)' },
      to: { opacity: 0, transform: 'translateY(-50vh)' },
    },
    animationFade: {
      opacity: 0,
      alignSelf: 'self-end',
      animation: '$enterY',
      animationDuration: '5000ms',
      animationDelay: '0.2s',
      animationFillMode: 'forwards',
      animationTimingFunction: 'ease-out',
    },
  }))

  const styles = useStyles()

  return (
    <div className={styles.container}>
      <div className={styles.loading}>
        <Progress progress={progress} />
      </div>
      <div className={styles.animationFade}>
        <Typography variant="h5" style={{ color: color || '#539fdd' }}>
          {text}
        </Typography>
      </div>
    </div>
  )
}

export default React.memo(BackdropProgress)
