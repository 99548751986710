/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { format } from 'date-fns'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useState } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
import SelectField from '../../components/SelectField'
import { fetchBilling } from '../BillingActions'

export default function Filter(props) {
  const items = useSelector(state => state.billing.items)
  const statuses = useSelector(state => state.billing.statuses)
  const billingTypes = useSelector(state => state.billing.billingTypes)
  const paymentMethods = useSelector(state => state.billing.paymentMethods)
  const companies = useSelector(state => state.billing.companies)
  const banks = useSelector(state => state.billing.banks)
  const billingTypesGeneric = ['Despesa', 'Receita']

  const dispatch = useDispatch()
  const { classes } = props
  const [form, setForm] = useState({
    person: '',
    date: null,
    date_end: null,
    date_payment: null,
    date_end_payment: null,
    status: '',
    billing_type: '',
    payment_method: '',
    service_id: '',
    company: '',
    bank: '',
    billing_type_generic: '',
  })

  const updateFields = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    })
  }

  const setDate = date => {
    if (date) {
      const formated = format(date, 'yyyy-MM-dd HH:mm:ss')
      setForm({ ...form, date: formated })
    } else {
      setForm({ ...form, date })
    }
  }

  const setDateEnd = date_end => {
    if (date_end) {
      const formated = format(date_end, 'yyyy-MM-dd HH:mm:ss')
      setForm({ ...form, date_end: formated })
    } else {
      setForm({ ...form, date_end })
    }
  }

  const onSubmit = e => {
    e.preventDefault()
    const data = {
      person: form.person,
      status: form.status?.id,
      billing_type: form.billing_type?.id,
      payment_method: form.payment_method?.id,
      date: form.date,
      date_end: form.date_end,
      date_payment: form.date_payment,
      date_end_payment: form.date_end_payment,
      service_id: form.service_id,
      company_id: form.company?.id,
      bank: form.bank,
      billing_type_generic: form.billing_type_generic,
    }
    dispatch(fetchBilling(data))
  }

  return (
    <Tooltip title="Filter list">
      <PopupState variant="popover" popupId="demo-popup-popover">
        {popupState => (
          <div>
            <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
              <BadgeGlobal form={form} items={items}>
                <FilterListIcon />
              </BadgeGlobal>
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2} className={classes.popover}>
                <div className={classes.root}>
                  <ValidatorForm className={classes.form} onSubmit={onSubmit}>
                    <Grid
                      container
                      className={classes.gridContainer}
                      spacing={1}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item sm={12} xs={12} lg={6}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, service_id: e.target.value })
                          }
                          label="Numero da OS"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          value={form.service_id}
                        />
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, person: e.target.value })
                          }
                          label="Pessoa"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          value={form.person}
                        />
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <KeyboardDatePicker
                          clearable
                          value={form.date}
                          label="Data de Vencimento Inicial"
                          fullWidth
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          margin="dense"
                          inputVariant="outlined"
                          onChange={date =>
                            setForm({ ...form, date: date })
                          }
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <KeyboardDatePicker
                          clearable
                          value={form.date_end}
                          label="Data de Vencimento Final"
                          fullWidth
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          margin="dense"
                          inputVariant="outlined"
                          onChange={date =>
                            setForm({ ...form, date_end: date })
                          }
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <KeyboardDatePicker
                          clearable
                          value={form.date_payment}
                          label="Data de Pagamento Inicial"
                          fullWidth
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          margin="dense"
                          inputVariant="outlined"
                          onChange={date =>
                            setForm({ ...form, date_payment: date })
                          }
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <KeyboardDatePicker
                          clearable
                          value={form.date_end_payment}
                          label="Data de Pagamento Final"
                          fullWidth
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          margin="dense"
                          inputVariant="outlined"
                          onChange={date =>
                            setForm({ ...form, date_end_payment: date })
                          }
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <SelectField
                          options={statuses}
                          setForm={updateFields}
                          data={form.status}
                          name="status"
                          variant="outlined"
                          margin="dense"
                          label="Status"
                        />
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <SelectField
                          options={billingTypes}
                          setForm={updateFields}
                          data={form.billing_type}
                          name="billing_type"
                          variant="outlined"
                          margin="dense"
                          label="Tipo de Pagamento"
                        />
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <SelectField
                          options={paymentMethods}
                          setForm={updateFields}
                          data={form.payment_method}
                          name="payment_method"
                          variant="outlined"
                          margin="dense"
                          label="Método de pagamento"
                        />
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <SelectField
                          options={companies}
                          setForm={updateFields}
                          data={form.company}
                          name="company"
                          variant="outlined"
                          margin="dense"
                          label="Empresa"
                        />
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <SelectField
                          options={banks}
                          setForm={updateFields}
                          data={form.bank}
                          name="bank"
                          variant="outlined"
                          margin="dense"
                          label="Banco"
                        />
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <SelectField
                          options={billingTypesGeneric}
                          setForm={updateFields}
                          data={form.billing_type_generic}
                          name="billing_type_generic"
                          variant="outlined"
                          margin="dense"
                          label="Despesa / Receita"
                        />
                      </Grid>

                      <Grid item xs>
                        <Button
                          type="submit"
                          color="primary"
                          fullWidth
                          variant="contained"
                          className={classes.submit}
                          onClick={popupState.close}
                        >
                          <SearchIcon />
                          {` Filtrar`}
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                </div>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </Tooltip>
  )
}
