import {
  Checkbox,
  DialogActions,
  FormControlLabel,
  MenuItem,
  TextField,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUp'
import React, { useEffect, useState } from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'

function Form(props) {
  const { data, onClose, onSubmit, classes } = props

  const [form, updateForm] = useState({
    id: '',
    name: '',
    description: '',
    status: 1,
    type: '',
  })

  const updateField = React.useCallback(name => e => {
    updateForm({
      ...form,
      [name]: e.target.value,
    })
  })

  const handleClose = () => onClose && onClose()

  const onSubmitForm = e => {
    e.preventDefault()
    const send = { ...form, status: form.status || 0 }
    onSubmit && onSubmit(send)
  }

  useEffect(() => {
    updateForm({ ...form, ...data })
  }, [data])

  return (
    <ValidatorForm onSubmit={onSubmitForm}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextValidator
              validators={['required']}
              errorMessages={['Campo Obrigatório']}
              onChange={updateField('name')}
              label="Nome"
              fullWidth
              value={form.name}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              placeholder="Descrição"
              value={form.description}
              onChange={updateField('description')}
              variant="outlined"
              multiline
              fullWidth
              rows={5}
              rowsMax={10}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              onChange={updateField('type')}
              select
              label="Tipo"
              className={classes.inputFilter}
              fullWidth
              value={form.type}
            >
              <MenuItem key="" value="">
                Selecione...
              </MenuItem>
              <MenuItem key={1} value={1}>
                Despesa
              </MenuItem>
              <MenuItem key={2} value={2}>
                Receita
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<ThumbDownAltOutlinedIcon />}
                  checkedIcon={<ThumbUpAltOutlinedIcon color="secondary" />}
                  checked={Boolean(form.status)}
                  onChange={e =>
                    updateForm({ ...form, status: e.target.checked })
                  }
                  value={form.status}
                />
              }
              label="Ativo?"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" type="submit">
          Salvar
        </Button>
        <Button onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </ValidatorForm>
  )
}

export default Form
