/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import { MenuItem } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useState } from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'
import { endOfMonth, format, startOfMonth } from 'date-fns'

import BadgeGlobal from '../../components/Badge'
import { fetchTeamProdutivityRelatoryInit } from '../TeamProdutivityRelatoryActions'

export default function Filter(props) {
  const items = useSelector(state => state.team_produtivity_relatory.items)

  const teams = useSelector(state => state.team_produtivity_relatory.teams)
  const services_types = useSelector(
    state => state.team_produtivity_relatory.services_types
  )

  const dispatch = useDispatch()
  const { classes } = props
  const [form, setForm] = useState({
    team: '',
    service_type: '',
    date_start: startOfMonth(new Date()),
    date_end: endOfMonth(new Date()),
  })

  const onSubmit = e => {
    e.preventDefault()
    const data = {
      ...form,
      date_start: form.date_start
        ? format(form.date_start, 'yyyy-MM-dd HH:mm')
        : null,
      date_end: form.date_end
        ? format(form.date_end, 'yyyy-MM-dd HH:mm')
        : null,
    }
    dispatch(fetchTeamProdutivityRelatoryInit(null, data, null))
  }

  return (
    <Tooltip title="Filter list">
      <PopupState variant="popover" popupId="demo-popup-popover">
        {popupState => (
          <div>
            <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
              <BadgeGlobal form={form} items={items}>
                <FilterListIcon />
              </BadgeGlobal>
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2} className={classes.popover}>
                <div className={classes.root}>
                  <ValidatorForm className={classes.form} onSubmit={onSubmit}>
                    <Grid
                      container
                      className={classes.gridContainer}
                      spacing={1}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, team: e.target.value })
                          }
                          select
                          label="Equipes"
                          margin="dense"
                          variant="outlined"
                          className={classes.inputFilter}
                          fullWidth
                          value={form.team}
                        >
                          <MenuItem key="" value="">
                            Selecione...
                          </MenuItem>
                          {teams?.map(team => (
                            <MenuItem key={team.id} value={team.id}>
                              {team.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, service_type: e.target.value })
                          }
                          select
                          label="Tipo de Serviço"
                          margin="dense"
                          variant="outlined"
                          className={classes.inputFilter}
                          fullWidth
                          value={form.service_type}
                        >
                          <MenuItem key="" value="">
                            Selecione...
                          </MenuItem>
                          {services_types?.map(service_type => (
                            <MenuItem
                              key={service_type.id}
                              value={service_type.id}
                            >
                              {service_type.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <KeyboardDatePicker
                          clearable
                          value={form.date_start}
                          label="Data Inicial"
                          fullWidth
                          margin="dense"
                          inputVariant="outlined"
                          views={['date']}
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          onChange={date =>
                            setForm({ ...form, date_start: date })
                          }
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <KeyboardDatePicker
                          clearable
                          value={form.date_end}
                          label="Data Final"
                          fullWidth
                          margin="dense"
                          inputVariant="outlined"
                          views={['date']}
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          onChange={date =>
                            setForm({ ...form, date_end: date })
                          }
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item xs>
                        <Button
                          type="submit"
                          color="primary"
                          fullWidth
                          variant="contained"
                          className={classes.submit}
                          onClick={popupState.close}
                        >
                          <SearchIcon />
                          {` Filtrar`}
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                </div>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </Tooltip>
  )
}
