import axios from 'axios'

import config from '../../config'
import consts from '../../util/consts'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const FETCH_SERVICE_CATEGORIES_RELATORY_INIT = createAsyncConst('FETCH_SERVICE_CATEGORIES_RELATORY_INIT')
export const FETCH_SERVICE_CATEGORIES_RELATORY_DETAILS = createAsyncConst('FETCH_SERVICE_CATEGORIES_RELATORY_DETAILS')
export const MAKE_SERVICE_CATEGORIES_RELATORY = createAsyncConst('MAKE_SERVICE_CATEGORIES_RELATORY')

export function fetchServiceCategoriesRelatoryInit(page, filters = [], rows = consts.DEFAULT_PAGINATION) {
  return dispatch => {
    dispatch(action(FETCH_SERVICE_CATEGORIES_RELATORY_INIT.ACTION))
    return axios
      .get(`${config.API_URL}/relatory-service-category`, {
        params: {
          filters: JSON.stringify(filters),
          rows: rows || consts.DEFAULT_PAGINATION,
          page: page || 1,
        },
      })
      .then(resp => {
        dispatch(action(FETCH_SERVICE_CATEGORIES_RELATORY_INIT.SUCCESS, { data: resp.data, filters: filters }))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_SERVICE_CATEGORIES_RELATORY_INIT, e)))
  }
}

export function fetchServiceCategoriesRelatoryDetailsInit(id, page, filters = [], rows = consts.DEFAULT_PAGINATION) { 
  return dispatch => {
    dispatch(action(FETCH_SERVICE_CATEGORIES_RELATORY_DETAILS.ACTION))
     return axios
      .get(`${config.API_URL}/relatory-service-category/${id}`, {
        params: {
          filters: JSON.stringify(filters),
          rows: rows || consts.DEFAULT_PAGINATION,
          page: page || 1,
        },
      })
      .then(resp => {
        dispatch(action(FETCH_SERVICE_CATEGORIES_RELATORY_DETAILS.SUCCESS, { selected_category: resp.selected_category, data: resp.data, filters: filters }))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_SERVICE_CATEGORIES_RELATORY_DETAILS, e)))
  }
}

export function makeDetailsRelatory(filters, id) {
  return dispatch => {
    return axios({
      url:
        `${config.API_URL}/relatory-service-category/${id}/report`,
        method: 'POST',
        responseType: 'blob',
        headers: { Accept: 'application/vnd.ms-excel' },
        params: {
          filters: JSON.stringify(filters),
        }
      })
      .then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        
        link.href = url
        link.setAttribute('download', 'service_categories_report.xlsx')
        document.body.appendChild(link)
        link.click()
        return resp
      })
    .catch(e => dispatch(actionFailed(MAKE_SERVICE_CATEGORIES_RELATORY, e)))
  }  
}

export function makeRelatory(data) {
  return dispatch => {
    return axios({
      url:
        `${config.API_URL}/make-relatory-service-category`,
        method: 'POST',
        responseType: 'blob',
        headers: { Accept: 'application/vnd.ms-excel' },
        params: {
          filters: JSON.stringify(data),
        }
      })
      .then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        
        link.href = url
        link.setAttribute('download', 'service_categories_report.xlsx')
        document.body.appendChild(link)
        link.click()
        return resp
      })
    .catch(e => dispatch(actionFailed(MAKE_SERVICE_CATEGORIES_RELATORY, e)))
  }
}

export function clearFilter() {
  return { type: "CLEAR_ACTION_SERVICE_CATEGORIES" }
}