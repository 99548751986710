/* eslint-disable import/order */
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from '../../../resources/theme/users'
import { clearBilling, edit, update } from '../BillingActions'
import Form from './Form'
import { Link } from 'react-router-dom'
import { Slide } from '@material-ui/core'

function Transition(props) {
  return <Slide direction="down" {...props} />
}

function EditBill(props) {
  const { classes } = props
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearBilling())
    dispatch(edit(props.match.params.id))
  }, [])

  const billing = useSelector(state => state.billing.editingBilling)

  const onSubmit = values => {
    dispatch(update(values)).then(data => {
      if (data?.status === 204) {
        props.history.push('/billing')
      }
    })
  }

  const onClose = () => {
    props.history.goBack()
  }

  const getPath = () => {
    if (billing?.service?.client)
      return `/attendance/${billing?.service.id}/edit`
    if (billing?.service?.laundry)
      return `/laundry-os/${billing?.service.id}/details`

    return null
  }

  const returnInfo = () => {
    if (getPath()) {
      return (
        <Typography
          variant="inherit"
          color="inherit"
          component={Link}
          to={getPath()}
        >
          {billing?.service.id}
        </Typography>
      )
    }
  }

  return (
    <Dialog
      fullScreen
      open={billing !== null}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Editar Conta - {returnInfo()}
          </Typography>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Form data={billing} onClose={onClose} onSubmit={onSubmit} />
    </Dialog>
  )
}

export default withStyles(styles)(EditBill)
