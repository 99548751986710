import Fade from '@material-ui/core/Fade'
import Grow from '@material-ui/core/Grow'
import Slide from '@material-ui/core/Slide'
import Zoom from '@material-ui/core/Zoom'
import React from 'react'

export default function Transition(props) {
  const { propis, refi } = props
  const slides = ['up', 'down', 'left', 'right']
  const transitions = ['Slide', 'Fade', 'Grow', 'Zoom']

  const transition = transitions[Math.floor(Math.random() * transitions.length)]

  if (transition === 'Slide') {
    const slide = slides[Math.floor(Math.random() * slides.length)]
    return <Slide direction={slide} {...propis} ref={refi} />
  }
  if (transition === 'Grow') {
    return <Grow style={{ transformOrigin: '0 0 0' }} {...propis} ref={refi} />
  }
  if (transition === 'Zoom') {
    return <Zoom {...propis} ref={refi} />
  }

  return <Fade {...propis} ref={refi} />
}
