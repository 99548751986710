// import 'isomorphic-fetch';
import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from 'axios'

import config from '../../config'

export default function Asynchronous(props) {
  const {
    label,
    link,
    setForm,
    name,
    data,
    uniqueState,
    disabled,
    variant,
    margin,
  } = props
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const [typed, setTyped] = React.useState('')
  // const loading = open && options.length === 0
  const [loading, setLoading] = React.useState(false)
  let timeout = 0

  function setTypeFunction(e, value) {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      setTyped(value)
    }, 500)
  }

  React.useEffect(() => {
    let active = true

    // if (!loading) {
    //   return undefined
    // }

    if (typed.length >= 3) {
      ;(async () => {
        setLoading(true)
        axios
          .get(`${config.API_URL}/${link}`, {
            params: {
              filter: JSON.stringify(typed),
            },
          })
          .then(resp => {
            if (active) setOptions(resp.data.data)
            setLoading(false)
          })
          .catch(() => setLoading(false))
      })()
    }

    return () => {
      active = false
    }
  }, [typed])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  return (
    <Autocomplete
      id={label}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      disabled={disabled}
      onInputChange={(e, value) => setTypeFunction(e, value)}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.name
      }
      onChange={(action, value) =>
        uniqueState ? setForm(value) : setForm(name, value)
      }
      loadingText="Digite o termo de busca"
      noOptionsText="Sem opções"
      options={options}
      loading={loading}
      value={data}
      renderInput={params => (
        <TextField
          {...params}
          label={label || ''}
          fullWidth
          variant={variant || 'standard'}
          margin={margin || 'none'}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
