/* eslint-disable react/prop-types */
import {
  Box,
  TextField as MuiTextField,
  Button,
  Grid,
  IconButton,
  Popover,
  Tooltip,
  FormControlLabel,
} from '@material-ui/core'
import { Checkbox, TextField } from 'formik-material-ui'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React from 'react'
import { Formik, Form, Field } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { KeyboardDatePicker } from 'formik-material-ui-pickers'
import { Autocomplete } from 'formik-material-ui-lab'
import { useParams } from 'react-router'

import { edit } from '../AttendanceLaundryActions'

export default function Filter(props) {
  const dispatch = useDispatch()
  const statuses = useSelector(state => state.attendanceLaundry.statuses)
  const { classes } = props
  const { id } = useParams()

  return (
    <Tooltip title="Filter list">
      <PopupState variant="popover" popupId="demo-popup-popover">
        {popupState => (
          <div>
            <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
              <FilterListIcon style={{ color: '#fff' }} />
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2} className={classes.popover}>
                <div className={classes.root}>
                  <Formik
                    initialValues={{
                      bar_code: '',
                      date: null,
                      date_to: null,
                      is_urgent: false,
                      is_rework: false,
                      is_personalized: false,
                      is_delivery: false,
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setSubmitting(false)
                      dispatch(edit(id, values))
                    }}
                  >
                    {({ submitForm, isSubmitting }) => (
                      <Form className={classes.form}>
                        <Grid
                          container
                          className={classes.gridContainer}
                          spacing={1}
                          justify="center"
                          alignItems="center"
                        >
                          <Grid item xs={6}>
                            <Field
                              component={TextField}
                              label="Etiqueta"
                              margin="dense"
                              className={classes.inputFilter}
                              variant="outlined"
                              fullWidth
                              name="bar_code"
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <Field
                              component={KeyboardDatePicker}
                              name="date"
                              clearLabel="Limpar"
                              label="Data (de)"
                              margin="dense"
                              fullWidth
                              clearable
                              format="dd/MM/yyyy"
                              orientation="landscape"
                              inputVariant="outlined"
                              autoOk
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <Field
                              component={KeyboardDatePicker}
                              name="date_to"
                              clearLabel="Limpar"
                              label="Data (até)"
                              margin="dense"
                              fullWidth
                              clearable
                              format="dd/MM/yyyy"
                              orientation="landscape"
                              inputVariant="outlined"
                              autoOk
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <Field
                              name="status"
                              component={Autocomplete}
                              options={statuses}
                              getOptionSelected={(option, value) =>
                                option.id === value.id
                              }
                              getOptionLabel={option =>
                                typeof option === 'string'
                                  ? option
                                  : option.name
                              }
                              renderInput={params => (
                                <MuiTextField
                                  {...params}
                                  label="Status"
                                  variant="outlined"
                                  margin="dense"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Field
                                  component={Checkbox}
                                  type="checkbox"
                                  name="is_rework"
                                />
                              }
                              label="Retrabalho"
                            />
                          </Grid>

                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Field
                                  component={Checkbox}
                                  type="checkbox"
                                  name="is_urgent"
                                />
                              }
                              label="Urgente"
                            />
                          </Grid>

                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Field
                                  component={Checkbox}
                                  type="checkbox"
                                  name="is_delivery"
                                />
                              }
                              label="Delivery"
                            />
                          </Grid>

                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Field
                                  component={Checkbox}
                                  type="checkbox"
                                  name="is_personalized"
                                />
                              }
                              label="Personalizado"
                            />
                          </Grid>

                          <Grid item xs>
                            <Button
                              color="primary"
                              fullWidth
                              type="submit"
                              variant="contained"
                              className={classes.submit}
                              onClick={popupState.close}
                            >
                              <SearchIcon />
                              {` Filtrar`}
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </Tooltip>
  )
}
