import React from 'react'

import { remove } from '../SupplierActions'
import GlobalList from '../../components/GlobalList'

function createData(id, name, status, address, phone, cpf_cnpj, email, commission) {
  return {
    id,
    name,
    status,
    address,
    phone,
    cpf_cnpj,
    email,
    commission,
    update: 'update-supplier',
    delete: 'delete-supplier',
    link_update: `/supplier/${id}/edit`,
  }
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'address',
    numeric: true,
    disablePadding: false,
    label: 'Endereço',
  },
  {
    id: 'phone',
    numeric: true,
    disablePadding: false,
    label: 'Telefone',
  },
  {
    id: 'cpf_cnpj',
    numeric: true,
    disablePadding: false,
    label: 'CPF / CNPJ',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'e-mail',
  },
  {
    id: 'commission',
    numeric: true,
    disablePadding: false,
    label: 'Comissão',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Ações',
  },
]

function List(props) {
  const { items } = props
  const rows = []

  items.map(item => {    
    if (item.street && item.number && item.neighborhood && item.city) {
      item.address = item.street +', '+ item.number +', '+ item.neighborhood +' - '+ item.city +'/'+ item.uf} 
    else{ item.address = "-"}
      
    return rows.push(createData(
      item.id, 
      item.name, 
      item.status_text,
      item.address,
      item.phone? item.phone : "-",
      item.cpf_cnpj? item.cpf_cnpj : "-",
      item.email? item.email : "-",
      item.commission? item.commission + " %" : "-",
      ))
  })

  return (
    <GlobalList {...props} headCells={headCells} rows={rows} remove={remove} />
  )
}

export default List
