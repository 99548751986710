/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable import/order */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable eqeqeq */
/* eslint-disable no-return-assign */
/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
import {
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import colors from '../../../../../assets/colors'
import classNames from 'classnames'

const List = ({ form, setForm, classes }) => {
  const items = useSelector(state => state.leadsReport.items)
  const meta = useSelector(state => state.leadsReport.meta)

  const [paginate, setPaginate] = useState({
    page: 0,
    per_page: 10,
  })

  const handleChangeRowsPerPage = event => {
    setForm({
      ...form,
      per_page: event.target.value,
    })
  }

  const handleChangePage = newPage => {
    setForm({
      ...form,
      page: newPage,
    })
  }

  useEffect(() => {
    setForm({
      ...form,
      ...paginate,
    })
  }, [paginate])

  return (
    <Paper>
      <TableContainer
        className={classNames({
          [classes.tableContainer]: true,
          [classes.tableStickHeader]: true,
        })}
      >
        <Table stickyHeader size="medium" className={classes.tableStyle}>
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ width: '20%' }}>
                Atendente
              </TableCell>
              <TableCell align="left">Lavanderia</TableCell>
              <TableCell align="left">Nome do cliente</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Vendido</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map(lead => (
              <TableRow key={lead?.id} hover>
                <TableCell align="left">{lead?.attendant}</TableCell>
                <TableCell align="left">{lead?.company}</TableCell>
                <TableCell align="left">{lead?.client_name}</TableCell>
                <TableCell align="left">{lead?.state_name}</TableCell>
                <TableCell align="left">{lead?.sold}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={Number(meta?.total)}
          rowsPerPage={Number(meta?.per_page)}
          page={Number(meta?.current_page) - 1}
          labelRowsPerPage="Linhas por Página"
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          labelDisplayedRows={({ from, to, count }) =>
            `Mostrando linhas ${from}-${to} de ${count}`
          }
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onPageChange={(e, page) => handleChangePage(page + 1)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Paper>
  )
}

export default List
