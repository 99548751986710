/* eslint-disable no-shadow */
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import NoteAddIcon from '@material-ui/icons/NoteAdd'

import styles from '../../../resources/theme/global'
import { renderRoute } from '../../../util/utils'
import { fetchPaymentMethod } from '../PaymentTypeActions'
import { getPaymentMethodListData } from '../PaymentTypeReducers'
import { makeReport } from '../PaymentTypeActions'

import Edit from './Edit'
import Filter from './Filter'
import List from './List'
import New from './New'

class PaymentMethod extends Component {
  componentDidMount() {
    const { fetchPaymentMethod } = this.props
    fetchPaymentMethod()
  }

  render() {
    const { classes, permissions, filters} = this.props

    return (     
      <>
        <Grid
          container
          className={classes.pageHead}
          spacing={1}
          alignItems="center"
        >
          <Grid item xs>
            <Typography variant="h4" color="secondary">
              Métodos de pagamento
            </Typography>
          </Grid>
          <Grid item>
            <Filter classes={classes} />
          </Grid>
          <Grid item>
            <Button
              color="primary"
              type="button"
              onClick={() => this.props.makeReport(filters)}
            >
              <NoteAddIcon className={classes.btnIcon} /> Relatório
            </Button>
          </Grid>
          <Grid item>
            {renderRoute(['create-payment-methods'], permissions) && (
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to="/payment-method/new"
              >
                <AddCircleOutlineIcon className={classes.btnIcon} /> Cadastrar
              </Button>
            )}
          </Grid>
        </Grid>

        {renderRoute(['create-payment-methods'], permissions) && (
          <Route path="/payment-method/new" component={New} />
        )}
        {renderRoute(['update-payment-methods'], permissions) && (
          <Route path="/payment-method/:id/edit" component={Edit} />
        )}

        <List {...this.props} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...getPaymentMethodListData(state),  
  filters: state.paymentMethod.filters,
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPaymentMethod, makeReport
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PaymentMethod))
