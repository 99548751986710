import React from 'react'
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from 'material-ui-popup-state/hooks'
import { IconButton, Popover, withStyles } from '@material-ui/core'
import MailIcon from '@material-ui/icons/Mail'
import { GrDocumentPdf, GrDocumentWord, GrMailOption } from 'react-icons/gr'
import { useConfirm } from 'material-ui-confirm'
import { useDispatch } from 'react-redux'

import { sendMail, downloadOrcamento } from '../AttendanceActions'

const styles = theme => ({
  container: {
    margin: theme.spacing.unit * 2,
  },
})

const PopoverPopupState = ({ classes, id }) => {
  const confirm = useConfirm()
  const dispatch = useDispatch()

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  })

  const confirmSendMail = async () => {
    confirm({
      description: 'Deseja enviar este email?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      dispatch(sendMail(id))
    })
  }

  const confirmDownloadOrcamento = async type => {
    confirm({
      description: 'Deseja baixar este arquivo?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      dispatch(downloadOrcamento(id, type))
    })
  }

  return (
    <span>
      <IconButton {...bindTrigger(popupState)}>
        <MailIcon />
      </IconButton>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.container}>
          <IconButton
            onClick={() => {
              confirmDownloadOrcamento('docx')
              popupState.close()
            }}
          >
            <GrDocumentWord />
          </IconButton>
          <IconButton
            onClick={() => {
              confirmDownloadOrcamento('pdf')
              popupState.close()
            }}
          >
            <GrDocumentPdf />
          </IconButton>
          <IconButton
            onClick={() => {
              confirmSendMail()
              popupState.close()
            }}
          >
            <GrMailOption />
          </IconButton>
        </div>
      </Popover>
    </span>
  )
}

export default withStyles(styles)(PopoverPopupState)
