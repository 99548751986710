import React from 'react'
import {
  DialogActions,
  FormControlLabel,
  TextField as TextFieldOr,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUp'
import { Field, Formik, Form } from 'formik'
import { TextField, Checkbox } from 'formik-material-ui'
import * as yup from 'yup'

import NumberFormatCustom from '../../components/NumberFormat'

function BankForm(props) {
  const { classes, data, onClose, onSubmit } = props

  const handleClose = () => onClose && onClose()

  const initialValues = data || {
    id: '',
    name: '',
    status: 1,
    current_balance: 0,
    agency: '',
    account: '',
  }

  const schema = yup.object().shape({
    name: yup.string().required('Obrigatório'),
  })

  return (
    <DialogContent>
      <Formik
        key={initialValues.id}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false)
          onSubmit && onSubmit(values)
        }}
      >
        {({
          submitForm,
          isSubmitting,
          handleChange,
          touched,
          errors,
          values,
        }) => (
          <Form>
            <Grid container spacing={1}>
              <Grid item xs>
                <Field
                  component={TextField}
                  name="name"
                  label="Nome"
                  fullWidth
                  error={touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              {!values.id && (
                <Grid item xs>
                  <Field name="current_balance">
                    {({ field }) => (
                      <TextFieldOr
                        {...field}
                        label="Valor inicial"
                        onChange={handleChange('current_balance')}
                        fullWidth
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                    )}
                  </Field>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs>
                <Field
                  component={TextField}
                  name="agency"
                  label="Agência"
                  fullWidth
                  error={touched.agency && !!errors.agency}
                  helperText={touched.agency && errors.agency}
                />
              </Grid>

              <Grid item xs>
                <Field
                  component={TextField}
                  name="account"
                  label="Conta"
                  fullWidth
                  error={touched.account && !!errors.account}
                  helperText={touched.account && errors.account}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs>
                <FormControlLabel
                  control={
                    <Field
                      icon={<ThumbDownAltOutlinedIcon />}
                      checkedIcon={<ThumbUpAltOutlinedIcon color="secondary" />}
                      component={Checkbox}
                      type="checkbox"
                      name="status"
                    />
                  }
                  label="Ativo?"
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Salvar
              </Button>
              <Button onClick={handleClose}>Cancelar</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </DialogContent>
  )
}

export default BankForm
