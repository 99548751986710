import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import consts from '../../util/consts'
import createAsyncConst from '../../util/createAsyncConst'
import { SNACKBAR } from '../main/MainActions'

export const FETCH_LAUNDRY_DASHBOARD = createAsyncConst(
  'FETCH_LAUNDRY_DASHBOARD'
)

export const FETCH_HISTORIC_ITEM_LAUNDRY = createAsyncConst(
  'FETCH_HISTORIC_ITEM_LAUNDRY'
)

export function fetchDashboard(page, filters = {}, rows) {
  return dispatch => {
    dispatch(action(FETCH_LAUNDRY_DASHBOARD.ACTION))
    return axios
      .get(`${config.API_URL}/laundry/laundry-items`, {
        params: {
          filters: JSON.stringify(filters),
          rows: rows || consts.DEFAULT_PAGINATION,
          page: page || 1,
        },
      })
      .then(resp => {
        dispatch(
          action(FETCH_LAUNDRY_DASHBOARD.SUCCESS, { data: resp.data, filters })
        )
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_LAUNDRY_DASHBOARD, e)))
  }
}

export function getItemHistory(id) {
  return dispatch => {
    dispatch(action(FETCH_HISTORIC_ITEM_LAUNDRY.ACTION))
    return axios
      .get(`${config.API_URL}/service-item/history/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(FETCH_HISTORIC_ITEM_LAUNDRY.SUCCESS, data))
        return data
      })
      .catch(e => dispatch(actionFailed(FETCH_HISTORIC_ITEM_LAUNDRY, e)))
  }
}

export function clearFilters() {
  return {
    type: 'LAUNDRY_DASHBOARD_CLEAR_FILTERS',
  }
}

export function makeReport(data) {
  return dispatch => {
    return axios({
      url:
        `${config.API_URL}/laundry/laundry-items-report`,
        method: 'POST',
        responseType: 'blob',
        headers: { Accept: '*/*' },
        params: {
          filters: JSON.stringify(data),
        }      
      })
      .then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        
        link.href = url
        link.setAttribute('download', 'Relatorio Dashboard Lavanderia.xlsx')
        document.body.appendChild(link)
        link.click()
        return resp
      })
    .catch(e => dispatch({
      type: SNACKBAR.HARDFAIL,
      error: { message: 'Erro na geração do arquivo.' },
    }))
  }
}
