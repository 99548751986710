import React from 'react'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
  IconButton,
} from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { useDispatch, useSelector } from 'react-redux'

import { saveFilters } from '../../attendance/AttendanceActions'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56,
  },
  differenceIcon: {
    color: colors.green[600],
  },
  nullIcon: {
    color: colors.grey[600],
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1),
  },
  noDifference: {
    color: colors.grey[900],
    marginRight: theme.spacing(1),
  },
}))

const DashboardCard = ({ item, history }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const filters = useSelector(state => state.dashboard.filters)

  const makeRequest = id => {
    const infos = { ...filters, service_status_id: id }
    dispatch(saveFilters(infos))
    history.push('/attendance')
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              {item?.status?.name}
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {item?.qtd}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              variant="contained"
              onClick={() => makeRequest(item.service_status_id)}
            >
              <VisibilityIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default DashboardCard
