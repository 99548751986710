import React from 'react'
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { archive, fetchAttendanceInit } from '../AttendanceActions'
import styles from '../../../resources/theme/global'
import CloseIcon from '@material-ui/icons/Close'
import Transiction from '../../components/Transition'

const Transition = React.forwardRef((props, ref) => {
  return <Transiction propis={props} refi={ref} />
})

const ArchiveOrcament = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const archiveReason = useSelector(state => state.attendance.archiveReason)
  const sizePerPage = useSelector(state => state.attendance.sizePerPage)
  const filters = useSelector(state => state.attendance.filters)

  const { classes } = props

  const { id } = props.match.params

  const handleClose = () => {
    history.push('/attendance')
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const formData = e.currentTarget
    const data = new FormData(formData)
    const entries = data.entries()
    const values = Object.fromEntries(entries)

    try {
      const resp = await dispatch(archive(values, id))

      if (resp) {
        dispatch(fetchAttendanceInit(filters, sizePerPage))
        handleClose()
      }
    } catch (e) {
      //
    }
  }
  return (
    <Dialog
      open
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Arquivar OS
          </Typography>
          <IconButton color="inherit" onClick={handleClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            select
            name="archive_reason"
            variant="standard"
            fullWidth
            label="Motivo"
            required
          >
            <MenuItem value="">Selecione...</MenuItem>

            {archiveReason?.map((trigger, index) => (
              <MenuItem value={trigger?.id} key={index}>
                {trigger?.reason}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button color="primary" type="submit">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default withStyles(styles)(ArchiveOrcament)
