import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const REMOVE_BILLING_TYPE = createAsyncConst('REMOVE_BILLING_TYPE')
export const SUBMIT_BILLING_TYPE = createAsyncConst('SUBMIT_BILLING_TYPE')
export const FETCH_BILLING_TYPE = createAsyncConst('FETCH_BILLING_TYPE')
export const EDIT_BILLING_TYPE = createAsyncConst('EDIT_BILLING_TYPE')

export function fetchBillingType(filters = []) {
  return dispatch => {
    dispatch(action(FETCH_BILLING_TYPE.ACTION))
    return axios
      .get(`${config.API_URL}/billingType`, {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => {
        dispatch(action(FETCH_BILLING_TYPE.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_BILLING_TYPE, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_BILLING_TYPE.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/billingType/${id}`)
      .then(resp => {
        return resp.data.data
      })
      .then(data => dispatch(action(EDIT_BILLING_TYPE.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(EDIT_BILLING_TYPE, e)))
  }
}

function submit(values, method) {
  const id = values.id ? `/${values.id}` : ''

  return dispatch => {
    dispatch(action(SUBMIT_BILLING_TYPE.ACTION))
    return axios[method](`${config.API_URL}/billingType${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_BILLING_TYPE.SUCCESS, data))
        dispatch(fetchBillingType())
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_BILLING_TYPE, e)))
  }
}

export function post(values) {
  return submit(values, 'post')
}

export function update(values) {
  return submit(values, 'put')
}

export function remove(id) {
  return dispatch => {
    dispatch(action(REMOVE_BILLING_TYPE.ACTION))
    return axios
      .delete(`${config.API_URL}/billingType/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(REMOVE_BILLING_TYPE.SUCCESS, data))
        dispatch(fetchBillingType())
      })
      .catch(e => dispatch(actionFailed(REMOVE_BILLING_TYPE, e)))
  }
}
