import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import React, { Component, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import styles from '../../../resources/theme/global'
import { renderRoute, renderRouteRole } from '../../../util/utils'
import {
  fetchNfseCompanies,
  fetchServiceCategory,
} from '../EletronicNfseActions'
import { getServiceCatListData } from '../EletronicNfseReducer'
import Filter from './Filter'
import List from './List'
import createEdit from './create-edit'

const CompanyNfse = props => {
  const dispatch = useDispatch()

  const permissions = useSelector(state => state.auth.permissions)
  const role = useSelector(state => state.auth.role)

  const { classes } = props

  const [filter, setFilter] = useState({
    cnpj: '',
    company: '',
    page: 1,
    per_page: 10,
  })

  useEffect(() => {
    dispatch(fetchNfseCompanies(filter))
  }, [filter])

  return (
    <>
      <Grid
        container
        className={classes.pageHead}
        spacing={1}
        alignItems="center"
      >
        <Grid item xs>
          <Typography variant="h4" color="secondary">
            Companias para emitir nota
          </Typography>
        </Grid>
        <Grid item>
          <Filter classes={classes} form={filter} setForm={setFilter} />
        </Grid>

        <Grid item>
          {renderRoute(['create-users'], permissions) && (
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to="/company-nfse/create-edit"
            >
              <AddCircleOutlineIcon className={classes.btnIcon} /> Cadastrar
            </Button>
          )}
        </Grid>
      </Grid>

      {renderRouteRole(['lavanderia', 'administrador'], role) && (
        <Route path="/company-nfse/create-edit" component={createEdit} />
      )}

      <List classes={classes} form={filter} setForm={setFilter} />
    </>
  )
}

export default withStyles(styles)(CompanyNfse)
