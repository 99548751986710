import React from 'react'

import { remove } from '../PaymentTypeActions'
import GlobalList from '../../components/GlobalList'

function createData(id, name, quota, daysToDeadline, status) {
  return {
    id,
    name,
    quota,
    daysToDeadline,
    status,
    update: 'update-payment-methods',
    delete: 'delete-payment-methods',
    link_update: `/payment-method/${id}/edit`,
  }
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'quota',
    numeric: true,
    disablePadding: false,
    label: 'Parcelas',
  },
  {
    id: 'daysToDeadline',
    numeric: true,
    disablePadding: false,
    label: 'Dias p/ vencimento',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Ações',
  },
]

function List(props) {
  const { items } = props
  const rows = []

  items.map(item => {
    return rows.push(
      createData(
        item.id,
        item.name,
        item.quota,
        item.days_to_deadline,
        item.status_text
      )
    )
  })

  return (
    <GlobalList {...props} headCells={headCells} rows={rows} remove={remove} />
  )
}

export default List
