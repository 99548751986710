import { Grid } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import PrevIcon from '@material-ui/icons/NavigateBefore'
import NextIcon from '@material-ui/icons/NavigateNext'
import classnames from 'classnames'
import React from 'react'

const Pagination = props => {
  const { classes, elevation, prevPage, nextPage, text, disabled } = props

  return (
    <Paper
      className={classnames(classes.root, classes.grow)}
      elevation={elevation !== undefined ? elevation : 1}
    >
      <Toolbar>
        <div
          className={classnames({
            [classes.grow]: true,
          })}
        >
          <Typography
            className={classnames({
              [classes.grow]: true,
            })}
          />
        </div>
        <Grid container justify="space-between" alignItems="center">
          <IconButton onClick={prevPage} disabled={disabled}>
            <PrevIcon />
          </IconButton>
          <Typography variant="h6">{text}</Typography>

          <IconButton onClick={nextPage} disabled={disabled}>
            <NextIcon />
          </IconButton>
        </Grid>
      </Toolbar>
    </Paper>
  )
}

export default Pagination
