import React, { useEffect, useState } from 'react'
import EventIcon from '@material-ui/icons/Event'
import MailIcon from '@material-ui/icons/Mail'
import { useDispatch, useSelector } from 'react-redux'
import { useConfirm } from 'material-ui-confirm'
import { format } from 'date-fns'
import ArchiveIcon from '@material-ui/icons/Archive'

import {
  remove,
  fetchAttendanceInit,
  sendMail,
  eraseFilters,
  archive,
} from '../AttendanceActions'
import { exportFormatedMoney, renderRoute } from '../../../util/utils'
import consts from '../../../util/consts'

import GlobalList from './ListTable'

function createData(
  id,
  name,
  status,
  value,
  items,
  canRender,
  hasBilling,
  statusId,
  companyName,
  nfseStatus,
  nfseStatusId,
  nfse_company_id,
  paymentCompleted,
  // eslint-disable-next-line camelcase
  loss_reason_id
) {
  return {
    id,
    name,
    companyName,
    status,
    value,
    items,
    nfseStatus,
    nfseStatusId,
    nfse_company_id,
    hasBilling,
    statusId,
    update: 'update-attendances',
    delete: 'delete-attendances',
    finance: 'create-billing-client',
    link_update: `/attendance/${id}/edit`,
    link_finance: `/attendance/${id}/payment`,
    canRender,
    loss_reason_id,
    paymentCompleted,
  }
}

const headCells = [
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'companyName',
    numeric: true,
    disablePadding: false,
    label: 'Empresa',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'value',
    numeric: true,
    disablePadding: false,
    label: 'Valor',
  },
  {
    id: 'items',
    numeric: true,
    disablePadding: false,
    label: 'Items',
  },
  {
    id: 'nfseStatus',
    numeric: true,
    disablePadding: false,
    label: 'NFSe',
    align: 'center',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Ações',
  },
]

function List(props) {
  const { items, lossReasonId } = props
  const rows = []
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const sizePerPage = useSelector(state => state.attendance.sizePerPage)
  const page = useSelector(state => state.attendance.page)
  const totalSize = useSelector(state => state.attendance.totalSize)
  const [rowsPerPage, setRowsPerPage] = useState(sizePerPage)
  const filters = useSelector(state => state.attendance.filters)
  const permissions = useSelector(state => state.auth.permissions)

  items.map(item => {
    return rows.push(
      createData(
        item.id,
        item.client.name,
        item.status.name,
        exportFormatedMoney(item.total_with_discount),
        item.items.length,
        item.can_edit,
        item.has_billing,
        item.status.id,
        item.company_name,
        item?.nfse_status?.value,
        item?.nfse_status_id,
        item?.nfse_company_id,
        item.payment_completed,
        item.loss_reason_id
      )
    )
  })

  const onMovePage = async (event, newPage) => {
    const date = filters?.date
      ? format(new Date(filters.date), 'yyyy-MM-dd HH:mm')
      : null
    const data = { ...filters, date }
    const resp = await dispatch(
      fetchAttendanceInit(data, rowsPerPage, newPage + 1)
    )

    // if (resp?.status === 200) {
    //   dispatch(eraseFilters())
    // }
  }

  useEffect(() => {
    onMovePage()
  }, [rowsPerPage])

  useEffect(() => {
    return () => {
      dispatch(eraseFilters())
    }
  }, [])

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  const otherIcons = [
    {
      link: id => `/calendar/${id}?os=${id}`,
      ariaLabel: 'calendar',
      completeID: true,
      icon: <EventIcon />,
    },
  ]

  const otherIconsClick = [
    // {
    //   onClick: async id => {
    //     confirm({
    //       description: 'Deseja reenviar este email?',
    //       title: 'Tem certeza?',
    //       confirmationText: 'Sim',
    //       cancellationText: 'Cancelar',
    //       dialogProps: {
    //         fullWidth: true,
    //       },
    //     }).then(() => {
    //       const resp = dispatch(sendMail(id))
    //       if (resp?.code === 200) {
    //       }
    //     })
    //   },
    //   renderRule: id => {
    //     return true
    //   },
    //   ariaLabel: 'email',
    //   icon: <MailIcon />,
    // },
    {
      onClick: async id => {
        confirm({
          description: 'Deseja arquivar este orçamento?',
          title: 'Tem certeza?',
          confirmationText: 'Sim',
          cancellationText: 'Cancelar',
          dialogProps: {
            fullWidth: true,
          },
        }).then(async () => {
          const resp = await dispatch(archive(id))
          if (resp.status === 200) {
            onMovePage(null, page - 1)
          }
        })
      },
      renderRule: id => {
        return (
          id === consts.ORCAMENTO &&
          renderRoute(['archive-attendances'], permissions)
        )
      },
      ariaLabel: 'archive',
      icon: <ArchiveIcon />,
    },
  ]

  return (
    <GlobalList
      {...props}
      onMovePage={onMovePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      headCells={headCells}
      rows={rows}
      remove={remove}
      rowsPerPage={rowsPerPage}
      page={page - 1}
      totalSize={totalSize}
      otherIcons={otherIcons}
      otherIconsClick={otherIconsClick}
      lossReasonId={lossReasonId}
    />
  )
}

export default List
