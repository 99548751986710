/* eslint-disable import/order */
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import consts from '../../../util/consts'
import styles from '../../../resources/theme/users'
import Paginate from '../../components/Pagination'
import { team, makeRelatory } from '../TeamProdutivityRelatoryActions'
import { exportFormatedMoney } from '../../../util/utils'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import { Button, Slide } from '@material-ui/core'
import classNames from 'classnames'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

function Produtivity(props) {
  const dispatch = useDispatch()
  const [subRowsPerPage, setSubRowsPerPage] = useState(
    consts.DEFAULT_PAGINATION
  )
  const { classes } = props

  const subItems = useSelector(
    state => state.team_produtivity_relatory.subItems
  )
  const subTotalSize = useSelector(
    state => state.team_produtivity_relatory.subTotalSize
  )
  const items = useSelector(state => state.team_produtivity_relatory.items)
  const subPage = useSelector(state => state.team_produtivity_relatory.subPage)
  const filters = useSelector(state => state.team_produtivity_relatory.filters)

  const onMovePage = (event, newPage) => {
    dispatch(team(props.match.params.id, newPage + 1, subRowsPerPage, filters))
  }

  useEffect(() => {
    onMovePage()
  }, [subRowsPerPage])

  const handleChangeRowsPerPage = event => {
    setSubRowsPerPage(event.target.value)
  }

  const onClose = () => {
    props.history.push('/relatory-team-produtivity')
  }

  const makeReport = () => {
    const { id } = props.match.params
    const { team } = items.find(item => item.id == id)
    dispatch(
      makeRelatory(
        filters,
        `make-relatory-team-produtivity/${id}`,
        `${team.replace(' ', '_')}_productivity_relatory`
      )
    )
  }

  return (
    <Dialog fullScreen open onClose={onClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Equipe
          </Typography>
          <Button color="inherit" onClick={makeReport}>
            <NoteAddIcon className={classes.btnIcon} /> Relatório
          </Button>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Paper>
        <TableContainer
          className={classNames({
            [classes.tableContainer]: true,
            [classes.tableStickHeader]: true,
          })}
        >
          <Table stickyHeader size="medium" className={classes.tableStyle}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Tipo de Serviço</TableCell>
                <TableCell>Nome do Cliente</TableCell>
                <TableCell>Endereço</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Valor do Técnico</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subItems.map(item => (
                <TableRow key={item.id}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.date}</TableCell>
                  <TableCell>{item.type}</TableCell>
                  <TableCell>{item.client_name}</TableCell>
                  <TableCell>{item.address}</TableCell>
                  <TableCell>{exportFormatedMoney(item.value)}</TableCell>
                  <TableCell>
                    {exportFormatedMoney(item.technician_value)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Paginate
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={subRowsPerPage}
          rows={subTotalSize}
          page={subPage - 1}
          handleChangePage={onMovePage}
        />
      </Paper>
    </Dialog>
  )
}

export default withStyles(styles)(Produtivity)
