import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Grid, Slide } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { DateTimePicker } from 'formik-material-ui-pickers'
import { Autocomplete } from 'formik-material-ui-lab'
import MuiTextField from '@material-ui/core/TextField'
import { useDispatch } from 'react-redux'
import { addDays, format, setHours, setMinutes } from 'date-fns'

import { newDetachedCalendar } from '../CalendarActions'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function DetachedForm({ open, handleClose, attendants, reloadBookedEvents }) {
  const dispatch = useDispatch()
  const startDate = setMinutes(setHours(addDays(new Date(), 1), 8), 0)
  const finalDate = setMinutes(setHours(addDays(new Date(), 1), 18), 0)

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        aria-labelledby="form-detached-callendar-title"
        TransitionComponent={Transition}
      >
        <DialogTitle id="form-detached-callendar-title">
          Novo Agendamento
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              attendant: null,
              startDate,
              finalDate,
            }}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false)
              const data = {
                ...values,
                startDate: format(values.startDate, 'yyyy-MM-dd HH:mm'),
                finalDate: format(values.finalDate, 'yyyy-MM-dd HH:mm'),
              }
              dispatch(newDetachedCalendar(data)).then(() => {
                handleClose()
                reloadBookedEvents()
              })
            }}
            validate={values => {
              const errors = {}
              if (!values.attendant) {
                errors.attendant = 'Obrigatório'
              }
              if (!values.startDate) {
                errors.startDate = 'Obrigatório'
              }
              if (!values.finalDate) {
                errors.finalDate = 'Obrigatório'
              }
              return errors
            }}
          >
            {({ submitForm, isSubmitting, touched, errors }) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Field
                      name="attendant"
                      component={Autocomplete}
                      options={attendants}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={option =>
                        typeof option === 'string' ? option : option.name
                      }
                      renderInput={params => (
                        <MuiTextField
                          {...params}
                          error={touched.attendant && !!errors.attendant}
                          helperText={touched.attendant && errors.attendant}
                          label="Atendente"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Field
                      component={DateTimePicker}
                      name="startDate"
                      clearable
                      minDateMessage="A data mínima é a atual"
                      clearLabel="Limpar"
                      disablePast
                      label="Data inicial"
                      autoOk
                      fullWidth
                      ampm={false}
                      format="dd/MM/yyyy HH:mm"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Field
                      component={DateTimePicker}
                      name="finalDate"
                      clearable
                      minDateMessage="A data mínima é a atual"
                      clearLabel="Limpar"
                      disablePast
                      label="Data final"
                      autoOk
                      fullWidth
                      ampm={false}
                      format="dd/MM/yyyy HH:mm"
                    />
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    onClick={submitForm}
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Salvar
                  </Button>
                  <Button onClick={handleClose}>Cancelar</Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default React.memo(DetachedForm)
