import React from 'react'

import { remove } from '../BankActions'
import GlobalList from '../../components/GlobalList'

function createData(id, name, status, agency, account) {
  return {
    id,
    name,
    status,
    agency,
    account,
    update: 'update-bank',
    delete: 'delete-bank',
    link_update: `/bank/${id}/edit`,
  }
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'agency',
    numeric: true,
    disablePadding: false,
    label: 'Agência',
  },
  {
    id: 'account',
    numeric: true,
    disablePadding: false,
    label: 'Conta',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Ações',
  },
]

function List(props) {
  const { items } = props
  const rows = []

  items.map(item => {
    return rows.push(
      createData(item.id, item.name, item.status, item.agency, item.account)
    )
  })

  return (
    <GlobalList
      {...props}
      headCells={headCells}
      rows={rows}
      remove={remove}
      orderField="id"
    />
  )
}

export default List
