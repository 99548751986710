import { EDIT_LOSS_REASON, FETCH_LOSS_REASON } from './LossReasonActions'

const INITIAL_STATE = {
  items: [],
  meta: {},
  filters: {},
  editingLossReason: null,
  triggerTypes: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LOSS_REASON.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        meta: action.payload.data.meta,
        triggerTypes: action.payload.data.types,
      }
    case EDIT_LOSS_REASON.SUCCESS:
      return {
        ...state,
        editingLossReason: action.payload,
      }
    default:
      return state
  }
}
