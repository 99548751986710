/* eslint-disable react/sort-comp */
/* eslint-disable import/order */
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import { connect } from 'react-redux'

import styles from '../../../resources/theme/users'
import { edit, update } from '../AttendanceActions'
import Form from './Form'
import { bindActionCreators } from 'redux'
import { Slide } from '@material-ui/core'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

class EditAttendance extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = { attendance: null }
  }

  async refresh() {
    const { edit, match } = this.props
    const resp = await edit(match.params.id)
    if (resp?.code === 200) {
      const { data } = resp

      const editingAttendance = {
        ...data,
        final_value_discount: data.total_with_discount,
        services: data.services.map(_item => {
          let item = { ..._item }
          if (item?.measureType.id === 1)
            item = { ...item, quantity: parseInt(item.quantity, 10) }
          item = {
            ...item,
            technician_default: item.serviceType.technician_value,
          }
          return item
        }),
      }

      this.setState({ attendance: editingAttendance })
    }
  }

  componentDidMount() {
    const { match } = this.props

    window.Echo.private(`Attendance.${match.params.id}`).listen(
      `AttendanceHasUpdate`,
      () => {
        document.location.reload()
        // this.refresh()
      }
    )

    this.refresh()
  }

  componentWillUnmount() {
    const { match } = this.props
    window.Echo.leave(`Attendance.${match.params.id}`)
  }

  onSubmit = async (values, redirect) => {
    const { update, history } = this.props

    const data = await update(values)
    if (data?.code === 200) {
      if (redirect) {
        history.push(`/calendar/${data.data.id}`)
      } else {
        history.push('/attendance')
      }
    }
  }

  onClose = () => {
    const { history } = this.props
    history.push('/attendance')
  }

  render() {
    const { classes } = this.props
    const { attendance } = this.state
    return (
      <Dialog
        fullScreen
        open={attendance !== null}
        onClose={this.onClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Editar Atendimento
            </Typography>
            <IconButton
              color="inherit"
              onClick={this.onClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Form
          data={attendance}
          classes={classes}
          onClose={this.onClose}
          onSubmit={this.onSubmit}
        />
      </Dialog>
    )
  }
}

// export default withStyles(styles)(
//   React.memo(EditType, (props, nextProps) => {
//     console.log(props)
//     return true
//   })
// )

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      edit,
      update,
    },
    dispatch
  )

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(EditAttendance))
