import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const EDIT_ATTENDANCE_LAUNDRY = createAsyncConst(
  'EDIT_ATTENDANCE_LAUNDRY'
)
export const UPDATE_ATTENDANCE_LAUNDRY = createAsyncConst(
  'UPDATE_ATTENDANCE_LAUNDRY'
)
export const FETCH_ATTENDANCE_LAUNDRY = createAsyncConst(
  'FETCH_ATTENDANCE_LAUNDRY'
)
export const FETCH_OSS_REPORT = createAsyncConst('FETCH_OSS_REPORT')
export const CLOSE_LAUNDRY_OS = createAsyncConst('CLOSE_LAUNDRY_OS')
export const CLOSE_ALL_LAUNDRY_OS = createAsyncConst('CLOSE_ALL_LAUNDRY_OS')
export const TOOGLE_URGENT = createAsyncConst('TOOGLE_URGENT')
export const DELETE_ITEM = createAsyncConst('DELETE_ITEM')
export const TOOGLE_SHOW_IMAGES = createAsyncConst('TOOGLE_SHOW_IMAGES')
export const TOOGLE_PERSONALIZED = createAsyncConst('TOOGLE_PERSONALIZED')
export const TOOGLE_REWORK = createAsyncConst('TOOGLE_REWORK')
export const FETCH_DOCUMENTS = createAsyncConst('FETCH_DOCUMENTS')
export const DOWNLOAD_BOLETO = createAsyncConst('DOWNLOAD_BOLETO')
export const SEND_MAIL_BILLING = createAsyncConst('SEND_MAIL_BILLING')
export const TOOGLE_DELIVERY = createAsyncConst('TOOGLE_DELIVERY')
export const REOPEN_LAUNDRY_OS = createAsyncConst('REOPEN_LAUNDRY_OS')
export const CHANGE_ITEM_FROM_OS = createAsyncConst('CHANGE_ITEM_FROM_OS')

export function fetchAttendanceLaundry(filters = {}, rows, page) {
  return dispatch => {
    dispatch(action(FETCH_ATTENDANCE_LAUNDRY.ACTION))
    return axios
      .get(`${config.API_URL}/laundryService`, {
        params: {
          filters: JSON.stringify(filters),
          rows,
          page: page || 1,
        },
      })
      .then(resp => {
        dispatch(
          action(FETCH_ATTENDANCE_LAUNDRY.SUCCESS, { data: resp.data, filters })
        )
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_ATTENDANCE_LAUNDRY, e)))
  }
}

export function edit(id, filters = {}) {
  return dispatch => {
    dispatch(action(EDIT_ATTENDANCE_LAUNDRY.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/laundryService/${id}`, {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(EDIT_ATTENDANCE_LAUNDRY.SUCCESS, data))
        return data
      })
      .catch(e => dispatch(actionFailed(EDIT_ATTENDANCE_LAUNDRY, e)))
  }
}

export function update(id, data) {
  return dispatch => {
    dispatch(action(UPDATE_ATTENDANCE_LAUNDRY.ACTION, { id }))
    return axios
      .put(`${config.API_URL}/laundry-service-item/${id}`, data)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(UPDATE_ATTENDANCE_LAUNDRY.SUCCESS, data))
        return data
      })
      .catch(e => dispatch(actionFailed(UPDATE_ATTENDANCE_LAUNDRY, e)))
  }
}

export function exportOSsRelatory(filter, url) {
  return dispatch => {
    dispatch(action(FETCH_OSS_REPORT.ACTION))
    return axios({
      url: `${config.API_URL}/${url}`,
      method: 'POST',
      responseType: 'blob',
      headers: { Accept: 'application/vnd.ms-excel' },
      data: filter,
    })
      .then(response => {
        dispatch(action(FETCH_OSS_REPORT.SUCCESS, {}))
        const blob = new Blob([response.data], { type: response.data.type })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        const contentDisposition = response.headers['content-disposition']
        let fileName = 'unknown'
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename=(.+)/)
          if (fileNameMatch.length === 2) {
            fileName = fileNameMatch[1].replaceAll('"', '')
          }
        }
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
      })
      .catch(e => {
        dispatch(actionFailed(FETCH_OSS_REPORT, e))
      })
  }
}

export function clearFilters() {
  return {
    type: 'ATTENDANCE_LAUNDRY_CLEAR_FILTERS',
  }
}

export function closeLaundryOS(id, values) {
  return dispatch => {
    dispatch(action(CLOSE_LAUNDRY_OS.ACTION, { id }))
    return axios
      .post(`${config.API_URL}/laundryService/close/${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(CLOSE_LAUNDRY_OS.SUCCESS, data))
        return data
      })
      .catch(e => dispatch(actionFailed(CLOSE_LAUNDRY_OS, e)))
  }
}

export function closeAllLaundryOSs() {
  return dispatch => {
    dispatch(action(CLOSE_ALL_LAUNDRY_OS.ACTION))
    return axios
      .post(`${config.API_URL}/laundryService/closeAll`)
      .then(data => {
        dispatch(action(CLOSE_ALL_LAUNDRY_OS.SUCCESS, data))
        return data
      })
      .catch(e => dispatch(actionFailed(CLOSE_ALL_LAUNDRY_OS, e)))
  }
}

export function toogleUrgent(id) {
  return dispatch => {
    dispatch(action(TOOGLE_URGENT.ACTION))
    return axios
      .post(`${config.API_URL}/laundryServiceItem/toogleUrgent/${id}`)
      .then(data => {
        dispatch(action(TOOGLE_URGENT.SUCCESS, data))
        return data
      })
      .catch(e => dispatch(actionFailed(TOOGLE_URGENT, e)))
  }
}

export function deleteItem(id) {
  return dispatch => {
    dispatch(action(DELETE_ITEM.ACTION))
    return axios
      .delete(`${config.API_URL}/laundryServiceItem/${id}`)
      .then(data => {
        dispatch(action(DELETE_ITEM.SUCCESS, data))
        return data
      })
      .catch(e => dispatch(actionFailed(DELETE_ITEM, e)))
  }
}

export function toogleShowImages(id) {
  return dispatch => {
    dispatch(action(TOOGLE_SHOW_IMAGES.ACTION))
    return axios
      .post(`${config.API_URL}/laundryServiceItem/toogle-show-images/${id}`)
      .then(data => {
        dispatch(action(TOOGLE_SHOW_IMAGES.SUCCESS, { id }))
        return data
      })
      .catch(e => dispatch(actionFailed(TOOGLE_SHOW_IMAGES, e)))
  }
}

export function toogleIsRework(id) {
  return dispatch => {
    dispatch(action(TOOGLE_SHOW_IMAGES.ACTION))
    return axios
      .post(`${config.API_URL}/laundryServiceItem/toogle-rework/${id}`)
      .then(data => {
        dispatch(action(TOOGLE_SHOW_IMAGES.SUCCESS, { id }))
        return data
      })
      .catch(e => dispatch(actionFailed(TOOGLE_SHOW_IMAGES, e)))
  }
}

export function toogleIsPersonalized(id) {
  return dispatch => {
    dispatch(action(TOOGLE_PERSONALIZED.ACTION))
    return axios
      .post(`${config.API_URL}/laundryServiceItem/toogle-personalized/${id}`)
      .then(data => {
        dispatch(action(TOOGLE_PERSONALIZED.SUCCESS, { id }))
        return data
      })
      .catch(e => dispatch(actionFailed(TOOGLE_PERSONALIZED, e)))
  }
}

export function fetchDocuments(id) {
  return dispatch => {
    dispatch(action(FETCH_DOCUMENTS.ACTION))
    return axios
      .get(`${config.API_URL}/laundryService/documents/${id}`)
      .then(data => {
        dispatch(action(FETCH_DOCUMENTS.SUCCESS, { id }))
        return data
      })
      .catch(e => dispatch(actionFailed(FETCH_DOCUMENTS, e)))
  }
}

export function updateServiceBill(id, values) {
  return dispatch => {
    dispatch(action(FETCH_DOCUMENTS.ACTION))

    const formData = new FormData()

    values.files.length &&
      Object.values(values.files).map(key => {
        formData.append(`files[]`, key)
      })

    return axios
      .post(`${config.API_URL}/laundryService/documents/${id}`, formData)
      .then(resp => {
        dispatch(action(FETCH_DOCUMENTS.SUCCESS, { id, data: resp.data }))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_DOCUMENTS, e)))
  }
}

export function download(id) {
  return dispatch => {
    dispatch(action(DOWNLOAD_BOLETO.ACTION))
    return axios({
      url: `${config.API_URL}/laundryService/documents/${id}/download`,
      method: 'get',
      responseType: 'blob',
      headers: { Accept: 'application/zip' },
    })
      .then(resp => {
        dispatch(action(DOWNLOAD_BOLETO.SUCCESS, { id }))
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')

        link.href = url
        link.setAttribute('download', `boletos_os_${id}.zip`)
        document.body.appendChild(link)
        link.click()
        return resp
      })
      .catch(e => dispatch(actionFailed(DOWNLOAD_BOLETO, e)))
  }
}

export function sendMail(id) {
  return dispatch => {
    dispatch(action(SEND_MAIL_BILLING.ACTION))
    return axios
      .get(`${config.API_URL}/laundryService/${id}/send-mail-billing`)
      .then(resp => {
        dispatch(action(SEND_MAIL_BILLING.SUCCESS, { id, data: resp.data }))
        return resp
      })
      .catch(e => dispatch(actionFailed(SEND_MAIL_BILLING, e)))
  }
}

export function toogleIsDelivery(id, values = {}) {
  return dispatch => {
    dispatch(action(TOOGLE_DELIVERY.ACTION))
    return axios
      .post(
        `${config.API_URL}/laundryServiceItem/toogle-delivery/${id}`,
        values
      )
      .then(data => {
        dispatch(action(TOOGLE_DELIVERY.SUCCESS, { id }))
        return data
      })
      .catch(e => dispatch(actionFailed(TOOGLE_DELIVERY, e)))
  }
}

export function reopenOS(id) {
  return dispatch => {
    dispatch(action(REOPEN_LAUNDRY_OS.ACTION))
    return axios
      .get(`${config.API_URL}/laundryService/${id}/reopen`)
      .then(resp => {
        dispatch(action(REOPEN_LAUNDRY_OS.SUCCESS, { id, data: resp.data }))
        return resp
      })
      .catch(e => dispatch(actionFailed(REOPEN_LAUNDRY_OS, e)))
  }
}

export function changeItemFromOs(itemId, newOs) {
  return dispatch => {
    dispatch(action(CHANGE_ITEM_FROM_OS.ACTION))
    return axios
      .get(`${config.API_URL}/laundryServiceItem/${itemId}/changeOS/${newOs}`)
      .then(resp => {
        dispatch(action(CHANGE_ITEM_FROM_OS.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(CHANGE_ITEM_FROM_OS, e)))
  }
}
