/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/order */
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip'
import EmailCheck from '@material-ui/icons/Check'
import Email from '@material-ui/icons/Email'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import styles from '../../../resources/theme/auth'
import EnhancedComponent from '../../components/EnhancedComponent'
import * as AuthActions from '../AuthActions'
import Reset from './Reset'

class Login extends EnhancedComponent {
  state = {
    fields: {
      email: '',
      password: '',
      showPassword: false,
      showForgot: false,
    },
    failLogin: false,
    initialAnimation: true,
  }

  onSubmit = event => {
    event.preventDefault()
    this.props.login(this.state.fields).then(resp => {
      if (!resp) {
        this.setState(
          prevState => ({
            ...prevState,
            initialAnimation: false,
          }),
          () => {
            this.setState(
              prevState => ({ ...prevState, failLogin: true }),
              () => {
                setTimeout(() => {
                  this.setState(prevState => ({
                    ...prevState,
                    failLogin: false,
                  }))
                }, 500)
              }
            )
          }
        )
      }
    })
  }

  handleMouseDownPassword = event => {
    event.preventDefault()
  }

  handleClickShowPassword = () => {
    this.setState({
      fields: {
        ...this.state.fields,
        showPassword: !this.state.fields.showPassword,
      },
    })
  }

  clickForgot = () => {
    this.setState({
      fields: {
        ...this.state.fields,
        showForgot: !this.state.fields.showForgot,
      },
    })
  }

  verifyEmail = () => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(this.state.fields.email).toLowerCase())
  }

  render() {
    const { classes, loading } = this.props
    return (
      <>
        {!this.state.fields.showForgot && (
          <form
            id="login-form"
            onSubmit={this.onSubmit}
            className={classNames({
              [classes.animationNono]: this.state.failLogin,
              [classes.animationFade]: this.state.initialAnimation,
              [classes.main]: true,
            })}
          >
            <img
              className={classes.logo}
              src="/img/logo.png"
              alt="Logo Tapetex"
            />
            <Paper className={classes.paper}>
              <FormControl className={classes.fieldMargin}>
                <InputLabel htmlFor="adornment-email">E-mail</InputLabel>
                <Input
                  required
                  id="adornment-email"
                  type="email"
                  tabIndex="1"
                  value={this.state.fields.email}
                  onChange={this.onChange('email')}
                  endAdornment={
                    <InputAdornment position="end">
                      {this.verifyEmail() ? (
                        <Tooltip title="Email Ok" aria-label="Email">
                          <IconButton aria-label="user field">
                            <EmailCheck />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Digite um email" aria-label="Email">
                          <IconButton aria-label="user field">
                            <Email />
                          </IconButton>
                        </Tooltip>
                      )}
                    </InputAdornment>
                  }
                />
              </FormControl>

              <FormControl className={classes.fieldMargin}>
                <InputLabel htmlFor="adornment-password">Senha</InputLabel>
                <Input
                  id="adornment-password"
                  tabIndex="2"
                  type={this.state.fields.showPassword ? 'text' : 'password'}
                  value={this.state.fields.password}
                  onChange={this.onChange('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <Tooltip
                        title={
                          !this.state.fields.showPassword
                            ? 'Revelar senha'
                            : 'Esconder Senha'
                        }
                        aria-label="password"
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.fields.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {loading ? (
                  <CircularProgress size={22} className={classes.circular} />
                ) : (
                  'Entrar'
                )}
              </Button>

              <Link
                href="#"
                align="left"
                onClick={() => this.clickForgot()}
                className={classes.link}
              >
                Esqueci minha senha
              </Link>
            </Paper>
          </form>
        )}

        {this.state.fields.showForgot && (
          <Reset onclickBack={this.clickForgot} />
        )}
      </>
    )
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
}

// const mapStateToProps = () => ({})
const mapStateToProps = state => ({ loading: state.auth.loading })

const mapDispatchToProps = dispatch => {
  return bindActionCreators(AuthActions, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Login))
