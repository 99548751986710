/* eslint-disable jsx-a11y/anchor-is-valid */
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'

import styles from '../../../resources/theme/auth'
import EnhancedComponent from '../../components/EnhancedComponent'
import * as AuthActions from '../AuthActions'

class Reset extends EnhancedComponent {
  state = {
    fields: {
      email: '',
    },
  }

  onSubmit = event => {
    event.preventDefault()
    this.props.forgotPassword(this.state.fields).then(resp => {
      if (resp?.code === 201) {
        this.props.onclickBack()
      }
    })
  }

  render() {
    const { classes } = this.props
    return (
      <form id="reset-form" onSubmit={this.onSubmit} className={classes.main}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            Redefinir Senha
          </Typography>

          <FormControl className={classes.fieldMargin}>
            <InputLabel htmlFor="adornment-email">E-mail</InputLabel>
            <Input
              required
              id="adornment-email"
              type="email"
              value={this.state.fields.email}
              onChange={this.onChange('email')}
            />
          </FormControl>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Enviar
          </Button>

          <Link
            href="#"
            align="left"
            onClick={() => this.props.onclickBack()}
            className={classes.link}
          >
            Voltar ao Login
          </Link>
        </Paper>
      </form>
    )
  }
}

Reset.propTypes = {
  login: PropTypes.func.isRequired,
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(AuthActions, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(Reset)))
