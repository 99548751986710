/* eslint-disable react/prop-types */
import { Chip } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React from 'react'

export default function AutoComplete(props) {
  const {
    options,
    noOptions,
    data,
    updateFunction,
    readOnly,
    placeholder,
    label,
    style,
    limitTags,
    name,
  } = props

  return (
    <Autocomplete
      multiple
      disabled={readOnly}
      autoComplete
      blurOnSelect
      id={label}
      options={options}
      noOptionsText={noOptions || 'Sem opções'}
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.name || option.description
      }
      value={data}
      limitTags={limitTags}
      onChange={(event, value) => updateFunction(value, name)}
      getOptionSelected={(option, values) => option.id === values.id}
      filterSelectedOptions
      openText="Abrir"
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option.name}
            size="medium"
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder={placeholder}
          fullWidth
          style={style}
        />
      )}
    />
  )
}
