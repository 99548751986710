import {
  EDIT_SERVICE_CATEGORY,
  FETCH_SERVICE_CATEGORY,
} from './ServiceCategoryActions'

const INITIAL_STATE = {
  items: [],
  filters: [],
  editingServiceCategory: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SERVICE_CATEGORY.SUCCESS:
      return {
        ...state,
        items: action.payload.data,
      }
    case EDIT_SERVICE_CATEGORY.SUCCESS:
      return {
        ...state,
        editingServiceCategory: action.payload,
      }
    default:
      return state
  }
}

export const getServiceCatListData = state => {
  return {
    items: state.serviceCategory.items,
  }
}

export const getEditingServiceCat = state => {
  return state.serviceCategory.editingServiceCategory
}
