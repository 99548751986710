/* eslint-disable import/order */
/* eslint-disable import/extensions */
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from '../../../../resources/theme/global'
import Transiction from '../../../components/Transition'
import Form from './Form'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const Transition = React.forwardRef((props, ref) => {
  return <Transiction propis={props} refi={ref} />
})

const CreateEdit = props => {
  const history = useHistory()

  const { classes } = props

  const onClose = () => {
    history.push('/company-nfse')
  }

  return (
    <Dialog
      open
      maxWidth="md"
      fullWidth
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Cadastrar Compania
          </Typography>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Form classes={classes} />
    </Dialog>
  )
}

export default withStyles(styles)(CreateEdit)
